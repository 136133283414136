import { Response } from '../utils/types/general/api';
import { createClient } from './client';
import {
  AccountStats,
  ESPLinkCreationProps,
  UserESPLink,
} from '../utils/types/ESP';

const espAxiosAPI = createClient('esp');

export const getById = async (espLinkId: number): Response<UserESPLink> => {
  return espAxiosAPI.get(`/${espLinkId}`);
};

export const getAccountActions = async (
  url: string,
): Response<AccountStats> => {
  return espAxiosAPI.get(`/accounts/info/${url}`);
};

export const create = async (data: ESPLinkCreationProps): Response<string> => {
  return espAxiosAPI.post('/user_link', data);
};

export const setCookie = async (accountId: string): Response<void> => {
  return espAxiosAPI.get(`/cookie/${accountId}`);
};
