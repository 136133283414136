import React, { CSSProperties, FC } from 'react';
import styles from './Divider.module.scss';
import classnames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  extraStyles?: CSSProperties;
  type?: 'vertical' | 'horizontal';
}

export const Divider: FC<Props> = ({ extraStyles, type, ...props }) => {
  if (type === 'vertical') {
    return (
      <div
        {...props}
        style={extraStyles}
        className={classnames(
          styles.divider,
          styles.divider__vertical,
          props.className,
        )}
      />
    );
  }

  return <div className={styles.divider} style={extraStyles} />;
};
