import React from 'react';

import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { BaseLayout } from './components/BaseLayout/BaseLayout';
import { RequireAuth } from './components/common/RequireAuth';
import { SmartLinkPage } from './pages/CreateDataURLPage';
import { GenerateLeadFormPage } from './pages/GenerateLeadFormPage';
import { MailchimpAccountActionsPage } from './pages/MailchimpAccountActionsPage/MailchimpAccountActionsPage';
import { MailchimpAccountDetailsPage } from './pages/MailchimpAccountDetailsPage/MailchimpAccountDetailsPage';
import { MailchimpAccountsPage } from './pages/MailchimpAccountsPage/MailchimpAccountsPage';
import { MailchimpConnectPage } from './pages/MailchimpConnectPage/MailchimpConnectPage';
import { MailchimpConnectingLoader } from './pages/MailchimpConnectPage/MailchimpConnectingLoader';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { SigninLoader } from './pages/StartScreenPage/SigninLoader';
import { StartScreenPage } from './pages/StartScreenPage/StartScreenPage';
import { SupportPage } from './pages/SupportPage/SupportPage';
import { SurveysEditorPage } from './pages/SurveyEditorPage/SurveyEditorPage';
import { UserAccountPage } from './pages/UserAccountPage/UserAccountPage';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/accounts' replace />,
  },
  {
    path: '/signin',
    element: <StartScreenPage />,
  },
  {
    path: '/signin/loading',
    element: <SigninLoader />,
  },
  {
    path: '/login/mailchimp',
    element: (
      <RequireAuth>
        <BaseLayout>
          <MailchimpConnectPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  {
    path: '/login/mailchimp/loading',
    element: (
      <RequireAuth>
        <MailchimpConnectingLoader />
      </RequireAuth>
    ),
  },
  {
    path: '/accounts',
    element: (
      <RequireAuth>
        <BaseLayout>
          <MailchimpAccountsPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  {
    path: '/accounts/:accountId',
    element: (
      <RequireAuth>
        <BaseLayout withReturnBackButton>
          <MailchimpAccountDetailsPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  {
    path: '/actions/:accountId/:audienceId',
    element: (
      <RequireAuth>
        <BaseLayout withReturnBackButton padding={0}>
          <MailchimpAccountActionsPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  {
    path: '/new/data-url/:accountId/:audienceId',
    element: (
      <RequireAuth>
        <BaseLayout withReturnBackButton>
          <SmartLinkPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  {
    path: '/new/survey/:accountId/:audienceId/:surveyId',
    element: (
      <RequireAuth>
        <SurveysEditorPage />
      </RequireAuth>
    ),
  },
  {
    path: '/new/form/:accountId/:audienceId',
    element: (
      <RequireAuth>
        <GenerateLeadFormPage />
      </RequireAuth>
    ),
  },
  // {
  //   path: '/privacy-policy',
  //   element: (
  //     <BaseLayout withReturnBackButton>
  //       <PrivacyPolicy />
  //     </BaseLayout>
  //   ),
  // },
  // {
  //   path: '/terms-of-service',
  //   element: (
  //     <BaseLayout withReturnBackButton>
  //       <TermsOfService />
  //     </BaseLayout>
  //   ),
  // },
  {
    path: '/support',
    element: (
      <RequireAuth>
        <BaseLayout withReturnBackButton>
          <SupportPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  {
    path: '/user/account',
    element: (
      <RequireAuth>
        <BaseLayout withReturnBackButton>
          <UserAccountPage />
        </BaseLayout>
      </RequireAuth>
    ),
  },
  // {
  //   path: '/pricing',
  //   element: (
  //     <RequireAuth>
  //       <BaseLayout>
  //         <SubscriptionPlansPage />
  //       </BaseLayout>
  //     </RequireAuth>
  //   ),
  // },
  {
    path: '*',
    element: (
      <BaseLayout withReturnBackButton padding={0}>
        <NotFoundPage />
      </BaseLayout>
    ),
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
