import React, { FC, useState } from 'react';
import { Divider, FlexContainer, If } from '../common';
import { AccountActionModal } from './AccountActionModal';

import { AccountStatsInfo } from 'src/utils/types/Mailchimp';
import { ModalActionEnum } from 'src/utils/types/general/enums';

import { ActionButton } from './ActionButton';
import styles from './index.module.scss';

interface Props {
  item: AccountStatsInfo;
}

export const MailchimpAccountActionButtons: FC<Props> = ({ item }) => {
  const [modalType, setModalType] = useState(ModalActionEnum.NONE);

  const handleModalClose = () => {
    setModalType(ModalActionEnum.NONE);
  };

  return (
    <FlexContainer direction='row' gap={30} className={styles.button_container}>
      {Object.values(ModalActionEnum)
        .slice(-3)
        .map((value, index) => (
          <>
            <If condition={index !== 0}>
              <Divider type='vertical' className={styles.divider} />
            </If>

            <ActionButton
              key={value}
              type={value}
              handleModalTypeChange={setModalType}
            />
          </>
        ))}

      <AccountActionModal
        item={item}
        modalType={modalType}
        handleModalClose={handleModalClose}
      />
    </FlexContainer>
  );
};
