import React, { CSSProperties, FC, HTMLProps } from 'react';
import styles from './Switch.module.scss';
import { FlexContainer } from '../FlexContainer/FlexContainer';

type InputProps = Omit<HTMLProps<HTMLInputElement>, 'value' | 'onChange'>;

interface Props extends InputProps {
  value: boolean;
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  size?: number;
  extraStyles?: CSSProperties;
  extraLabelStyles?: CSSProperties;
}

export const Switch: FC<Props> = ({
  value,
  onChange,
  label,
  size,
  extraStyles,
  extraLabelStyles,
  ...props
}) => {
  return (
    <label className={styles.switch} style={{ ...extraStyles, fontSize: size }}>
      <FlexContainer>
        <input
          type='checkbox'
          checked={value}
          className={styles.switch__input}
          onChange={() => onChange(prev => !prev)}
          {...props}
        />

        <span className={styles.switch__slider} />
      </FlexContainer>

      <p className={styles.switch__label} style={extraLabelStyles}>
        {label}
      </p>
    </label>
  );
};
