import { combineReducers } from 'redux';
import {
  Store,
  configureStore,
  createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import userReducer from './slice/user';
import dataUrlReducer from './slice/dataUrl';
import mailchimpReducer from './slice/mailchimp';
import surveyReducer from './slice/survey';
import editorReducer from './slice/editor';
import smartFormReducer from './slice/smartForm';
import smartLinkReducer from './slice/smartLink';
import accountStatsReducer from './slice/accountStats';
import recentColorsReducer from './slice/recentColors';
import { mailchimpApi } from './slice/mailchimpApi';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';

const reducer = combineReducers({
  user: userReducer,
  dataUrl: dataUrlReducer,
  mailchimp: mailchimpReducer,
  survey: surveyReducer,
  editor: editorReducer,
  smartForm: smartFormReducer,
  smartLink: smartLinkReducer,
  accountStats: accountStatsReducer,
  recentColors: recentColorsReducer,
  [mailchimpApi.reducerPath]: mailchimpApi.reducer,
});

const store: Store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      createSerializableStateInvariantMiddleware(),
      mailchimpApi.middleware,
    ),
});

setupListeners(store.dispatch);

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
