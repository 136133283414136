import { useEffect, useState } from 'react';
import { CustomTextBoxType } from 'src/utils/types/Editor';

export const useObjectTextSizes = (activeObject: CustomTextBoxType) => {
  const [fontSize, setFontSize] = useState(activeObject.fontSize.toFixed(0));
  const [lineHeight, setLineHeight] = useState(
    (activeObject.lineHeight * Number(fontSize)).toFixed(0) || 'Auto',
  );
  const [letterSpacing, setLetterSpacing] = useState(
    (activeObject.charSpacing / activeObject.fontSize).toFixed(0) || '0',
  );

  useEffect(() => {
    const handleScale = e => {
      const { target } = e.transform;

      setLetterSpacing(
        (target.charSpacing / target.fontSize).toFixed(0) || '0',
      );
      setFontSize(target.fontSize.toFixed(0));
      setLineHeight((target.lineHeight * target.fontSize).toFixed(0) || 'Auto');
    };

    activeObject.on('scaling', handleScale);

    return () => {
      activeObject.off('scaling', handleScale);
    };
  }, []);

  return {
    fontSize,
    setFontSize,
    lineHeight,
    setLineHeight,
    letterSpacing,
    setLetterSpacing,
  };
};
