import React, { ChangeEvent, FC } from 'react';
import { FlexContainer, Input } from '../common';
import { useVotes } from './actions/useVotes';

import styles from './index.module.scss';
import { CustomCanvasType } from 'src/utils/types/Editor';
import { useChartUpdate } from './actions/useChartUpdate';

type Props = {
  canvas: CustomCanvasType;
};

export const EditorSurveyResultsPreviewVotes: FC<Props> = ({ canvas }) => {
  const { votes, setVotes } = useVotes();

  useChartUpdate(canvas, votes);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, objectId: string) => {
    const { value } = e.target;

    if (value && !/\d/.test(value)) {
      return;
    }

    setVotes(prev => {
      const newVotes = prev.map(vote => {
        if (vote.objectId === objectId) {
          return {
            ...vote,
            count: value,
          };
        }

        return vote;
      });

      return newVotes;
    });
  };

  return (
    <FlexContainer>
      <h4 className={styles.preview_votes__title}>Total Votes</h4>

      {votes.map(vote => (
        <Input
          value={vote.count}
          key={vote.objectId}
          placeholder={vote.vote}
          label={vote.vote}
          variant='editor-sidebar-input'
          onChange={e => handleChange(e, vote.objectId)}
        />
      ))}
    </FlexContainer>
  );
};
