import React, { FC } from 'react';
import { FlexContainer } from '../../FlexContainer/FlexContainer';
import styles from './index.module.scss';
import classNames from 'classnames';

interface Props {
  page: number | string;
  currentPage: number | string;
  onChange: (page: number | string) => void;
}

export const PaginationButton: FC<Props> = ({
  currentPage,
  page,
  onChange,
}) => {
  const handleClick = () => {
    onChange(page);
  };

  return (
    <FlexContainer
      onClick={handleClick}
      className={classNames(styles.pagination_button, {
        [styles.pagination_button__active]: page === currentPage,
      })}
      align='center'
      justify='center'
    >
      <p>{page}</p>
    </FlexContainer>
  );
};
