import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { CustomCanvasType, CustomFabricObject } from '../types/Editor';
import { IEvent } from 'fabric/fabric-impl';
import { getInnerCanvas } from '../helpers/editor/getInnerCanvas';
import { handlePropSet } from '../helpers/editor/handlePropSet';

export const useGeneralObjectProperties = (
  activeObject: CustomFabricObject,
  canvas: CustomCanvasType,
  onSetRadius?: Dispatch<SetStateAction<string>>,
) => {
  const { objWidth, objHeight } = getObjectWidthAndHeight(activeObject);

  const [x, setX] = useState(activeObject.left.toFixed(0));
  const [y, setY] = useState(activeObject.top.toFixed(0));
  const [width, setWidth] = useState(objWidth.toFixed(0));
  const [height, setHeight] = useState(objHeight.toFixed(0));
  const [angle, setAngle] = useState(activeObject.angle.toFixed(0));

  useEffect(() => {
    const innerCanvas = getInnerCanvas(canvas);

    const { objHeight: tempHeight, objWidth: tempWidth } =
      getObjectWidthAndHeight(activeObject);

    setX(((activeObject.left ?? 0) - innerCanvas.left).toFixed(0));
    setY(((activeObject.top ?? 0) - innerCanvas.top).toFixed(0));
    setWidth(tempWidth.toFixed(0));
    setHeight(tempHeight.toFixed(0));
    setAngle((activeObject.angle ?? 0).toFixed(0));

    const handleScale = (event: IEvent<MouseEvent | Event>) => {
      const target = event.transform?.target as CustomFabricObject;

      if (!target || target.type !== activeObject.type) {
        return;
      }

      if (target.type === 'image') {
        setWidth(target.getScaledWidth().toFixed(0));
        setHeight(target.getScaledHeight().toFixed(0));
        setX(target.left.toFixed(0));
        setY(target.top.toFixed(0));
        onSetRadius?.(target.borderRadius.toFixed(0));

        return;
      }

      const { objHeight: newHeight, objWidth: newWidth } =
        getObjectWidthAndHeight(target);

      setWidth(newWidth.toFixed(0));
      setHeight(newHeight.toFixed(0));
      setX(target.left.toFixed(0));
      setY(target.top.toFixed(0));
    };

    const handleResize = (event: IEvent<MouseEvent | Event>) => {
      const target = event.transform?.target as CustomFabricObject;

      if (target.type === 'image') {
        setWidth(target.getScaledWidth().toFixed(0));
        setHeight(target.getScaledHeight().toFixed(0));
        onSetRadius?.(target.borderRadius.toFixed(0));

        return;
      }

      if (!target || target.type !== 'text') {
        return;
      }

      const { objHeight: newHeight, objWidth: newWidth } =
        getObjectWidthAndHeight(target);

      setWidth(newWidth.toFixed(0));
      setHeight(newHeight.toFixed(0));
      setX(target.left.toFixed(0));
      setY(target.top.toFixed(0));
    };

    const handleMove = (event: IEvent<MouseEvent | Event>) => {
      const target = event.transform?.target;

      if (!target || target.type !== activeObject.type) {
        return;
      }

      setX(((target.left ?? 0) - innerCanvas.left).toFixed(0));
      setY(((target.top ?? 0) - innerCanvas.top).toFixed(0));
    };

    const handleRotate = (event: IEvent<MouseEvent | Event>) => {
      const target = event.transform?.target;

      if (!target || target.type !== activeObject.type) {
        return;
      }

      setAngle((target.angle ?? 0).toFixed(0));
    };

    activeObject.on('scaling', handleScale);
    activeObject.on('moving', handleMove);
    activeObject.on('rotating', handleRotate);
    activeObject.on('resizing', handleResize);

    return () => {
      activeObject.off('scaling', handleScale);
      activeObject.off('moving', handleMove);
      activeObject.off('rotating', handleRotate);
      activeObject.off('resizing', handleResize);
    };
  }, [activeObject.objectId, canvas]);

  const handleCoordsSet = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const { value } = event.target;
    const innerCanvas = getInnerCanvas(canvas);

    if (field === 'top') {
      handlePropSet(activeObject, value, 'top', setY, innerCanvas);

      return;
    }

    handlePropSet(activeObject, value, 'left', setX, innerCanvas);
  };

  const handleImageSizeChange = (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const { value } = event.target;
    const numberRegex = /^-?\d+(\.\d+)?$/;

    if (value && !numberRegex.test(value)) {
      return;
    }

    const isWidth = field === 'width';
    const numValue = Number(value);
    const scaleFactor = isWidth
      ? numValue / activeObject.getScaledWidth()
      : numValue / activeObject.getScaledHeight();

    const imageWidth = isWidth
      ? numValue || 1
      : activeObject.getScaledWidth() *
        (activeObject.proportionalResize ? scaleFactor : 1);
    const imageHeight = !isWidth
      ? numValue || 1
      : activeObject.getScaledHeight() *
        (activeObject.proportionalResize ? scaleFactor : 1);

    (activeObject as any).set({
      width: imageWidth || 1,
      height: imageHeight || 1,
      scaleX: 1,
      scaleY: 1,
    });

    if (activeObject.type === 'image') {
      activeObject.applyCrop(imageWidth, imageHeight);
    }

    setWidth(isWidth ? value : imageWidth.toFixed(0));
    setHeight(!isWidth ? value : imageHeight.toFixed(0));
  };

  return {
    width,
    height,
    x,
    y,
    angle,
    setX,
    setY,
    setWidth,
    setHeight,
    setAngle,
    handleCoordsSet,
    handleImageSizeChange,
  };
};

export const getObjectWidthAndHeight = (activeObject: CustomFabricObject) => {
  switch (activeObject.type) {
    case 'image':
    case 'clickableArea':
      return {
        objWidth: activeObject.getScaledWidth(),
        objHeight: activeObject.getScaledHeight(),
      };

    case 'surveyResult':
      switch (activeObject.variant) {
        case 'bar:stacked':
          return {
            objWidth:
              activeObject.sizeX * activeObject.clickableAreas.length ||
              activeObject.sizeX,
            objHeight: activeObject.sizeY,
          };

        case 'column:stacked':
          return {
            objWidth: activeObject.sizeX,
            objHeight:
              activeObject.sizeY * activeObject.clickableAreas.length ||
              activeObject.sizeY,
          };

        default:
          return {
            objWidth: activeObject.sizeX,
            objHeight: activeObject.sizeY,
          };
      }

    case 'text':
      const lineHeight =
        activeObject.lineHeight * Number(activeObject.fontSize);

      return {
        objWidth: activeObject.width,
        objHeight: activeObject.textLines.length * lineHeight,
      };
  }
};
