/* eslint-disable @typescript-eslint/indent */
import React, {
  CSSProperties,
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useState,
  JSX,
} from 'react';

import classnames from 'classnames';
import styles from './Input.module.scss';
import { If } from '../If/If';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  value: any;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  variant?: 'editor-sidebar-input' | '';
  type?: string;
  icon?: string;
  rightIcon?: string | JSX.Element | null;
  placeholder?: string;
  extraStyles?: CSSProperties;
  labelExtraStyles?: CSSProperties;
  disabled?: boolean;
  required?: boolean;
  autocompleteList?: string[];
  label?: string;
  onBlur?: () => void;
  hasError?: boolean;
  onFocus?: () => void;
  focusedInput?: string;
  maxLength?: number;
  minLength?: number;
  dataCy?: string;
  name?: string;
}

export const Input: FC<Props> = ({
  placeholder,
  type,
  icon,
  rightIcon,
  extraStyles,
  labelExtraStyles,
  value,
  onChange,
  disabled,
  required,
  label,
  onBlur = () => {},
  hasError,
  autocompleteList: userAutocompleteList,
  onFocus = () => {},
  focusedInput,
  maxLength,
  minLength,
  variant,
  dataCy,
  name = '',
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [autocompleteList, setAutocompleteList] = useState(
    userAutocompleteList || [],
  );
  const [showAutocompleteList, setShowAutocompleteList] = useState(false);

  useEffect(() => {
    const lowerValue = value?.toLowerCase() || '';

    const filteredListByUserInput =
      userAutocompleteList?.filter(item => {
        const lowerItem = item?.toLowerCase() || '';

        return (
          (lowerItem.startsWith(lowerValue) || item.includes(value)) &&
          lowerItem !== lowerValue
        );
      }) || [];

    setAutocompleteList(filteredListByUserInput);

    const isEmptyAutocompleteList = Boolean(filteredListByUserInput.length);

    setShowAutocompleteList(isEmptyAutocompleteList);
  }, [value, userAutocompleteList?.length]);

  const handleAutocompleteItemClick = (item: string) => {
    onChange({
      target: { value: item },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const setFocusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={styles.customInput_container}>
      <div className={styles.customInput} style={extraStyles}>
        <If condition={icon}>
          <img
            src={icon}
            alt='Input icon'
            onClick={() => setFocusOnInput()}
            className={classnames(styles.customInput_icon, {
              [styles.customInput_icon__editor_sidebar]:
                variant === 'editor-sidebar-input',
            })}
          />
        </If>

        <input
          required={required !== undefined}
          ref={inputRef}
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder || ''}
          disabled={disabled}
          onBlur={() => onBlur()}
          onFocus={() => onFocus()}
          maxLength={maxLength}
          minLength={minLength}
          style={{ paddingLeft: icon ? 32 : 10, ...props.style }}
          data-cy={dataCy}
          name={name}
          {...props}
          className={classnames(styles.customInput_input, props.className, {
            [styles.customInput_input__disabled]: disabled,
            [styles.customInput_input__error]: hasError,
            [styles.customInput_input__editor]:
              variant === 'editor-sidebar-input',
          })}
        />

        <If condition={rightIcon}>
          <div className={styles.customInput__right_icon}>
            <If condition={React.isValidElement(rightIcon)}>
              {rightIcon as JSX.Element}
            </If>

            <If condition={!React.isValidElement(rightIcon)}>{rightIcon}</If>
          </div>
        </If>
      </div>

      <If condition={showAutocompleteList && focusedInput === label}>
        <div className={styles.customInput__autocomplete}>
          {autocompleteList.map(item => (
            <div
              onClick={() => handleAutocompleteItemClick(item)}
              onMouseDown={e => e.preventDefault()}
              key={item}
              className={styles.customInput__autocompleteItem}
            >
              {item}
            </div>
          ))}
        </div>
      </If>

      <If condition={label}>
        <label
          onClick={() => setFocusOnInput()}
          className={classnames(styles.customInput__label, {
            [styles.customInput__label_editor]:
              variant === 'editor-sidebar-input',
          })}
          style={labelExtraStyles}
        >
          {label}
        </label>
      </If>
    </div>
  );
};
