import { SupportMessageType } from '../utils/types/User';
import { createClient } from './client';

const supportAxiosAPI = createClient('support');

export const support = async (message: string, type: SupportMessageType) => {
  return supportAxiosAPI.post('', {
    message,
    type,
  });
};
