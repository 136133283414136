import classnames from 'classnames';
import React, {
  CSSProperties,
  FC,
  HTMLAttributes,
  ReactNode,
  useMemo,
} from 'react';
import { safeStringify } from '../../../utils/helpers/safeStringify';
import { If } from '../If/If';
import { Loader } from '../Loader/Loader';
import styles from './Button.module.scss';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  type: 'fill' | 'fill-blue' | 'outlined' | 'disabled' | 'outlined-blue';
  text?: string | ReactNode;
  variant?: 'small' | 'extra-small';
  color?: string;
  bgColor?: string;
  icon?: string;
  extraStyles?: CSSProperties;
  extraTextStyles?: CSSProperties;
  children?: any;
  isLoading?: boolean;
  className?: string;
  dataCy?: string;
  disable?: boolean;
}

export const Button: FC<Props> = ({
  onClick,
  text,
  color = '#3B4147',
  bgColor = '#fff',
  icon,
  type,
  extraStyles,
  extraTextStyles,
  children,
  isLoading,
  className,
  dataCy,
  variant,
  disable,
}) => {
  const dynamicStyles = useMemo(() => {
    if (disable) {
      return {
        color: '#DBDCDF',
        border: `2px solid #DBDCDF`,
        backgroundColor: '#fff',
        cursor: 'default',
      };
    }

    switch (type) {
      case 'outlined':
        return {
          border: `2px solid ${color}`,
          color,
          backgroundColor: bgColor,
        };

      case 'fill':
        return {
          color,
          backgroundColor: bgColor,
          border: `2px solid ${bgColor}`,
        };

      case 'disabled':
        return {
          color: '#DBDCDF',
          border: `2px solid #DBDCDF`,
          backgroundColor: '#fff',
          cursor: 'default',
        };

      case 'outlined-blue':
        return {
          color: '#45869F',
          border: `2px solid #45869F`,
          backgroundColor: '#fff',
        };

      case 'fill-blue':
        return {
          color: 'white',
          backgroundColor: '#45869F',
          border: '2px solid #45869F',
        };
    }
  }, [type, color, bgColor, disable]);

  const containerStyles = useMemo(
    () => ({ ...dynamicStyles, ...extraStyles }),
    [safeStringify(dynamicStyles), safeStringify(extraStyles)],
  );

  return (
    <button
      onClick={() => (disable ? () => {} : onClick())}
      className={classnames(styles.button, {
        [styles.button__disabled]: type === 'disabled' || disable,
        [className || '']: className,
        [styles.button__small]: variant === 'small',
        [styles.button__extra_small]: variant === 'extra-small',
      })}
      style={containerStyles}
      data-cy={dataCy}
      disabled={disable}
    >
      <If condition={icon}>
        <img className={styles.button_logo} src={icon} alt='Linkedin logo' />
      </If>

      <If condition={React.isValidElement(text)}>
        <>{text}</>
      </If>

      <If condition={!React.isValidElement(text)}>
        <span
          className={classnames(styles.button_title, {
            [styles.button_title__small]: variant === 'small',
            [styles.button_title__extra_small]: variant === 'extra-small',
          })}
          style={extraTextStyles}
        >
          <If condition={isLoading}>
            <Loader size={variant === 'small' ? 'xsm' : 'sm'} />
          </If>

          <If condition={!isLoading}>{text}</If>
        </span>
      </If>

      {children}
    </button>
  );
};
