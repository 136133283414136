import React, { FC } from 'react';
import { FlexContainer } from '../common';

import styles from './index.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const SmartFormPropertiesSection: FC<Props> = ({ title, children }) => {
  return (
    <FlexContainer className={styles.smart_form_properties_section}>
      <h4
        className={styles.smart_form_properties_section__title}
      >{`${title} Properties`}</h4>

      <FlexContainer>{children}</FlexContainer>
    </FlexContainer>
  );
};
