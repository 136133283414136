import { useModal } from 'src/utils/hooks/useModal';

export const useHandleEmbed = (openSaveBeforeEmbed: () => void) => {
  const {
    isOpen: isOpenEmbedCode,
    openModal: openEmbedCodeModal,
    closeModal: closeEmbedCodeModal,
  } = useModal();

  const handleEmbed = async (isCanvasChanged: boolean) => {
    if (isCanvasChanged) {
      openSaveBeforeEmbed();

      return;
    }

    openEmbedCodeModal();
  };

  return {
    handleEmbed,
    isOpenEmbedCode,
    openEmbedCodeModal,
    closeEmbedCodeModal,
  };
};
