import Localbase from 'localbase';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import {
  CanvasObjectsByTypeList,
  EditorLocalbaseType,
} from 'src/utils/types/Editor';

export const db = new Localbase('db');

db.config.debug = false;

export const saveToEditorLocalbase = (
  key: EditorLocalbaseType,
  value: Record<string, any>,
  callback?: () => void,
) => {
  try {
    db.collection('editor').doc(key).set(value).then(callback);
  } catch (error) {
    showErrorMessage(error);
    callback?.();
  }
};

export const getFromEditorLocalbase = async <T extends EditorLocalbaseType>(
  key: T,
): Promise<CanvasObjectsByTypeList[T]> => {
  const data = await db.collection('editor').doc(key).get();

  return data as CanvasObjectsByTypeList[T];
};

export const updateEditorLocalbase = async (
  key: EditorLocalbaseType,
  value: Record<string, any>,
) => {
  await db.collection('editor').doc(key).update(value);
};

export const deleteFromEditorLocalbase = async (key: EditorLocalbaseType) => {
  await new Promise(resolve => {
    db.collection('editor')
      .doc(key)
      .get()
      .then(async () => {
        const docRef = db.collection('editor').doc(key);

        await docRef.delete();

        resolve(true);
      })
      .catch(() => {})
      .finally(() => resolve(true));
  });
};

export const clearEditorLocalbase = async () => {
  try {
    await deleteFromEditorLocalbase('surveyCanvas');
    await deleteFromEditorLocalbase('resultsCanvases');
  } catch (error) {
    showErrorMessage(error);
  }
};
