import React from 'react';

import { Footer } from 'src/components/Footer/Footer';
import { SmartFormHeader } from 'src/components/SmartFormHeader';
import { SmartFormEditor } from 'src/components/SmartFormEditor';

import styles from './index.module.scss';

export const GenerateLeadFormPage = () => {
  return (
    <>
      <SmartFormHeader />

      <main className={styles.smart_form_page__main}>
        <SmartFormEditor />
      </main>

      <Footer handleCloseNavPopup={() => {}} />
    </>
  );
};
