import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { FlexContainer, Input } from 'src/components/common';

import styles from './index.module.scss';
import { GooglePrefilledFormField } from 'src/utils/types/Survey';
import { ExtraStyles } from 'src/utils/types/general/types';

type Props = {
  fields: GooglePrefilledFormField[];
  setFields: Dispatch<SetStateAction<GooglePrefilledFormField[]>>;
};

export const PrefilledFieldsList: FC<Props> = ({ fields, setFields }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, entryId: string) => {
    const { value } = e.target;

    setFields(prevFields =>
      prevFields.map(field =>
        field.entryId === entryId ? { ...field, value } : field,
      ),
    );
  };

  return (
    <FlexContainer align='start'>
      {fields.map(field => (
        <Input
          value={field.value}
          onChange={e => handleChange(e, field.entryId)}
          variant='editor-sidebar-input'
          placeholder='Enter merge tag'
          label={field.questionTitle}
          key={field.entryId}
          className={styles.input}
          labelExtraStyles={extraStyles.inputLabel}
          extraStyles={extraStyles.input}
        />
      ))}
    </FlexContainer>
  );
};

const extraStyles: ExtraStyles = {
  inputLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  input: {
    minWidth: 130,
  },
};
