/* eslint-disable @typescript-eslint/indent */
import classnames from 'classnames';
import React, {
  CSSProperties,
  ComponentPropsWithRef,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { Divider } from '../Divider/Divider';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import { If } from '../If/If';

import { constants } from 'src/utils/constants';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { hexToRGBA } from '../ColorPicker/ColorPicker';
import { useRecentColors } from './actions/useRecentColors';
import { useTempValues } from './actions/useTempValues';
import styles from './index.module.scss';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { useAppDispatch } from 'src/redux/store';
import { recentColorsActions } from 'src/redux/slice/recentColors';

export type ColorItem = {
  id: string;
  color: string;
  changeHandler: Dispatch<SetStateAction<string>>;
};

type Props = {
  colors: ColorItem[];
  label?: string;
  extraStyles?: CSSProperties;
  extraStylesInput?: CSSProperties;
} & ComponentPropsWithRef<'div'>;

export const MultipleColorPicker: FC<Props> = ({ colors }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>(colors[0].id);

  const { tempValues, handleValueChange } = useTempValues(colors);
  const recentColors = useDistinctSelector('recentColors');

  const dispatch = useAppDispatch();

  useRecentColors(showPicker);

  const handleColorChange = (colorValue: string) => {
    const foundItem = colors.find(color => color.id === selectedColor);

    if (!foundItem) {
      return;
    }

    foundItem.changeHandler(colorValue);
    dispatch(recentColorsActions.update(colorValue));
  };

  const handleInputBlur = () => {
    colors.forEach(color => {
      const foundItem = tempValues[color.id];

      if (!foundItem) {
        return;
      }

      const rgbaColor = hexToRGBA(foundItem.hex, foundItem.opacity / 100);

      color.changeHandler(rgbaColor);
      dispatch(recentColorsActions.update(rgbaColor));
    });
  };

  const tempEntries = useMemo(
    () => Object.entries(tempValues),
    [safeStringify(tempValues)],
  );

  return (
    <FlexContainer direction='row' align='center' className={styles.wrapper}>
      <FlexContainer className={styles.color_picker}>
        <FlexContainer
          onClick={() => setShowPicker(prev => !prev)}
          className={styles.color_picker__container}
          direction='row'
          align='center'
          gap={2}
        >
          {tempEntries.map(([id, color]) => (
            <div
              key={id}
              className={styles.color_picker__color}
              style={{
                backgroundColor: hexToRGBA(color.hex, color.opacity / 100),
              }}
            />
          ))}
        </FlexContainer>

        <If condition={showPicker}>
          <FlexContainer className={styles.color_picker__picker} gap={0}>
            <FlexContainer
              className={styles.color_picker__block_wrapper}
              align='center'
            >
              {tempEntries.map(([id, color]) => {
                return (
                  <FlexContainer
                    key={id}
                    direction='row'
                    align='center'
                    gap={10}
                    onClick={() => setSelectedColor(id)}
                  >
                    <div
                      className={classnames(
                        styles.color_picker__item,
                        styles.color_picker__item__big,
                        {
                          [styles.color_picker__color__selected]:
                            id === selectedColor,
                        },
                      )}
                      style={{
                        backgroundColor: hexToRGBA(
                          color.hex,
                          color.opacity / 100,
                        ),
                      }}
                    />

                    <FlexContainer
                      direction='row'
                      justify='center'
                      align='center'
                      gap={0}
                      className={styles.color_picker__input_container}
                    >
                      <input
                        type='text'
                        value={color.hex}
                        onChange={e => handleValueChange(e, id)}
                        onBlur={handleInputBlur}
                        data-input-type='hex'
                        className={classnames(
                          styles.color_picker__input,
                          styles.color_picker__input__left,
                        )}
                      />

                      <input
                        type='text'
                        value={color.opacity || ''}
                        onChange={e => handleValueChange(e, id)}
                        onBlur={handleInputBlur}
                        data-input-type='opacity'
                        className={classnames(
                          styles.color_picker__input,
                          styles.color_picker__input__right,
                        )}
                      />
                    </FlexContainer>

                    <p className={styles.color_picker__input_label}>{id}</p>
                  </FlexContainer>
                );
              })}
            </FlexContainer>

            <Divider />

            <FlexContainer className={styles.color_picker__block_wrapper}>
              <p className={styles.color_picker__text}>Recent:</p>

              <FlexContainer direction='row' align='center' gap={5}>
                {recentColors.map(colorValue => (
                  <div
                    className={styles.color_picker__item}
                    style={{ backgroundColor: colorValue }}
                    key={colorValue}
                    onClick={() => handleColorChange(colorValue)}
                  />
                ))}
              </FlexContainer>
            </FlexContainer>

            <Divider />

            <FlexContainer className={styles.color_picker__block_wrapper}>
              <FlexContainer gap={5}>
                {constants.defaultColorPickerColors.map(row => (
                  <FlexContainer
                    key={row[0]}
                    direction='row'
                    align='center'
                    gap={5}
                  >
                    {row.map(colorValue => (
                      <div
                        key={colorValue}
                        className={classnames(styles.color_picker__item, {
                          [styles.color_picker__item__selected]: colors.some(
                            color => color.color === colorValue,
                          ),
                        })}
                        style={{ backgroundColor: colorValue }}
                        onClick={() => {
                          handleColorChange(colorValue);
                        }}
                      />
                    ))}
                  </FlexContainer>
                ))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </If>
      </FlexContainer>
    </FlexContainer>
  );
};
