import React, { FC, useEffect, useState } from 'react';
import { useGeneralObjectProperties } from 'src/utils/hooks/useGeneralObjectProperties';
import { applyImageFit } from '../../utils/helpers/editor/applyImageFit';
import { CustomCanvasType, CustomImageType } from '../../utils/types/Editor';
import { SurveysEditorSizePropertiesBlock } from '../EditorSizePropertiesBlock';
import { SurveysEditorUploadedImageContainer } from '../EditorUploadedImageContainer';
import { Divider } from '../common/Divider/Divider';
import { DragAndDropZone } from '../common/DragAndDropZone/DragAndDropZone';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';

interface Props {
  activeObject: CustomImageType;
  canvas: CustomCanvasType;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
}

export const SurveysEditorImageEditSidebar: FC<Props> = ({
  activeObject,
  canvas,
  onGetCanvasObjects,
}) => {
  const [radius, setRadius] = useState(
    activeObject.borderRadius?.toFixed(0) || '0',
  );

  const {
    x,
    y,
    width,
    height,
    angle,
    setWidth,
    setHeight,
    setAngle,
    handleCoordsSet,
    handleImageSizeChange,
  } = useGeneralObjectProperties(activeObject, canvas, setRadius);

  const [image, setImage] = useState({
    file: activeObject.getSrc() || '',
    fileName: activeObject.fileName || '',
    fileSize: activeObject.fileSize || 0,
  });
  const [imageFit, setImageFit] = useState(activeObject.imageFit || 'cover');

  useEffect(() => {
    if (activeObject.fileName) {
      return;
    }

    activeObject.fileName = image.fileName;
    activeObject.fileSize = image.fileSize;

    activeObject.setSrc(image.file);
  }, [image]);

  useEffect(() => {
    canvas.requestRenderAll();
  }, [width, height, angle, x, y, radius]);

  useEffect(() => {
    const { width: objWidth, height: objHeight } = applyImageFit(
      activeObject,
      canvas,
      imageFit,
    );

    setWidth(objWidth);
    setHeight(objHeight);
  }, [imageFit]);

  useEffect(() => {
    setImage({
      file: activeObject.getSrc() || '',
      fileName: activeObject.fileName || '',
      fileSize: activeObject.fileSize,
    });
    setRadius(activeObject.borderRadius?.toFixed(0) || '0');
  }, [activeObject.objectId, activeObject.src]);

  useEffect(() => {
    onGetCanvasObjects();
  }, [image.file]);

  const handleDeleteImage = () => {
    canvas.discardActiveObject();
    canvas.remove(activeObject);
    canvas.requestRenderAll();

    onGetCanvasObjects();
  };

  return (
    <FlexContainer>
      <SurveysEditorSizePropertiesBlock
        activeObject={activeObject}
        x={x}
        y={y}
        width={width}
        height={height}
        angle={angle}
        radius={radius}
        withLock
        setWidth={setWidth}
        setHeight={setHeight}
        setAngle={setAngle}
        handleImageSizeChange={handleImageSizeChange}
        setRadius={setRadius}
        handleCoordsSet={handleCoordsSet}
      />

      <Divider extraStyles={extraStyles.divider} />

      <If condition={!image.file}>
        <DragAndDropZone onValueChange={setImage} />
      </If>

      <If condition={image.file}>
        <SurveysEditorUploadedImageContainer
          value={image}
          activeObject={activeObject}
          imageFit={imageFit}
          canvas={canvas}
          onImageChange={setImage}
          onImageFitChange={setImageFit}
          onImageDelete={handleDeleteImage}
          onGetCanvasObjects={onGetCanvasObjects}
        />
      </If>
    </FlexContainer>
  );
};

const extraStyles = {
  divider: { margin: '3px 0' },
};
