import { useEffect, useState } from 'react';
import { CustomTextBoxType } from 'src/utils/types/Editor';

export const useObjectStroke = (activeObject: CustomTextBoxType) => {
  const [strokeColor, setStrokeColor] = useState(
    activeObject.stroke || '#000000',
  );
  const [strokeWidth, setStrokeWidth] = useState(
    activeObject?.strokeWidth?.toFixed(0) || '0',
  );

  useEffect(() => {
    activeObject.set({ stroke: strokeColor });
  }, [strokeColor]);

  return {
    strokeColor,
    setStrokeColor,
    strokeWidth,
    setStrokeWidth,
  };
};
