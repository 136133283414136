import { RoundedRect } from './customClasses/RoundedRect';

const defaultCorners = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

export const applyImageBorberRadius = (
  fabricObject: any,
  cornerRadius: number,
  roundedCorners: string[] = defaultCorners,
) => {
  const corners = defaultCorners.reduce((acc, corner) => {
    if (roundedCorners.includes(corner)) {
      acc[corner] = [cornerRadius, cornerRadius];

      return acc;
    }

    return {
      ...acc,
      [corner]: [0, 0],
    };
  }, {} as any);

  return new RoundedRect({
    width: fabricObject.width,
    height: fabricObject.height,
    left: -fabricObject.width / 2,
    top: -fabricObject.height / 2,
    ...corners,
    strokeWidth: 0,
  });
};
