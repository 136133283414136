import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { ClickableAreaType } from 'src/utils/types/Editor';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { EditorGroupForm } from './forms/EditorGroupForm';
import { EditorMergeTagForm } from './forms/EditorMergeTagForm';
import { EditorTagForm } from './forms/EditorTagForm';
import { If } from '../common/If/If';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

const dataUrlFormTypes = {
  mergeTag: EditorMergeTagForm,
  group: EditorGroupForm,
  tag: EditorTagForm,
};

export type DataURLFormKey = keyof typeof dataUrlFormTypes;

type DataUrlFormProps = {
  type: DataURLFormKey;
  activeObject: ClickableAreaType;
};

export const EditorDataURLForm: FC<DataUrlFormProps> = ({
  type,
  activeObject,
}) => {
  const [focusedInput, setFocusedInput] = useState<string | null>(null);
  const { mergeTagType, saveResultAs } = useDistinctSelector('survey');

  const FormComponent = dataUrlFormTypes[type];

  const handleInput = (
    value: string,
    setState: Dispatch<SetStateAction<string>>,
  ) => {
    if (!value) {
      setState(value);

      activeObject.updateUrlData(value);

      return;
    }

    if (saveResultAs === 'mergeTag' && mergeTagType === 'number') {
      const regex = new RegExp('^[0-9]*$');

      if (value && regex.test(value)) {
        setState(value);

        activeObject.updateUrlData(value);
      }

      return;
    }

    setState(value);

    activeObject.updateUrlData(value);
  };

  const handleInputBlur = () => {
    setFocusedInput(null);

    activeObject.validateUrlData();
  };

  return (
    <FlexContainer extraStyles={extraStyles.formWrapper}>
      <FormComponent
        activeObject={activeObject}
        focusedInput={focusedInput}
        onFocusedInput={setFocusedInput}
        onHandleInput={handleInput}
        onInputBlur={handleInputBlur}
      />

      <If condition={!activeObject.isValidUrlData}>
        <p style={extraStyles.error}>This field can not be empty!</p>
      </If>
    </FlexContainer>
  );
};

const extraStyles = {
  formWrapper: { maxWidth: 400 },
  error: {
    fontSize: 10,
    color: 'red',
    marginTop: -6,
  },
};
