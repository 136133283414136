import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MailchimpAccountsTable } from '../../components/MailchimpAccountsTable/MailchimpAccountsTable';
import { Button } from '../../components/common/Button/Button';
import styles from './MailchimpAccountsPage.module.scss';

export const MailchimpAccountsPage = () => {
  const navigate = useNavigate();

  return (
    <section className={styles.mailchimp_accounts_page}>
      <h2 className={styles.mailchimp_accounts_page__title}>Select Account</h2>

      <div className={styles.mailchimp_accounts_page__table_wrapper}>
        <MailchimpAccountsTable />
      </div>

      <Button
        type='outlined-blue'
        text='Add Mailchimp Account'
        onClick={() => navigate('/login/mailchimp')}
        className={styles.mailchimp_accounts_page__add_account}
        dataCy='add-mc-acc-btn'
      />
    </section>
  );
};
