import { constants } from '../../constants';

export const applyFontStyles = (fontStyles: string[], activeObject: any) => {
  for (const fontStyleOption of constants.fontStyleOptions) {
    if (fontStyles.includes(fontStyleOption.fontStyle)) {
      if (fontStyleOption.fontStyle === 'italic') {
        activeObject.set({ fontStyle: 'italic' });

        continue;
      }

      if (fontStyleOption.fontStyle === 'bold') {
        activeObject.set({ fontWeight: 700 });

        continue;
      }

      activeObject.set({ [fontStyleOption.fontStyle]: true });

      continue;
    }

    if (fontStyleOption.fontStyle === 'italic') {
      activeObject.set({ fontStyle: 'normal' });

      continue;
    }

    if (fontStyleOption.fontStyle === 'bold') {
      activeObject.set({ fontWeight: 400 });

      continue;
    }

    activeObject.set({ [fontStyleOption.fontStyle]: false });
  }
};
