import React, { FC } from 'react';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  isSelected: boolean;
}

export const SurveysEditorIconBox: FC<Props> = ({
  image,
  isSelected,
  ...props
}) => {
  return (
    <FlexContainer
      align='center'
      justify='center'
      className={classNames(styles.surveys_editor_icon_box, {
        [styles.surveys_editor_icon_box__selected]: isSelected,
      })}
      style={{ backgroundImage: `url(${image})` }}
      {...props}
    />
  );
};
