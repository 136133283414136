import { GooglePrefilledFormField } from 'src/utils/types/Survey';

export const generateNewDestionationUrl = (
  url: string,
  fields: GooglePrefilledFormField[],
) => {
  const urlObj = new URL(url);
  const urlParams = new URLSearchParams(urlObj.search);

  fields.forEach(field => {
    if (!field.value) {
      return;
    }

    let value = field.value.replace(/[|*]/g, '');

    if (!value.startsWith('*|')) {
      value = '*|' + value;
    }

    if (!value.endsWith('|*')) {
      value = value + '|*';
    }

    urlParams.set(`entry.${field.entryId}`, value.toUpperCase());
  });

  return `${urlObj.origin}${urlObj.pathname}?${urlParams.toString()}`;
};
