import React, { ChangeEventHandler, FC, useEffect } from 'react';
import { FlexContainer, Input } from 'src/components/common';
import { useMergeTags } from './actions/useMergeTags';

import { safeStringify } from 'src/utils/helpers/safeStringify';
import { CustomCanvasType } from 'src/utils/types/Editor';
import * as images from '../../assets/index';
import { updateTextToMergeTags } from './actions/updateTextToMergeTags';
import { useCanvasObjects } from './actions/useCanvasObjects';
import styles from './index.module.scss';
import { checkForMergeTags } from 'src/utils/helpers/saveDynamicTexts';

type Props = {
  canvas: CustomCanvasType;
};

export const PreviewMergeTagsList: FC<Props> = ({ canvas }) => {
  const { mergeTagsList, setMergeTagsList } = useMergeTags();
  const canvasObjects = useCanvasObjects(canvas);

  useEffect(() => {
    canvasObjects.forEach(object => {
      if (object.type !== 'text') {
        return;
      }

      if (checkForMergeTags(object.text) && object.saveFallback) {
        object.set({ text: object.fallback });
      }
    });

    canvas.renderAll();
  }, [
    safeStringify(mergeTagsList),
    safeStringify(canvasObjects.map(obj => obj.objectId)),
    canvas,
  ]);

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { name: mergeTag, value } = e.target;

    setMergeTagsList(prev =>
      prev.map(item => {
        if (item.mergeTag !== mergeTag) {
          return item;
        }

        return {
          ...item,
          value,
        };
      }),
    );
  };

  useEffect(() => {
    updateTextToMergeTags(canvas, mergeTagsList);
  }, [safeStringify(mergeTagsList), canvas]);

  return (
    <FlexContainer className={styles.merge_tags_list__container}>
      <ul className={styles.merge_tags_list}>
        {mergeTagsList.map(item => (
          <li key={item.mergeTag} className={styles.merge_tags_list__item}>
            <p className={styles.merge_tags_list__item__title}>
              Merge Tag: {item.mergeTag}
            </p>

            <Input
              value={item.value}
              onChange={handleValueChange}
              name={item.mergeTag}
              icon={images.mailchimpIcon}
              id={item.mergeTag}
              variant='editor-sidebar-input'
              placeholder='Apple'
            />
          </li>
        ))}
      </ul>
    </FlexContainer>
  );
};
