/* eslint-disable @typescript-eslint/indent */
import { useState } from 'react';
import { constants } from 'src/utils/constants';
import { findDropdownOption } from 'src/utils/helpers/findDropdownOptions';
import {
  AccountStatsSurveyInfo,
  MergeFieldsType,
} from 'src/utils/types/Mailchimp';
import { DropdownOption } from 'src/utils/types/general/types';

const dum = { labelValue: '', label: '' };

export const useSurveyData = (item: AccountStatsSurveyInfo) => {
  const { title, collectAdditionalData, saveResultAs }: AccountStatsSurveyInfo =
    item;

  const [tempTitle, setTempTitle] = useState(title);
  const [tempCollectAdditionalData, setTempCollectAdditionalData] = useState(
    collectAdditionalData,
  );
  const [currentSaveResultsOption, setCurrentSaveResultsOption] = useState(
    findDropdownOption(
      constants.surveySaveResultsOptions,
      saveResultAs,
      constants.surveySaveResultsOptions[0],
    ),
  );
  const [currentMergeTagType, setCurrentMergeTagType] = useState<
    DropdownOption<MergeFieldsType>
  >({
    labelValue: item.mergeTagType,
    label: findDropdownOption(constants.mergeFieldTypes, item.mergeTagType, dum)
      .label,
  });

  return {
    tempTitle,
    tempCollectAdditionalData,
    currentSaveResultsOption,
    currentMergeTagType,
    setTempTitle,
    setTempCollectAdditionalData,
    setCurrentSaveResultsOption,
    setCurrentMergeTagType,
  };
};
