import { DropdownOption } from './types/general/types';
import { MergeFieldsType } from './types/Mailchimp';

import * as images from '../assets/index';
import { FilterByEnum } from './types/general/enums';

const { href } = window.location;

let apiHost = '';
let host = '';

switch (true) {
  case href.includes('asksung-vysochanskiys-novel-campaigns.vercel.app'):
    apiHost = 'https://asksungapi-vysochanskiys-novel-campaigns.vercel.app/api';
    host = 'https://asksung-vysochanskiys-novel-campaigns.vercel.app';

    break;

  case href.includes('app.novelcampaigns.com'):
    apiHost = 'https://asksung.vercel.app/api';
    host = 'https://app.novelcampaigns.com';

    break;

  default:
    apiHost = 'http://localhost:8000/api';
    host = 'http://localhost:3000';
}

const defaultFonts: DropdownOption[] = [
  { labelValue: 'Arial', label: 'Arial' },
  { labelValue: 'Century Gothic', label: 'Century Gothic' },
  { labelValue: 'Comic Sans MS', label: 'Comic Sans MS' },
  { labelValue: 'Courier New', label: 'Courier New' },
  { labelValue: 'Cormorant Garamond', label: 'Cormorant Garamond' },
  { labelValue: 'Geneva', label: 'Geneva' },
  { labelValue: 'Helvetica', label: 'Helvetica' },
  { labelValue: 'Impact', label: 'Impact' },
  { labelValue: 'Lato', label: 'Lato' },
  { labelValue: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' },
  { labelValue: 'Tahoma', label: 'Tahoma' },
  { labelValue: 'Times New Roman', label: 'Times New Roman' },
  { labelValue: 'Trebuchet MS', label: 'Trebuchet MS' },
  { labelValue: 'Verdana', label: 'Verdana' },
];

const textAlignOptions = [
  {
    align: 'left',
    icon: images.alignLeftIcon,
  },
  {
    align: 'center',
    icon: images.alignCenterIcon,
  },
  {
    align: 'right',
    icon: images.alignRightIcon,
  },
  {
    align: 'justify',
    icon: images.alignFullWidthIcon,
  },
];

const fontStyleOptions = [
  {
    fontStyle: 'bold',
    icon: images.fontStyleBoldIcon,
  },
  {
    fontStyle: 'italic',
    icon: images.fontStyleItalicIcon,
  },
  {
    fontStyle: 'underline',
    icon: images.fontStyleUnderlineIcon,
  },
  {
    fontStyle: 'linethrough',
    icon: images.fontStyleLinethroughIcon,
  },
];

const zoomOptions: DropdownOption[] = [
  { label: 'Fit', labelValue: 'fit' },
  { label: '50%', labelValue: '0.5' },
  { label: '75%', labelValue: '0.75' },
  { label: '100%', labelValue: '1' },
  { label: '125%', labelValue: '1.25' },
  { label: '150%', labelValue: '1.5' },
  { label: '200%', labelValue: '2' },
];

const surveySaveResultsOptions: DropdownOption[] = [
  { label: 'Save Results as Merge tags', labelValue: 'mergeTag' },
  { label: 'Save Results as Groups', labelValue: 'group' },
  { label: 'Save Results as Tags', labelValue: 'tag' },
];

const surveyResultTypeOptions: DropdownOption[] = [
  {
    label: 'Bar:Stacked - 100%',
    labelValue: 'bar:stacked',
    icon: images.horizontalColumnIcon,
  },
  {
    label: 'Bar:Single',
    labelValue: 'bar:single',
    icon: images.horizontalColumnIcon,
  },
  {
    label: 'Column:Stacked - 100%',
    labelValue: 'column:stacked',
    icon: images.verticalColumnIcon,
  },
  {
    label: 'Column:Single',
    labelValue: 'column:single',
    icon: images.verticalColumnIcon,
  },
  {
    label: 'Pie:Stacked - 100%',
    labelValue: 'pie:stacked',
    icon: images.pieChartIcon,
  },
  { label: 'Pie:Single', labelValue: 'pie:single', icon: images.pieChartIcon },
];

const createActionButtons = [
  {
    title: 'Smart Forms',
    type: 'form',
    icon: images.smartFormIcon,
  },
  {
    title: 'Smart Surveys',
    type: 'survey',
    icon: images.surveyIconDark,
  },
  {
    title: 'Smart Links',
    type: 'link',
    icon: images.addpictonSecondIconDark,
  },
];

const filterOptions: DropdownOption<FilterByEnum>[] = [
  { label: 'All Files', labelValue: FilterByEnum.ALL },
  { label: 'Smart Surveys', labelValue: FilterByEnum.SURVEY },
  { label: 'Smart Links', labelValue: FilterByEnum.SMARTLINK },
];

const itemsPerPageOptions: DropdownOption[] = [
  { label: '25 per page', labelValue: 25 },
  { label: '50 per page', labelValue: 50 },
  { label: '75 per page', labelValue: 75 },
  { label: '100 per page', labelValue: 100 },
];

const colorsArray = [
  'rgba(88, 120, 164, 1)',
  'rgba(228, 147, 67, 1)',
  'rgba(209, 96, 94, 1)',
  'rgba(133, 181, 178, 1)',
  'rgba(106, 159, 88, 1)',
  'rgba(231, 202, 96, 1)',
  'rgba(168, 124, 160, 1)',
  'rgba(151, 119, 98, 1)',
  'rgba(184, 176, 173, 1)',
  'rgba(151, 157, 166, 1)',
];

const defaultColorPickerColors = [
  [
    'rgba(0, 0, 0, 1)',
    'rgba(67, 67, 67, 1)',
    'rgba(102, 102, 102, 1)',
    'rgba(102, 102, 103, 1)',
    'rgba(183, 183, 183, 1)',
    'rgba(204, 204, 204, 1)',
    'rgba(217, 217, 217, 1)',
    'rgba(239, 239, 239, 1)',
    'rgba(243, 243, 243, 1)',
    'rgba(255, 255, 255, 1)',
  ],
  [
    'rgba(129, 37, 25, 1)',
    'rgba(217, 69, 50, 1)',
    'rgba(231, 162, 79, 1)',
    'rgba(255, 253, 115, 1)',
    'rgba(150, 246, 104, 1)',
    'rgba(149, 248, 251, 1)',
    'rgba(97, 135, 219, 1)',
    'rgba(11, 36, 235, 1)',
    'rgba(128, 53, 236, 1)',
    'rgba(217, 78, 239, 1)',
  ],
  [
    'rgba(218, 187, 179, 1)',
    'rgba(233, 206, 207, 1)',
    'rgba(246, 230, 210, 1)',
    'rgba(252, 243, 212, 1)',
    'rgba(223, 233, 215, 1)',
    'rgba(213, 223, 226, 1)',
    'rgba(207, 219, 243, 1)',
    'rgba(213, 225, 239, 1)',
    'rgba(215, 212, 229, 1)',
    'rgba(227, 211, 219, 1)',
  ],
  [
    'rgba(199, 134, 116, 1)',
    'rgba(214, 161, 157, 1)',
    'rgba(237, 206, 167, 1)',
    'rgba(248, 230, 171, 1)',
    'rgba(193, 212, 176, 1)',
    'rgba(173, 194, 200, 1)',
    'rgba(173, 193, 236, 1)',
    'rgba(172, 196, 227, 1)',
    'rgba(176, 168, 208, 1)',
    'rgba(200, 169, 187, 1)',
  ],
  [
    'rgba(177, 82, 58, 1)',
    'rgba(198, 114, 110, 1)',
    'rgba(230, 183, 127, 1)',
    'rgba(245, 218, 134, 1)',
    'rgba(164, 193, 138, 1)',
    'rgba(135, 163, 172, 1)',
    'rgba(125, 158, 224, 1)',
    'rgba(132, 167, 212, 1)',
    'rgba(136, 126, 186, 1)',
    'rgba(176, 129, 157, 1)',
  ],
  [
    'rgba(142, 51, 31, 1)',
    'rgba(174, 53, 38, 1)',
    'rgba(210, 152, 88, 1)',
    'rgba(228, 196, 100, 1)',
    'rgba(129, 164, 99, 1)',
    'rgba(92, 127, 138, 1)',
    'rgba(83, 121, 203, 1)',
    'rgba(90, 132, 188, 1)',
    'rgba(95, 83, 157, 1)',
    'rgba(146, 87, 118, 1)',
  ],
  [
    'rgba(114, 46, 30, 1)',
    'rgba(130, 38, 26, 1)',
    'rgba(161, 102, 50, 1)',
    'rgba(179, 147, 67, 1)',
    'rgba(81, 115, 54, 1)',
    'rgba(47, 77, 89, 1)',
    'rgba(47, 87, 190, 1)',
    'rgba(45, 82, 139, 1)',
    'rgba(46, 34, 108, 1)',
    'rgba(100, 41, 69, 1)',
  ],
  [
    'rgba(77, 27, 13, 1)',
    'rgba(86, 22, 14, 1)',
    'rgba(107, 67, 31, 1)',
    'rgba(119, 98, 42, 1)',
    'rgba(54, 75, 34, 1)',
    'rgba(30, 50, 59, 1)',
    'rgba(44, 69, 126, 1)',
    'rgba(29, 54, 93, 1)',
    'rgba(28, 21, 71, 1)',
    'rgba(64, 25, 46, 1)',
  ],
];

const mergeFieldTypes = [
  { label: 'Text', labelValue: 'text' },
] as DropdownOption<MergeFieldsType>[];

export const constants = {
  apiHost,
  host,
  defaultFonts,
  textAlignOptions,
  fontStyleOptions,
  zoomOptions,
  surveySaveResultsOptions,
  surveyResultTypeOptions,
  createActionButtons,
  filterOptions,
  itemsPerPageOptions,
  colorsArray,
  defaultColorPickerColors,
  mergeFieldTypes,
};
