import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import * as images from '../../../assets/index';
import styles from './index.module.scss';

type Props = {
  customReturnCallback?: () => void;
};

export const ReturnBackButton: FC<Props> = ({ customReturnCallback }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (customReturnCallback) {
      customReturnCallback();
    } else {
      navigate(-1);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={styles.return_back_button}
      data-cy='return-back-button'
    >
      <img
        src={images.returnBackIcon}
        className={styles.return_back_button__icon}
        alt='Return to previous page button'
      />
    </button>
  );
};
