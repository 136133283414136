/* eslint-disable @typescript-eslint/indent */
import { fabric } from 'fabric';
import { FabricJSEditor } from 'fabricjs-react';
import React, { FC, useEffect, useState } from 'react';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { centerCanvasView } from '../../utils/helpers/editor/centerCanvasView';
import { getInnerCanvas } from '../../utils/helpers/editor/getInnerCanvas';
import {
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from '../../utils/types/Editor';
import { SurveysEditor } from '../Editor';
import { SurveysEditorHeader } from '../EditorHeader';
import { EditorResultsSection } from '../EditorResultsSection';
import { SurveysEditorSidebar } from '../EditorSidebar';
import { EditorPreviewRightSidebar } from '../EditorSurveyPreviewRightSidebar';
import { If } from '../common';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import {
  useActiveObjects,
  useBackgroundSave,
  useCanvasScroll,
  useCopyPaste,
  useGeneralEventListeners,
  useGetCanvasObjects,
  useKeyDownListener,
  useSelectionListeners,
  useUpdateCanvas,
} from './actions';
import { EditorSurveyResultsPreviewRightSidebar } from '../EditorSurveyResultsPreviewRightSidebar';

interface Props {
  editor: FabricJSEditor | undefined;
  onReady: (canvas: fabric.Canvas) => void;
}

export const SurveysEditorContainer: FC<Props> = ({ editor, onReady }) => {
  const canvas = editor?.canvas as CustomCanvasType;

  const [innerCanvas, setInnerCanvas] = useState(getInnerCanvas(canvas));

  const [canvasRect, setCanvasRect] = useState<InnerCanvasType | null>(null);
  const [canvasObjects, setCanvasObjects] = useState<CustomFabricObject[]>([]);
  const { activeObject, activeObjects, setActiveObject, setActiveObjects } =
    useActiveObjects(canvas);

  const [canvasProps, setCanvasProps] = useUpdateCanvas(canvas);
  const getCanvasObjects = useGetCanvasObjects(canvas, setCanvasObjects);
  const { showPreview, currentCanvasType } = useDistinctSelector('editor');

  useGeneralEventListeners(canvas);
  useSelectionListeners(canvas, setActiveObjects, getCanvasObjects);
  useCanvasScroll(canvas);
  useBackgroundSave(canvas);

  useKeyDownListener(
    canvas,
    activeObjects,
    activeObject,
    getCanvasObjects,
    setActiveObject,
  );

  useCopyPaste({
    canvas,
    activeObject,
    activeObjects,
    onGetCanvasObjects: getCanvasObjects,
  });

  useEffect(() => {
    setActiveObject(canvas?.getActiveObject() || null);
  }, [JSON.stringify(canvas?._activeObject)]);

  useEffect(() => {
    const handleResize = () => {
      if (!canvas) {
        return;
      }

      if (!canvasRect) {
        setCanvasRect(getInnerCanvas(canvas) ?? null);
      }

      if (canvasRect) {
        canvas.setWidth(window.innerWidth - 310);
        canvas.setHeight(window.innerHeight - 121);
        canvas.renderAll();

        centerCanvasView(canvas, canvas.getZoom());
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvas, canvasRect]);

  return (
    <FlexContainer gap={0}>
      <SurveysEditorHeader
        canvas={canvas}
        onGetCanvasObjects={getCanvasObjects}
        onSetActiveObjects={setActiveObjects}
        onSetActiveObject={setActiveObject}
        onSetInnerCanvas={setInnerCanvas}
      />

      <FlexContainer direction='row' gap={0}>
        <If condition={currentCanvasType === 'results'}>
          <EditorResultsSection canvas={canvas} innerCanvas={innerCanvas} />
        </If>

        <SurveysEditor
          onReady={onReady}
          canvas={canvas}
          canvasProps={canvasProps}
          onGetCanvasObjects={getCanvasObjects}
        />

        <If condition={!showPreview}>
          <SurveysEditorSidebar
            canvasProps={canvasProps}
            onCanvasPropsChange={setCanvasProps}
            activeObject={activeObject}
            activeObjects={activeObjects}
            canvasObjects={canvasObjects}
            canvas={canvas}
            onGetCanvasObjects={getCanvasObjects}
            onSetActiveObject={setActiveObject}
          />
        </If>

        <If condition={showPreview && currentCanvasType === 'survey'}>
          <EditorPreviewRightSidebar canvas={canvas} />
        </If>

        <If condition={showPreview && currentCanvasType === 'results'}>
          <EditorSurveyResultsPreviewRightSidebar canvas={canvas} />
        </If>
      </FlexContainer>
    </FlexContainer>
  );
};
