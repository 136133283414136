import { fabric } from 'fabric';
import {
  CustomImageType,
  ImageScaleTransform,
  Size,
} from '../../../types/Editor';
import { applyImageBorberRadius } from '../applyImageBorberRadius';

const controlsUtils = (fabric as any).controlsUtils;
const scaleSkewStyleHandler = controlsUtils.scaleSkewCursorStyleHandler;
const scaleStyleHandler = controlsUtils.scaleCursorStyleHandler;
const scalingEqually = controlsUtils.scalingEqually;
const scalingYOrSkewingX = controlsUtils.scalingYOrSkewingX;
const scalingXOrSkewingY = controlsUtils.scalingXOrSkewingY;
const scaleOrSkewActionName = controlsUtils.scaleOrSkewActionName;

export const CustomImage = fabric.util.createClass(fabric.Image, {
  initialize(element = new Image(), options: Partial<CustomImageType> = {}) {
    this.CLIP_POSITIONS = {
      LEFT_TOP: 'left-top',
      LEFT_MIDDLE: 'left-middle',
      LEFT_BOTTOM: 'left-bottom',
      CENTER_TOP: 'center-top',
      CENTER_MIDDLE: 'center-middle',
      CENTER_BOTTOM: 'center-bottom',
      RIGHT_TOP: 'right-top',
      RIGHT_MIDDLE: 'right-middle',
      RIGHT_BOTTOM: 'right-bottom',
    };

    const validOptions = Object.assign(
      {
        cropHeight: options.cropHeight ?? 0,
        cropWidth: options.cropWidth ?? 0,
        left: options.left ?? 40,
        top: options.top ?? 40,
      },
      options,
    );

    if (
      !('clipPosition' in validOptions) ||
      Object.values(this.CLIP_POSITIONS).indexOf(validOptions.clipPosition) ===
        -1
    ) {
      validOptions.clipPosition = 'center-middle';
    }

    this.callSuper('initialize', element, validOptions);
    this.setControls();

    const width =
      (validOptions?.scaleX as number) * (validOptions?.width as number);
    const height =
      (validOptions?.scaleY as number) * (validOptions?.height as number);

    this.cropWidth = validOptions.cropWidth || this.width || 0;
    this.cropHeight = validOptions.cropHeight || this.height || 0;

    if (!this.disableCrop && this.src) {
      this.applyCrop({
        width: (options?.width ?? 0) * (options?.scaleX ?? 1),
        height: (options?.height ?? 0) * (options?.scaleY ?? 1),
      });
    }

    if (!this.src) {
      this.applyScale(width, height);
    }

    this.type = 'image';
    this.objectId = validOptions.objectId ?? crypto.randomUUID();
    this.async = true;
    this.fileName = validOptions.fileName || '';
    this.fileSize = validOptions.fileSize ?? 0;
    this.disableCrop = false;
    this.cropWidth = validOptions.cropWidth || this.width || 0;
    this.cropHeight = validOptions.cropHeight || this.height || 0;
    this.cropX = validOptions.cropX ?? 0;
    this.cropY = validOptions.cropY ?? 0;
    this.src = validOptions.src ?? element.src;
    this.left = validOptions.left ?? 40;
    this.top = validOptions.top ?? 40;
    this.crossOrigin = 'anonymous';
    this.order = options?.order;
    this.borderRadius = options?.borderRadius ?? 0;
    this.proportionalResize = options?.proportionalResize ?? false;

    if (this.borderRadius) {
      this.set({ clipPath: applyImageBorberRadius(this, this.borderRadius) });
    }

    this.applyObjectLock(options?.isLocked);
    this.applySnapToGrid();
  },

  setSrc(src: string, callback?: (image: CustomImageType) => void) {
    fabric.util.loadImage(
      src,
      imgObj => {
        const scaledWidth = this.getScaledWidth();
        const scaledHeight = this.getScaledHeight();

        if (!imgObj?.src) {
          return;
        }

        this.setElement(imgObj);
        this.set({ src, scaleX: 1, scaleY: 1, crossOrigin: 'anonymous' });

        this.canvas?.renderAll?.();

        if (scaledWidth >= scaledHeight) {
          this.scaleToWidth(scaledWidth);
        } else {
          this.scaleToHeight(scaledHeight);
        }

        this.set({
          width: scaledWidth,
          height: scaledHeight,
          scaleX: 1,
          scaleY: 1,
        });
        this.applyCrop(scaledWidth, scaledHeight);

        if (this.borderRadius) {
          this.set({
            clipPath: applyImageBorberRadius(this, this.borderRadius),
          });
        }

        this.canvas?.renderAll?.();

        callback?.(this);
      },
      undefined,
      'anonymous',
    );
  },

  setControls() {
    const userImageControls = {
      mr: new fabric.Control({
        x: 0.5,
        y: 0,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      ml: new fabric.Control({
        x: -0.5,
        y: 0,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      mt: new fabric.Control({
        x: 0,
        y: -0.5,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      mb: new fabric.Control({
        x: 0,
        y: 0.5,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      tl: new fabric.Control({
        x: -0.5,
        y: -0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      tr: new fabric.Control({
        x: 0.5,
        y: -0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      bl: new fabric.Control({
        x: -0.5,
        y: 0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      br: new fabric.Control({
        x: 0.5,
        y: 0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      mtr: new fabric.Control({
        x: 0,
        y: -0.5,
        actionHandler: controlsUtils.rotationWithSnapping,
        cursorStyleHandler: controlsUtils.rotationStyleHandler,
        offsetY: -40,
        withConnection: true,
        actionName: 'rotate',
      }),
    };

    this.controls = userImageControls;
  },

  getCrop(image: Size, size: Size) {
    const width = size.width;
    const height = size.height;
    const aspectRatio = width / height;

    let newWidth: number;
    let newHeight: number;

    const imageRatio = image.width / image.height;

    if (aspectRatio >= imageRatio) {
      newWidth = image.width;
      newHeight = image.width / aspectRatio;
    } else {
      newWidth = image.height * aspectRatio;
      newHeight = image.height;
    }

    let x = 0;
    let y = 0;

    switch (this.clipPosition) {
      case this.CLIP_POSITIONS.LEFT_TOP:
        x = 0;
        y = 0;
        break;
      case this.CLIP_POSITIONS.LEFT_MIDDLE:
        x = 0;
        y = (image.height - newHeight) / 2;
        break;
      case this.CLIP_POSITIONS.LEFT_BOTTOM:
        x = 0;
        y = image.height - newHeight;
        break;
      case this.CLIP_POSITIONS.CENTER_TOP:
        x = (image.width - newWidth) / 2;
        y = 0;
        break;
      case this.CLIP_POSITIONS.CENTER_MIDDLE:
        x = (image.width - newWidth) / 2;
        y = (image.height - newHeight) / 2;
        break;
      case this.CLIP_POSITIONS.CENTER_BOTTOM:
        x = (image.width - newWidth) / 2;
        y = image.height - newHeight;
        break;
      case this.CLIP_POSITIONS.RIGHT_TOP:
        x = image.width - newWidth;
        y = 0;
        break;
      case this.CLIP_POSITIONS.RIGHT_MIDDLE:
        x = image.width - newWidth;
        y = (image.height - newHeight) / 2;
        break;
      case this.CLIP_POSITIONS.RIGHT_BOTTOM:
        x = image.width - newWidth;
        y = image.height - newHeight;
        break;
    }

    return {
      cropX: x,
      cropY: y,
      cropWidth: newWidth,
      cropHeight: newHeight,
    };
  },

  getContained() {
    const img = new Image();

    img.src = this.src;

    img.onload = () => {
      const temp = new fabric.Image(img);

      const scaleFactor = Math.max(
        this.width * this.scaleX,
        this.height * this.scaleY,
      );

      if (scaleFactor === this.width * this.scaleX) {
        temp.scaleToWidth(scaleFactor);
      } else {
        temp.scaleToHeight(scaleFactor);
      }

      const pattern = new fabric.Pattern({
        source: temp as unknown as HTMLImageElement,
      });

      this.set({
        fill: pattern,
      });
    };
  },

  applyCrop(newWidth?: number, newHeight?: number) {
    if (this.disableCrop) {
      // this.getContained();

      return;
    }

    const crop = this.getCrop(this.getOriginalSize(), {
      width: newWidth ?? this.getScaledWidth(),
      height: newHeight ?? this.getScaledHeight(),
    });

    this.set(crop);
    this.applyBorderRadiusOnScale(this);
    this.setCoords();
  },

  _render(ctx: CanvasRenderingContext2D) {
    if (this.disableCrop) {
      this.cropX = 0;
      this.cropY = 0;
      this.callSuper('_render', ctx);

      return;
    }

    const width = this.width;
    const height = this.height;
    const cropWidth = this.cropWidth;
    const cropHeight = this.cropHeight;
    const cropX = this.cropX;
    const cropY = this.cropY;

    ctx.save();

    ctx.drawImage(
      this._element,
      Math.max(cropX, 0),
      Math.max(cropY, 0),
      Math.max(1, cropWidth),
      Math.max(1, cropHeight),
      -width / 2,
      -height / 2,
      Math.max(0, width),
      Math.max(0, height),
    );
    ctx.restore();
  },

  toObject(options: string[] = []): Record<string, any> {
    return this.callSuper(
      'toObject',
      ([] as string[]).concat(Array.from(options), [
        'cropWidth',
        'cropHeight',
        'disableCrop',
      ]),
    );
  },

  actionScalingOrSkewingCropHandler(
    eventData: any,
    transform: ImageScaleTransform,
    x: number,
    y: number,
  ) {
    const { target, corner } = transform;

    target.applyCrop();

    const { shiftKey, ...eventDataWithoutShiftkey } = eventData;

    if (corner === 'mr' || corner === 'ml') {
      return scalingXOrSkewingY(
        { ...eventDataWithoutShiftkey, isShiftPressed: shiftKey },
        transform,
        x,
        y,
      );
    }

    if (corner === 'mt' || corner === 'mb') {
      return scalingYOrSkewingX(
        { ...eventDataWithoutShiftkey, isShiftPressed: shiftKey },
        transform,
        x,
        y,
      );
    }
  },

  actionScalingEquallyCropHandler(
    eventData: any,
    transform: ImageScaleTransform,
    x: number,
    y: number,
  ) {
    const { target, corner } = transform;

    if (['tl', 'tr', 'bl', 'br'].indexOf(corner) > -1 && eventData.shiftKey) {
      target.applyCrop();
    }

    const { shiftKey, ...eventDataWithoutShiftkey } = eventData;

    return scalingEqually(
      { ...eventDataWithoutShiftkey, isShiftPressed: shiftKey },
      transform,
      x,
      y,
    );
  },

  applyScale(width: number = 200, height: number = 200) {
    const newWidth = isNaN(width) ? 200 : width;
    const newHeight = isNaN(height) ? 200 : height;

    if (newWidth >= newHeight) {
      this.scaleToWidth(newWidth);
    } else {
      this.scaleToHeight(newHeight);
    }
  },

  applyBorderRadiusOnScale(target: CustomImageType) {
    const scaledWidth = target.getScaledWidth();
    const scaledHeight = target.getScaledHeight();

    let borderRadius = target.borderRadius ?? 0;

    if (scaledWidth > scaledHeight && scaledHeight / 2 < borderRadius) {
      borderRadius = scaledHeight / 2;
    } else if (scaledWidth < scaledHeight && scaledWidth / 2 < borderRadius) {
      borderRadius = scaledWidth / 2;
    }

    if (target.borderRadius) {
      target.set({
        borderRadius,
        clipPath: applyImageBorberRadius(target, borderRadius),
      });
    }
  },

  applyObjectLock(isLocked?: boolean) {
    this.isLocked = isLocked !== undefined ? isLocked : this.isLocked ?? false;

    if (this.isLocked) {
      this.lockMovementX = true;
      this.lockMovementY = true;
      this.hasControls = false;
      this.hasBorders = false;
      this.evented = false;
      this.selectable = false;
    } else {
      this.lockMovementX = false;
      this.lockMovementY = false;
      this.hasControls = true;
      this.hasBorders = true;
      this.evented = true;
      this.selectable = true;
    }
  },

  getSnappedToGrid(value: number, step: number = 10) {
    return Math.round(value / step) * step;
  },

  applySnapToGrid() {
    this.on('scaling', e => {
      const { target, corner } = e.transform;
      const { x, y } = e.pointer;
      const { isShiftPressed = false } = e.e;

      let desiredWidth = target.getSnappedToGrid(x - target.left, 10) || 1;
      let desiredHeight = target.getSnappedToGrid(y - target.top, 10) || 1;

      let newX = target.getSnappedToGrid(x, 10);
      let newY = target.getSnappedToGrid(y, 10);

      const destY = target.top + target.getScaledHeight();
      let height = target.getSnappedToGrid(Math.abs(destY - newY)) || 1;

      const destX = target.left + target.getScaledWidth();
      let width = target.getSnappedToGrid(Math.abs(destX - newX)) || 1;

      if (isShiftPressed) {
        desiredWidth = target.getSnappedToGrid(x - target.left, 1) || 1;
        desiredHeight = target.getSnappedToGrid(y - target.top, 1) || 1;

        newX = target.getSnappedToGrid(x, 1) || 1;
        newY = target.getSnappedToGrid(y, 1) || 1;

        height = target.getSnappedToGrid(Math.abs(destY - newY), 1) || 1;
        width = target.getSnappedToGrid(Math.abs(destX - newX), 1) || 1;
      }

      if (isNaN(height) || isNaN(destY) || isNaN(width) || isNaN(destX)) {
        return;
      }

      switch (corner) {
        case 'ml': {
          if (width < 10) {
            target.set({
              width: desiredWidth || 1,
              scaleX: 1,
            });

            break;
          }

          target.set({
            left: newX,
            width,
            scaleX: 1,
          });

          break;
        }

        case 'mr': {
          if (desiredWidth < 10) {
            target.set({
              left: newX,
              width,
              scaleX: 1,
            });

            break;
          }

          target.set({
            width: desiredWidth || 1,
            scaleX: 1,
          });

          break;
        }

        case 'mt': {
          if (height < 10) {
            target.set({
              height: desiredHeight || 1,
              scaleY: 1,
            });

            break;
          }

          target.set({
            top: newY,
            height,
            scaleY: 1,
          });

          break;
        }

        case 'mb': {
          if (desiredHeight < 10) {
            target.set({
              top: newY,
              height,
              scaleY: 1,
            });

            break;
          }

          target.set({
            height: desiredHeight || 1,
            scaleY: 1,
          });

          break;
        }

        case 'br': {
          target.set({
            width: desiredWidth,
            height: desiredHeight,
            scaleX: 1,
            scaleY: 1,
          });

          if (desiredWidth < 10) {
            target.set({
              width: 1,
            });
          }

          if (desiredHeight < 10) {
            target.set({
              height: 1,
            });
          }

          break;
        }

        case 'tr': {
          if (height < 10) {
            target.set({
              width: desiredWidth || 1,
              height: desiredHeight || 1,
              scaleX: 1,
              scaleY: 1,
            });

            break;
          }

          target.set({
            top: newY,
            width: desiredWidth || 1,
            height,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }

        case 'tl': {
          target.set({
            width,
            height,
            left: newX,
            top: newY,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }

        case 'bl': {
          target.set({
            width,
            height: desiredHeight || 1,
            left: newX,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }
      }

      target.applyCrop();
      target.canvas.renderAll();
    });
  },
}) as new (
  element: HTMLImageElement,
  options?: Partial<CustomImageType>,
) => CustomImageType;
