type BooleanCallback = (...args: unknown[]) => boolean;

export const isInstanceOf = <T>(
  value: unknown,
  callback?: BooleanCallback | boolean,
): value is T => {
  if (typeof callback === 'function') {
    if (callback?.(value)) {
      return true;
    }
  } else {
    if (callback) {
      return true;
    }
  }

  return false;
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type MakeMutable<Type> = {
  -readonly [Property in keyof Type]: Type[Property];
};

export type MakeReadonly<Type> = {
  readonly [Property in keyof Type]: Type[Property];
};
