import React, { useEffect, useState } from 'react';
import { useSmartLinkData } from 'src/utils/hooks/useSmarLinkData';
import { SmartLinkMailchimpData } from '../SmartLinkMailchimpData';
import { SmartLinkDestionationUrlBlock } from '../SmartLinkDestionationUrlBlock';
import { SmartLinkResultBlock } from '../SmartLinkResultBlock';
import { useCreateSmartLink } from './actions/useCreateSmartLink';
import { useAppDispatch } from 'src/redux/store';
import { smartLinkActions } from 'src/redux/slice/smartLink';
import styles from './index.module.scss';
import { useUpdateSmartLink } from './actions/useUpdateSmartLink';

export const SmartLinkCreatingSteps = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    groupOrLabel,
    mergeTag,
    data,
    saveAs,
    handleMergeTagChange,
    handleGroupOrLabelChange,
    handleDataChange,
    setSaveAs,
  } = useSmartLinkData();

  const { smartLink, handleCreateSmartLink } = useCreateSmartLink(setIsLoading);
  const { handleUpdateSmartLink } = useUpdateSmartLink(setIsLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(smartLinkActions.clear());
    };
  }, []);

  return (
    <section className={styles.create_data_url_page__main}>
      <SmartLinkMailchimpData
        groupOrLabel={groupOrLabel}
        mergeTag={mergeTag}
        data={data}
        saveAs={saveAs}
        handleMergeTagChange={handleMergeTagChange}
        handleGroupOrLabelChange={handleGroupOrLabelChange}
        handleDataChange={handleDataChange}
        setSaveAs={setSaveAs}
      />

      <SmartLinkDestionationUrlBlock
        data={data}
        isLoading={isLoading}
        handleCreateSmartLink={handleCreateSmartLink}
        handleUpdateSmartLink={handleUpdateSmartLink}
        handleDataChange={handleDataChange}
      />

      <SmartLinkResultBlock smartLink={smartLink} />
    </section>
  );
};
