import { useEffect, useState } from 'react';
import { getSurveyCanvas } from 'src/utils/helpers/editor/getSurveyCanvas';
import { getSurveyResultsCanvases } from 'src/utils/helpers/editor/getSurveyResultsCanvases';
import { checkForMergeTags } from 'src/utils/helpers/saveDynamicTexts';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomFabricObject, CustomTextBoxType } from 'src/utils/types/Editor';

export type MergeTagListItem = {
  mergeTag: string;
  value: string;
  originalLinks: Record<string, string>;
};

export const useMergeTags = () => {
  const [mergeTagsList, setMergeTagsList] = useState<MergeTagListItem[]>([]);
  const { currentCanvasType, currentCanvasId } = useDistinctSelector('editor');

  useEffect(() => {
    const getMergeTagList = async () => {
      let objects: CustomFabricObject[] = [];

      if (currentCanvasType === 'survey') {
        const { objects: surveyObjects } = await getSurveyCanvas();

        objects = surveyObjects;
      } else {
        const canvases = await getSurveyResultsCanvases();
        const { objects: resultsObjects } = canvases.find(
          canvas => canvas.canvasId === currentCanvasId,
        )!;

        objects = resultsObjects;
      }

      const textObjects = objects.filter(
        obj => obj.type === 'text',
      ) as CustomTextBoxType[];

      const textObjectsWithMergeTags = textObjects.filter(obj =>
        checkForMergeTags(obj.text),
      );

      const newMergeTagList: MergeTagListItem[] = textObjectsWithMergeTags.map(
        obj => {
          const temp = getMergeTagItems(obj.text, obj);

          return temp.map(item => ({ ...item, originalText: obj.text }));
        },
      );

      let uniqueArray: MergeTagListItem[] = [];

      for (const mergeTag of newMergeTagList.flat(Infinity)) {
        const foundItem = uniqueArray.find(
          item => item.mergeTag === mergeTag.mergeTag,
        );

        if (!foundItem) {
          uniqueArray.push(mergeTag);

          continue;
        }

        uniqueArray = [
          ...uniqueArray.filter(item => item.mergeTag !== mergeTag.mergeTag),
          {
            ...mergeTag,
            originalLinks: {
              ...mergeTag.originalLinks,
              ...foundItem.originalLinks,
            },
          },
        ];
      }

      setMergeTagsList(uniqueArray);
    };

    getMergeTagList();
  }, [currentCanvasId, currentCanvasType]);

  return { mergeTagsList, setMergeTagsList };
};

const regex = /\*\|([A-Za-z0-9]{1,10})\|\*/;

const getMergeTagItems = (
  text: string,
  object: CustomTextBoxType,
  items: Omit<MergeTagListItem, 'originalText'>[] = [],
) => {
  const match = text.match(regex);

  if (!match) {
    return items;
  }

  const tempText = text.replace(match[0], '');

  if (items.some(item => item.mergeTag === match[1])) {
    return getMergeTagItems(tempText, object, items);
  }

  return getMergeTagItems(tempText, object, [
    ...items,
    {
      mergeTag: match?.[1] ?? '',
      value: '',
      originalLinks: { [object.objectId]: object.text },
    },
  ]);
};
