import { AxiosError } from 'axios';
import { isInstanceOf } from '../types/general/generics';
import { toast } from 'react-toastify';
import { ServerCustomError } from '../types/general/api';

export const showErrorMessage = (error: unknown) => {
  if (
    isInstanceOf<AxiosError<ServerCustomError>>(
      error,
      Boolean((error as AxiosError<ServerCustomError>).response?.data?.message),
    )
  ) {
    toast.error(error.response?.data.message || '');

    return;
  }

  if (isInstanceOf<Error>(error, Boolean((error as Error)?.message))) {
    toast.error(error.message || '');

    return;
  }

  toast.error('Unexpexted error occured!');
};
