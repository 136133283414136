import { fabric } from 'fabric';
import { ClickableAreaType } from '../../../types/Editor';


const controlsUtils = (fabric as any).controlsUtils;
const scaleSkewStyleHandler = controlsUtils.scaleSkewCursorStyleHandler;
const scaleStyleHandler = controlsUtils.scaleCursorStyleHandler;
const scalingEqually = controlsUtils.scalingEqually;
const scalingYOrSkewingX = controlsUtils.scalingYOrSkewingX;
const scalingXOrSkewingY = controlsUtils.scalingXOrSkewingY;
const scaleOrSkewActionName = controlsUtils.scaleOrSkewActionName;

export const ClickableArea = fabric.util.createClass(fabric.Rect, {
  width: 200,
  height: 200,
  fill: 'rgba(69, 134, 159, 0.2)',
  strokeWidth: 0,
  selectable: true,
  type: 'clickableArea',

  initialize: function (options: Partial<ClickableAreaType> = {}) {
    this.callSuper('initialize', {
      ...options,
      objectCaching: false,
      lockUniScaling: true,
    });
    this.setControls();
    this.applySnapToGrid();

    this.objectId = options?.objectId || crypto.randomUUID();
    this.destinationUrl = options?.destinationUrl || '';
    this.value = options?.value ?? null;
    this.isValidUrlData = options?.isValidUrlData ?? true;
    this.isValidDestinationUrl = options?.isValidDestinationUrl ?? true;
    this.type = options?.type || 'clickableArea';
    this.proportionalResize = options?.proportionalResize || false;
    this.defaultColor = options?.defaultColor || 'rgba(0, 0, 0, 0.2)';
    this.enablePrefilledFields = options?.enablePrefilledFields ?? false;
    this.prefilledFields = options?.prefilledFields ?? [];
    this.applyObjectLock(options?.isLocked);

    if (!this.isValidUrlData || !this.isValidDestinationUrl) {
      this.set({
        fill: 'rgba(255, 2, 0, 0.2)',
        borderColor: 'rgba(255, 2, 0, 0.5)',
        cornerStrokeColor: 'rgba(255, 2, 0, 0.5)',
      });
    }
  },

  validateUrlData() {
    if (!this.value) {
      this.isValidUrlData = false;
    } else {
      this.isValidUrlData = true;
    }

    this.changeAppearanceBasedOnValidity();

    if (!this.isValidUrlData) {
      return false;
    }

    return true;
  },

  changeAppearanceBasedOnValidity() {
    if (!this.isValidUrlData || !this.isValidDestinationUrl) {
      this.set({
        fill: 'rgba(255, 2, 0, 0.2)',
        borderColor: 'rgba(255, 2, 0, 0.5)',
        cornerStrokeColor: 'rgba(255, 2, 0, 0.5)',
      });
      this.canvas.requestRenderAll();

      return;
    }

    this.set({
      fill: 'rgba(69, 134, 159, 0.2)',
      borderColor: 'rgb(178,204,255)',
      cornerStrokeColor: 'rgb(178,204,255)',
    });
    this.canvas.requestRenderAll();
  },

  updateUrlData(value: string) {
    if (value && !this.isValidUrlData) {
      this.set({ isValidUrlData: true });
    }

    this.value = value;

    this.canvas.requestRenderAll();
  },

  applyObjectLock(isLocked?: boolean) {
    this.isLocked = isLocked !== undefined ? isLocked : this.isLocked ?? false;

    if (this.isLocked) {
      this.lockMovementX = true;
      this.lockMovementY = true;
      this.hasControls = false;
      this.hasBorders = false;
      this.evented = false;
      this.selectable = false;
    } else {
      this.lockMovementX = false;
      this.lockMovementY = false;
      this.hasControls = true;
      this.hasBorders = true;
      this.evented = true;
      this.selectable = true;
    }
  },

  setControls() {
    const clickableAreaControls = {
      mr: new fabric.Control({
        x: 0.5,
        y: 0,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      ml: new fabric.Control({
        x: -0.5,
        y: 0,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      mt: new fabric.Control({
        x: 0,
        y: -0.5,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      mb: new fabric.Control({
        x: 0,
        y: 0.5,
        cursorStyleHandler: scaleSkewStyleHandler,
        getActionName: scaleOrSkewActionName,
        actionHandler: this.actionScalingOrSkewingCropHandler,
      }),

      tl: new fabric.Control({
        x: -0.5,
        y: -0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      tr: new fabric.Control({
        x: 0.5,
        y: -0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      bl: new fabric.Control({
        x: -0.5,
        y: 0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      br: new fabric.Control({
        x: 0.5,
        y: 0.5,
        cursorStyleHandler: scaleStyleHandler,
        actionHandler: this.actionScalingEquallyCropHandler,
      }),

      mtr: new fabric.Control({
        x: 0,
        y: -0.5,
        actionHandler: controlsUtils.rotationWithSnapping,
        cursorStyleHandler: controlsUtils.rotationStyleHandler,
        offsetY: -40,
        withConnection: true,
        actionName: 'rotate',
      }),
    };

    this.controls = clickableAreaControls;
  },

  actionScalingOrSkewingCropHandler(
    eventData: any,
    transform: any,
    x: number,
    y: number,
  ) {
    const { corner } = transform;
    const { shiftKey, ...eventDataWithoutShiftkey } = eventData;

    if (corner === 'mr' || corner === 'ml') {
      return scalingXOrSkewingY(
        { ...eventDataWithoutShiftkey, isShiftPressed: shiftKey },
        transform,
        x,
        y,
      );
    }

    if (corner === 'mt' || corner === 'mb') {
      return scalingYOrSkewingX(
        { ...eventDataWithoutShiftkey, isShiftPressed: shiftKey },
        transform,
        x,
        y,
      );
    }
  },

  actionScalingEquallyCropHandler(
    eventData: any,
    transform: any,
    x: number,
    y: number,
  ) {
    const { shiftKey, ...eventDataWithoutShiftkey } = eventData;

    return scalingEqually(
      { ...eventDataWithoutShiftkey, isShiftPressed: shiftKey },
      transform,
      x,
      y,
    );
  },

  applySnapToGrid() {
    this.on('scaling', e => {
      const { target, corner } = e.transform;
      const { x, y } = e.pointer;
      const { isShiftPressed = false } = e.e;

      let desiredWidth = target.getSnappedToGrid(x - target.left, 10) || 1;
      let desiredHeight = target.getSnappedToGrid(y - target.top, 10) || 1;

      let newX = target.getSnappedToGrid(x, 10);
      let newY = target.getSnappedToGrid(y, 10);

      const destY = target.top + target.getScaledHeight();
      let height = target.getSnappedToGrid(Math.abs(destY - newY)) || 1;

      const destX = target.left + target.getScaledWidth();
      let width = target.getSnappedToGrid(Math.abs(destX - newX)) || 1;

      if (isShiftPressed) {
        desiredWidth = target.getSnappedToGrid(x - target.left, 1) || 1;
        desiredHeight = target.getSnappedToGrid(y - target.top, 1) || 1;

        newX = target.getSnappedToGrid(x, 1) || 1;
        newY = target.getSnappedToGrid(y, 1) || 1;

        height = target.getSnappedToGrid(Math.abs(destY - newY), 1) || 1;
        width = target.getSnappedToGrid(Math.abs(destX - newX), 1) || 1;
      }

      if (isNaN(height) || isNaN(destY) || isNaN(width) || isNaN(destX)) {
        return;
      }

      switch (corner) {
        case 'ml': {
          if (width < 10) {
            target.set({
              width: desiredWidth || 1,
              scaleX: 1,
            });

            break;
          }

          target.set({
            left: newX,
            width,
            scaleX: 1,
          });

          break;
        }

        case 'mr': {
          if (desiredWidth < 10) {
            target.set({
              left: newX,
              width,
              scaleX: 1,
            });

            break;
          }

          target.set({
            width: desiredWidth || 1,
            scaleX: 1,
          });

          break;
        }

        case 'mt': {
          if (height < 10) {
            target.set({
              height: desiredHeight || 1,
              scaleY: 1,
            });

            break;
          }

          target.set({
            top: newY,
            height,
            scaleY: 1,
          });

          break;
        }

        case 'mb': {
          if (desiredHeight < 10) {
            target.set({
              top: newY,
              height,
              scaleY: 1,
            });

            break;
          }

          target.set({
            height: desiredHeight || 1,
            scaleY: 1,
          });

          break;
        }

        case 'br': {
          target.set({
            width: desiredWidth,
            height: desiredHeight,
            scaleX: 1,
            scaleY: 1,
          });

          if (desiredWidth < 10) {
            target.set({
              width: 1,
            });
          }

          if (desiredHeight < 10) {
            target.set({
              height: 1,
            });
          }

          break;
        }

        case 'tr': {
          if (height < 10) {
            target.set({
              width: desiredWidth || 1,
              height: desiredHeight || 1,
              scaleX: 1,
              scaleY: 1,
            });

            break;
          }

          target.set({
            top: newY,
            width: desiredWidth || 1,
            height,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }

        case 'tl': {
          target.set({
            width,
            height,
            left: newX,
            top: newY,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }

        case 'bl': {
          target.set({
            width,
            height: desiredHeight || 1,
            left: newX,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }
      }

      target.canvas.renderAll();
    });
  },

  getSnappedToGrid(value: number, step: number = 10) {
    return Math.round(value / step) * step;
  },
}) as new (options?: Partial<ClickableAreaType>) => ClickableAreaType;
