import {
  ClickableAreaType,
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from 'src/utils/types/Editor';
import { getInnerCanvas } from './getInnerCanvas';
import { toast } from 'react-toastify';
import { getSurveyCanvas } from './getSurveyCanvas';

export const checkClickableAreasForValidity = async (
  canvas: CustomCanvasType,
  showError = true,
) => {
  const innerCanvas = getInnerCanvas(canvas);
  let hasError = false;

  if (innerCanvas.canvasType === 'survey') {
    const clickableAreas = canvas.getObjectsByType('clickableArea');

    clickableAreas.forEach(area => area.validateUrlData());

    hasError = clickableAreas.some(
      area => !area.isValidDestinationUrl || !area.isValidUrlData,
    );
  } else {
    const surveyCanvasObjects: (CustomFabricObject | InnerCanvasType)[] = (
      await getSurveyCanvas()
    ).objects;
    const clickableAreas = surveyCanvasObjects.filter(
      object => object.type === 'clickableArea',
    ) as ClickableAreaType[];

    hasError = clickableAreas.some(
      area => !area.isValidDestinationUrl || !area.isValidUrlData,
    );
  }

  if (hasError) {
    if (showError) {
      toast.error('Please, fix the clickable areas data!');
    }

    return false;
  }

  return true;
};
