import { FabricJSEditor } from 'fabricjs-react';
import { useEffect, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { surveyClient } from 'src/api';
import {
  getFromEditorLocalbase,
  saveToEditorLocalbase,
} from 'src/api/local/editor';
import { editorActions } from 'src/redux/slice/editor';
import { surveyActions } from 'src/redux/slice/survey';
import { useAppDispatch } from 'src/redux/store';
import { loadSurveyCanvasObjects } from 'src/utils/helpers/editor/loadSurveyCanvasObjects';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { safeParse } from 'src/utils/helpers/safeParse';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType } from 'src/utils/types/Editor';
import { SurveyCanvas, SurveyResultsCanvases } from 'src/utils/types/Survey';

export const useGetSurveyDataFromServer = (
  editor: FabricJSEditor | undefined,
) => {
  const [isLoadingSurvey, setIsLoadingSurvey] = useState(false);
  const [hasInitLoad, setHasInitLoad] = useState(false);

  const survey = useDistinctSelector('survey');
  const { isLoadingObjects } = useDistinctSelector('editor');
  const { surveyId = '' } = useParams<Params>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getSurveyData = async () => {
      try {
        setIsLoadingSurvey(true);

        dispatch(editorActions.update({ isLoadingObjects: true }));

        const surveyDataFromServer =
          await surveyClient.getSurveyWithCanvases(surveyId);

        const surveyCanvasObjectsFromLocalbase =
          await getFromEditorLocalbase('surveyCanvas');
        const resultCanvasesObjectsFromLocalbase =
          await getFromEditorLocalbase('resultsCanvases');

        const parsedSurveyData = safeParse(
          surveyDataFromServer.data.surveyCanvas,
        );
        const parsedSurveyResultsData = safeParse(
          surveyDataFromServer.data.resultsCanvases,
        );

        let surveyCanvasObjects: SurveyCanvas =
          surveyCanvasObjectsFromLocalbase;
        let surveyResultsCanvasObjects: SurveyResultsCanvases =
          resultCanvasesObjectsFromLocalbase?.canvases;

        if (
          !surveyCanvasObjects ||
          surveyCanvasObjects?.objects?.length === 0
        ) {
          surveyCanvasObjects = parsedSurveyData || { objects: [] };
        }

        if (
          !surveyResultsCanvasObjects ||
          surveyResultsCanvasObjects?.[0]?.objects?.length === 0
        ) {
          surveyResultsCanvasObjects = parsedSurveyResultsData || [
            { canvasId: 'fallback', objects: [] },
          ];
        }

        saveToEditorLocalbase('resultsCanvases', {
          canvases: surveyResultsCanvasObjects,
        });
        saveToEditorLocalbase('surveyCanvas', surveyCanvasObjects);

        dispatch(
          surveyActions.update({
            ...surveyDataFromServer.data,
            initSurveyObjectsJSON: surveyCanvasObjects.objects,
            initResultsObjectsJSON: surveyResultsCanvasObjects,
          }),
        );
      } catch (error: unknown) {
        showErrorMessage(error);
      } finally {
        setIsLoadingSurvey(false);
        dispatch(editorActions.update({ isLoadingObjects: false }));
      }
    };

    if (!survey.title) {
      getSurveyData();
    }
  }, []);

  useEffect(() => {
    if (
      !survey.initSurveyObjectsJSON ||
      !Object.keys(survey.initSurveyObjectsJSON).length
    ) {
      return;
    }

    if (hasInitLoad || isLoadingObjects) {
      return;
    }

    if (!editor?.canvas) {
      return;
    }

    loadSurveyCanvasObjects(editor?.canvas as CustomCanvasType).then(() =>
      setHasInitLoad(true),
    );
  }, [editor?.canvas, survey.initSurveyObjectsJSON, hasInitLoad]);

  return { isLoadingSurvey };
};
