import { useState, useEffect } from 'react';
import { isValidUrl } from 'src/utils/helpers/validateURL';
import { ClickableAreaType } from 'src/utils/types/Editor';

export const useDestinationUrl = (activeObject: ClickableAreaType) => {
  const [destinationUrl, setDestinationUrl] = useState(
    activeObject.destinationUrl || '',
  );
  const [, setIsValidDestinationUrl] = useState(true);

  useEffect(() => {
    setDestinationUrl(activeObject.destinationUrl || '');
  }, [activeObject.objectId]);

  useEffect(() => {
    activeObject.set({ destinationUrl });
  }, [destinationUrl]);

  const handleDestinationUrlBlur = () => {
    if (!destinationUrl || isValidUrl(destinationUrl)) {
      activeObject.set({ isValidDestinationUrl: true });
    } else {
      activeObject.set({ isValidDestinationUrl: false });
    }

    setIsValidDestinationUrl(prev => !prev);

    activeObject.changeAppearanceBasedOnValidity();
  };

  return {
    destinationUrl,
    setDestinationUrl,
    handleDestinationUrlBlur,
  };
};
