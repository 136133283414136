import React, { FC } from 'react';
import { AccountStatsSurveyInfo } from 'src/utils/types/Mailchimp';

const arrow = (
  <span style={{ whiteSpace: 'nowrap' }}>
    &nbsp;&nbsp;&nbsp;→&nbsp;&nbsp;&nbsp;
  </span>
);

type Props = {
  item: AccountStatsSurveyInfo;
  value: string;
};

export const SurveyEnterData: FC<Props> = ({ item, value }) => {
  switch (item.saveResultAs) {
    case 'mergeTag':
      return <p>{generateMergeTagData(item.mergeTag, value)}</p>;

    case 'tag':
      return <p>{generateTagData(value, 'add')}</p>;

    case 'group':
      return <p>{generateGroupData(item.groupOrLabel, value, 'add')}</p>;
  }
};

const generateMergeTagData = (tag: string, value: string) => {
  const correctMergeTag = tag;
  const correctValue = value;

  const highlightedMergeTag = (
    <span style={{ whiteSpace: 'nowrap' }}>
      {highlightData(`*|${correctMergeTag}|*`, 'Merge Tag: ')}
    </span>
  );
  const highlightedValue = highlightData(correctValue, 'Value: ');

  return (
    <span
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      {highlightedMergeTag} {arrow} {highlightedValue}
    </span>
  );
};

const generateTagData = (tag: string, action: string) => {
  const correctTag = tag;

  const highlightedValue = highlightData(correctTag, `${action}: `);

  return (
    <span
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      Tags {arrow} {highlightedValue}
    </span>
  );
};

const generateGroupData = (group: string, subgroup: string, action: string) => {
  const correctGroup = group;
  const correctSubgroup = subgroup;

  const highlightedGroup = highlightData(correctGroup, 'Group: ');

  return (
    <span
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      Groups {arrow} {highlightedGroup} {arrow}{' '}
      {highlightData(correctSubgroup, `${action}: `)}
    </span>
  );
};

const highlightData = (data: string, title: string) => {
  return (
    <span
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      {title}
      <span style={{ color: '#437E05' }}>{data}</span>
    </span>
  );
};
