import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ActionType, DataURL } from '../../utils/types/DataURL';
import { ButtonClicksTrackingInfoDataURLType } from '../../utils/types/CreatedDataURLStats';

const initialState: DataURL = {
  mergeTag: null,
  group: null,
  tag: null,
};

const dataUrlSlice = createSlice({
  name: 'dataUrl',
  initialState,
  reducers: {
    update: (dataUrl, action: PayloadAction<Partial<DataURL>>) => ({
      ...dataUrl,
      ...action.payload,
    }),
    clear: () => initialState,
    clearValue: (
      dataUrl,
      action: PayloadAction<ButtonClicksTrackingInfoDataURLType>,
    ) => {
      switch (action.payload) {
        case 'mergeTag':
          return {
            group: null,
            tag: null,
            mergeTag: {
              fieldLabel: dataUrl.mergeTag?.fieldLabel || '',
              fieldType: dataUrl.mergeTag?.fieldType || 'text',
              mergeTag: dataUrl.mergeTag?.mergeTag || '',
              value: '',
            },
          };

        case 'group':
          return {
            mergeTag: null,
            tag: null,
            group: {
              group: dataUrl.group?.group || '',
              action: 'add',
              subgroup: '',
            },
          };

        case 'tag':
          return {
            group: null,
            mergeTag: null,
            tag: {
              action: 'add',
              tag: '',
            },
          };
      }
    },
    clearAllExpectOne: (
      dataUrl,
      action: PayloadAction<ButtonClicksTrackingInfoDataURLType>,
    ) => {
      switch (action.payload) {
        case 'mergeTag':
          return {
            group: null,
            tag: null,
            mergeTag: {
              fieldLabel: dataUrl.mergeTag?.fieldLabel || '',
              fieldType: dataUrl.mergeTag?.fieldType || 'text',
              mergeTag: dataUrl.mergeTag?.mergeTag || '',
              value: dataUrl.mergeTag?.value || '',
            },
          };

        case 'group':
          return {
            mergeTag: null,
            tag: null,
            group: {
              group: dataUrl.group?.group || '',
              action: 'add' satisfies ActionType,
              subgroup: dataUrl.group?.subgroup || '',
            },
          };

        case 'tag':
          return {
            group: null,
            mergeTag: null,
            tag: {
              action: 'add' satisfies ActionType,
              tag: dataUrl.tag?.tag || '',
            },
          };
      }
    },
  },
});

export default dataUrlSlice.reducer;
export const dataUrlActions = dataUrlSlice.actions;
