import React, { FC } from 'react';
import styles from './index.module.scss';
import * as images from '../../assets/index';
import { convertDate } from 'src/utils/helpers/convertDate';
import { FlexContainer } from '../common';

type Props = {
  date: string;
};

export const MailchimpAccountActionsTableItemDate: FC<Props> = ({ date }) => {
  return (
    <FlexContainer
      direction='row'
      align='center'
      className={styles.account_stat_item_date}
    >
      <img
        src={images.calendarIcon}
        alt='Calendar Icon'
        className={styles.account_stat_item_date__icon}
      />

      <p className={styles.account_stat_item_date__date}>{convertDate(date)}</p>
    </FlexContainer>
  );
};
