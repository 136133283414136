export const getFontStyles = (activeObject: any) => {
  const fontStyles: string[] = [];

  if (activeObject.underline) {
    fontStyles.push('underline');
  }

  if (activeObject.linethrough) {
    fontStyles.push('linethrough');
  }

  if (activeObject.fontStyle === 'italic') {
    fontStyles.push('italic');
  }

  if (activeObject.fontWeight === 700) {
    fontStyles.push('bold');
  }

  return fontStyles;
};
