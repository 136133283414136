import React, { useEffect, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import * as images from '../../assets/index';
import {
  MailchimpAccountInfo,
  useDeleteMailchimpAccountMutation,
  useGetMailchimpAccountsQuery,
} from '../../redux/slice/mailchimpApi';
import { remakeString } from '../../utils/helpers/encodeAPIKey';
import { useModal } from '../../utils/hooks/useModal';
import { Button } from '../common/Button/Button';
import { Input } from '../common/Input/Input';
import { Modal } from '../common/Modal/Modal';
import styles from './MailchimpAccountDetails.module.scss';

export const MailchimpAccountDetails = () => {
  const [currentAccount, setCurrentAccount] =
    useState<MailchimpAccountInfo | null>(null);

  const { accountId = '' } = useParams<Params>();
  const navigate = useNavigate();

  const [deleteMailchimpAccount, { isLoading, isError }] =
    useDeleteMailchimpAccountMutation();
  const {
    data: mailchimpAccounts,
    refetch,
    isFetching,
  } = useGetMailchimpAccountsQuery();

  const [, setAccountId] = useLocalStorage('mcAccountId');

  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    const foundAccount =
      mailchimpAccounts?.find((acc: any) => acc.accountId === accountId) ||
      null;

    setCurrentAccount(foundAccount);
  }, [accountId]);

  const handleUnlinkAccount = async () => {
    if (!currentAccount) {
      return;
    }

    await deleteMailchimpAccount(currentAccount?.accountId);

    const { data: mailchimpAccountsNew } = await refetch();

    closeModal();

    if (!mailchimpAccountsNew?.length) {
      setAccountId('');
      navigate('/login/mailchimp');

      return;
    }

    navigate('/accounts');
  };

  if (isError) {
    return <h1>Error occured</h1>;
  }

  return (
    <div>
      <div className={styles.mailchimp_account_details}>
        <Input
          value={remakeString(
            currentAccount?.accessToken,
            currentAccount?.prefix,
          )}
          label='API key'
          onChange={() => {}}
          disabled
          extraStyles={extraStyles.input}
          dataCy='api-key-input'
        />

        <Input
          value={currentAccount?.accountName || ''}
          label='Account Name'
          onChange={() => {}}
          disabled
          extraStyles={extraStyles.input}
        />

        <Input
          value={currentAccount?.contactsEnhanced.toString() || ''}
          icon={images.contactIcon}
          label='Contacts Enhanced'
          onChange={() => {}}
          disabled
          extraStyles={extraStyles.input}
        />

        <Input
          value={currentAccount?.dataEnhanced.toString() || ''}
          icon={images.mailchimpIcon}
          label='Data Enhanced'
          onChange={() => {}}
          disabled
          extraStyles={extraStyles.input}
        />
      </div>

      <Button
        type='outlined'
        text={'Unlink Mailchimp Account'}
        onClick={openModal}
        icon={isLoading || isFetching ? '' : images.unlinkIcon}
        extraStyles={extraStyles.button}
        dataCy='unlink-mc-acc-btn'
      />

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        type='confirmation'
        onConfirm={handleUnlinkAccount}
        isLoading={isLoading || isFetching}
        title='Confirm unlinking'
        confirmationButtonText='Unlink Mailchimp Account'
        dataCy='unlink-mc-account-modal'
      >
        <h3 style={extraStyles.warning}>
          Unlinking this Mailchimp Account will make all Data URLs created and
          associated with this account to no longer work.
        </h3>
      </Modal>
    </div>
  );
};

const extraStyles = {
  input: { width: 400 },
  button: { minWidth: 270, maxWidth: 400, width: '100%' },
  warning: { fontSize: 16, fontWeight: 400, margin: '20px 0' },
};
