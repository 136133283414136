import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SurveyOption, ReduxSurvey } from '../../utils/types/Survey';

const initialState: ReduxSurvey = {
  id: 0,
  audienceId: '',
  userESPLinkId: 1,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  isKeepResultsSame: true,
  mergeTagType: 'text',
  saveResultAs: 'mergeTag',
  groupOrLabel: '',
  mergeTag: '',
  audienceName: '',
  collectAdditionalData: false,
  title: '',
  embededCode: '',
  imageUrl: '',
  width: 600,
  height: 400,
  embedSurveyResultsCode: '',
  options: [],
  initSurveyObjectsJSON: [],
  initResultsObjectsJSON: [],
  answerVariants: [],
};

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<ReduxSurvey>>) => {
      const validPayload = {
        ...action.payload,
        saveResultAs: action.payload.saveResultAs || 'mergeTag',
      };

      return {
        ...state,
        ...validPayload,
      } as any;
    },
    updateSurveyOptions: (state, action: PayloadAction<SurveyOption>) => {
      const filteredOptions = state.options.filter(
        area => area.clickableAreaId !== action.payload.clickableAreaId,
      );

      return {
        ...state,
        options: [...filteredOptions, action.payload],
      };
    },
  },
});

export default surveySlice.reducer;
export const surveyActions = surveySlice.actions;
