import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { safeParse } from '../helpers/safeParse';
import { safeStringify } from '../helpers/safeStringify';

type LocalStorage<T> = [T, (value: T | ((val: T) => T)) => void];

export function useLocalStorage<T>(key: string, initial?: T): LocalStorage<T> {
  const [stored, setStored] = useState<T>(() => {
    const item = window.localStorage.getItem(key);

    return item ? safeParse(item) : initial;
  });

  const setValue = useCallback(
    (value: T | ((prev: T) => T)) => {
      setStored(prev => {
        const next = value instanceof Function ? value(prev) : value;

        try {
          window.localStorage.setItem(key, safeStringify(next));
        } catch (error) {
          toast.error('Error occured when trying to save canvas!');
        }

        return next;
      });
    },
    [key],
  );

  return [stored, setValue];
}
