import React, { FC } from 'react';
import { MailchimpAccountInfo } from '../../../redux/slice/mailchimpApi';
import { TableItem } from './TableItem';
import { FlexContainer } from '../../common/FlexContainer/FlexContainer';
import * as images from '../../../assets/index';
import { Divider } from '../../common/Divider/Divider';

interface Props {
  mcAccount: MailchimpAccountInfo;
}

export const TableItemMobile: FC<Props> = ({ mcAccount }) => (
  <FlexContainer
    justify='space-between'
    gap='10px'
    extraStyles={{ marginTop: '-10px' }}
  >
    <TableItem
      text={mcAccount.accountName}
      leftIcon={mcAccount.avatarUrl}
      accountId={mcAccount.accountId}
      key={mcAccount.accountId}
      audienceId={mcAccount.audienceId}
      withRightIcon
    />

    <FlexContainer
      direction='row'
      justify='space-between'
      extraStyles={{ padding: '0 5px' }}
    >
      {renderItem(
        mcAccount.contactsEnhanced.toLocaleString().replace(/\s+/g, ','),
        images.contactIcon,
      )}

      <Divider type='vertical' extraStyles={{ height: 16 }} />

      {renderItem(
        mcAccount.dataEnhanced.toLocaleString().replace(/\s+/g, ','),
        images.mailchimpIcon,
      )}
    </FlexContainer>
  </FlexContainer>
);

const renderItem = (text: string, icon: string) => (
  <FlexContainer
    direction='row'
    justify='center'
    align='center'
    extraStyles={{ maxWidth: 'min-content' }}
  >
    <img src={icon} alt={text} />

    <p>{text}</p>
  </FlexContainer>
);
