import { Dispatch, SetStateAction, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { smartLinkClient, trackingClient } from 'src/api';
import { smartLinkActions } from 'src/redux/slice/smartLink';
import { useAppDispatch } from 'src/redux/store';
import { getValidSaveAsData } from 'src/utils/helpers/editor/getValidSaveAsData';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { isValidUrl } from 'src/utils/helpers/validateURL';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

export const useUpdateSmartLink = (
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  const { destinationUrl, groupOrLabel, mergeTag, saveResultAs, value, id } =
    useDistinctSelector('smartLink');

  const { audienceId = '', accountId = '' } = useParams();

  const dispatch = useAppDispatch();

  const isValidData = () => {
    if (!isValidUrl(destinationUrl)) {
      toast.error('Invalid URL! Please, enter a valid URL.');

      return false;
    }

    if (saveResultAs !== 'tag' && !groupOrLabel) {
      toast.error('Incorrect empty value!');

      return false;
    }

    if (saveResultAs === 'mergeTag' && !mergeTag) {
      toast.error('Incorrect empty value!');

      return false;
    }

    if (!value) {
      toast.error('Incorrect empty value!');

      return false;
    }

    return true;
  };

  const handleUpdateSmartLink = useCallback(async () => {
    const isValid = isValidData();

    if (!isValid) {
      return;
    }

    try {
      setIsLoading(true);

      const saveAsData = getValidSaveAsData(
        saveResultAs,
        groupOrLabel ?? '',
        mergeTag ?? '',
      );

      const { data: temp } = await smartLinkClient.update(id, {
        ...saveAsData,
        destinationUrl,
        value,
      });

      dispatch(smartLinkActions.update(temp));

      toast.success('Smart link updated successfully!');

      trackingClient.saveButtonClick({
        buttonAction: 'createDataURL',
        urlDataType: saveResultAs,
        url: destinationUrl,
        mailchimpAccountId: accountId,
        audienceId,
        isSurveyOption: false,
        surveyOptionId: null,
      });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    value,
    groupOrLabel,
    mergeTag,
    destinationUrl,
    saveResultAs,
    accountId,
    audienceId,
    id,
  ]);

  return {
    handleUpdateSmartLink,
  };
};
