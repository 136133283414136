import React, { Dispatch, FC, SetStateAction } from 'react';
import { CanvasProps } from 'src/utils/types/Editor';
import { EditorSidebarSection } from '../EditorSidebarSection';
import { ColorPicker } from '../common/ColorPicker/ColorPicker';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { Input } from '../common/Input/Input';

type Props = {
  canvasProps: CanvasProps;
  onCanvasPropsChange: Dispatch<SetStateAction<CanvasProps>>;
};

export const EditorCanvasPropsSidebar: FC<Props> = ({
  canvasProps,
  onCanvasPropsChange,
}) => {
  const handleCanvasSizeChange = (
    { target }: React.ChangeEvent<HTMLInputElement>,
    field: string,
    max: number,
    min: number,
  ) => {
    const numValue = Number(target.value);

    if (isNaN(numValue)) {
      return;
    }

    if (numValue > max) {
      onCanvasPropsChange(prev => ({
        ...prev,
        [field]: max,
      }));

      return;
    }

    if (numValue < min) {
      onCanvasPropsChange(prev => ({
        ...prev,
        [field]: 100,
      }));

      return;
    }

    onCanvasPropsChange(prev => ({
      ...prev,
      [field]: numValue,
    }));
  };

  return (
    <EditorSidebarSection title='Canvas Properties' fixed>
      <FlexContainer direction='row'>
        <Input
          value={canvasProps.width.toString()}
          onChange={event => handleCanvasSizeChange(event, 'width', 4096, 0)}
          rightIcon='W'
          extraStyles={extraStyles.input}
          variant='editor-sidebar-input'
        />

        <Input
          value={canvasProps.height.toString()}
          onChange={event => handleCanvasSizeChange(event, 'height', 4096, 0)}
          rightIcon='H'
          extraStyles={extraStyles.input}
          variant='editor-sidebar-input'
        />

        <ColorPicker
          color={canvasProps.backgroundColor}
          onColorChange={onCanvasPropsChange}
          extraStyles={extraStyles.colorPicker}
          extraStylesInput={{ width: 110 }}
        />
      </FlexContainer>
    </EditorSidebarSection>
  );
};

const extraStyles = {
  input: { width: 70 },
  colorPicker: { left: -150, top: -340 },
};
