import { useState } from 'react';
import { rgbaToHexAndOpacity } from '../ColorPicker';

export const useTempValues = (color: string) => {
  const { hex, opacity } = rgbaToHexAndOpacity(color);
  const [tempColor, setTempColor] = useState(hex);
  const [tempTransparency, setTempTransparency] = useState(
    (opacity * 100).toString() || '100',
  );

  return {
    tempColor,
    tempTransparency,
    setTempColor,
    setTempTransparency,
  };
};
