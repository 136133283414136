import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { userClient } from '../../api';
import { Loader } from '../../components/common/Loader/Loader';
import { useLazyGetMailchimpAccountsQuery } from '../../redux/slice/mailchimpApi';
import { userActions } from '../../redux/slice/user';
import { useAppDispatch } from '../../redux/store';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

export const SigninLoader = () => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();
  const [, setAccessToken] = useLocalStorage('accessToken', '');
  const [, setAccountId] = useLocalStorage('accountId', '');

  const dispatch = useAppDispatch();
  const [getMailchimpAccounts] = useLazyGetMailchimpAccountsQuery();

  useEffect(() => {
    const accessToken = queryParams.get('accessToken');

    queryParams.delete('accessToken');

    setQueryParams(queryParams);
    setAccessToken(accessToken as string);

    const getUser = async () => {
      try {
        const { data } = await userClient.getUser();

        dispatch(userActions.create(data));

        userClient.saveUserData();

        const { data: mailchimpAccounts } = await getMailchimpAccounts();

        if (!mailchimpAccounts || !mailchimpAccounts.length) {
          navigate('/login/mailchimp');

          return;
        }

        if (mailchimpAccounts.length === 1) {
          setAccountId(mailchimpAccounts[0].accountId);

          navigate(
            `/actions/${mailchimpAccounts[0].accountId}/${mailchimpAccounts[0].audienceId}?items=25&filter=all&page=1`,
          );

          return;
        }

        navigate('/accounts');
      } catch (error) {
        navigate('/signin?status=error');
      }
    };

    getUser();
  }, []);

  return <Loader fullScreen />;
};
