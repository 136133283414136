import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackingClient } from 'src/api';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import * as images from '../../assets/index';
import { SmartLinkCreatingBlock } from '../SmartLinkCreatingBlock';
import { StepWithDescription } from '../StepWithDescription/StepWithDescription';
import { Button, FlexContainer, Input } from '../common';
import styles from './index.module.scss';

type Props = {
  smartLink: string;
};

export const SmartLinkResultBlock: FC<Props> = ({ smartLink }) => {
  const { saveResultAs, audienceId } = useDistinctSelector('smartLink');
  const { accountId = '' } = useParams();

  const handleClickCopyButton = async () => {
    try {
      await navigator.clipboard.writeText(smartLink);
      toast.success('Data URL copied to clipboard!');

      trackingClient.saveButtonClick({
        buttonAction: 'copyDataURL',
        urlDataType: saveResultAs,
        url: smartLink,
        mailchimpAccountId: accountId,
        isSurveyOption: false,
        audienceId,
        surveyOptionId: null,
      });
    } catch {
      toast.error('Data URL was copied with error! Please, try again.');
    }
  };

  return (
    <SmartLinkCreatingBlock>
      <StepWithDescription title='Step 3.'>
        Copy the <strong>Smart Link</strong> and use it as a Link in your email
        design.
      </StepWithDescription>

      <FlexContainer>
        <Input
          placeholder='Smart Link'
          icon={images.addpictonSecondIconDark}
          value={smartLink}
          onChange={() => {}}
          disabled
          dataCy='data-url-in'
        />

        <Button
          text='Copy Smart Link'
          color='#45869F'
          bgColor='#fff'
          onClick={handleClickCopyButton}
          type={smartLink ? 'outlined' : 'disabled'}
          className={styles.create_data_url_button}
          dataCy='copy-dcu-btn'
        />
      </FlexContainer>
    </SmartLinkCreatingBlock>
  );
};
