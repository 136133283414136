/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';
import { SurveysEditorLayers } from '../EditorLayers';
import { EditorSidebarSection } from '../EditorSidebarSection';
import { EditorCanvasPropsSidebar } from '../EditorCanvasPropsSidebar';
import { EditorActiveObjectProperties } from '../EditorActiveObjectProperties';
import { Divider, FlexContainer, If } from '../common';
import {
  CanvasProps,
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from '../../utils/types/Editor';
import styles from './index.module.scss';

interface Props {
  canvasProps: CanvasProps;
  activeObject: CustomFabricObject | null;
  activeObjects: CustomFabricObject[];
  canvasObjects: (CustomFabricObject | InnerCanvasType)[];
  onCanvasPropsChange: React.Dispatch<React.SetStateAction<CanvasProps>>;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
  canvas: CustomCanvasType;
  onSetActiveObject: React.Dispatch<
    React.SetStateAction<CustomFabricObject | null>
  >;
}

export const SurveysEditorSidebar: FC<Props> = ({
  canvasProps,
  activeObject,
  activeObjects,
  onCanvasPropsChange,
  canvas,
  canvasObjects,
  onGetCanvasObjects,
  onSetActiveObject,
}) => {
  return (
    <div className={styles.surveys_editor_sidebar} id='editor-right-sidebar'>
      <EditorSidebarSection title='Layers' fixed layers>
        <FlexContainer gap={0} extraStyles={extraStyles.layersContainer}>
          <SurveysEditorLayers
            activeObject={activeObject}
            activeObjects={activeObjects}
            canvas={canvas}
            canvasObjects={canvasObjects}
            onGetCanvasObjects={onGetCanvasObjects}
            onSetActiveObject={onSetActiveObject}
          />
        </FlexContainer>
      </EditorSidebarSection>

      <Divider />

      <If condition={!activeObject}>
        <EditorCanvasPropsSidebar
          canvasProps={canvasProps}
          onCanvasPropsChange={onCanvasPropsChange}
        />
      </If>

      <EditorActiveObjectProperties
        canvas={canvas}
        activeObject={activeObject}
        onGetCanvasObjects={onGetCanvasObjects}
      />
    </div>
  );
};

const extraStyles = {
  layersContainer: {
    marginTop: 16,
  },
};
