import React, { FC } from 'react';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';
import { SurveysEditorClickableAreaSidebar } from '../EditorClickableAreaSidebar';
import { SurveysEditorImageEditSidebar } from '../EditorImageEditSidebar';
import { EditorSurveyResultSidebar } from '../EditorSurveyResultSidebar';
import { EditorSidebarSection } from '../EditorSidebarSection';
import { SurveysEditorTextEditSidebar } from '../EditorTextEditSidebar';

type Props = {
  canvas: CustomCanvasType;
  activeObject: CustomFabricObject | null;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
};

export const EditorActiveObjectProperties: FC<Props> = ({
  activeObject,
  canvas,
  onGetCanvasObjects,
}) => {
  switch (activeObject?.type) {
    case 'text':
      return (
        <EditorSidebarSection title='Text Properties'>
          <SurveysEditorTextEditSidebar
            activeObject={activeObject}
            canvas={canvas}
          />
        </EditorSidebarSection>
      );

    case 'clickableArea':
      return (
        <EditorSidebarSection title='Clickable Area Properties'>
          <SurveysEditorClickableAreaSidebar
            activeObject={activeObject}
            canvas={canvas}
          />
        </EditorSidebarSection>
      );

    case 'image':
      return (
        <EditorSidebarSection title='Image Properties'>
          <SurveysEditorImageEditSidebar
            activeObject={activeObject}
            canvas={canvas}
            onGetCanvasObjects={onGetCanvasObjects}
          />
        </EditorSidebarSection>
      );

    case 'surveyResult':
      return (
        <EditorSidebarSection title='Survey Result Properties'>
          <EditorSurveyResultSidebar
            activeObject={activeObject}
            canvas={canvas}
            onGetCanvasObjects={onGetCanvasObjects}
          />
        </EditorSidebarSection>
      );

    default:
      return null;
  }
};
