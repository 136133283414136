import React, { Dispatch, FC, SetStateAction } from 'react';
import { Radio } from '../common/Radio/Radio';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { SaveAs } from 'src/utils/types/general/types';

interface Props {
  selectedDataUrlType: SaveAs;
  onSelectDataUrl: Dispatch<SetStateAction<SaveAs>>;
}

export const DataUrlRadioGroup: FC<Props> = ({
  selectedDataUrlType,
  onSelectDataUrl,
}) => {
  const handleSelectDataUrlType = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onSelectDataUrl(event.target.value as SaveAs);
  };

  return (
    <FlexContainer direction='row' gap='16px'>
      <Radio
        value='mergeTag'
        currentValue={selectedDataUrlType}
        onChange={handleSelectDataUrlType}
        name='data-url-type'
        labelText='Merge Tags'
        dataCy='merge-tag-radio'
      />

      <Radio
        value='group'
        currentValue={selectedDataUrlType}
        onChange={handleSelectDataUrlType}
        name='data-url-type'
        labelText='Groups'
        dataCy='group-radio'
      />

      <Radio
        value='tag'
        currentValue={selectedDataUrlType}
        onChange={handleSelectDataUrlType}
        name='data-url-type'
        labelText='Tags'
        dataCy='tag-radio'
      />
    </FlexContainer>
  );
};
