import { saveToEditorLocalbase } from 'src/api/local/editor';
import { CustomCanvasType } from 'src/utils/types/Editor';
import { getUniqueObjects } from '../getUniqueObjects';
import { convertToObjects } from './convertToObjects';

export const saveSurveyCanvas = (
  canvas: CustomCanvasType,
  clearCanvas: boolean = true,
  callback?: () => void,
) => {
  if (!canvas) {
    return;
  }

  canvas.discardActiveObject();

  const objects = convertToObjects(canvas);
  const uniqueObjects = getUniqueObjects(objects, 'objectId');

  saveToEditorLocalbase(
    'surveyCanvas',
    {
      objects: [...uniqueObjects],
    },
    () => {
      callback?.();

      if (clearCanvas) {
        canvas.clear();
      }
    },
  );
};
