import { fabric } from 'fabric';
import { RoundedRectType } from 'src/utils/types/Editor';

export const RoundedRect = fabric.util.createClass(fabric.Rect, {
  type: 'roundedRect',
  topLeft: [0, 0],
  topRight: [0, 0],
  bottomLeft: [0, 0],
  bottomRight: [0, 0],

  _render: function (ctx) {
    const w = this.width;
    const h = this.height;
    const x = -this.width / 2;
    const y = -this.height / 2;
    const k = 1 - 0.5522847498;

    ctx.beginPath();

    ctx.moveTo(x + this.topLeft[0], y);

    ctx.lineTo(x + w - this.topRight[0], y);

    ctx.bezierCurveTo(
      x + w - k * this.topRight[0],
      y,
      x + w,
      y + k * this.topRight[1],
      x + w,
      y + this.topRight[1],
    );

    ctx.lineTo(x + w, y + h - this.bottomRight[1]);

    ctx.bezierCurveTo(
      x + w,
      y + h - k * this.bottomRight[1],
      x + w - k * this.bottomRight[0],
      y + h,
      x + w - this.bottomRight[0],
      y + h,
    );

    ctx.lineTo(x + this.bottomLeft[0], y + h);

    ctx.bezierCurveTo(
      x + k * this.bottomLeft[0],
      y + h,
      x,
      y + h - k * this.bottomLeft[1],
      x,
      y + h - this.bottomLeft[1],
    );

    ctx.lineTo(x, y + this.topLeft[1]);

    ctx.bezierCurveTo(
      x,
      y + k * this.topLeft[1],
      x + k * this.topLeft[0],
      y,
      x + this.topLeft[0],
      y,
    );

    ctx.closePath();

    this._renderPaintInOrder(ctx);
  },
}) as new (options?: Partial<RoundedRectType>) => RoundedRectType;
