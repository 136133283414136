import { useEffect } from 'react';
import { recentColorsActions } from 'src/redux/slice/recentColors';
import { useAppDispatch } from 'src/redux/store';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

export const useRecentColors = (showPicker: boolean) => {
  const recentColors = useDistinctSelector('recentColors');
  const [LSrecentColors, setLSRecentColors] = useLocalStorage(
    'recentColors',
    recentColors,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLSRecentColors(recentColors);
  }, [safeStringify(recentColors)]);

  useEffect(() => {
    dispatch(recentColorsActions.update(LSrecentColors));
  }, [showPicker]);
};
