import { useState, useEffect } from 'react';

export const useContainerId = () => {
  const [containerId, setContainerId] = useState(generateContainerId());

  useEffect(() => {
    setContainerId(generateContainerId());
  }, []);

  return containerId;
};

const generateContainerId = () => {
  return Math.random().toString(36).substring(2, 15);
};
