import React, { FC } from 'react';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import styles from './index.module.scss';

interface Props {
  icon: string;
  text: string;
}

export const IconWithText: FC<Props> = ({ icon, text }) => {
  return (
    <FlexContainer
      gap={8}
      direction='row'
      align='center'
      className={styles.icon_with_text}
    >
      <img src={icon} alt={text} className={styles.icon_with_text__icon} />

      <p className={styles.icon_with_text__text}>{text}</p>
    </FlexContainer>
  );
};
