import { useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { surveyClient } from 'src/api';
import { clearEditorLocalbase } from 'src/api/local/editor';
import { editorActions } from 'src/redux/slice/editor';
import { recentColorsActions } from 'src/redux/slice/recentColors';
import { surveyActions } from 'src/redux/slice/survey';
import { useAppDispatch } from 'src/redux/store';
import { getValidSaveAsData } from 'src/utils/helpers/editor/getValidSaveAsData';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { MergeFieldsType } from 'src/utils/types/Mailchimp';
import { Survey, SurveyCreationProps } from 'src/utils/types/Survey';
import { DropdownOption, SaveAs } from 'src/utils/types/general/types';

type Props = {
  surveyTitle: string;
  collectAdditionalData: boolean;
  currentSaveResultsOption: DropdownOption;
  groupOrLabel: string;
  mergeTag: string;
  mergeTagType: MergeFieldsType;
};

export const useCreateSurvey = ({
  surveyTitle,
  collectAdditionalData,
  currentSaveResultsOption,
  groupOrLabel,
  mergeTag,
  mergeTagType,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { audienceId = '', accountId = '' } = useParams<Params>();

  const { currentAudienceId, currentAudience } =
    useDistinctSelector('mailchimp');

  const isTitleValid = () => {
    const trimmedValue = surveyTitle?.trim();

    if (!trimmedValue) {
      toast.error('Survey title cannot be empty!');
      setHasError(true);

      return false;
    }

    if (trimmedValue.length < 4) {
      toast.error('Survey title too short!');
      setHasError(true);

      return false;
    }

    if (trimmedValue.length > 100) {
      toast.error('Survey title too long!');
      setHasError(true);

      return false;
    }

    return true;
  };

  const updateReduxStoreItems = (surveyData: Survey) => {
    dispatch(
      surveyActions.update({
        ...surveyData,
      }),
    );

    dispatch(
      editorActions.update({
        keepResultsSame: true,
        currentCanvasId: 'fallback',
        prevCanvasId: 'fallback',
      }),
    );

    dispatch(recentColorsActions.clear());
  };

  const getRedirectUrl = (surveyId: number) => {
    const validAudienceId = currentAudienceId || audienceId;

    return `/new/survey/${accountId}/${validAudienceId}/${surveyId}?new=true`;
  };

  const handleCreate = async () => {
    setIsLoading(true);

    const isValidTitle = isTitleValid();

    if (!isValidTitle) {
      return;
    }

    try {
      const saveResultAs = getValidSaveAsData(
        currentSaveResultsOption.labelValue as SaveAs,
        groupOrLabel,
        mergeTag,
      );

      const body: SurveyCreationProps = {
        title: surveyTitle?.trim(),
        audienceId: currentAudienceId,
        audienceName: currentAudience,
        embededCode: '',
        imageUrl: '',
        width: 600,
        height: 400,
        collectAdditionalData,
        mergeTagType,
        ...saveResultAs,
      };

      const res = await surveyClient.create(accountId, body);
      const survey = res.data;

      updateReduxStoreItems(survey);
      await clearEditorLocalbase();

      surveyClient.createCanvases(survey.id);

      navigate(getRedirectUrl(survey.id));
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCreate,
    onErrorChange: setHasError,
    isLoading,
    hasError,
  };
};
