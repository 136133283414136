import React, { CSSProperties, FC, useMemo } from 'react';
import './Loader.scss';
import { If } from '../If/If';

interface Props {
  fullScreen?: boolean;
  size?: 'xsm' | 'sm' | 'md' | 'l';
  extraStyles?: CSSProperties;
  backdropStyles?: CSSProperties;
}

export const Loader: FC<Props> = ({
  fullScreen,
  size,
  extraStyles,
  backdropStyles,
}) => {
  const LoaderCircle = useMemo(
    () => <div style={extraStyles} className={`loader loader--${size}`} />,
    [],
  );

  return (
    <>
      <If condition={fullScreen}>
        <div className='fullScreen' style={backdropStyles}>
          {LoaderCircle}
        </div>
      </If>

      <If condition={!fullScreen}>{LoaderCircle}</If>
    </>
  );
};
