import React, { FC, useEffect } from 'react';
import { useGeneralObjectProperties } from 'src/utils/hooks/useGeneralObjectProperties';
import { constants } from '../../utils/constants';
import { getFontStyles } from '../../utils/helpers/editor/getFontStyles';
import { handlePropSet } from '../../utils/helpers/editor/handlePropSet';
import { CustomCanvasType, CustomTextBoxType } from '../../utils/types/Editor';
import { SurveysEditorIconBox } from '../EditorIconBox';
import { SurveysEditorSizePropertiesBlock } from '../EditorSizePropertiesBlock';
import { ColorPicker } from '../common/ColorPicker/ColorPicker';
import { Divider } from '../common/Divider/Divider';
import { Dropdown } from '../common/Dropdown/Dropdown';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { Input } from '../common/Input/Input';
import { Switch } from '../common/Switch/Switch';
import { Textarea } from '../common/Textarea/Textarea';
import { useObjectColor } from './actions/useObjectColor';
import { useObjectFont } from './actions/useObjectFont';
import { useObjectFontStyles } from './actions/useObjectFontStyles';
import { useObjectStroke } from './actions/useObjectStroke';
import { useObjectText } from './actions/useObjectText';
import { useObjectTextAlign } from './actions/useObjectTextAlign';
import { useObjectTextSizes } from './actions/useObjectTextSizes';
import styles from './index.module.scss';

interface Props {
  activeObject: CustomTextBoxType;
  canvas: CustomCanvasType;
}

export const SurveysEditorTextEditSidebar: FC<Props> = ({
  activeObject,
  canvas,
}) => {
  const {
    x,
    y,
    width,
    height,
    angle,
    setWidth,
    setHeight,
    setAngle,
    handleCoordsSet,
  } = useGeneralObjectProperties(activeObject, canvas);

  const {
    fontSize,
    lineHeight,
    letterSpacing,
    setFontSize,
    setLineHeight,
    setLetterSpacing,
  } = useObjectTextSizes(activeObject);

  const {
    text,
    fallback,
    saveFallback,
    setText,
    setFallback,
    setSaveFallback,
    handleTextChange,
  } = useObjectText(activeObject);

  const { strokeColor, strokeWidth, setStrokeColor, setStrokeWidth } =
    useObjectStroke(activeObject);

  const { fontStyles, setFontStyles, handleFontStyleClick } =
    useObjectFontStyles(activeObject);

  const [currentFont, setCurrentFont] = useObjectFont(activeObject);
  const [textAlign, setTextAlign] = useObjectTextAlign(activeObject);
  const [color, setColor] = useObjectColor(activeObject);

  useEffect(() => {
    canvas.requestRenderAll();
  }, [
    currentFont.labelValue,
    fontStyles.length,
    letterSpacing,
    strokeColor,
    lineHeight,
    textAlign,
    fontSize,
    strokeWidth,
    width,
    height,
    color,
    angle,
    x,
    y,
  ]);

  useEffect(() => {
    setLineHeight(
      (activeObject.lineHeight * activeObject.fontSize).toFixed(0) || 'Auto',
    );
    setText(activeObject.text || '');
    setFontSize(activeObject.fontSize.toFixed(0));

    setLetterSpacing(
      (activeObject.charSpacing / activeObject.fontSize).toFixed(0) || '0',
    );

    setColor(activeObject.fill as string);
    setTextAlign(activeObject.textAlign || 'left');
    setStrokeWidth(activeObject?.strokeWidth?.toFixed(0) || '0');
    setStrokeColor(activeObject.stroke || '#000');
    setFontStyles(getFontStyles(activeObject));
    setFallback(activeObject.fallback || '');
  }, [activeObject.objectId]);

  return (
    <FlexContainer>
      <SurveysEditorSizePropertiesBlock
        activeObject={activeObject}
        x={x}
        y={y}
        width={width}
        height={height}
        angle={angle}
        setWidth={setWidth}
        setHeight={setHeight}
        setAngle={setAngle}
        handleCoordsSet={handleCoordsSet}
      />

      <Divider extraStyles={extraStyles.divider} />

      <Dropdown
        options={constants.defaultFonts}
        value={currentFont}
        onChange={setCurrentFont}
        width={250}
        extraStyles={extraStyles.dropdown}
      />

      <Input
        value={fontSize}
        labelExtraStyles={extraStyles.label}
        onChange={event =>
          handlePropSet(activeObject, event, 'fontSize', setFontSize)
        }
        variant='editor-sidebar-input'
        label='Font Size'
        rightIcon='px'
        extraStyles={extraStyles.input}
      />

      <Input
        value={lineHeight}
        labelExtraStyles={extraStyles.label}
        onChange={event =>
          handlePropSet(activeObject, event, 'lineHeight', setLineHeight)
        }
        variant='editor-sidebar-input'
        label='Line Height'
        rightIcon='px'
        extraStyles={extraStyles.input}
      />

      <Input
        value={letterSpacing}
        labelExtraStyles={extraStyles.label}
        onChange={event =>
          handlePropSet(activeObject, event, 'charSpacing', setLetterSpacing)
        }
        variant='editor-sidebar-input'
        label='Letter Spacing'
        rightIcon='%'
        extraStyles={extraStyles.input}
      />

      <FlexContainer direction='row' align='center'>
        <ColorPicker color={color} onColorChange={setColor} />

        <p className={styles.surveys_editor_sidebar__field_label}>Text Color</p>
      </FlexContainer>

      <FlexContainer direction='row' align='center'>
        {constants.textAlignOptions.map(option => (
          <SurveysEditorIconBox
            key={option.align}
            image={option.icon}
            isSelected={option.align === textAlign}
            onClick={() => setTextAlign(option.align)}
          />
        ))}

        <p className={styles.surveys_editor_sidebar__field_label}>Text Align</p>
      </FlexContainer>

      <FlexContainer direction='row' align='center'>
        {constants.fontStyleOptions.map(option => (
          <SurveysEditorIconBox
            key={option.fontStyle}
            image={option.icon}
            isSelected={fontStyles.includes(option.fontStyle)}
            onClick={() => handleFontStyleClick(option.fontStyle)}
          />
        ))}

        <p className={styles.surveys_editor_sidebar__field_label}>Font Style</p>
      </FlexContainer>

      <Divider extraStyles={extraStyles.divider} />

      <Input
        value={strokeWidth.toString()}
        labelExtraStyles={extraStyles.label}
        onChange={event =>
          handlePropSet(activeObject, event, 'strokeWidth', setStrokeWidth)
        }
        label='Text Stroke'
        rightIcon='px'
        variant='editor-sidebar-input'
        extraStyles={extraStyles.input}
      />

      <FlexContainer direction='row' align='center'>
        <ColorPicker color={strokeColor} onColorChange={setStrokeColor} />

        <p className={styles.surveys_editor_sidebar__field_label}>
          Text Stroke Color
        </p>
      </FlexContainer>

      <Divider extraStyles={extraStyles.divider} />

      <p className={styles.surveys_editor_sidebar__field_label}>Primary Copy</p>

      <Textarea
        value={text}
        onChange={e => handleTextChange(e.target.value)}
        placeholder='Enter your text here'
      />

      <FlexContainer direction='row' justify='space-between' align='center'>
        <p className={styles.surveys_editor_sidebar__field_label}>
          Fallback Copy
        </p>

        <Switch value={saveFallback} onChange={setSaveFallback} size={8} />
      </FlexContainer>

      <Textarea
        value={fallback}
        disabled={!saveFallback}
        onChange={e =>
          handlePropSet(activeObject, e.target.value, 'fallback', setFallback)
        }
        placeholder='No merge tags'
      />
    </FlexContainer>
  );
};

const extraStyles = {
  input: { width: 150 },
  label: { fontSize: 12 },
  divider: { margin: '10px 0' },
  dropdown: { height: 30 },
};
