import React, { Dispatch, FC, SetStateAction } from 'react';
import { ModalActionEnum } from 'src/utils/types/general/enums';

import * as images from '../../../assets/index';
import styles from './index.module.scss';

type Props = {
  type: ModalActionEnum;
  handleModalTypeChange: Dispatch<SetStateAction<ModalActionEnum>>;
};

const imagesMap = {
  [ModalActionEnum.DUPLICATE]: images.copyIcon,
  [ModalActionEnum.EMBED]: images.codeIcon,
  [ModalActionEnum.DELETE]: images.deleteIcon,
};

export const ActionButton: FC<Props> = ({ type, handleModalTypeChange }) => (
  <button className={styles.button} onClick={() => handleModalTypeChange(type)}>
    <img src={imagesMap[type]} alt={type} className={styles.button__icon} />
  </button>
);
