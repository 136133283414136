import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CanvasProps, CustomCanvasType } from 'src/utils/types/Editor';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

export const useUpdateCanvas = (
  canvas: CustomCanvasType,
): [CanvasProps, Dispatch<SetStateAction<CanvasProps>>] => {
  const [canvasProps, setCanvasProps] = useState<CanvasProps>({
    width: 600,
    height: 400,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  });

  const { isLoadingObjects } = useDistinctSelector('editor');

  useEffect(() => {
    if (isLoadingObjects || !canvas) {
      return;
    }

    const innerCanvas = getInnerCanvas(canvas);

    setCanvasProps({
      height: innerCanvas?.height ?? 0,
      width: innerCanvas?.width ?? 0,
      backgroundColor: (innerCanvas?.fill as string) ?? '',
    });
  }, [isLoadingObjects]);

  useEffect(() => {
    const innerCanvas = getInnerCanvas(canvas);

    if (!innerCanvas) {
      return;
    }

    const isEqual =
      canvasProps?.height === innerCanvas?.height &&
      canvasProps?.width === innerCanvas?.width &&
      canvasProps.backgroundColor === (innerCanvas?.fill as string);

    if (isEqual) {
      return;
    }

    if (canvas) {
      innerCanvas.set({
        height: canvasProps?.height ?? 0,
        width: canvasProps?.width ?? 0,
        fill: canvasProps.backgroundColor ?? '',
      });

      canvas.requestRenderAll();

      if (innerCanvas.canvasType === 'results') {
        canvas
          .getObjects()
          .forEach(obj => obj.type === 'surveyResult' && obj.rerenderGroup());
      }
    }
  }, [
    canvasProps?.width,
    canvasProps?.height,
    canvasProps.backgroundColor,
    canvas,
  ]);

  return [canvasProps, setCanvasProps];
};
