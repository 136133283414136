import React, { FC, useEffect, useState } from 'react';
import { Button, FlexContainer, If, Modal, Switch } from '../common';

import styles from './index.module.scss';
import { ClickableAreaType } from 'src/utils/types/Editor';
import { usePrefilledFields } from './actions/usePrefilledFields';
import { PrefilledFieldsList } from './PrefilledFieldsList';
import { useModal } from 'src/utils/hooks/useModal';
import { generateNewDestionationUrl } from 'src/utils/helpers/editor/generateNewDestionationUrl';

type Props = {
  activeObject: ClickableAreaType;
  destinationUrl: string;
};

export const EditorClickableAreaGoogleForm: FC<Props> = ({
  activeObject,
  destinationUrl,
}) => {
  const [enableIntegration, setEnableIntegration] = useState(
    activeObject.enablePrefilledFields ?? false,
  );
  const { prefilledFields, setPrefieldFields, scrapTheForm } =
    usePrefilledFields(destinationUrl, activeObject);

  useEffect(() => {
    activeObject.set({
      enablePrefilledFields: enableIntegration,
    });
  }, [enableIntegration]);

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <FlexContainer className={styles.editor_clickable_area_google_form}>
      <FlexContainer direction='row' align='center' justify='space-between'>
        <p className={styles.editor_clickable_area_google_form__title}>
          Enable prefilled fields
        </p>

        <Switch
          value={enableIntegration}
          onChange={setEnableIntegration}
          size={10}
        />
      </FlexContainer>

      <If condition={enableIntegration}>
        <PrefilledFieldsList
          fields={prefilledFields}
          setFields={setPrefieldFields}
        />

        <FlexContainer
          className={styles.editor_clickable_area_google_form__button_container}
        >
          <Button
            type='fill-blue'
            onClick={openModal}
            text='View prefilled form'
            className={styles.editor_clickable_area_google_form__button}
          />

          <Button
            type='outlined-blue'
            onClick={scrapTheForm}
            text='Update form fields'
            className={styles.editor_clickable_area_google_form__button}
          />
        </FlexContainer>
      </If>

      <Modal isOpen={isOpen} onClose={closeModal} title='Google Form'>
        <iframe
          src={generateNewDestionationUrl(destinationUrl, prefilledFields)}
          className={styles.iframe}
          title='Google Form'
        ></iframe>
      </Modal>
    </FlexContainer>
  );
};
