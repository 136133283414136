import { useState, useEffect } from 'react';
import {
  useGetMailchimpAccountsQuery,
  MailchimpAccountInfo,
} from 'src/redux/slice/mailchimpApi';
import { findDropdownOption } from '../helpers/findDropdownOptions';
import { DropdownOption } from '../types/general/types';
import { useDistinctSelector } from './useDistinctSelector';

const dum = { labelValue: '', label: '' };

export const useMailchimpAccountsDropdown = () => {
  const { currentAudienceId } = useDistinctSelector('mailchimp');

  const { data: mailchimpAccounts, isLoading } = useGetMailchimpAccountsQuery();
  const [accounts, setAccounts] = useState<DropdownOption[]>(
    createAccountsOptionsForDropdown(mailchimpAccounts),
  );
  const [currentAudience, setCurrentAudience] = useState<DropdownOption>(
    findDropdownOption(accounts, currentAudienceId, dum),
  );
  const [currentAccount, setCurrentAccount] = useState(
    findCurrentAccount(mailchimpAccounts, currentAudience.labelValue),
  );

  useEffect(() => {
    setAccounts(createAccountsOptionsForDropdown(mailchimpAccounts));
  }, [mailchimpAccounts?.length]);

  useEffect(() => {
    setCurrentAudience(findDropdownOption(accounts, currentAudienceId, dum));
  }, [accounts.length]);

  useEffect(() => {
    setCurrentAccount(
      findCurrentAccount(mailchimpAccounts, currentAudience.labelValue),
    );
  }, [currentAudience.labelValue, mailchimpAccounts?.length]);

  return {
    currentAudience: isLoading
      ? { labelValue: '', label: 'Loading...' }
      : currentAudience,
    accounts,
    currentAccount,
    mailchimpAccounts,
    setCurrentAudience,
    setAccounts,
  };
};

const createAccountsOptionsForDropdown = (
  mailchimpAccounts: MailchimpAccountInfo[] | undefined,
) => {
  return (
    mailchimpAccounts?.map(data => ({
      label: data?.accountName,
      labelValue: data?.audienceId,
      icon: data?.avatarUrl || '',
    })) || []
  );
};

const findCurrentAccount = (
  mailchimpAccounts: MailchimpAccountInfo[] | undefined,
  audienceId: string | number,
) => {
  return (
    mailchimpAccounts?.find(account => account.audienceId === audienceId) ??
    null
  );
};
