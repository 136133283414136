/* eslint-disable @typescript-eslint/indent */

import { Coords } from './Editor';
import { MergeFieldsType } from './Mailchimp';
import { Response } from './general/api';

export type SurveyOptionCreationProps = {
  surveyId: number;
  clickableAreaId: string;
  destinationUrl: string;
  value: string;
  width: number;
  height: number;
  radius: number;
  angle: number;
  coords: { x: number; y: number };
};

export type SurveyOption = {
  id: number;
} & SurveyOptionCreationProps;

export type MergeTag = {
  saveResultAs: 'mergeTag';
  groupOrLabel: string;
  mergeTag: string;
};

export type Group = {
  saveResultAs: 'group';
  groupOrLabel: string;
  mergeTag: null;
};

export type Tag = {
  saveResultAs: 'tag';
  groupOrLabel: null;
  mergeTag: null;
};

export type SurveyCreationProps = {
  title: string;
  audienceId: string;
  audienceName: string;
  embededCode: string;
  imageUrl: string;
  width: number;
  height: number;
  collectAdditionalData: boolean;
  mergeTagType: MergeFieldsType;
} & (MergeTag | Group | Tag);

export type SurveyCanvases = {
  surveyCanvas: string;
  resultsCanvases: string;
};

export type SurveyCanavsesCreationProps = {
  surveyCanvasObjects: string;
  resultCanvasesObjects: string;
};

export type Survey = Omit<SurveyCreationProps, 'audienceName'> & {
  readonly id: number;
  readonly userESPLinkId: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  isKeepResultsSame: boolean;
  answerVariants: string[];
} & (MergeTag | Group | Tag);

export type ReduxSurvey = Survey & {
  options: SurveyOption[];
  embedSurveyResultsCode: string;
  initSurveyObjectsJSON: any;
  initResultsObjectsJSON: any;
  audienceName: string;
};

export type SaveSurveyProps = {
  image: string;
  width: number;
  height: number;
  fileName: string;
  surveyId: number;
  audienceId: string;
  accountId: string;
  resultCanvasesObjects: string;
  surveyCanvasObjects: string;
  isKeepResultsSame: boolean;
  surveyBackgroundColor: string;
  answerVariants: string[];
};

export type SaveSurveyReturnType = Omit<SurveyCreationProps, 'audienceName'> & {
  id: number;
  isKeepResultsSame: boolean;
  createdAt: string;
} & (MergeTag | Group | Tag);

export type SurveyDuplicateProps = SurveyCreationProps & {
  isKeepResultsSame: boolean;
  surveyId: number;
  espAccountId: string;
};

export type SurveyCanvas = {
  objects: any[];
};

export type SurveyResultsCanvases = {
  canvasId: string;
  objects: any[];
}[];

export type SurveyCanavses = {
  surveyCanvas: SurveyCanvas;
  resultsCanvases: SurveyResultsCanvases;
};

export type ChartColor = {
  color: string;
  defaultColor: string;
  order: number;
  optionId: number;
};

export type ChartType = 'pie' | 'column' | 'bar';

export type Chart = {
  type: ChartType;
  holeDiameter: number;
  pieDiameter: number;
  width: number;
  height: number;
  gap: number;
  order: number;
  borderRadius: number;
  coords: Coords;
  angle: number;
  colors: ChartColor[];
};

export type SurveyResultCreationProps = {
  optionId: number | null;
  surveyId: number;
  imageUrl: string;
  width: number;
  height: number;
  isFallback: boolean;
  fileName: string;
  charts: Chart[];
};

export type DuplicatedSurveyReturnType = Response<
  Omit<Survey, 'initSurveyObjectsJSON' | 'initResultsObjectsJSON'> &
    SurveyCanvases
>;

export type SurveyAnswer = {
  id: number;
  optionId: number;
  surveyId: number;
  email: string;
};

export type SurveyWithCanvases = Survey & {
  surveyCanvas: string;
  resultsCanvases: string;
};

export enum SurveyDynamicObjectTypeEnum {
  POLL = 'survey',
  RESULT = 'result',
}

export enum SurveyDynamicObjectVariantEnum {
  TEXT = 'text',
  IMAGE = 'image',
}

export type SurveyDynamicImageCreationProps = {
  variant: SurveyDynamicObjectVariantEnum.IMAGE;
  width: number;
  height: number;
  cropX: number;
  cropY: number;
  cropWidth: number;
  cropHeight: number;
  angle: number;
  borderRadius: number;
  coords: Coords;
  imageUrl: string;
};

export const SurveyTextFontFamilyValues = [
  'Arial',
  'Times New Roman',
  'Lato',
  'Helvetica',
  'Courier New',
  'Verdana',
  'Georgia',
  'Palatino',
  'Garamond',
  'Bookman',
  'Trebuchet MS',
  'Impact',
  'Lucida Sans Unicode',
  'Tahoma',
  'Geneva',
  'Comic Sans MS',
  'Century Gothic',
] as const;

export type SurveyTextFontFamilyType =
  (typeof SurveyTextFontFamilyValues)[number];

export type SurveyTextAlignType = 'left' | 'center' | 'right' | 'justify';

export type SurveyDynamicTextCreationProps = {
  variant: SurveyDynamicObjectVariantEnum.TEXT;
  text: string;
  fallback: string;
  useFallback: boolean;
  width: number;
  height: number;
  angle: number;
  coords: Coords;
  fontFamily: SurveyTextFontFamilyType;
  fontSize: number;
  lineHeight: number;
  letterSpacing: number;
  stroke: string;
  strokeWidth: number;
  color: string;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  linethrough: boolean;
  textAlign: SurveyTextAlignType;
};

export type DynamicText = SurveyDynamicTextCreationProps & {
  id: number;
  dynamicObjectId: number;
};

type SurveyDynamicImageObjectCreationProps = {
  variant: SurveyDynamicObjectVariantEnum.IMAGE;
  object: SurveyDynamicImageCreationProps;
};

type SurveyDynamicTextObjectCreationProps = {
  variant: SurveyDynamicObjectVariantEnum.TEXT;
  object: SurveyDynamicTextCreationProps;
};

export type SurveyDynamicObjectCreationProps = {
  surveyId: number;
  optionId: number | null;
  type: SurveyDynamicObjectTypeEnum;
  order: number;
} & (
  | SurveyDynamicImageObjectCreationProps
  | SurveyDynamicTextObjectCreationProps
);

export type ExtendedSurveyDynamicObjectCreationProps = (
  | SurveyDynamicImageCreationProps
  | SurveyDynamicTextCreationProps
) & {
  objectId: string;
  order: number;
};

export type SaveSurveyOptions = {
  onCloseModal: () => void;
  disableSuccessMessage: boolean;
};

export type GooglePrefilledFormField = {
  entryId: string;
  questionTitle: string;
  value: string;
};
