export const deepCompare = (
  obj1: any,
  obj2: any,
  ...keysToIgnore: string[]
): boolean => {
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const isClipPath =
    (obj1?.type === 'surveyResult' || obj1?.type === 'image') &&
    Math.abs(keys1.length - keys2.length) === 1;

  if (keys1.length !== keys2.length && !isClipPath) {
    return false;
  }

  if (obj1?.type === 'text') {
    return keys1.every(key =>
      keysToIgnore.includes(key) || key === 'height'
        ? true
        : deepCompare(obj1[key], obj2[key], ...keysToIgnore),
    );
  }

  if (obj1?.type === 'image') {
    return keys1.every(key => {
      if (key === 'scaleY' || key === 'scaleX') {
        return true;
      }

      if (key === 'width' && (obj1.scaleX !== 1 || obj2.scaleX !== 1)) {
        return deepCompare(
          Math.round(obj1[key] * obj1.scaleX),
          Math.round(obj2[key] * obj2.scaleX),
          ...keysToIgnore,
        );
      }

      if (key === 'height' && (obj1.scaleY !== 1 || obj2.scaleY !== 1)) {
        return deepCompare(
          Math.round(obj1[key] * obj1.scaleY),
          Math.round(obj2[key] * obj2.scaleY),
          ...keysToIgnore,
        );
      }

      return keysToIgnore.includes(key) ||
        key === 'fill' ||
        key === 'rx' ||
        key === 'ry' ||
        key === 'crossOrigin'
        ? true
        : deepCompare(obj1[key], obj2[key], ...keysToIgnore);
    });
  }

  if (obj1?.type === 'clickableArea') {
    return keys1.every(key => {
      if (key === 'scaleY' || key === 'scaleX') {
        const diff = Math.abs(obj1[key] - obj2[key]);

        return diff <= 0.1;
      }

      return keysToIgnore.includes(key)
        ? true
        : deepCompare(obj1[key], obj2[key], ...keysToIgnore);
    });
  }

  return keys1.every(key => {
    if (
      key === 'width' ||
      key === 'left' ||
      key === 'top' ||
      key === 'height'
    ) {
      const diff = Math.abs(obj1[key] - obj2[key]);

      return diff <= 10;
    }

    return keysToIgnore.includes(key)
      ? true
      : deepCompare(obj1[key], obj2[key], ...keysToIgnore);
  });
};
