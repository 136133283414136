import React, { FC, useEffect } from 'react';
import { useGeneralObjectProperties } from 'src/utils/hooks/useGeneralObjectProperties';
import * as images from '../../assets/index';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { ClickableAreaType, CustomCanvasType } from '../../utils/types/Editor';
import { EditorDataURLForm } from '../EditorDataURLForm';
import { SurveysEditorSizePropertiesBlock } from '../EditorSizePropertiesBlock';
import { Divider } from '../common/Divider/Divider';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import { Input } from '../common/Input/Input';
import { useDestinationUrl } from './actions/useDestinationUrl';
import styles from './index.module.scss';
import { checkIfGoogleFormLink } from './actions/checkIfGoogleFormLink';
import { EditorClickableAreaGoogleForm } from '../EditorClickableAreaGoogleForm';

interface Props {
  canvas: CustomCanvasType;
  activeObject: ClickableAreaType;
}

export const SurveysEditorClickableAreaSidebar: FC<Props> = ({
  canvas,
  activeObject,
}) => {
  const survey = useDistinctSelector('survey');

  const {
    x,
    y,
    width,
    height,
    angle,
    setWidth,
    setHeight,
    setAngle,
    handleCoordsSet,
    handleImageSizeChange,
  } = useGeneralObjectProperties(activeObject, canvas);

  const { destinationUrl, setDestinationUrl, handleDestinationUrlBlur } =
    useDestinationUrl(activeObject);

  useEffect(() => {
    canvas.requestRenderAll();
  }, [x, y, width, height, angle]);

  return (
    <FlexContainer className={styles.editor_clickable_area_sidebar}>
      <SurveysEditorSizePropertiesBlock
        activeObject={activeObject}
        x={x}
        y={y}
        width={width}
        height={height}
        angle={angle}
        withLock
        setWidth={setWidth}
        setHeight={setHeight}
        setAngle={setAngle}
        handleCoordsSet={handleCoordsSet}
        handleImageSizeChange={handleImageSizeChange}
      />

      <Divider extraStyles={extraStyles.divider} />

      <p className={styles.editor_clickable_area_sidebar__title}>
        Destination URL
      </p>

      <Input
        value={destinationUrl}
        onChange={e => setDestinationUrl(e.target.value)}
        icon={images.webIcon}
        placeholder='Enter web address'
        variant='editor-sidebar-input'
        onBlur={handleDestinationUrlBlur}
        hasError={!activeObject.isValidDestinationUrl}
      />

      <If condition={!activeObject.isValidDestinationUrl}>
        <p style={extraStyles.error}>Incorrect destination URL!</p>
      </If>

      <If condition={checkIfGoogleFormLink(destinationUrl)}>
        <EditorClickableAreaGoogleForm
          destinationUrl={destinationUrl}
          activeObject={activeObject}
        />
      </If>

      <Divider extraStyles={extraStyles.divider} />

      <EditorDataURLForm
        type={survey.saveResultAs}
        activeObject={activeObject}
      />
    </FlexContainer>
  );
};

const extraStyles = {
  divider: { margin: '10px 0' },
  error: {
    fontSize: 10,
    color: 'red',
    marginTop: -6,
  },
};
