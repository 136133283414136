import { fabric } from 'fabric';
import { InnerCanvasType } from '../../../types/Editor';

export const CustomCanvas = fabric.util.createClass(fabric.Rect, {
  type: 'canvas',
  stroke: '#DBDCDF',
  strokeWidth: 1,
  selectable: false,
  evented: false,

  initialize: function (options: any = {}) {
    this.callSuper('initialize', { ...options, left: 0, top: 0 });

    this.canvasId = options?.canvasId || crypto.randomUUID();
    this.objectId = options?.objectId || crypto.randomUUID();
    this.canvasType = options?.canvasType || 'survey';
    this.keepResultsSame = options?.keepResultsSame ?? true;
  },
}) as new (options?: Partial<InnerCanvasType>) => InnerCanvasType;
