import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from 'src/api/redux/baseQuery';

export interface MailchimpAccountInfo {
  accountId: string;
  accountName: string;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  avatarUrl: string;
  contactsEnhanced: number;
  dataEnhanced: number;
  accessToken: string;
  prefix: string;
  audience: string;
  audienceId: string;
}

export const mailchimpApi = createApi({
  reducerPath: 'mailchimpApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    getMailchimpAccounts: builder.query<MailchimpAccountInfo[], void>({
      query: () => ({
        url: '/esp/accounts',
      }),
    }),
    deleteMailchimpAccount: builder.mutation<MailchimpAccountInfo[], string>({
      query: accountId => ({
        url: `/esp/remove/${accountId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMailchimpAccountsQuery,
  useDeleteMailchimpAccountMutation,
  useLazyGetMailchimpAccountsQuery,
} = mailchimpApi;
