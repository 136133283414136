import { useMemo } from 'react';
import { isValidString } from 'src/utils/helpers/isValidString';
import { MergeFieldsType } from 'src/utils/types/Mailchimp';
import { DropdownOption } from 'src/utils/types/general/types';

type Props = {
  currentSaveResultsOption: DropdownOption;
  surveyTitle: string;
  groupOrLabel: string;
  mergeTag: string;
  mergeTagType: MergeFieldsType;
};

export const useIsDisableConfirm = ({
  currentSaveResultsOption,
  surveyTitle,
  groupOrLabel,
  mergeTag,
  mergeTagType,
}: Props) => {
  const isDisabledConfirm = useMemo(() => {
    switch (currentSaveResultsOption.labelValue) {
      case 'mergeTag':
        return (
          !mergeTag ||
          !groupOrLabel ||
          !isValidString(mergeTag) ||
          mergeTag.trim()?.length > 10 ||
          mergeTag.trim()?.length <= 3
        );

      case 'group':
        return !groupOrLabel;

      default:
        return false;
    }
  }, [
    currentSaveResultsOption.labelValue,
    groupOrLabel,
    mergeTag,
    mergeTagType,
    surveyTitle,
  ]);

  return isDisabledConfirm || !surveyTitle;
};
