import axios from 'axios';
import { constants } from '../utils/constants';
import { safeParse } from 'src/utils/helpers/safeParse';

const createClient = (path = '') => {
  const instance = axios.create({
    baseURL: `${constants.apiHost}/${path}`,
  });

  instance.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');
    const accountId = localStorage.getItem('accountId');

    if (token) {
      config.headers.Authorization = `Bearer ${safeParse(token)}`;
    }

    if (accountId) {
      config.headers['X-Account-ID'] = safeParse(accountId);
    }

    return config;
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (
        window.location.href.includes('privacy') ||
        window.location.href.includes('terms')
      ) {
        return;
      }

      if ([401, 403].includes(error?.response?.status)) {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('accountId');

        window.location.href = '/signin';
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export { createClient };
