import { CustomTextBoxType, InnerCanvasType } from '../types/Editor';
import {
  SurveyDynamicObjectVariantEnum,
  SurveyDynamicTextCreationProps,
  SurveyTextAlignType,
  SurveyTextFontFamilyType,
} from '../types/Survey';

export const checkForMergeTags = (text: string) => {
  const regex = /\*\|(.{1,10}?)\|\*/;

  return regex.test(text);
};

export const checkForResultMergeTags = (text: string) => {
  const regex = /(?:#|%)(.*?)(?:#|%)/;

  return regex.test(text);
};

export const getDynamicTextOptions = (
  object: CustomTextBoxType,
  innerCanvas: InnerCanvasType,
): SurveyDynamicTextCreationProps => {
  return {
    variant: SurveyDynamicObjectVariantEnum.TEXT,
    text: object.text,
    fallback: object.fallback,
    useFallback: object.saveFallback,
    width: object.width * 3,
    height: object.height * 3,
    angle: object.angle,
    coords: {
      x: (object.left - innerCanvas.left) * 3,
      y: (object.top - innerCanvas.top) * 3,
    },
    fontFamily: object.fontFamily as SurveyTextFontFamilyType,
    fontSize: object.fontSize * 3,
    lineHeight: object.lineHeight,
    letterSpacing: object.charSpacing * 3,
    stroke: object.stroke as string,
    strokeWidth: (object.strokeWidth ?? 0) * 3,
    color: object.fill as string,
    bold: object.fontWeight === 700,
    italic: object.fontStyle === 'italic',
    underline: object.underline as boolean,
    linethrough: object.linethrough as boolean,
    textAlign: object.textAlign as SurveyTextAlignType,
  };
};
