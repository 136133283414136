import { SaveAs } from 'src/utils/types/general/types';

export const getValidSaveAsData = (
  saveAs: SaveAs,
  groupOrLabel: string,
  mergeTag: string,
) => {
  switch (saveAs) {
    case 'mergeTag':
      return {
        saveResultAs: saveAs,
        groupOrLabel,
        mergeTag,
      };
    case 'group':
      return {
        saveResultAs: saveAs,
        groupOrLabel,
        mergeTag: null,
      };
    case 'tag':
      return {
        saveResultAs: saveAs,
        groupOrLabel: null,
        mergeTag: null,
      };
  }
};
