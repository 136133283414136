/* eslint-disable @typescript-eslint/indent */
import { ButtonClicksTrackingInfoDataURLType } from './CreatedDataURLStats';
import { SmartLink, SmartLinkTracks } from './SmartLink';
import { Group, MergeTag, Survey, Tag } from './Survey';

interface MailchimpResponseLink {
  rel: string;
  href: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
  targetSchema: string;
  schema: string;
}

export interface MailchimpAudienceInfo {
  audience: string;
  audienceId: string;
}

export type MergeFieldsType =
  | 'text'
  | 'number'
  | 'url'
  | 'date'
  | 'birthday'
  | 'zip'
  | 'phone'
  | 'imageurl'
  | 'radio'
  | 'dropdown';

export interface MailchimpMergeField {
  merge_id: number;
  tag: string;
  name: string;
  type: MergeFieldsType;
  required: boolean;
  default_value: string;
  public: boolean;
  display_order: number;
  options: {
    default_country: number;
    phone_format: string;
    date_format: string;
    size: number;
  };
  help_text: string;
  list_id: string;
  _links: MailchimpResponseLink[];
}

export interface AudienceContact {
  company: string;
  address1: string;
  city: string;
  country: string;
  address2?: string;
  state?: string;
  zip?: string;
  phone?: string;
}

export interface CampaignDefaults {
  from_name: string;
  from_email: string;
  subject: string;
  language: string;
}

export interface MailchimpAudienceCreationProps {
  name: string;
  permission_reminder: string;
  email_type_option: boolean;
  contact: AudienceContact;
  campaign_defaults: CampaignDefaults;
}

export interface MailchimpAudienceStats {
  member_count: number;
  total_contacts: number;
  unsubscribe_count: number;
  cleaned_count: number;
  member_count_since_send: number;
  unsubscribe_count_since_send: number;
  cleaned_count_since_send: number;
  campaign_count: number;
  campaign_last_sent: Date;
  merge_field_count: number;
  avg_sub_rate: number;
  avg_unsub_rate: number;
  target_sub_rate: number;
  open_rate: number;
  click_rate: number;
  last_sub_date: Date;
  last_unsub_date: Date;
}

export interface MailchimpAudience {
  id: string;
  web_id: number;
  name: string;
  contact: AudienceContact;
  permission_reminder: string;
  use_archive_bar: boolean;
  campaign_defaults: CampaignDefaults;
  notify_on_subscribe: boolean;
  notify_on_unsubscribe: boolean;
  date_created: Date;
  list_rating: number;
  email_type_option: boolean;
  subscribe_url_short: string;
  subscribe_url_long: string;
  beamer_address: string;
  visibility: 'pub' | 'prv';
  double_optin: boolean;
  has_welcome: boolean;
  marketing_permissions: boolean;
  stats: MailchimpAudienceStats;
  _links: MailchimpResponseLink[];
}

export type SurveyOptionStats = {
  id: number;
  url: string;
  urlDataType: ButtonClicksTrackingInfoDataURLType;
  count: number;
};

export enum AccountActionTypes {
  SMARTLINK = 'smartLink',
  SURVEY = 'survey',
}

export type AccountStatsSurveyInfo = Omit<Survey, 'saveResultAs'> & {
  answerVariantsWithVotesCount: Record<string, number>;
  totalAnswersCount: number;
  type: AccountActionTypes.SURVEY;
} & (MergeTag | Group | Tag);

export type MailchimpGroup = {
  list_id: string;
  id: string;
  title: string;
  display_order: number;
  type: 'checkboxes' | 'dropdown' | 'radio' | 'hidden';
  _links: MailchimpResponseLink[];
};

export type MailchimpSubgroup = {
  category_id: string;
  list_id: string;
  id: string;
  name: string;
  subscriber_count: number;
  display_order: number;
  _links: MailchimpResponseLink[];
};

export type MailchimpTag = {
  id: string;
  name: string;
};

export type AccountStatsSmartLinkInfo = SmartLink & {
  count: number;
  tracks: SmartLinkTracks[];
  type: AccountActionTypes.SMARTLINK;
};

export type AccountStatsInfo = AccountStatsSmartLinkInfo | AccountStatsSurveyInfo;

export type AutocompleteListRoute = 'merge-tag' | 'group' | 'subgroup' | 'tag';

export type MailchimpMergeFieldsList = {
  merge_fields: MailchimpMergeField[];
  list_id: string;
  total_items: 21;
  _links: MailchimpResponseLink[];
};

export type MailchimpGroupsList = {
  categories: MailchimpGroup[];
  list_id: string;
  total_items: 21;
  _links: MailchimpResponseLink[];
};

export type MailchimpSubgroupsList = MailchimpSubgroup[];

export type MailchimpTagsList = {
  tags: MailchimpTag[];
  total_items: 21;
};

export type AutocompleteLists = {
  'merge-tag': MailchimpMergeFieldsList;
  group: MailchimpGroupsList;
  subgroup: MailchimpSubgroupsList;
  tag: MailchimpTagsList;
};
