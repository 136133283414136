/* eslint-disable @typescript-eslint/indent */
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import * as images from '../../assets/index';
import { splitAndUppercase } from '../../utils/helpers/splitAndUppercase';
import {
  CustomCanvasType,
  CustomFabricObject,
  SurveyResultType,
} from '../../utils/types/Editor';
import { Divider } from '../common/Divider/Divider';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './index.module.scss';
import { isInstanceOf } from 'src/utils/types/general/generics';

interface Props {
  activeObject: CustomFabricObject | null;
  activeObjects: CustomFabricObject[];
  canvasObject: CustomFabricObject;
  canvas: CustomCanvasType;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
  onSetActiveObject: React.Dispatch<
    React.SetStateAction<CustomFabricObject | null>
  >;
}

export const SurveysEditorSidebarLayer: FC<Props> = ({
  activeObject,
  activeObjects,
  canvas,
  canvasObject,
  onGetCanvasObjects,
  onSetActiveObject,
}) => {
  const [layerInfo, setLayerInfo] = useState({ layerImage: '', layerText: '' });

  const isSelected = (() =>
    activeObject?.objectId === canvasObject?.objectId ||
    (activeObjects.length > 1 &&
      activeObjects.some(
        object => object?.objectId === canvasObject?.objectId,
      )))();

  useEffect(() => {
    const type = canvasObject.type;

    switch (type) {
      case 'text':
        setLayerInfo({
          layerImage: isSelected ? images.addTextBoldIcon : images.addTextIcon,
          layerText: canvasObject.text,
        });

        break;

      case 'clickableArea':
        setLayerInfo({
          layerImage: isSelected
            ? images.addClickableAreaBoldIcon
            : images.addClickableAreaIcon,
          layerText: canvasObject?.value || 'Clickable Area',
        });

        break;

      case 'surveyResult':
        setLayerInfo({
          layerText: `Survey Result - ${splitAndUppercase(
            canvasObject.variant,
          ).split(' - ')}`,
          layerImage: images.surveyIconDark,
        });

        break;

      default:
        setLayerInfo({
          layerImage: isSelected
            ? images.addImageBoldIcon
            : images.addImageIcon,
          layerText: canvasObject.fileName || 'Image',
        });
    }
  }, [
    canvasObject.objectId,
    activeObject?.objectId,
    (canvasObject as SurveyResultType)?.variant,
  ]);

  const handleLayerClick = () => {
    if (canvasObject.isLocked) {
      canvas.discardActiveObject();
      onSetActiveObject(canvasObject);

      return;
    }

    if (activeObject?.type === 'image' && !activeObject?.getSrc()) {
      return;
    }

    canvas.discardActiveObject();
    canvas.setActiveObject(canvasObject);
    canvas.requestRenderAll();
  };

  const handleLayerLock = (e: unknown) => {
    const isMouseEvent = isInstanceOf<MouseEvent>(
      e,
      (e as MouseEvent)?.stopPropagation !== undefined,
    );

    if (isMouseEvent) {
      e.stopPropagation();
    }

    if (canvasObject?.objectId === activeObject?.objectId) {
      canvas.discardActiveObject();
    }

    canvasObject.applyObjectLock(!canvasObject.isLocked);

    onGetCanvasObjects();
    canvas.requestRenderAll();
  };

  return (
    <FlexContainer gap={0} className='draggable-item'>
      <FlexContainer
        direction='row'
        align='center'
        justify='space-between'
        className={classnames(styles.surveys_editor_sidebar_layer, {
          [styles.surveys_editor_sidebar_layer__selected]: isSelected,
        })}
        onClick={() => handleLayerClick()}
      >
        <FlexContainer
          style={{
            maxWidth: 'max-content',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={layerInfo.layerImage}
            alt={canvasObject.type}
            className={styles.surveys_editor_sidebar_layer__icon}
          />

          <p
            className={classnames(styles.surveys_editor_sidebar_layer__title, {
              [styles.surveys_editor_sidebar_layer__title__selected]:
                isSelected,
            })}
          >
            {layerInfo.layerText}
          </p>
        </FlexContainer>

        <FlexContainer
          direction='row'
          className={styles.surveys_editor_sidebar_layer__buttons_container}
        >
          <img
            src={canvasObject.isLocked ? images.lockIcon : images.unlockIcon}
            alt='Lock layer icon'
            className={classnames(styles.surveys_editor_sidebar_layer__button, {
              [styles.surveys_editor_sidebar_layer__button_lock]:
                !canvasObject.isLocked,
            })}
            onMouseDown={e => e.preventDefault()}
            onClick={handleLayerLock}
          />

          <img
            src={
              activeObject?.objectId === canvasObject?.objectId
                ? images.moveArrowsBoldIcon
                : images.moveArrowsIcon
            }
            alt='Move layer icon'
            className={classnames(
              styles.surveys_editor_sidebar_layer__button,
              'column-drag-handle',
            )}
            onMouseDown={e => e.preventDefault()}
          />
        </FlexContainer>
      </FlexContainer>

      <Divider />
    </FlexContainer>
  );
};
