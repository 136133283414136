import React, { FC } from 'react';
import * as images from '../../assets/index';
import { splitAndUppercase } from '../../utils/helpers/splitAndUppercase';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { Divider } from '../common/Divider/Divider';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { IconWithText } from '../common/IconWithText';

interface Props {
  surveyTitle?: string;
  surveySaveResultAs?: string;
}

export const SurveyDialogsTopInfo: FC<Props> = ({
  surveyTitle,
  surveySaveResultAs,
}) => {
  const { title, saveResultAs } = useDistinctSelector('survey');

  return (
    <FlexContainer style={{ marginTop: -5 }}>
      <IconWithText icon={images.surveyIconDark} text={surveyTitle ?? title} />

      <Divider />

      <IconWithText
        icon={images.mailchimpIcon}
        text={`Save Results as ${splitAndUppercase(
          surveySaveResultAs ?? saveResultAs,
        )}s`}
      />

      <Divider />
    </FlexContainer>
  );
};
