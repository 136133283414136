import { fabric } from 'fabric';
import { useEffect } from 'react';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { snapToGrid } from 'src/utils/helpers/editor/snapToGrid';
import { getRoundNumber } from 'src/utils/helpers/roundNumber';
import {
  CustomCanvasType,
  CustomFabricObject,
  CustomFabricObjectType,
  CustomFabricObjectsList,
  InnerCanvasType,
} from 'src/utils/types/Editor';

export const useGeneralEventListeners = (canvas: CustomCanvasType) => {
  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (!canvas.preserveObjectStacking) {
      canvas.preserveObjectStacking = true;
    }

    fabric.Textbox.prototype.insertNewStyleBlock = function () {};
    fabric.Object.prototype.centeredRotation = false;

    canvas.isLoadingObjects = false;

    canvas.getObjectsByType = function <T extends CustomFabricObjectType>(
      type: T,
    ) {
      const canvasObjects = this.getObjects();

      return canvasObjects.filter(
        obj => obj.type === type,
      ) as unknown as CustomFabricObjectsList[T];
    };

    if (!canvas.__eventListeners) {
      canvas.__eventListeners = {};
    }

    if (!canvas.__eventListeners['mouse:down']) {
      canvas.on('mouse:down', (opt: any) => {
        const evt = opt.e;

        if (evt.ctrlKey) {
          canvas.upperCanvasEl.style.cursor = 'grabbing';

          canvas.isDragging = true;
          canvas.selection = false;
          canvas.lastPosX = evt.clientX;
          canvas.lastPosY = evt.clientY;
        }
      });
    }

    if (!canvas.__eventListeners['mouse:move']) {
      canvas.on('mouse:move', (opt: any) => {
        if (canvas.isDragging) {
          canvas.upperCanvasEl.style.cursor = 'grabbing';

          const e = opt.e;
          const vpt = canvas.viewportTransform || [];

          vpt[4] += e.clientX - canvas.lastPosX;
          vpt[5] += e.clientY - canvas.lastPosY;

          canvas.requestRenderAll();
          canvas.lastPosX = e.clientX;
          canvas.lastPosY = e.clientY;
        }
      });
    }

    if (!canvas.__eventListeners['mouse:up']) {
      canvas.on('mouse:up', () => {
        canvas.setViewportTransform(canvas?.viewportTransform || []);
        canvas.isDragging = false;
        canvas.upperCanvasEl.style.cursor = 'auto';
        canvas.selection = true;
      });
    }

    if (!canvas.__eventListeners['object:moving']) {
      canvas.on('object:moving', e => {
        const movingObject = e.target;

        if (!movingObject) {
          return;
        }

        const tempInnerCanvas = getInnerCanvas(canvas);

        if (!tempInnerCanvas) {
          return;
        }

        const top = Math.round((movingObject.top ?? 0) - tempInnerCanvas.top);
        const left = Math.round(
          (movingObject.left ?? 0) - tempInnerCanvas.left,
        );

        if (e.e.shiftKey) {
          snapToGrid(movingObject, 1, 'left', 'top');
        } else {
          if (left % 10 !== 0) {
            movingObject.set({
              left: getRoundNumber(left, 10) + tempInnerCanvas.left,
            });
          }

          if (top % 10 !== 0) {
            movingObject.set({
              top: getRoundNumber(top, 10) + tempInnerCanvas.top,
            });
          }

          if (top % 10 === 0 && left % 10 === 0) {
            snapToGrid(movingObject, 10, 'left', 'top');
          }
        }

        canvas.requestRenderAll();
      });
    }

    if (!canvas.__eventListeners['object:added']) {
      canvas.on('object:added', e => {
        const addedObject = e.target as CustomFabricObject | InnerCanvasType;

        if (!addedObject || addedObject.type === 'canvas') {
          return;
        }

        const innerCanvas = getInnerCanvas(canvas);

        addedObject.canvasId = innerCanvas.canvasId;

        const unfilteredObjects = canvas.getObjects();
        const objectsAmountWithoutInnerCanvas = unfilteredObjects.filter(
          obj => obj.type !== 'canvas',
        ).length;

        addedObject.order = objectsAmountWithoutInnerCanvas - 1;

        canvas.requestRenderAll();
      });
    }

    canvas.requestRenderAll();
  }, [canvas]);
};
