import React, { CSSProperties, FC } from 'react';
import styles from './FooterInfo.module.scss';
import classnames from 'classnames';

interface Props {
  type: 'light' | 'dark';
  extraStyles?: CSSProperties;
}

export const FooterInfo: FC<Props> = ({ type, extraStyles }) => {
  return (
    <div
      style={extraStyles}
      className={classnames(styles.footer_info, {
        [styles.footer_info__dark]: type === 'dark',
      })}
    >
      <a
        href='https://www.novelcampaigns.com/terms'
        target='__blank'
        className={classnames(
          styles.footer_info__item,
          styles.footer_info__item__link,
        )}
      >
        Terms of Service
      </a>

      <a
        href='https://www.novelcampaigns.com/privacy'
        target='__blank'
        className={classnames(
          styles.footer_info__item,
          styles.footer_info__item__link,
        )}
      >
        Privacy Policy
      </a>

      <h6 className={styles.footer_info__item}>
        © 2022 AskSung LLC All Rights Reserved.
      </h6>
    </div>
  );
};
