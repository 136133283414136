export const useHandleSaveBeforeEmebd = (
  handleSave: () => Promise<boolean>,
  openEmbedCodeModal: () => void,
  closeSaveBeforeEmbed: () => void,
) => {
  const handleSaveBeforeEmbed = async () => {
    const isSaved = await handleSave();

    if (!isSaved) {
      return;
    }

    closeSaveBeforeEmbed();
    openEmbedCodeModal();
  };

  return {
    handleSaveBeforeEmbed,
  };
};
