/* eslint-disable @typescript-eslint/indent */
import { fabric } from 'fabric';
import { constants } from 'src/utils/constants';
import {
  ChartInputVotes,
  ClickableAreaType,
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
  SurveyResultType,
  SurveyResultVariant,
} from '../../../types/Editor';
import { applyImageBorberRadius } from '../applyImageBorberRadius';
import { getInnerCanvas } from '../getInnerCanvas';
import { changeRgbaColorOpacity } from '../getValidResultsCanvases';
import { RoundedRect } from './RoundedRect';

export const SurveyResult = fabric.util.createClass(fabric.Group, {
  ...new fabric.Group(),

  initialize(
    canvas: CustomCanvasType,
    objects: CustomFabricObject[] = [],
    clickableAreas: ClickableAreaType[] = [],
    options: Partial<SurveyResultType> = {},
    isAlreadyGrouped?: boolean,
  ) {
    this.sizeX = options?.sizeX ?? 100;
    this.sizeY = options?.sizeY ?? 30;
    this.holeRadius = options?.holeRadius ?? 40;
    this.pieSize = options?.pieSize ?? 200;
    this.borderRadius = options?.borderRadius ?? 0;
    this.updateClickableAreas(clickableAreas, true);
    this.left = options?.left ?? 40;
    this.top = options?.top ?? 40;

    const generatedObjects = this.generateObjects(
      options?.variant || 'bar:stacked',
      this.clickableAreas,
      canvas,
    );
    const groupObjects = objects.length > 1 ? objects : generatedObjects;

    this.callSuper('initialize', groupObjects, options, isAlreadyGrouped);

    this.objectId = options?.objectId || crypto.randomUUID();
    this.type = options?.type || 'surveyResult';
    this.variant = options?.variant || 'bar:stacked';
    this.originX = options?.originX || 'left';
    this.originY = options?.originY || 'top';
    this.selectable = options?.selectable || true;
    this.proportionalResize = options?.proportionalResize || false;

    this.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tr: false,
      tl: false,
      br: false,
      bl: false,
      mtr: true,
    });

    this.set({
      left: this.left,
      top: this.top,
    });

    this.applyObjectLock(options?.isLocked);
  },

  changeVariant(variant: SurveyResultVariant) {
    this.set({ variant });

    const newObjects = this.generateObjects(variant, this.clickableAreas);

    this.replaceObjectsInGroup([], newObjects);
  },

  getClickableAreas() {
    return this?.clickableAreas || [];
  },

  generateObjects(
    variant: SurveyResultVariant,
    clickableAreas: ClickableAreaType[] = [],
    canvas?: CustomCanvasType,
  ) {
    const innerCanvas = getInnerCanvas(this.canvas || canvas);

    if (!innerCanvas) {
      return [];
    }

    if (!clickableAreas.length) {
      return this.generateOutlinedObjects(variant, canvas);
    }

    // for pie diagram
    const startAngle = -Math.PI / 2;
    const wedgeSize = 360 / clickableAreas.length;
    let currentAngle = startAngle;
    const radius = this?.pieSize / 2 || 100;

    // for other results types
    const left = this.left ?? 40 + (innerCanvas.left ?? 0);
    const top = this.top ?? 40 + (innerCanvas.top ?? 0);

    const convertedClickableAreas = clickableAreas?.map((el, index) => {
      const newLeft = left + index * this.sizeX;
      const newColumnLeft = left + index * this.sizeX;
      const barSingle = new fabric.Rect({
        left: newLeft,
        fill: el.fill,
        top: top,
        strokeWidth: 0,
        width: this.sizeX,
        height: this.sizeY,
      });

      /* eslint-disable @typescript-eslint/indent */

      const color = el?.fill
        ? el?.fill
        : index > 9
        ? constants.colorsArray[9]
        : constants.colorsArray[index];

      /* eslint-enable @typescript-eslint/indent */

      const endAngle = currentAngle + (Math.PI * 2 * wedgeSize) / 360;
      const wedgeObject = this.createWedge(
        currentAngle,
        endAngle,
        radius,
        color,
        innerCanvas,
      );

      switch (variant) {
        case 'bar:single':
          barSingle.set({
            left,
            top: top + (index === 0 ? 0 : index * (this.sizeY + 20)),
            clipPath: applyImageBorberRadius(barSingle, this.borderRadius),
          });

          return barSingle;

        case 'column:single':
          barSingle.set({
            left: newColumnLeft + (index === 0 ? 0 : 20) * index,
            clipPath: applyImageBorberRadius(barSingle, this.borderRadius),
          });

          return barSingle;

        case 'column:stacked':
          barSingle.set({
            left,
            top: top + this.sizeY * index,
          });

          return barSingle;

        case 'pie:stacked':
          currentAngle = endAngle;

          return wedgeObject;

        case 'pie:single':
          const newWedgeLeft =
            index === 0
              ? wedgeObject?.left - wedgeObject?.width + this?.pieSize / 2
              : wedgeObject?.left -
                wedgeObject?.width +
                wedgeObject?.width * 2 * index +
                20 * index +
                this?.pieSize / 2;

          wedgeObject.set({
            left: newWedgeLeft - 2,
          });

          return wedgeObject;

        case 'bar:stacked':
        default:
          return barSingle;
      }
    });

    if (variant === 'pie:stacked') {
      const maxHoleR = Math.abs(this?.pieSize / 2 - 1);
      let holeR =
        this?.holeRadius !== 0 || !this?.holeRadius ? this?.holeRadius / 2 : 20;

      if (holeR > maxHoleR) {
        holeR = maxHoleR;
      }

      const circleLeft =
        (innerCanvas?.left ?? 0) +
        radius +
        (this?.left - innerCanvas.left ?? 40) -
        holeR -
        0.5;
      const circleTop =
        (innerCanvas?.top ?? 0) +
        radius +
        (this?.top - innerCanvas.top ?? 0) -
        holeR -
        0.5;

      const pieCenterCircle = new fabric.Circle({
        fill: '#fff',
        left: circleLeft,
        top: circleTop,
        radius: holeR,
      });

      pieCenterCircle.set('globalCompositeOperation', 'destination-out');

      convertedClickableAreas.push(pieCenterCircle);
    }

    if (variant === 'pie:single') {
      [...convertedClickableAreas].forEach((wedge, index) => {
        const maxHoleR = Math.abs(this?.pieSize / 2 - 1);
        let holeR =
          this?.holeRadius !== 0 || !this?.holeRadius
            ? this?.holeRadius / 2
            : 20;

        if (holeR > maxHoleR) {
          holeR = maxHoleR;
        }

        const cornerX = this.left + this?.pieSize / 2;
        const cornerY = wedge.path[0][2];

        const pieCenterCircle = new fabric.Circle({
          fill: '#fff',
          left: cornerX - holeR + index * (wedge.width * 2 + 20) - 2.5,
          top: cornerY - holeR - 0.5,
          radius: holeR,
        });

        pieCenterCircle.set('globalCompositeOperation', 'destination-out');

        convertedClickableAreas.push(pieCenterCircle);
      });

      convertedClickableAreas.push(
        new fabric.Rect({
          width: this?.pieSize / 2,
          height: this?.pieSize / 2,
          left: this.left - 2,
          top: this.top,
          fill: '#fff',
          globalCompositeOperation: 'destination-out',
        }),
      );
    }

    return convertedClickableAreas;
  },

  generateChartWithData(data: ChartInputVotes) {
    const isAvailableData = data.every(el => el.count === 0);

    if (!this.canvas) {
      return;
    }

    if (isAvailableData && this.variant !== 'pie:single') {
      const outlinedObjects = this.generateOutlinedObjects(this.variant);

      const angle = this.angle;

      this.getObjects().forEach(obj => {
        this.remove(obj);
      });

      outlinedObjects.forEach(obj => {
        this.addWithUpdate(obj);
      });

      this.set('angle', angle);
      this.canvas.renderAll();

      return;
    }

    let parts = [];

    switch (this.variant) {
      case 'bar:stacked':
        parts = this.generateBarStackedObjects(data);

        break;

      case 'bar:single':
        parts = this.generateBarSingleObjects(data);

        break;

      case 'column:stacked':
        parts = this.generateColumnStackedObjects(data);

        break;

      case 'column:single':
        parts = this.generateColumnSingleObjects(data);

        break;

      case 'pie:stacked':
        parts = this.generatePieStackedObjects(data);

        break;

      case 'pie:single':
        parts = this.generatePieSingleObjects(data);

        break;
    }

    this.replaceObjectsInGroup([], parts);
    this.canvas.renderAll();
  },

  generateBarStackedObjects(data: ChartInputVotes) {
    let left = this.left ?? 0;
    const top = this.top ?? 0;
    const totalVotes = data.reduce((acc, el) => acc + el.count, 0);
    const maxWidth = this.sizeX * this.clickableAreas.length;

    const objects = this.clickableAreas.map(area => {
      const votesCount = data.find(el => el.vote === area.value)?.count || 0;
      const width = (votesCount / totalVotes) * maxWidth;
      const chartPart = new fabric.Rect({
        left,
        fill: area.fill,
        top: top,
        strokeWidth: 0,
        width,
        height: this.sizeY,
      });

      left += width;

      return chartPart;
    });

    return objects;
  },

  generateBarSingleObjects(data: ChartInputVotes) {
    const left = this.left ?? 40;
    let top = this.top ?? 40;
    const totalVotes = data.reduce((acc, el) => acc + el.count, 0);
    const maxWidth = this.sizeX;

    const objects: unknown[] = this.clickableAreas.map(
      (area: ClickableAreaType) => {
        const votesCount = data.find(el => el.vote === area.value)?.count || 0;
        const width = (votesCount / totalVotes) * maxWidth;

        const chartPart = new RoundedRect({
          left,
          fill: area.fill,
          top,
          strokeWidth: 0,
          width,
          height: this.sizeY,
        });

        const bgChartPart = new RoundedRect({
          left,
          fill: area.defaultColor,
          top,
          strokeWidth: 0,
          width: maxWidth,
          height: this.sizeY,
        });

        top += this.sizeY + 20;

        const partGroup = new fabric.Group([bgChartPart, chartPart]);

        if (this.borderRadius) {
          partGroup.set(
            'clipPath',
            applyImageBorberRadius(partGroup, this.borderRadius),
          );
        }

        return partGroup;
      },
    );

    return objects;
  },

  generateColumnStackedObjects(data: ChartInputVotes) {
    const left = this.left ?? 40;
    let top = this.top ?? 40;
    const totalVotes = data.reduce((acc, el) => acc + el.count, 0);
    const maxHeight = this.sizeY * this.clickableAreas.length;

    const objects = this.clickableAreas.map(area => {
      const votesCount = data.find(el => el.vote === area.value)?.count || 0;
      const height = (votesCount / totalVotes) * maxHeight;
      const chartPart = new fabric.Rect({
        left,
        fill: area.fill,
        top: top,
        strokeWidth: 0,
        height,
        width: this.sizeX,
      });

      top += height;

      return chartPart;
    });

    return objects;
  },

  generateColumnSingleObjects(data: ChartInputVotes) {
    let left = this.left ?? 40;
    const top = this.top ?? 40;
    const totalVotes = data.reduce((acc, el) => acc + el.count, 0);
    const maxHeight = this.sizeY;

    const objects: unknown[] = this.clickableAreas.map(
      (area: ClickableAreaType) => {
        const votesCount = data.find(el => el.vote === area.value)?.count || 0;
        const height = (votesCount / totalVotes) * maxHeight;

        const chartPart = new RoundedRect({
          left,
          fill: area.fill,
          top,
          strokeWidth: 0,
          width: this.sizeX,
          height,
        });

        const bgChartPart = new RoundedRect({
          left,
          fill: area.defaultColor,
          top,
          strokeWidth: 0,
          width: this.sizeX,
          height: maxHeight,
        });

        left += this.sizeX + 20;

        const partGroup = new fabric.Group([bgChartPart, chartPart]);

        if (this.borderRadius) {
          partGroup.set(
            'clipPath',
            applyImageBorberRadius(partGroup, this.borderRadius),
          );
        }

        return partGroup;
      },
    );

    return objects;
  },

  generatePieStackedObjects(data: ChartInputVotes) {
    let startAngle = -Math.PI / 2;
    const radius = this.pieSize / 2;
    const totalVotes = data.reduce((acc, el) => acc + el.count, 0);

    const objects = this.clickableAreas
      .sort((area1, area2) => area2.order - area1.order)
      .map((area: ClickableAreaType) => {
        const votesCount = data.find(el => el.vote === area.value)?.count || 0;

        if (!votesCount) {
          return null;
        }

        const wedgeSize = (votesCount / totalVotes) * 360;
        let endAngle = startAngle + (Math.PI * 2 * wedgeSize) / 360;

        if (totalVotes === votesCount) {
          endAngle -= 0.0001;
        }

        const wedge = this.createWedge(
          startAngle,
          endAngle,
          radius,
          area.fill,
          getInnerCanvas(this.canvas),
        );

        startAngle = endAngle;

        return wedge;
      });

    const pieCenterCircle = this.generatePieStackedInnerCircle();

    return [...objects.filter(Boolean), pieCenterCircle];
  },

  generatePieSingleObjects(data: ChartInputVotes) {
    const startAngle = -90;
    const radius = this.pieSize / 2;
    const totalVotes = data.reduce((acc, el) => acc + el.count, 0);
    const top = this.top;

    const objects = this.clickableAreas
      .sort((area1, area2) => area2.order - area1.order)
      .map((area: ClickableAreaType, index: number) => {
        const votesCount = data.find(el => el.vote === area.value)?.count || 0;

        const wedgeSize = (votesCount / totalVotes) * 360;
        let endAngle = startAngle + wedgeSize;
        const fill = area.fill;

        if (totalVotes === votesCount) {
          endAngle -= 0.0001;
        }

        const left = (() => {
          const base =
            index === 0 ? this.left : this.left + (this.pieSize + 20) * index;

          if (wedgeSize < 270 && wedgeSize >= 180) {
            const beta = wedgeSize - 180;
            const diff = radius * Math.sin(fabric.util.degreesToRadians(beta));

            return base + radius - diff;
          }

          if (wedgeSize < 180) {
            return base + radius;
          }

          return base + 0;
        })();

        const wedge = new fabric.Path(
          `M ${radius} ${radius} L ${
            radius + radius * Math.cos(fabric.util.degreesToRadians(startAngle))
          } ${
            radius + radius * Math.sin(fabric.util.degreesToRadians(startAngle))
          } A ${radius} ${radius} 0 ${endAngle - startAngle > 180 ? 1 : 0} 1 ${
            radius + radius * Math.cos(fabric.util.degreesToRadians(endAngle))
          } ${
            radius + radius * Math.sin(fabric.util.degreesToRadians(endAngle))
          } z`,
          {
            left,
            top,
            originX: 'left',
            originY: 'top',
            fill,
          },
        );

        wedge.set({ left });

        return wedge;
      });

    const pieCenterCircles = this.generatePieSingleInnerCircles();
    const bgCirlces = this.generatePieSingleBackgroundCirlces();

    return [...bgCirlces, ...objects, ...pieCenterCircles];
  },

  generatePieStackedInnerCircle() {
    const radius = this?.pieSize / 2 || 100;
    const { holeR } = this.getHoleRadius();

    const circleLeft = this?.left + radius;
    const circleTop = this?.top + radius;

    const pieCenterCircle = new fabric.Circle({
      fill: '#fff',
      originX: 'center',
      originY: 'center',
      left: circleLeft,
      top: circleTop,
      radius: holeR,
    });

    pieCenterCircle.set('globalCompositeOperation', 'destination-out');

    return pieCenterCircle;
  },

  generatePieSingleInnerCircles() {
    const circles: (fabric.Circle | fabric.Rect)[] = [];
    const radius = this?.pieSize / 2 ?? 100;

    this.clickableAreas.forEach((_, index) => {
      const left =
        index === 0
          ? this.left + radius
          : this.left + radius + (this.pieSize + 20) * index;

      const { holeR, remaining } = this.getHoleRadius();

      const pieCenterCircle = new fabric.Circle({
        fill: '#fff',
        left: left + remaining,
        top: this.top + radius + remaining,
        originX: 'center',
        originY: 'center',
        radius: holeR,
      });

      pieCenterCircle.set('globalCompositeOperation', 'destination-out');

      circles.push(pieCenterCircle);
    });

    return circles;
  },

  generatePieSingleBackgroundCirlces() {
    return this.clickableAreas
      .sort((area1, area2) => area2.order - area1.order)
      .map((area, index) => {
        const newLeft =
          index === 0 ? this.left : this.left + (this.pieSize + 20) * index;

        const part = new fabric.Circle({
          radius: this.pieSize / 2,
          fill: area.defaultColor,
          originX: 'left',
          originY: 'top',
          left: newLeft,
          top: this.top,
        });

        return part;
      });
  },

  generateOutlinedObjects(
    variant: SurveyResultVariant,
    canvas?: CustomCanvasType,
  ) {
    const innerCanvas = getInnerCanvas(this.canvas || canvas);

    if (!innerCanvas) {
      return;
    }

    const left = this.left ?? 40 + (innerCanvas.left ?? 0);
    const top = this.top ?? 40 + (innerCanvas.top ?? 0);

    const width =
      variant === 'bar:stacked'
        ? this.sizeX * this.clickableAreas.length
        : this.sizeX;
    const height =
      variant === 'column:stacked'
        ? this.sizeY * this.clickableAreas.length
        : this.sizeY;

    const barSingle = new fabric.Rect({
      width,
      height,
      fill: 'transparent',
      strokeWidth: 2,
      stroke: '#DBDCDF',
      left,
      top,
    });

    const { holeR } = this.getHoleRadius();
    const outerCircleRadius = this.pieSize / 2 - 2;

    const outerCircle = new fabric.Circle({
      radius: outerCircleRadius < 0 ? 0 : outerCircleRadius,
      fill: 'transparent',
      strokeWidth: 2,
      stroke: '#DBDCDF',
      left,
      top,
    });
    const innerCircle = new fabric.Circle({
      radius: holeR < 0 ? 0 : holeR,
      fill: 'transparent',
      strokeWidth: 2,
      stroke: '#DBDCDF',
      left: left + (this.pieSize / 2 - this.holeRadius / 2),
      top: top + (this.pieSize / 2 - this.holeRadius / 2),
    });
    const pie = new fabric.Group([outerCircle, innerCircle], {
      left: this.left,
      top: this.top,
    });

    switch (variant) {
      case 'bar:stacked':
      case 'column:stacked':
        return [barSingle];

      case 'bar:single':
      case 'column:single':
        const borderRadius = this.borderRadius ?? 0;

        return this.clickableAreas
          .sort((area1, area2) => area2.order - area1.order)
          .map((area, index) => {
            const part = new RoundedRect({
              width: this.sizeX,
              height: this.sizeY,
              fill: area.defaultColor,
              topLeft: [borderRadius, borderRadius],
              topRight: [borderRadius, borderRadius],
              bottomLeft: [borderRadius, borderRadius],
              bottomRight: [borderRadius, borderRadius],
            });

            if (variant === 'bar:single') {
              part.set({
                left,
                top: top + (index === 0 ? 0 : index * (this.sizeY + 20)),
              });
            }

            if (variant === 'column:single') {
              part.set({
                left: left + (index === 0 ? 0 : index * (this.sizeX + 20)),
                top,
              });
            }

            return part;
          });

      case 'pie:stacked':
        return [pie];

      case 'pie:single':
        const bgCircles = this.generatePieSingleBackgroundCirlces();
        const innerCircles = this.generatePieSingleInnerCircles();

        return [...bgCircles, ...innerCircles];
    }
  },

  getHoleRadius() {
    const maxHoleR = Math.abs(this?.pieSize / 2 - 1);
    let holeR =
      this?.holeRadius !== 0 || !this?.holeRadius ? this?.holeRadius / 2 : 20;

    let remaining = 0;

    if (holeR > maxHoleR) {
      holeR = maxHoleR;
      remaining = Math.abs(this.pieSize / 2 - maxHoleR) / 2;
    }

    return { holeR, remaining };
  },

  replaceObjectsInGroup(
    newClickableAreas: ClickableAreaType[],
    generatedObjects?: any[],
  ) {
    const angle = this.angle;
    const left = this.left;
    const top = this.top;

    this.getObjects().forEach(obj => {
      this.remove(obj);
    });

    const newObjects = generatedObjects?.length
      ? generatedObjects
      : this.generateObjects(this.variant, newClickableAreas);

    newObjects.forEach(obj => {
      this.addWithUpdate(obj);
    });

    this.set({
      clipPath: applyImageBorberRadius(this, this.borderRadius),
      angle,
      top,
      left,
    });
  },

  updateClickableAreas(
    clickableAreas: ClickableAreaType[],
    isInit: boolean = false,
  ) {
    const tempArr = clickableAreas || this.clickableAreas || [];

    this.clickableAreas = tempArr
      .sort((area1, area2) => area2.order - area1.order)
      .map((area, index) => {
        const clickableArea =
          this?.clickableAreas?.find(temp => temp.objectId === area.objectId) ||
          null;

        const tempColor =
          index > 9 ? constants.colorsArray[9] : constants.colorsArray[index];
        const fill = isInit
          ? tempColor
          : area?.fill ?? clickableArea?.fill ?? tempColor;

        const tempDefaultColor = changeRgbaColorOpacity(
          area?.fill ?? clickableArea?.fill ?? tempColor,
          0.2,
        );
        const defaultColor = isInit
          ? changeRgbaColorOpacity(tempColor, 0.2)
          : area?.defaultColor ??
            clickableArea?.defaultColor ??
            tempDefaultColor;

        return {
          ...(area ?? clickableArea),
          objectId: area.objectId,
          value: area.value,
          fill,
          defaultColor,
        };
      });

    return this.clickableAreas;
  },

  createWedge(
    startAngle: number,
    endAngle: number,
    radius: number,
    color: string,
    innerCanvas: InnerCanvasType,
  ) {
    const centerX = (innerCanvas.left ?? 0) + (this?.left ?? 40) + radius;
    const centerY = (innerCanvas.top ?? 0) + (this?.top ?? 40) + radius;

    const angleDiff = endAngle - startAngle;

    const x1 = centerX + Math.cos(startAngle) * radius;
    const y1 = centerY + Math.sin(startAngle) * radius;

    const x2 = centerX + Math.cos(endAngle) * radius;
    const y2 = centerY + Math.sin(endAngle) * radius;

    const pathData = `M ${centerX} ${centerY} L ${x1} ${y1} A ${radius} ${radius} 0 ${
      angleDiff > Math.PI ? 1 : 0
    } 1 ${x2} ${y2} Z`;

    const wedge = new fabric.Path(pathData, {
      fill: color,
      originX: 'center',
      originY: 'center',
      strokeWidth: 0,
    });

    wedge.set({
      centerX,
      centerY,
      endAngle,
    } as any);

    return wedge;
  },

  createPieSingleWedge(
    radius: number,
    startAngle: number,
    endAngle: number,
    fill: string,
  ) {
    const wedge = new fabric.Circle({
      radius: radius,
      startAngle: startAngle,
      endAngle: endAngle,
      originX: 'center',
      originY: 'center',
      left: 0,
      top: 0,
      fill,
    });

    return wedge;
  },

  replaceColorInChartPart(
    newColor: string,
    newDefaultColor: string,
    objectId: string,
  ) {
    const clickalbeAreas = this.getClickableAreas();
    const convertedClickableAreas = clickalbeAreas.map(
      (area): ClickableAreaType =>
        area.objectId === objectId
          ? { ...area, fill: newColor, defaultColor: newDefaultColor }
          : area,
    );

    this.updateClickableAreas(convertedClickableAreas);
    this.rerenderGroup();
  },

  getChartSize() {
    switch (this.variant) {
      case 'bar:stacked':
        return {
          width: this.sizeX * this.clickableAreas.length,
          height: this.sizeY as number,
        };

      case 'column:stacked':
        return {
          width: this.sizeX as number,
          height: this.sizeY * this.clickableAreas.length,
        };

      case 'bar:single':
      case 'column:single':
        return {
          width: this.sizeX as number,
          height: this.sizeY as number,
        };

      default:
        return {
          width: 0,
          height: 0,
        };
    }
  },

  rerenderGroup() {
    const objects = this.generateObjects(this.variant, this.clickableAreas);

    this.replaceObjectsInGroup([], objects);

    this.canvas.renderAll();
  },

  applyObjectLock(isLocked?: boolean) {
    this.isLocked = isLocked !== undefined ? isLocked : this.isLocked ?? false;

    if (this.isLocked) {
      this.lockMovementX = true;
      this.lockMovementY = true;
      this.hasControls = false;
      this.hasBorders = false;
      this.evented = false;
      this.selectable = false;
    } else {
      this.lockMovementX = false;
      this.lockMovementY = false;
      this.hasControls = true;
      this.hasBorders = true;
      this.evented = true;
      this.selectable = true;
    }
  },
}) as new (
  canvas: CustomCanvasType,
  objects?: CustomFabricObject[],
  clickableAreas?: ClickableAreaType[],
  options?: Partial<SurveyResultType>,
  isAlreadyGrouped?: boolean,
) => SurveyResultType;
