import {
  getFromEditorLocalbase,
  saveToEditorLocalbase,
} from 'src/api/local/editor';
import { CustomCanvasType } from 'src/utils/types/Editor';
import { getUniqueObjects } from '../getUniqueObjects';
import { convertToObjects } from './convertToObjects';
import { getInnerCanvas } from './getInnerCanvas';

export const saveSurveyResultsCanvases = (
  canvas: CustomCanvasType,
  canvasId: string,
  clearCanvas: boolean = true,
  callback?: () => void,
) => {
  canvas.discardActiveObject();

  const innerCanvas = getInnerCanvas(canvas);

  if (!innerCanvas) {
    return;
  }

  if (innerCanvas.canvasType !== 'results') {
    innerCanvas.set({ canvasType: 'results' });
  }

  const objects = convertToObjects(canvas);

  getFromEditorLocalbase('resultsCanvases').then(data => {
    const uniqueObjects = getUniqueObjects(objects, 'objectId');

    saveToEditorLocalbase('resultsCanvases', {
      canvases: [
        ...data?.canvases?.filter(temp => temp.canvasId !== canvasId),
        {
          canvasId: canvasId,
          objects: uniqueObjects,
        },
      ],
    });

    if (clearCanvas) {
      canvas.clear();
    }

    callback?.();
  });
};
