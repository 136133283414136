import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';

export const useSelectionListeners = (
  canvas: CustomCanvasType,
  onSetActiveObjects: Dispatch<SetStateAction<CustomFabricObject[]>>,
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void,
) => {
  useEffect(() => {
    if (!canvas) {
      return;
    }

    if (!canvas.__eventListeners['selection:cleared']) {
      canvas.on('selection:cleared', e => {
        const deselected = e.deselected as CustomFabricObject[] | undefined;

        deselected?.forEach(object => {
          if (object.type === 'image' && !object.getSrc()) {
            canvas.remove(object);
            canvas.requestRenderAll();

            onGetCanvasObjects();
          }
        });

        canvas.requestRenderAll();

        onSetActiveObjects([]);
      });
    }

    if (!canvas.__eventListeners['selection:created']) {
      canvas.on('selection:created', e => {
        const selected = e.selected as CustomFabricObject[] | undefined;

        const activeSelection = canvas.getActiveObject();
        const selectedObjects = (activeSelection as any)
          ?._objects as CustomFabricObject[];

        if (selectedObjects && selectedObjects?.length > 1) {
          const isTextOrResultsSelected = selectedObjects.some(
            obj => obj.type === 'text' || obj.type === 'surveyResult',
          );

          if (isTextOrResultsSelected) {
            activeSelection?.setControlsVisibility({
              ml: false,
              mr: false,
              mt: false,
              mb: false,
            });

            canvas.requestRenderAll();
          }
        }

        if (selected?.every(item => !item.isLocked)) {
          onSetActiveObjects(selected);

          return;
        }

        canvas.discardActiveObject();

        const nonLockedObjects =
          selected?.filter(item => !item.isLocked) ?? null;

        if (nonLockedObjects) {
          const selection = new fabric.ActiveSelection(nonLockedObjects, {
            canvas: canvas as Canvas,
          });

          canvas.setActiveObject(selection);
        }

        onSetActiveObjects(nonLockedObjects ?? []);
        canvas.requestRenderAll();
      });
    }

    const selectioUpdateHandler = e => {
      const selectedObjects = e.selected;

      if (!selectedObjects.length) {
        return;
      }

      const activeObjects = [
        ...new Set([...(canvas.getActiveObjects() || []), ...selectedObjects]),
      ];

      onSetActiveObjects(activeObjects);
    };

    canvas.on('selection:updated', selectioUpdateHandler);

    return () => {
      canvas.off('selection:updated', selectioUpdateHandler);
    };
  }, [canvas]);
};
