/* eslint-disable @typescript-eslint/indent */
import React, { CSSProperties, FC } from 'react';
import styles from './FlexContainer.module.scss';
import classnames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  direction?: 'row' | 'column';
  gap?: number | string;
  align?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end';
  justify?:
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end';
  className?: string;
  extraStyles?: CSSProperties;
  containerRef?: any;
}

export const FlexContainer: FC<Props> = ({
  children,
  direction,
  gap,
  align,
  justify,
  extraStyles,
  className,
  containerRef,
  ...props
}) => {
  return (
    <div
      className={classnames(styles.flex_container, {
        [className || '']: className,
      })}
      style={{
        flexDirection: direction ?? undefined,
        gap: gap ?? undefined,
        alignItems: align ?? undefined,
        justifyContent: justify ?? undefined,
        ...extraStyles,
      }}
      ref={containerRef}
      {...props}
    >
      {children}
    </div>
  );
};
