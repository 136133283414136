/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import { applyDrag } from '../../utils/helpers/applyDrag';
import { SurveyResultOption } from '../SurveyResultOption';
import './index.scss';
import {
  ClickableAreaType,
  CustomCanvasType,
  SurveyResultType,
} from '../../utils/types/Editor';

interface Props {
  activeObject: SurveyResultType;
  canvas: CustomCanvasType;
}

export const SurveyResultOptions: FC<Props> = ({ activeObject, canvas }) => {
  const [surveyResultOptions, setSurveyResultOptions] = useState<
    ClickableAreaType[]
  >([]);

  useEffect(() => {
    setSurveyResultOptions(activeObject.clickableAreas);
  }, [activeObject.objectId]);

  const renderReorderedList = (reorderedOptions: ClickableAreaType[]) => {
    const updatedClickableAreasCopy = reorderedOptions
      .reverse()
      .map((option, index) => {
        const copy = { ...option } as ClickableAreaType;

        copy.order = index;

        return copy;
      });

    const updatedClickableAreas = activeObject.updateClickableAreas(
      updatedClickableAreasCopy,
    );

    setSurveyResultOptions(updatedClickableAreasCopy);

    activeObject.replaceObjectsInGroup(updatedClickableAreas);
    canvas.renderAll();
  };

  return (
    <Container
      dragHandleSelector='.column-drag-handle'
      animationDuration={500}
      onDrop={e => renderReorderedList(applyDrag(surveyResultOptions, e))}
      render={ref => (
        <div ref={ref}>
          {surveyResultOptions.map(object => {
            return (
              <Draggable
                key={object.objectId}
                render={() => (
                  <div className='draggable-item'>
                    <SurveyResultOption
                      option={object}
                      activeObject={activeObject}
                      onChnageSurveyResultOptions={setSurveyResultOptions}
                    />
                  </div>
                )}
              />
            );
          })}
        </div>
      )}
    />
  );
};
