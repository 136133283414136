import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { constants } from 'src/utils/constants';
import { SmartForm } from 'src/utils/types/SmartForm';

const initialState: SmartForm = {
  accountId: '',
  audienceId: '',
  tag: '',
  collectAdditionalData: false,
  buttonText: 'GET NEWSLETTER',
  textColor: '#ffffff',
  buttonDefaultColor: '#D82E8A',
  buttonHoverColor: '#D82E8A',
  buttonActiveColor: '#D82E8A',
  successTitle: 'SUCCESS!',
  successMessage: 'Thank you for signing up for our weekly newsletter!',
  successTitleColor: '#D82E8A',
  successMessageColor: '#000',
};

export const getSmartFormCode = (state: SmartForm) => {
  const uniqueId = Math.random().toString(36).substring(7);

  const script = `
    var successMessage = "${state.successTitle}";
    var successMessage2 = "${state.successMessage.replace(/\n/g, '\\n')}";

    async function handleSubmit(event) {
      event.preventDefault();

      var name = event.target.elements.name.value;
      var email = event.target.elements.email.value;

      var errorNode = document.getElementById("error-${uniqueId}");
      var formNode = document.getElementById("form-${uniqueId}");
      var successMessageNode = document.getElementById("success-message-${uniqueId}");
      var successMessage2Node = document.getElementById("success-message2-${uniqueId}");
      var buttonNode = document.getElementById("button-${uniqueId}");

      var initButtonText = buttonNode.innerText;

      try {
        if (!name) {
          throw new Error("Please, fill the name input!");
        }

        if (!email) {
          throw new Error("Please, fill the email input!");
        }

        buttonNode.innerText = "Loading...";

        await fetch(
          "${constants.apiHost}/mailchimp/submit/lead-form",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              email,
              tag: "${state.tag}",
              audienceId: "${state.audienceId}",
              accountId: "${state.accountId}",
              collectAdditionalData: ${state.collectAdditionalData},
            }),
          }
        );

        errorNode.style.display = "none";
        formNode.style.display = "none";

        successMessageNode.style.display = "block";
        successMessage2Node.style.display = "block";
        successMessageNode.innerText = successMessage;
        successMessage2Node.innerText = successMessage2;
      } catch (error) {
        console.log(error);
        errorNode.style.display = "block";
        errorNode.textContent = error?.message || "Some error occured!";
      } finally {
        buttonNode.innerText = initButtonText;
      }
    }`;

  const code = `\n\n<style>
    @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

    * {
      box-sizing: border-box;
      font-family: 'Lato', sans-serif;
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;
    }

    .input {
      width: 100%;
      max-width: 300px;
      height: 50px;
      border-radius: 6px;
      border: 1px solid #979da6;
      outline: none;
      color: #000;
      padding-left: 16px;
    }

    .input::placeholder {
      font-size: 16px;
      color: #979da6;
    }

    .button-${uniqueId} {
      width: 100%;
      max-width: 300px;
      height: 50px;
      background-color: ${state.buttonDefaultColor};
      border-radius: 26px;
      color: ${state.textColor};
      border: none;
    }

    .button-${uniqueId}:hover {
      background-color: ${state.buttonHoverColor};
      cursor: pointer;
    }

    .button-${uniqueId}:active {
      background-color: ${state.buttonActiveColor};
      cursor: pointer;
    }

    * {
      transition: all 0.2s ease-in-out;
    }
  </style>

  <p
    id="success-message-${uniqueId}"
    style="display: none; color: ${state.successTitleColor}; font-weight: bold"
  ></p>
  <p id="success-message2-${uniqueId}" style="display: none; color: ${state.successMessageColor}"></p>
  <form onsubmit="handleSubmit(event)" id="form-${uniqueId}">
    <div class="wrapper">
      <input
        name="name"
        type="text"
        class="input"
        placeholder="First Name"
        required
      />
      <input
        name="email"
        type="email"
        class="input"
        placeholder="Email Address"
        required
      />
      <p id="error-${uniqueId}" style="display: none"></p>
      <button class="button-${uniqueId}" id="button-${uniqueId}">${state.buttonText}</button>
    </div>
  </form>`;

  return { script, code };
};

const smartFormSlice = createSlice({
  name: 'smartForm',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<SmartForm>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export default smartFormSlice.reducer;
export const smartFormActions = smartFormSlice.actions;
