/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react';
import { Params, useParams } from 'react-router-dom';

import { NavigationPopup } from '../NavigationPopup/NavigationPopup';
import { Divider, FlexContainer, If, Loader } from '../common';

import axios, { AxiosError } from 'axios';
import {
  MailchimpAccountInfo,
  useGetMailchimpAccountsQuery,
} from '../../redux/slice/mailchimpApi';

import { constants } from '../../utils/constants';
import { safeStringify } from '../../utils/helpers/safeStringify';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';

import { ReturnBackButton } from '../common/ReturnBackButton';
import styles from './Header.module.scss';
import { useHeaderIcon } from './actions/useHeaderIcon';

interface Props {
  isOpenNav: boolean;
  withReturnBackButton: boolean;
  hasError: boolean;
  onOpenNav: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseNavPopup: () => void;
}

export const Header: FC<Props> = ({
  isOpenNav,
  withReturnBackButton,
  hasError,
  onOpenNav,
  handleCloseNavPopup,
}) => {
  const { audienceId = '' } = useParams<Params>();

  const user = useDistinctSelector('user');

  const { data: mailchimpAccounts, isLoading } = useGetMailchimpAccountsQuery();
  const [currentMailchimpAccount, setCurrentMailchimpAccout] =
    useState<MailchimpAccountInfo | null>(
      mailchimpAccounts?.find(acc => acc.audienceId === audienceId) || null,
    );

  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(user?.publicProfileURL, {
          responseType: 'arraybuffer',
        });

        const arrayBufferView = new Uint8Array(response.data);
        const blob = new Blob([arrayBufferView], {
          type: response.headers['content-type'],
        });

        const imageUrl = URL.createObjectURL(blob);

        setImageSrc(imageUrl);
      } catch (error: unknown) {
        if (error instanceof AxiosError && error.response?.status === 403) {
          window.location.assign(`${constants.apiHost}/user/signout`);
        }
      }
    })();
  }, [user?.publicProfileURL]);

  useEffect(() => {
    if (!audienceId) {
      setCurrentMailchimpAccout(null);

      return;
    }

    const foundAccount = mailchimpAccounts?.find(
      acc => acc.audienceId === audienceId,
    );

    setCurrentMailchimpAccout(foundAccount || null);
  }, [audienceId, safeStringify(mailchimpAccounts)]);

  const headerIcon = useHeaderIcon(currentMailchimpAccount?.avatarUrl);

  return (
    <header className={styles.header}>
      <div onClick={handleCloseNavPopup} className={styles.header_rightSide}>
        <If condition={withReturnBackButton}>
          <ReturnBackButton />

          <Divider extraStyles={extraStyles.divider} />
        </If>

        <If condition={isLoading}>
          <FlexContainer>
            <Loader size='md' />
          </FlexContainer>
        </If>

        <If condition={!isLoading}>
          <img
            src={headerIcon}
            alt='Main Addpicto Logotype'
            className={styles.header_logo}
          />
        </If>

        <FlexContainer direction='row' align='center'>
          <h3 className={styles.header_title} data-cy='header-title'>
            <If condition={currentMailchimpAccount && !isLoading}>
              {currentMailchimpAccount?.accountName}
            </If>

            <If
              condition={
                !currentMailchimpAccount &&
                !isLoading &&
                !window.location.pathname.includes('/actions')
              }
            >
              {`Welcome ${user?.firstName || 'User'}`}
            </If>
          </h3>
        </FlexContainer>
      </div>

      <div className={styles.header_leftSide}>
        <If condition={!hasError}>
          <button
            onClick={() => onOpenNav(prev => !prev)}
            className={styles.header__popup_button}
          >
            <img
              src={imageSrc}
              className={styles.header__popup_button_icon}
              alt='User profile picture'
            />
          </button>
        </If>

        <If condition={isOpenNav}>
          <div className={styles.header_popup__mobile}>
            <NavigationPopup onClosePopup={handleCloseNavPopup} />
          </div>

          <div className={styles.header_popup__desktop}>
            <NavigationPopup onClosePopup={handleCloseNavPopup} />
          </div>
        </If>
      </div>
    </header>
  );
};

const extraStyles = {
  divider: { width: 1, height: 20, margin: '0 10px' },
};
