import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';

export const handlePropSet = (
  activeObject: any,
  event: ChangeEvent<HTMLInputElement> | string,
  field: string,
  setState: Dispatch<SetStateAction<string>>,
  canvasRect: any = {},
) => {
  const value = typeof event === 'string' ? event : event.target.value;
  let validState = { isValid: true, message: 'Incorrect value!' };

  if (field === 'text' || field === 'fallback') {
    setState(value);

    activeObject.set({
      [field]: value,
    });

    return;
  }

  if (field === 'lineHeight' && (!value || value === '0')) {
    setState('');

    activeObject.set({
      [field]: 0.01,
    });

    return;
  }

  if ((field === 'top' || field === 'left') && !value) {
    setState('');

    activeObject.set({
      [field]: canvasRect[field],
    });

    return;
  }

  if (!value) {
    setState('');

    activeObject.set({
      [field]: 0,
    });

    return;
  }

  if (
    field === 'angle' ||
    field === 'left' ||
    field === 'top' ||
    field === 'charSpacing'
  ) {
    if (value === '-') {
      setState(value);

      return;
    }

    validState = validatePossibleNegativeNumebers(value, field);
  } else {
    validState = validatePositiveNumbers(value);
  }

  if (!validState.isValid) {
    toast.error(validState.message);

    return;
  }

  if (field === 'top' || field === 'left') {
    setState(value);

    activeObject.set({
      [field]: Number(value) + Number(canvasRect[field]),
    });

    return;
  }

  if (field === 'lineHeight') {
    setState(value);

    const newLineHeight = Number(value) / activeObject.fontSize;

    activeObject.set({
      [field]: newLineHeight,
    });

    return;
  }

  if (field === 'charSpacing') {
    if (Number(value) < -30) {
      setState('-30');

      activeObject.set({
        [field]: activeObject.fontSize * -30,
      });

      return;
    }

    setState(value);

    activeObject.set({
      [field]: activeObject.fontSize * Number(value),
    });

    return;
  }

  if (field === 'sizeX' && activeObject.type === 'surveyResult') {
    if (activeObject.variant === 'bar:stacked') {
      const newWidth = Number(value) / activeObject.clickableAreas.length;

      activeObject.set({
        sizeX: newWidth,
      });
    } else {
      activeObject.set({
        sizeX: Number(value),
      });
    }

    setState(value);

    return;
  }

  if (field === 'sizeY' && activeObject.type === 'surveyResult') {
    if (activeObject.variant === 'column:stacked') {
      const newHeight = Number(value) / activeObject.clickableAreas.length;

      activeObject.set({
        sizeY: newHeight,
      });
    } else {
      activeObject.set({
        sizeY: Number(value),
      });
    }

    setState(value);

    return;
  }

  if (field === 'width' || field === 'height') {
    if (activeObject.proportionalResize) {
      const newWidth = field === 'width' ? Number(value) : activeObject.width;
      const newHeight =
        field === 'height' ? Number(value) : activeObject.height;

      const newScaleX = newWidth / activeObject.width;
      const newScaleY = newHeight / activeObject.height;

      activeObject.set({
        scaleX: newScaleX,
        scaleY: newScaleY,
        width: newWidth,
        height: newHeight,
      });

      setState(value);

      return;
    }
  }

  activeObject.set({
    [field]: isNaN(Number(value)) ? value : Number(value),
  });

  setState(value);
};

const validatePossibleNegativeNumebers = (value: string, field: string) => {
  if (!isValidNumber(value)) {
    return { isValid: false, message: 'Incorrect number!' };
  }

  const numValue = Number(value);

  if (field === 'angle') {
    if (numValue < -360 || numValue > 360) {
      return {
        isValid: false,
        message: 'Angle value should be from -360 to 360!',
      };
    }

    return { isValid: true, message: '' };
  }

  if (numValue < -6000 || numValue > 6000) {
    return { isValid: false, message: 'Value should be from -6000 to 6000!' };
  }

  return { isValid: true, message: '' };
};

const validatePositiveNumbers = (value: string) => {
  if (!isValidNumber(value)) {
    return { isValid: false, message: 'Incorrect number!' };
  }

  const numValue = Number(value);

  if (numValue < 0 || numValue > 6000) {
    return { isValid: false, message: 'Value should be from 0 to 6000!' };
  }

  return { isValid: true, message: '' };
};

const isValidNumber = (value: string) => {
  const pattern = /^-?\d+$/;

  return pattern.test(value);
};
