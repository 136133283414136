import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../common/Button/Button';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './index.module.scss';
import { DropdownOption, ExtraStyles } from 'src/utils/types/general/types';
import { Dropdown, If } from '../common';

interface Props {
  title: string;
  code: string;
  dropdownList?: DropdownOption[];
  current?: DropdownOption;
  setCurrent?: Dispatch<SetStateAction<DropdownOption<any>>>;
  isDisabled?: boolean;
  extraStyles?: CSSProperties;
}

export const EditorEmdebCode: FC<Props> = ({
  title,
  code,
  current,
  setCurrent,
  dropdownList,
  isDisabled = false,
  extraStyles,
}) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);

      toast.success('Copied to clipboard!');
    } catch {
      toast.error(
        'Error occured while copying! Please, copy the code manualy.',
      );
    }
  };

  return (
    <FlexContainer className={styles.editor_embed_code} gap={20}>
      <FlexContainer direction='row' align='end' justify='space-between'>
        <h5 className={styles.editor_embed_code__title}>{title}</h5>

        <FlexContainer className={styles.editor_embed_code__copy_btn_container}>
          <If condition={dropdownList}>
            <Dropdown
              options={dropdownList as DropdownOption[]}
              value={current as DropdownOption}
              onChange={setCurrent as Dispatch<SetStateAction<DropdownOption>>}
              extraStyles={commonExtraStyles.dropdown}
              extraStylesSelectedElement={commonExtraStyles.selectedElement}
            />
          </If>

          <Button
            onClick={handleCopy}
            text={`Copy`}
            type={isDisabled ? 'disabled' : 'outlined-blue'}
            variant='small'
            disable={isDisabled}
          />
        </FlexContainer>
      </FlexContainer>

      <code
        className={styles.editor_embed_code__code_container}
        style={extraStyles}
      >
        <pre>{code}</pre>
      </code>
    </FlexContainer>
  );
};

const commonExtraStyles: ExtraStyles = {
  dropdown: {
    maxWidth: 100,
    height: 32,
  },
  selectedElement: {
    maxWidth: 40,
  },
};
