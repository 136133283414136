import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { saveToEditorLocalbase } from 'src/api/local/editor';
import { editorActions } from 'src/redux/slice/editor';
import { useAppDispatch } from 'src/redux/store';
import { centerCanvasView } from 'src/utils/helpers/editor/centerCanvasView';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { getValidResultsCanvases } from 'src/utils/helpers/editor/getValidResultsCanvases';
import { loadSurveyCanvasObjects } from 'src/utils/helpers/editor/loadSurveyCanvasObjects';
import { loadSurveyResultsCanvasObjects } from 'src/utils/helpers/editor/loadSurveyResultsCanvasObjects';
import { saveSurveyCanvas } from 'src/utils/helpers/editor/saveSurveyCanvas';
import { saveSurveyResultsCanvases } from 'src/utils/helpers/editor/saveSurveyResultsCanvases';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import {
  CanvasType,
  CustomCanvasType,
  InnerCanvasType,
} from 'src/utils/types/Editor';

export const useCanvasSwitch = (
  canvas: CustomCanvasType,
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void,
  onSetInnerCanvas: Dispatch<SetStateAction<InnerCanvasType>>,
) => {
  const [type, setType] = useState<CanvasType>('survey');
  const [isLoadingSurveyCanvas, setIsLoadingSurveyCanvas] = useState(false);
  const [isLoadingSurveyResultsCanvas, setIsLoadingSurveyResultsCanvas] =
    useState(false);
  const [isTypeWasChanged, setIsTypeWasChanges] = useState(false);

  const {
    prevCanvasId,
    currentCanvasId,
    keepResultsSame,
    isLoadingImage,
    showPreview,
  } = useDistinctSelector('editor');

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (!canvas || type === 'survey' || showPreview) {
        return;
      }

      if (currentCanvasId !== prevCanvasId) {
        dispatch(editorActions.update({ isLoadingObjects: true }));

        await new Promise(resolve => {
          saveSurveyResultsCanvases(canvas, prevCanvasId, true, async () => {
            await loadSurveyResultsCanvasObjects(
              canvas,
              keepResultsSame ? 'fallback' : currentCanvasId,
            );

            onSetInnerCanvas(getInnerCanvas(canvas));
            onGetCanvasObjects(canvas);
            resolve(true);
          });
        });

        dispatch(editorActions.update({ isLoadingObjects: false }));
      }
    })();
  }, [currentCanvasId, prevCanvasId, keepResultsSame, showPreview, canvas]);

  useEffect(() => {
    (async () => {
      if (!canvas || isTypeWasChanged || showPreview) {
        return;
      }

      dispatch(editorActions.update({ isLoadingObjects: true }));

      await loadSurveyCanvasObjects(canvas, onGetCanvasObjects);

      centerCanvasView(canvas, 1);
      onGetCanvasObjects(canvas);
      dispatch(editorActions.update({ isLoadingObjects: false }));
    })();
  }, [isTypeWasChanged, showPreview, canvas]);

  const handleTypeChangeToResults = async () => {
    if (isLoadingImage || showPreview) {
      return;
    }

    try {
      if (isLoadingSurveyCanvas || isLoadingSurveyResultsCanvas) {
        return;
      }

      dispatch(editorActions.update({ isLoadingObjects: true }));

      setIsLoadingSurveyResultsCanvas(true);

      if (!canvas || type === 'results') {
        return;
      }

      if (!isTypeWasChanged) {
        setIsTypeWasChanges(true);
      }

      const clickableAreas = canvas.getObjectsByType('clickableArea');

      clickableAreas.forEach(area => area.validateUrlData());

      await new Promise(resolve => {
        saveSurveyCanvas(canvas, true, () => {
          return resolve(true);
        });
      });

      const validResultsCanvases = await getValidResultsCanvases();

      await new Promise(resolve => {
        saveToEditorLocalbase(
          'resultsCanvases',
          { canvases: validResultsCanvases },
          async () => {
            await loadSurveyResultsCanvasObjects(
              canvas,
              currentCanvasId,
              onGetCanvasObjects,
            );

            onGetCanvasObjects(canvas);
            onSetInnerCanvas(getInnerCanvas(canvas));
            setType('results');
            resolve(true);
          },
        );
      });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoadingSurveyResultsCanvas(false);
      dispatch(editorActions.update({ isLoadingObjects: false }));
    }
  };

  const handleTypeChangeToSurvey = async () => {
    if (isLoadingImage || showPreview) {
      return;
    }

    try {
      if (isLoadingSurveyCanvas || isLoadingSurveyResultsCanvas) {
        return;
      }

      dispatch(editorActions.update({ isLoadingObjects: true }));

      setIsLoadingSurveyCanvas(true);

      if (!canvas || type === 'survey') {
        return;
      }

      await new Promise(resolve => {
        saveSurveyResultsCanvases(canvas, currentCanvasId, true, async () => {
          await loadSurveyCanvasObjects(canvas, onGetCanvasObjects);
          onGetCanvasObjects(canvas);
          onSetInnerCanvas(getInnerCanvas(canvas));
          setType('survey');
          resolve(true);
        });
      });
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoadingSurveyCanvas(false);
      dispatch(editorActions.update({ isLoadingObjects: false }));
    }
  };

  useEffect(() => {
    dispatch(editorActions.update({ currentCanvasType: type }));
  }, [type]);

  return {
    isLoadingSurveyCanvas,
    isLoadingSurveyResultsCanvas,
    type,
    handleTypeChangeToSurvey,
    handleTypeChangeToResults,
  };
};
