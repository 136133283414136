import { useMemo } from 'react';
import { DropdownOption } from 'src/utils/types/general/types';

type Props = {
  tempTitle: string;
  title: string;
  currentSaveResultsOption: DropdownOption;
  mergeTag: string;
  groupOrLabel: string;
};

export const useIsDisableConfirm = ({
  tempTitle,
  title,
  currentSaveResultsOption,
  mergeTag,
  groupOrLabel,
}: Props) => {
  const isDisabledConfirm = useMemo(() => {
    const hasTitleChanged = tempTitle === title;

    if (hasTitleChanged) {
      return true;
    }

    switch (currentSaveResultsOption.labelValue) {
      case 'mergeTag':
        if (
          !mergeTag ||
          mergeTag.trim().length <= 3 ||
          mergeTag.trim().length > 10
        ) {
          return true;
        }

        return !groupOrLabel;

      case 'group':
        return !groupOrLabel;

      case 'tag':
      default:
        return false;
    }
  }, [
    tempTitle,
    title,
    currentSaveResultsOption.labelValue,
    mergeTag,
    groupOrLabel,
  ]);

  return isDisabledConfirm;
};
