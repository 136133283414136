import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MailchimpAudienceInfo } from '../../utils/types/Mailchimp';

export interface Mailchimp {
  currentAudience: string;
  currentAudienceId: string;
  audiencesList: MailchimpAudienceInfo[];
}

const initialState = {
  currentAudience: '',
  currentAudienceId: '',
  audiencesList: [] as MailchimpAudienceInfo[],
};

const mailchimpSlice = createSlice({
  name: 'mailchimp',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<Mailchimp>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default mailchimpSlice.reducer;
export const mailchimpActions = mailchimpSlice.actions;
