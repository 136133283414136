import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { constants } from 'src/utils/constants';
import { CustomTextBoxType } from 'src/utils/types/Editor';
import { DropdownOption } from 'src/utils/types/general/types';

export const useObjectFont = (
  activeObject: CustomTextBoxType,
): [DropdownOption, Dispatch<SetStateAction<DropdownOption>>] => {
  const [currentFont, setCurrentFont] = useState<DropdownOption>({
    label: activeObject.fontFamily,
    labelValue: activeObject.fontFamily,
  });

  useEffect(() => {
    activeObject.set({ fontFamily: currentFont.labelValue as string });
  }, [currentFont.labelValue]);

  useEffect(() => {
    const foundFont =
      constants.defaultFonts.find(
        font => font.labelValue === activeObject.fontFamily,
      ) ?? constants.defaultFonts[0];

    setCurrentFont(foundFont);
  }, [activeObject.objectId]);

  return [currentFont, setCurrentFont];
};
