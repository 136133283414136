import { useEffect, useState } from 'react';

import * as images from '../../../assets/index';

export const useHeaderIcon = (accIcon: string | undefined) => {
  const [icon, setIcon] = useState(accIcon);

  useEffect(() => {
    if (window.location.href.includes('/new/data-url')) {
      setIcon(images.smartLinkColoredIcon);

      return;
    }

    setIcon(accIcon || images.novelCampaignsLogo);
  }, [window.location.href, accIcon]);

  return icon;
};
