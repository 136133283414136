import {
  SmartLink,
  SmartLinkCreationType,
  SmartLinkUpdateType,
} from 'src/utils/types/SmartLink';
import { Response } from '../utils/types/general/api';
import { createClient } from './client';

const samrtLinkAxiosAPI = createClient('smart-link');

export const getAll = async (accountId: string): Response<SmartLink[]> => {
  return samrtLinkAxiosAPI.get(`/${accountId}`);
};

export const getById = async (
  id: number,
): Response<SmartLink & { accountId: string }> => {
  return samrtLinkAxiosAPI.get(`/by-id/${id}`);
};

export const create = async (
  data: SmartLinkCreationType,
): Response<SmartLink> => {
  return samrtLinkAxiosAPI.post('/create', data);
};

export const update = async (
  smartLinkId: number | string,
  data: SmartLinkUpdateType,
): Response<SmartLink> => {
  return samrtLinkAxiosAPI.patch(`/update/${smartLinkId}`, data);
};

export const remove = async (smartLinkId: number | string): Response<null> => {
  return samrtLinkAxiosAPI.delete(`/delete/${smartLinkId}`);
};
