import sha256 from 'sha256';

export const addImageToLs = (imageBase64Code: string, image: string) => {
  const images = localStorage.getItem('images');
  const hash = sha256(imageBase64Code);

  if (!images) {
    localStorage.setItem('images', JSON.stringify([{ url: image, hash }]));

    return;
  }

  const parsedImages = JSON.parse(images);
  const isImageAlreadyExists = parsedImages.some(
    (img: any) => img.hash === hash,
  );

  if (isImageAlreadyExists) {
    return;
  }

  parsedImages.push({ url: image, hash });
  localStorage.setItem('images', JSON.stringify(parsedImages));
};
