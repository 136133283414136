import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../common/Button/Button';
import { constants } from '../../utils/constants';

export const MailchimpLogin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const loginStatus = queryParams.get('status');

    if (!loginStatus) {
      return;
    }

    if (loginStatus === 'error') {
      setHasError(true);
    }
  }, [queryParams]);

  return (
    <>
      <a href={`${constants.apiHost}/mailchimp/auth`}>
        <Button
          text='Login With Mailchimp'
          type='outlined-blue'
          onClick={() => {}}
          extraStyles={{ minWidth: 300 }}
          dataCy='login-with-mailchimp-btn'
        />
      </a>

      {hasError && (
        <p style={{ color: 'red' }}>
          Error occured when trying to connect Mailchimp
        </p>
      )}
    </>
  );
};
