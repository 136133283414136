import React, { FC, useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { surveyClient } from 'src/api';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { ExtraStyles } from 'src/utils/types/general/types';
import { EditorEmdebCode } from '../EditorEmdebCode';
import { SurveyDialogsTopInfo } from '../SurveyDialogsTopInfo';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import { Loader } from '../common/Loader/Loader';
import { TextButton } from '../common/TextButton/TextButton';
import { EditorSurveyResultsEmbedCode } from '../EditorSurveyResultsEmbedCode';

export interface EmbeddedCodeType {
  code: string;
  title: string;
}

interface Props {
  data: EmbeddedCodeType[];
  surveyId: string | number;
  tempTitle?: string;
  tempSaveAs?: string;
}

export const EmbeddedCodeModalContent: FC<Props> = ({
  data,
  tempTitle = '',
  tempSaveAs = '',
  surveyId,
}) => {
  const { audienceId = '', accountId = '' } = useParams<Params>();
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = async () => {
    try {
      setIsLoading(true);

      await surveyClient.reset(surveyId, audienceId, accountId);

      toast.success('Survey results successfully reseted!');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <If condition={isLoading}>
        <Loader fullScreen backdropStyles={extraStyles.editorLoaderBackdrop} />
      </If>

      <FlexContainer>
        <SurveyDialogsTopInfo
          surveyTitle={tempTitle}
          surveySaveResultAs={tempSaveAs}
        />

        <EditorEmdebCode title={data[0].title} code={data[0].code} />

        <EditorSurveyResultsEmbedCode
          title={data[1].title}
          code={data[1].code}
          surveyId={surveyId}
        />

        <p style={{ ...extraStyles.note, ...extraStyles.marginBottom }}>
          NOTE: <br />
          If you have already embedded the Survey into your email campaign, any
          changes made to this file may break the functionality of the embedded
          Survey. If you’ve updated this file, please paste the updated embed
          code into your email campaign.
        </p>

        <TextButton text='Reset Survey Results' onCustomClick={handleReset} />

        <p style={extraStyles.note}>
          Resetting Survey Results will delete all past Survey Results from
          Contacts and reset results to zero.
        </p>
      </FlexContainer>
    </>
  );
};

const extraStyles: ExtraStyles = {
  editorLoaderBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: 10000,
    top: 0,
    left: 0,
  },
  note: { fontSize: 12, lineHeight: '20px' },
  marginBottom: { marginBottom: 10 },
};
