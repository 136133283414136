/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';
import { editorActions } from '../../redux/slice/editor';
import { useAppDispatch } from '../../redux/store';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import {
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from '../../utils/types/Editor';
import { EditorZoomControls } from '../EditorZoomControls';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import { Switch } from '../common/Switch/Switch';
import { AddObjectsBlock } from './AddObjectsBlock';
import { CanvasTypeSwitcher } from './CanvasTypeSwitcher';
import { PreviewButton } from './PreviewButton';
import { useCanvasSwitch } from './actions/useCanvasSwitch';
import { useCenterCanvas } from './actions/useCenterCanvas';
import styles from './index.module.scss';

interface Props {
  canvas: CustomCanvasType;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
  onSetActiveObject: React.Dispatch<
    React.SetStateAction<CustomFabricObject | null>
  >;
  onSetActiveObjects: React.Dispatch<
    React.SetStateAction<CustomFabricObject[]>
  >;
  onSetInnerCanvas: React.Dispatch<React.SetStateAction<InnerCanvasType>>;
}

export const SurveysEditorHeader: FC<Props> = ({
  canvas,
  onGetCanvasObjects,
  onSetActiveObject,
  onSetActiveObjects,
  onSetInnerCanvas,
}) => {
  const dispatch = useAppDispatch();
  const { keepResultsSame, currentCanvasId, showPreview } =
    useDistinctSelector('editor');

  const {
    type,
    isLoadingSurveyCanvas,
    isLoadingSurveyResultsCanvas,
    handleTypeChangeToSurvey,
    handleTypeChangeToResults,
  } = useCanvasSwitch(canvas, onGetCanvasObjects, onSetInnerCanvas);

  useCenterCanvas(canvas);

  const handleKeppSameResultsToggle = () => {
    dispatch(
      editorActions.update({
        keepResultsSame: !keepResultsSame,
        currentCanvasId: 'fallback',
        prevCanvasId: currentCanvasId,
      }),
    );
  };

  return (
    <FlexContainer
      className={styles.surveys_editor_header}
      direction='row'
      align='center'
      justify='space-between'
    >
      <FlexContainer
        direction='row'
        gap={20}
        align='center'
        className={styles.surveys_editor_header__left_side_container}
      >
        <AddObjectsBlock
          canvas={canvas}
          type={type}
          onGetCanvasObjects={onGetCanvasObjects}
          onSetActiveObject={onSetActiveObject}
          onSetActiveObjects={onSetActiveObjects}
        />

        <EditorZoomControls canvas={canvas} />

        <PreviewButton canvas={canvas} />
      </FlexContainer>

      <FlexContainer
        extraStyles={extraStyles.topRightActionsWrapper}
        direction='row'
        align='center'
        gap={0}
        id='top-right-actions'
      >
        <If condition={type === 'results'}>
          <FlexContainer>
            <Switch
              value={keepResultsSame}
              onChange={handleKeppSameResultsToggle}
              label='Keep Results Same'
              size={8}
              extraLabelStyles={extraStyles.label}
              extraStyles={extraStyles.keepResultsSame}
              id='keep-results-same-switch'
              disabled={showPreview}
            />
          </FlexContainer>
        </If>

        <CanvasTypeSwitcher
          type={type}
          isLoadingSurveyCanvas={isLoadingSurveyCanvas}
          isLoadingSurveyResultsCanvas={isLoadingSurveyResultsCanvas}
          handleTypeChangeToSurvey={handleTypeChangeToSurvey}
          handleTypeChangeToResults={handleTypeChangeToResults}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

const extraStyles = {
  keepResultsSame: { marginRight: 130 },
  topRightActionsWrapper: { maxWidth: 'max-content' },
  label: { fontSize: 12, fontWeight: 400 },
};
