import { FC, JSX } from 'react';

interface Props {
  children: JSX.Element;
}

export const RequireAuth: FC<Props> = ({ children }): JSX.Element | null => {
  const accessToken = window.localStorage.getItem('accessToken');

  if (accessToken) {
    return children;
  }

  window.location.href = '/signin';

  return null;
};
