import classnames from 'classnames';
import React, { FC } from 'react';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './index.module.scss';
import { If, Divider } from '../common';

type Props = {
  title: string;
  fixed?: boolean;
  layers?: boolean;
  children: React.ReactNode | React.JSX.Element;
};

export const EditorSidebarSection: FC<Props> = ({
  title,
  children,
  fixed = false,
  layers = false,
}) => {
  return (
    <FlexContainer
      className={classnames(styles.surveys_editor_sidebar__section, {
        [styles.surveys_editor_sidebar__section_fixed]: fixed,
        [styles.surveys_editor_sidebar__section_layers]: layers,
      })}
    >
      <h3
        className={classnames(styles.surveys_editor_sidebar__section_title, {
          [styles.surveys_editor_sidebar__section_title_fixed]: layers,
        })}
      >
        {title}
      </h3>

      <If condition={layers}>
        <Divider extraStyles={extraStyles.divider} />
      </If>

      {children}
    </FlexContainer>
  );
};

const extraStyles = {
  divider: { minHeight: 1, marginBottom: -15 },
};
