import { useMemo, useState } from 'react';

export const useModal = (initState: boolean = false) => {
  const [isOpen, setIsOpen] = useState(initState ?? false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const modal = useMemo(() => {
    return {
      isOpen,
      openModal,
      closeModal,
    };
  }, [isOpen]);

  return modal;
};
