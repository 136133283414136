import { useState, useEffect } from 'react';
import { generateSurveyResultsCode } from 'src/utils/helpers/editor/generateSurveyResultsCode';

export const useGoDaddyCode = (surveyId: string | number) => {
  const [goDaddyCode, setGoDaddyCode] = useState(
    generateSurveyResultsCode(surveyId, true).replace(
      /imageContainer/g,
      `imageContainer-${Math.random().toString(36).slice(7)}`,
    ),
  );

  useEffect(() => {
    setGoDaddyCode(
      generateSurveyResultsCode(surveyId, true).replace(
        /imageContainer/g,
        `imageContainer-${Math.random().toString(36).slice(7)}`,
      ),
    );
  }, [surveyId]);

  return goDaddyCode;
};
