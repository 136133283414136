import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Divider, FlexContainer, If } from 'src/components/common';
import { LinkedinLoginButton } from 'src/components/LinkedinLoginButton/LinkedinLoginButton';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

import styles from './StartScreenPage.module.scss';
import * as images from '../../assets';

export const StartScreenPage = () => {
  const [hasLoginError, setHasLoginError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loginStatus = queryParams.get('status');
  const [accessToken] = useLocalStorage('accessToken');

  if (
    accessToken &&
    window.location.href.includes('signin') &&
    status !== 'signedout'
  ) {
    navigate('/login/mailchimp');
  }

  useEffect(() => {
    if (loginStatus && loginStatus === 'error') {
      setHasLoginError(true);
    }

    if (accessToken) {
      navigate('/login/mailchimp');
    }
  }, [loginStatus, accessToken]);

  return (
    <FlexContainer className={styles.start_page__wrapper}>
      <FlexContainer gap={60} className={styles.start_page__main}>
        <FlexContainer direction='row' className={styles.start_page__main_logo}>
          <img
            src={images.novelCampaignsBigLogo}
            alt='Novel Campaigns Logotype'
            className={styles.start_page__main_logo_image}
          />
        </FlexContainer>

        <Divider
          type='vertical'
          extraStyles={extraStyles.divider}
          className={styles.start_page__divider}
        />

        <FlexContainer gap={60}>
          <FlexContainer className={styles.start_page__right_section}>
            <h3 className={styles.start_page__sub_title}>
              <a
                href='https://www.novelcampaigns.com/release-notes'
                target='__blank'
              >
                RELEASE NOTES
              </a>
            </h3>

            <h1 className={styles.start_page__title}>
              Unlock Insights & Boost Email Engagement with Novel Campaigns
            </h1>
          </FlexContainer>

          <LinkedinLoginButton />

          <If condition={hasLoginError}>
            <p className={styles.start_page__error}>Some error occured</p>
          </If>

          <If condition={loginStatus === 'cancelled'}>
            <p className={styles.start_page__error}>
              You cancelled the LinkedIn authorization process. Unfortunately,
              without it you cannot use the app.
            </p>
          </If>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        justify='center'
        align='center'
        className={styles.start_page__footer}
      >
        <p>© 2024 AskSung LLC All Rights Reserved</p>

        <FlexContainer
          direction='row'
          className={styles.start_page__footer_links}
        >
          <Link
            to='/terms-of-service'
            className={styles.start_page__footer_link}
          >
            Terms
          </Link>
          <span> • </span>
          <Link to='/privacy-policy' className={styles.start_page__footer_link}>
            Privacy
          </Link>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

const extraStyles = {
  divider: {
    height: 400,
  },
};
