import { Response } from 'src/utils/types/general/api';
import { createClient } from './client';
import { GoogleFormEntry } from 'src/utils/types/Scraper';

const scraperAxiosAPI = createClient('scraper');

export const getGoogleFormEntrys = async (
  url: string,
): Response<GoogleFormEntry[]> => {
  return scraperAxiosAPI.post('/google-form', {
    url,
  });
};
