export const convertDate = (date: string = '') => {
  const newDate = new Date(date);

  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedTime = new Intl.DateTimeFormat('en-US', options as any).format(
    newDate,
  );

  const correctDateString =
    newDate.toISOString().split('T')[0].replace(/[-]/g, '.') +
    ' — ' +
    formattedTime;

  return correctDateString;
};
