import React, { FC, useEffect, useState } from 'react';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './index.module.scss';
import { Dropdown } from '../common/Dropdown/Dropdown';
import { constants } from '../../utils/constants';
import { getInnerCanvas } from '../../utils/helpers/editor/getInnerCanvas';
import { centerCanvasView } from '../../utils/helpers/editor/centerCanvasView';
import { CustomCanvasType } from '../../utils/types/Editor';

interface Props {
  canvas: CustomCanvasType;
}

export const EditorZoomControls: FC<Props> = ({ canvas }) => {
  const [zoom, setZoom] = useState(constants.zoomOptions[3]);
  const [zoomOptions, setZoomOptions] = useState(constants.zoomOptions);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleScroll = (opt: any) => {
      opt.e.preventDefault();

      if (opt.e.ctrlKey) {
        const delta = opt.e.deltaY;

        let tempZoom = canvas.getZoom();

        tempZoom *= 0.999 ** (delta * 3);
        if (tempZoom > 25) tempZoom = 25;
        if (tempZoom < 0.05) tempZoom = 0.05;

        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, tempZoom);

        const newZoomOption = `${(tempZoom * 100).toFixed(0)}%`;

        setZoom({
          label: newZoomOption,
          labelValue: 'non-listed',
        });
      }
    };

    canvas.on('mouse:wheel', handleScroll);

    return () => {
      canvas.off('mouse:wheel', handleScroll);
    };
  }, [canvas, zoom, zoomOptions]);

  useEffect(() => {
    if (zoomOptions.length > 7) {
      setZoomOptions(prev => prev.slice(1));

      return;
    }

    if (!canvas || zoom.labelValue === 'non-listed') {
      return;
    }

    if (zoom.labelValue === 'fit') {
      const canvasRect = getInnerCanvas(canvas);
      const canvasWidth = canvas.width as number;
      const canvasHeight = canvas.height as number;
      const rectWidth = canvasRect.width;
      const rectHeight = canvasRect.height;

      const zoomToFitWidth = canvasWidth / rectWidth;
      const zoomToFitHeight = canvasHeight / rectHeight;

      const zoomLevel = Math.min(zoomToFitWidth, zoomToFitHeight);

      centerCanvasView(canvas, zoomLevel);

      return;
    }

    const zoomLevel = Number(zoom.labelValue);

    centerCanvasView(canvas, zoomLevel);

    return () => {
      setZoomOptions(constants.zoomOptions);
    };
  }, [zoom.labelValue]);

  return (
    <div>
      <FlexContainer
        direction='row'
        align='center'
        gap={8}
        className={styles.editor_zoom_controls}
      >
        <p className={styles.editor_zoom_controls__title}>Zoom</p>

        <Dropdown
          options={zoomOptions}
          value={zoom}
          onChange={setZoom}
          width={80}
          extraStyles={extraStyles.dropdown}
        />
      </FlexContainer>
    </div>
  );
};

const extraStyles = {
  dropdown: { height: 30 },
};
