import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { smartLinkClient } from 'src/api';
import { Modal } from 'src/components/common';
import { accountStatsActions } from 'src/redux/slice/accountStats';
import { useAppDispatch } from 'src/redux/store';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { AccountActionTypes } from 'src/utils/types/Mailchimp';

type Props = {
  smartLinkId: number;
  isOpen: boolean;
  onCloseModal: () => void;
};

export const DeleteSmartLink: FC<Props> = ({
  smartLinkId,
  isOpen,
  onCloseModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await smartLinkClient.remove(smartLinkId);

      dispatch(
        accountStatsActions.removeItem({
          type: AccountActionTypes.SMARTLINK,
          id: smartLinkId,
        }),
      );

      toast.success('Smart Link successfully deleted!');

      onCloseModal();
    } catch (error: unknown) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      type='confirmation'
      onConfirm={handleDelete}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onCloseModal}
      confirmationButtonText='Delete Smart Link'
      title='Delete Smart Link'
    >
      <p style={{ fontSize: 12, lineHeight: '20px', margin: '20px 0' }}>
        NOTE: <br />
        If you have already embedded the Smart Link in your email campaign, the
        user will be redirected to the destination URL without any data
        collection.
      </p>
    </Modal>
  );
};
