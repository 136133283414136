import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { CustomTextBoxType } from 'src/utils/types/Editor';

export const useObjectColor = (
  activeObject: CustomTextBoxType,
): [string, Dispatch<SetStateAction<string>>] => {
  const [color, setColor] = useState(activeObject.fill as string);

  useEffect(() => {
    activeObject.set({ fill: color });
  }, [color]);

  return [color, setColor];
};
