import { SetStateAction } from 'react';
import { getUniqueObjects } from 'src/utils/helpers/getUniqueObjects';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';

export const useGetCanvasObjects = (
  canvas: CustomCanvasType,
  onSetCanvasObjects: (value: SetStateAction<CustomFabricObject[]>) => void,
) => {
  const getCanvasObjects = (tempCanvas: CustomCanvasType = canvas) => {
    if (tempCanvas) {
      const canvasObjects = tempCanvas.getObjects();
      const uniqueObjects = getUniqueObjects(canvasObjects, 'objectId');

      const filteredCanvasObjects = uniqueObjects.filter(
        item => item.type !== 'canvas',
      ) as CustomFabricObject[];

      onSetCanvasObjects(filteredCanvasObjects);
    }
  };

  return getCanvasObjects;
};
