import { useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { surveyClient } from 'src/api';
import { dataUrlActions } from 'src/redux/slice/dataUrl';
import { surveyActions } from 'src/redux/slice/survey';
import { useAppDispatch } from 'src/redux/store';
import { getValidSaveAsData } from 'src/utils/helpers/editor/getValidSaveAsData';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { isValidTitle } from 'src/utils/helpers/isValidTitle';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { ButtonClicksTrackingInfoDataURLType } from 'src/utils/types/CreatedDataURLStats';
import { CustomCanvasType } from 'src/utils/types/Editor';
import { MergeFieldsType } from 'src/utils/types/Mailchimp';

export const useUpdateSurvey = (
  onCloseModal: () => void,
  groupOrLabel: string,
  mergeTag: string,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { audienceId = '' } = useParams<Params>();

  const survey = useDistinctSelector('survey');

  const dispatch = useAppDispatch();

  const updateSurvey = async (
    canvas: CustomCanvasType,
    currentSaveResultAs: ButtonClicksTrackingInfoDataURLType,
    currentCollectAdditionalData: boolean,
    currentTitle: string,
    mergeTagType: MergeFieldsType,
  ) => {
    setIsLoading(true);

    if (currentSaveResultAs !== survey.saveResultAs) {
      dispatch(dataUrlActions.clearAllExpectOne(survey.saveResultAs));
    }

    if (!isValidTitle(currentTitle)) {
      setIsLoading(false);

      return;
    }

    try {
      canvas.discardActiveObject();

      const { data: updatedSurvey } = await surveyClient.update(
        survey.id,
        audienceId,
        {
          title: currentTitle,
          collectAdditionalData: currentCollectAdditionalData,
          mergeTagType,
          ...getValidSaveAsData(currentSaveResultAs, groupOrLabel, mergeTag),
        },
      );

      dispatch(surveyActions.update(updatedSurvey));

      toast.success('Survey successfully updated!');
      onCloseModal();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, updateSurvey };
};
