import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as images from '../../../assets';
import {
  DropdownOption,
  EditableDropdownOption,
} from '../../../utils/types/general/types';
import styles from './Dropdown.module.scss';
import { DropdownItem } from './DropdownItem';
import classnames from 'classnames';
import { If } from '../If/If';
import { Loader } from '../Loader/Loader';
import { safeStringify } from '../../../utils/helpers/safeStringify';

interface Props {
  value: DropdownOption | EditableDropdownOption | null;
  onChange: React.Dispatch<React.SetStateAction<DropdownOption<any>>>;
  options: DropdownOption[] | null;
  editable?: boolean;
  valueLabel?: string;
  icon?: string;
  required?: boolean;
  placeHolder?: string;
  label?: string;
  isLoading?: boolean;
  extraStyles?: CSSProperties;
  extraStylesSubContainer?: CSSProperties;
  extraStylesMainContainer?: CSSProperties;
  extraStylesList?: CSSProperties;
  extraStylesLabel?: CSSProperties;
  extraStylesSelectedElement?: CSSProperties;
  className?: string;
  dataCy?: string;
  width?: number;
  disabled?: boolean;
}

export const Dropdown: FC<Props> = ({
  value,
  valueLabel,
  onChange,
  icon = '',
  options,
  label,
  isLoading,
  extraStyles,
  extraStylesMainContainer,
  extraStylesSubContainer,
  extraStylesList,
  extraStylesLabel,
  extraStylesSelectedElement,
  className,
  dataCy,
  width,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpen]);

  const handleOpen = useCallback(() => {
    if (disabled) {
      return;
    }

    setIsOpen(prev => !prev);
  }, [disabled]);

  const stylesMainContainer = useMemo(
    () => ({ ...extraStylesMainContainer, width }),
    [width, safeStringify(extraStylesMainContainer)],
  );

  const stylesList = useMemo(
    () => ({ ...extraStylesList, width }),
    [width, safeStringify(extraStylesList)],
  );

  const style = useMemo(
    () => ({ width, ...extraStyles }),
    [width, safeStringify(extraStyles)],
  );

  return (
    <div
      className={styles.dropdown}
      ref={dropdownRef}
      style={stylesMainContainer}
    >
      <div
        className={styles.dropdown__container}
        style={extraStylesSubContainer}
      >
        <div
          className={classnames(styles.dropdown_input, className, {
            [styles.dropdown_input__focused]: isOpen,
            [styles.dropdown_input__disabled]: disabled,
          })}
          style={style}
          onClick={handleOpen}
          data-cy={dataCy}
        >
          <div className={styles.dropdown_inputText}>
            {(value?.icon || icon) && !isLoading && (
              <img
                src={value?.icon || icon}
                alt='Dropdown icon'
                className={styles.dropdown__icon}
              />
            )}

            {isLoading && <Loader size='xsm' />}

            <p
              className={classnames(styles.dropdown_selectedItem, {
                [styles.dropdown_selectedItem__disabled]: disabled,
              })}
              style={extraStylesSelectedElement}
              data-cy='selected-dd-option'
            >
              <If condition={valueLabel}>{`${valueLabel}: ${value?.label}`}</If>

              <If condition={!valueLabel}>{value?.label}</If>
            </p>
          </div>

          <img
            className={styles.dropdown_dropIcon}
            src={isOpen ? images.polygonUpIcon : images.polygonDownIcon}
          />
        </div>

        <If condition={label}>
          <label onClick={handleOpen} style={extraStylesLabel}>
            {label}
          </label>
        </If>
      </div>

      <If condition={isOpen}>
        <div
          className={classnames(styles.dropdown_list, {
            [styles.dropdown_list__opened]: isOpen,
          })}
          style={stylesList}
        >
          {options?.map((option, index) => (
            <DropdownItem
              key={`${option?.labelValue}-${index}`}
              label={option?.label}
              labelValue={option?.labelValue}
              icon={option?.icon}
              isSelected={value?.labelValue === option.labelValue}
              onChange={onChange}
              handleOpen={handleOpen}
              dataCy={option.labelValue}
            />
          ))}
        </div>
      </If>
    </div>
  );
};
