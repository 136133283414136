import { useState, useEffect } from 'react';
import { constants } from 'src/utils/constants';

export const useWixCode = (
  surveyId: string | number,
  uniqueContainerId: string,
) => {
  const [wixCode, setWixCode] = useState(
    generateWixCode(surveyId, uniqueContainerId),
  );

  useEffect(() => {
    setWixCode(generateWixCode(surveyId, uniqueContainerId));
  }, [surveyId, uniqueContainerId]);

  return wixCode;
};

const generateWixCode = (
  surveyId: string | number,
  uniqueContainerId: string,
) => {
  return `import wixLocation from 'wix-location';

$w.onReady(function () {
  let optionId = wixLocation.query.optionId;
  let email = wixLocation.query.email;
  let iframe = $w('#${uniqueContainerId}');

  iframe.src = \`${constants.apiHost}/survey/result/wix/${surveyId}?optionId=\${optionId || null}&email=\${email || null}\`;
});`;
};
