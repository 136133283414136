import React, { JSX, useEffect, useState } from 'react';
import * as images from '../../assets/index';
import { mailchimpActions } from '../../redux/slice/mailchimp';
import { useGetMailchimpAccountsQuery } from '../../redux/slice/mailchimpApi';
import { useAppDispatch } from '../../redux/store';
import { If } from '../common/If/If';
import { Table } from '../common/Table/Table';
import './MailchimpAccountsTable.scss';
import { MailchimpAccountsTableLoader } from './MailchimpAccountsTableLoader/MailchimpAccountsTableLoader';
import { TableItem } from './TableItem/TableItem';
import { TableItemMobile } from './TableItem/TableItemMobile';
import { safeStringify } from '../../utils/helpers/safeStringify';

const columns = [
  {
    key: 'accountName',
    title: 'Mailchimp Account Name',
    dataKey: 'accountName',
    width: 820,
  },
  {
    key: 'contacts',
    title: 'CONTACTS',
    dataKey: 'contacts',
    width: 210,
  },
  {
    key: 'data',
    title: 'DATA ENHANCED',
    dataKey: 'data',
    width: 210,
  },
];

const mobileColumns = [
  {
    key: 'accountName',
    title: 'Mailchimp Account Name',
    dataKey: 'accountName',
    width: 500,
  },
];

export interface DataType {
  accountName: JSX.Element;
  dataURLs?: JSX.Element;
  contacts?: JSX.Element;
  data?: JSX.Element;
}

export const MailchimpAccountsTable = () => {
  const {
    data: mailchimpAccounts,
    isLoading,
    isError,
  } = useGetMailchimpAccountsQuery();
  const [data, setData] = useState<DataType[]>([]);
  const [mobileData, setMobileData] = useState<DataType[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!mailchimpAccounts || !mailchimpAccounts?.length) {
      setData([]);

      return;
    }

    setData(() =>
      mailchimpAccounts.map(mcAccount => ({
        accountName: (
          <TableItem
            text={mcAccount.accountName}
            leftIcon={mcAccount.avatarUrl}
            accountId={mcAccount.accountId}
            audienceId={mcAccount.audienceId}
            withRightIcon
          />
        ),
        contacts: (
          <TableItem
            text={mcAccount.contactsEnhanced
              .toLocaleString()
              .replace(/\s+/g, ',')}
            leftIcon={images.contactIcon}
            accountId={mcAccount.accountId}
            audienceId={mcAccount.audienceId}
          />
        ),
        data: (
          <TableItem
            text={mcAccount.dataEnhanced.toLocaleString().replace(/\s+/g, ',')}
            leftIcon={images.mailchimpIcon}
            accountId={mcAccount.accountId}
            audienceId={mcAccount.audienceId}
          />
        ),
      })),
    );

    setMobileData(() =>
      mailchimpAccounts.map(mcAccount => ({
        accountName: <TableItemMobile mcAccount={mcAccount} />,
      })),
    );

    dispatch(
      mailchimpActions.update({
        audiencesList: mailchimpAccounts.map(acc => ({
          audience: acc.audience,
          audienceId: acc.audienceId,
        })),
      }),
    );
  }, [safeStringify(mailchimpAccounts)]);

  if (isError) {
    return <h1>Error occured</h1>;
  }

  return (
    <>
      <If condition={!isLoading}>
        <div className='mailchimp-account-table mailchimp-account-table--desktop'>
          <Table
            identifier='mailchimp-accounts-table-desktop'
            data={data}
            columns={columns}
          />
        </div>

        <div className='mailchimp-account-table mailchimp-account-table--mobile'>
          <Table
            identifier='mailchimp-accounts-table-mobile'
            data={mobileData}
            columns={mobileColumns}
            rowHeight={90}
          />
        </div>
      </If>

      <If condition={isLoading}>
        <MailchimpAccountsTableLoader />
      </If>
    </>
  );
};
