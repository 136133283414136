import React, { FC, useEffect, useState } from 'react';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import * as images from '../../../assets';
import { EditorDataURLFormProps } from '../../../utils/types/DataURL';
import { Input } from '../../common/Input/Input';

export const EditorGroupForm: FC<EditorDataURLFormProps> = ({
  activeObject,
  focusedInput,
  onHandleInput,
  onInputBlur,
  onFocusedInput,
}) => {
  const survey = useDistinctSelector('survey');

  const group =
    survey.saveResultAs === 'group' ? survey.groupOrLabel || '' : '';
  const [subgroup, setSubgroup] = useState(activeObject.value);

  useEffect(() => {
    setSubgroup(activeObject.value);
  }, [activeObject.objectId]);

  return (
    <>
      <Input
        value={group}
        onChange={() => {}}
        placeholder={'Genre'}
        icon={images.mailchimpIcon}
        extraStyles={{
          maxWidth: 190,
        }}
        onBlur={() => onInputBlur(group)}
        label='Group'
        onFocus={() => onFocusedInput('Group')}
        focusedInput={focusedInput || ''}
        dataCy='group-in'
        disabled
        variant='editor-sidebar-input'
      />

      <Input
        value={subgroup}
        onChange={event => onHandleInput(event.target.value, setSubgroup)}
        placeholder={'Enter subgroup'}
        icon={images.mailchimpIcon}
        extraStyles={{ maxWidth: 190 }}
        label='Subgroup'
        onBlur={() => onInputBlur(subgroup)}
        hasError={!activeObject.isValidUrlData}
        onFocus={() => onFocusedInput('Subgroup')}
        focusedInput={focusedInput || ''}
        dataCy='subgroup-in'
        variant='editor-sidebar-input'
      />
    </>
  );
};
