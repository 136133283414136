import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import cloneDeep from 'lodash/cloneDeep';
import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { convertToObjects } from 'src/utils/helpers/editor/convertToObjects';
import { ClickableArea } from 'src/utils/helpers/editor/customClasses/ClickableArea';
import { CustomImage } from 'src/utils/helpers/editor/customClasses/CustomImage';
import { CustomTextbox } from 'src/utils/helpers/editor/customClasses/CustomTextBox';
import { SurveyResult } from 'src/utils/helpers/editor/customClasses/SurveyResult';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';

type Props = {
  canvas: CustomCanvasType;
  activeObjects: CustomFabricObject[];
  activeObject: CustomFabricObject | null;
  onGetCanvasObjects: (tempCanvas?: any) => void;
};

export const useCopyPaste = ({
  canvas,
  activeObjects,
  activeObject,
  onGetCanvasObjects,
}: Props) => {
  const [copiedObjects, setCopiedObjects] = useState<CustomFabricObject[]>([]);

  const { currentCanvasType } = useDistinctSelector('editor');

  const handleSelectedObjectsCoords = (objects: CustomFabricObject[]) => {
    if (objects.length > 1) {
      const tempTransfromMatrix = canvas
        .getActiveObject()
        ?.calcTransformMatrix() as number[];
      const transformMatrix = tempTransfromMatrix?.slice(-2) ?? [0, 0];
      const newActiveObjects = cloneDeep(objects);

      newActiveObjects.forEach(obj => {
        const coordsInSelection = obj.aCoords?.tl ?? { x: 0, y: 0 };

        (obj as any).set({
          left: coordsInSelection.x + transformMatrix[0],
          top: coordsInSelection.y + transformMatrix[1],
        });
      });

      setCopiedObjects(
        convertToObjects(canvas, newActiveObjects) as CustomFabricObject[],
      );

      return;
    }

    setCopiedObjects(
      convertToObjects(
        canvas,
        cloneDeep(objects ?? []),
      ) as CustomFabricObject[],
    );
  };

  const setCanvasActiveObjects = useCallback(
    (objects: CustomFabricObject[]) => {
      if (objects.length === 1) {
        canvas.setActiveObject(objects[0]);
      }

      if (objects.length > 1) {
        const selection = new fabric.ActiveSelection(objects, {
          canvas: canvas as Canvas,
        });

        canvas.setActiveObject(selection);
      }
    },
    [canvas, fabric],
  );

  const loadCopiedObjects = () => {
    const newCopiedObjects: CustomFabricObject[] = [];

    copiedObjects.map(object => {
      const innerCanvas = getInnerCanvas(canvas);

      const isAllowedToPaste = !(
        (object.type === 'clickableArea' && currentCanvasType === 'results') ||
        (object.type === 'surveyResult' && currentCanvasType === 'survey')
      );

      if (!isAllowedToPaste) {
        return;
      }

      let objectCopy: CustomFabricObject;

      const isTheSameCanvas = object?.canvasId !== innerCanvas?.canvasId;
      const left = isTheSameCanvas ? object.left : object.left + 10;
      const top = isTheSameCanvas ? object.top : object.top + 10;

      const additionalOptions = {
        left,
        top,
        objectId: crypto.randomUUID(),
      };

      switch (object.type) {
        case 'text':
          objectCopy = new CustomTextbox(object.text || '', {
            ...object,
            ...additionalOptions,
          });

          break;

        case 'image':
          const img = new Image();

          objectCopy = new CustomImage(img, {
            ...object,
            ...additionalOptions,
          });

          break;

        case 'clickableArea':
          objectCopy = new ClickableArea({
            ...object,
            ...additionalOptions,
          });

          break;

        case 'surveyResult':
          const surveyResult = new SurveyResult(canvas, [], [], {
            ...object,
            ...additionalOptions,
          });

          surveyResult.replaceObjectsInGroup(object.clickableAreas);

          objectCopy = surveyResult;

          break;
      }

      if (objectCopy.type === 'surveyResult') {
        canvas.add(objectCopy);
        objectCopy.rerenderGroup();
      } else {
        canvas.add(objectCopy);
      }

      newCopiedObjects.push(objectCopy);

      onGetCanvasObjects();
    });

    return newCopiedObjects;
  };

  const isInputSelected = useCallback(() => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.tagName === 'INPUT' ||
      activeElement?.tagName === 'TEXTAREA';

    return isInputField;
  }, [document.activeElement]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleObjectsCopy = (event: ClipboardEvent) => {
      if (isInputSelected()) {
        return;
      }

      event.preventDefault();

      if (copiedObjects.length > 0) {
        setCopiedObjects([]);
      }

      handleSelectedObjectsCoords(canvas.getActiveObjects());

      toast.success('Objects successfuly copied!');
    };

    const handleObjectsPaste = async (event: ClipboardEvent) => {
      if (isInputSelected()) {
        return;
      }

      event.preventDefault();
      canvas.discardActiveObject();

      const newCopiedObjects = loadCopiedObjects();

      await Promise.all(
        newCopiedObjects.map(obj => {
          return new Promise(resolve => {
            if (obj.type === 'image') {
              obj.set({
                src: obj.src,
              });

              obj.setSrc(obj.src, () => {
                resolve(true);
              });
            } else {
              resolve(true);
            }
          });
        }),
      );

      setCanvasActiveObjects(newCopiedObjects);
      handleSelectedObjectsCoords(newCopiedObjects);
    };

    document.addEventListener('copy', handleObjectsCopy);
    document.addEventListener('paste', handleObjectsPaste);

    return () => {
      document.removeEventListener('copy', handleObjectsCopy);
      document.removeEventListener('paste', handleObjectsPaste);
    };
  }, [
    activeObject?.objectId,
    safeStringify(activeObjects),
    safeStringify(copiedObjects),
  ]);
};
