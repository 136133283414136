import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { If, Loader } from '../common';
import { Divider } from '../common/Divider/Divider';
import styles from './NavigationPopup.module.scss';
import { useSignout } from './actions/useSignout';

interface Props {
  onClosePopup: () => void;
}

const navigationLinks = [
  {
    name: 'Mailchimp Accounts',
    path: '/accounts',
  },
  {
    name: 'Profile',
    path: '/user/account',
  },
  {
    name: 'Help',
    path: '/support',
  },
];

export const NavigationPopup: FC<Props> = ({ onClosePopup }) => {
  const { handleSignOut, isLoading } = useSignout(onClosePopup);

  return (
    <nav
      className={styles.navigation_popup}
      onMouseDown={e => e.preventDefault()}
    >
      <div className={styles.navigation_popup__main_links}>
        {navigationLinks.map(link => (
          <Link
            to={link.path}
            key={link.path}
            onClick={onClosePopup}
            className={styles.navigation_popup__link}
          >
            {link.name}
          </Link>
        ))}
      </div>

      <div className={styles.navigation_popup__sign_out_container}>
        <Divider />

        <button
          onClick={handleSignOut}
          className={styles.navigation_popup__link}
        >
          <If condition={isLoading}>
            <Loader size='xsm' />
          </If>

          <If condition={!isLoading}>Sign Out</If>
        </button>
      </div>
    </nav>
  );
};
