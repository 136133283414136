import React from 'react';
import { SmartLinkCreatingSteps } from 'src/components/SmartLinkCreatingSteps';

import styles from './index.module.scss';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { If } from 'src/components/common';

export const SmartLinkPage = () => {
  const { id } = useDistinctSelector('smartLink');

  return (
    <div className={styles.create_data_url_page}>
      <h1 className={styles.create_data_url_page__title} data-cy='cdu-title'>
        <If condition={id}>Edit Smart Link</If>

        <If condition={!id}>Create Smart Links</If>
      </h1>

      <SmartLinkCreatingSteps />
    </div>
  );
};
