import React, { FC } from 'react';
import { DropdownOption } from '../../../utils/types/general/types';
import styles from './DropdownItem.module.scss';
import { If } from '../If/If';
import * as images from '../../../assets/index';

interface Props {
  label: string | number;
  labelValue: string | number;
  isSelected: boolean;
  onChange: React.Dispatch<React.SetStateAction<DropdownOption>>;
  handleOpen: () => void;
  dataCy?: string | number;
  icon?: string;
}

export const DropdownItem: FC<Props> = ({
  label,
  labelValue,
  isSelected,
  onChange,
  handleOpen,
  dataCy,
  icon,
}) => {
  return (
    <div
      className={styles.dropdownItem}
      onClick={() => {
        onChange({ labelValue, label, icon: icon ?? '' });
        handleOpen();
      }}
      data-cy={dataCy}
    >
      <If condition={isSelected}>
        <img
          src={images.checkmarkIcon}
          alt='Selected element icon'
          className={styles.dropdownItem_selected}
        />
      </If>

      <p className={styles.dropdownItem_label}>{label}</p>
    </div>
  );
};
