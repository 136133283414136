import React, { FC, useEffect, useRef } from 'react';
import { wait } from '../../../utils/helpers/wait';
import { Button } from '../Button/Button';
import { Divider } from '../Divider/Divider';
import { If } from '../If/If';
import { Loader } from '../Loader/Loader';
import styles from './Modal.module.scss';
import * as images from '../../../assets/index';
import { FlexContainer } from '../FlexContainer/FlexContainer';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  type?: 'confirmation';
  onConfirm?: () => void;
  confirmationButtonText?: string;
  isLoading?: boolean;
  title?: string;
  dataCy?: string;
  disableConfirm?: boolean;
}

export const Modal: FC<Props> = ({
  isOpen,
  onClose,
  children,
  type,
  onConfirm,
  confirmationButtonText,
  isLoading,
  title,
  dataCy,
  disableConfirm,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = async () => {
    modalRef.current?.classList.add(styles.modal__content_container_unmount);

    await wait(270);

    onClose();
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose, isLoading]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';

      if (window.innerHeight < document.documentElement.scrollHeight) {
        document.body.style.paddingRight = '16.5px';
      }
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.modal} id='modal'>
      <div onClick={!isLoading ? handleClose : undefined}></div>
      <div
        className={styles.modal__content_container}
        ref={modalRef}
        data-cy={dataCy}
      >
        <div>
          <If condition={title || type !== 'confirmation'}>
            <FlexContainer className={styles.modal__top_actions}>
              <If condition={title}>
                <div className={styles.modal__title_container}>
                  <h3 className={styles.modal__title}>{title}</h3>
                </div>
              </If>

              <If condition={type !== 'confirmation'}>
                <img
                  src={images.closeIcon}
                  alt='Close Button'
                  onClick={handleClose}
                  data-cy='modal-close-btn'
                  className={styles.modal__close_btn}
                />
              </If>
            </FlexContainer>

            <Divider extraStyles={extraStyles.divider} />
          </If>

          {children}
        </div>

        <If condition={type === 'confirmation'}>
          <div className={styles.modal__button_container}>
            <Button
              type={disableConfirm ? 'disabled' : 'fill'}
              text={isLoading ? <Loader size='sm' /> : confirmationButtonText}
              onClick={onConfirm || (() => {})}
              color='white'
              bgColor='#45869F'
              className={styles.modal__button}
              dataCy='modal-confirm-btn'
              extraStyles={extraStyles.confirmButton}
              disable={disableConfirm}
            />

            <Button
              type='outlined'
              text='Cancel'
              onClick={!isLoading ? handleClose : () => {}}
              className={styles.modal__button}
              dataCy='modal-cancel-confirm-btn'
            />
          </div>
        </If>
      </div>
    </div>
  );
};

const extraStyles = {
  divider: { marginBottom: 15 },
  confirmButton: { width: '100%', maxWidth: '280px' },
};
