import React, { useEffect, useState } from 'react';
import { Params, useParams, useSearchParams } from 'react-router-dom';
import { If } from 'src/components/common';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { MailchimpAccountsActionsTable } from '../../components/MailchimpAccountsActionsTable/MailchimpAccountsActionsTable';
import { MailchimpAccountsActionsTableFilterBar } from '../../components/MailchimpAccountsActionsTableFilterBar';
import { MailchimpAccountsActionsTableHeader } from '../../components/MailchimpAccountsActionsTableHeader/MailchimpAccountsActionsTableHeader';
import { Divider } from '../../components/common/Divider/Divider';
import { FlexContainer } from '../../components/common/FlexContainer/FlexContainer';
import { Pagination } from '../../components/common/Pagination';
import { constants } from '../../utils/constants';

export const MailchimpAccountActionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { accountId = '' } = useParams<Params>();

  const initSortBy =
    constants.filterOptions.find(
      option => option.labelValue === searchParams.get('filter'),
    ) || constants.filterOptions[0];
  const initItemsPerPage =
    constants.itemsPerPageOptions.find(
      option => option.labelValue === Number(searchParams.get('items')),
    ) || constants.itemsPerPageOptions[0];

  const [sortOption, setSortOption] = useState(initSortBy);
  const [itemsPerPage, setItemsPerPage] = useState(initItemsPerPage);
  const [totalItems, setTotalItems] = useState(itemsPerPage.labelValue);
  const [page, setPage] = useState(searchParams.get('page') || '1');

  const [, setAccountId] = useLocalStorage('accountId');

  useEffect(() => {
    searchParams.set('items', itemsPerPage.labelValue.toString());
    searchParams.set('filter', sortOption.labelValue.toString());
    searchParams.set('page', page);

    setSearchParams(searchParams, { replace: true });
  }, [itemsPerPage.labelValue, sortOption.labelValue, page]);

  useEffect(() => {
    try {
      setAccountId(accountId);
    } catch {}
  }, [accountId]);

  return (
    <FlexContainer gap={0} extraStyles={{ maxWidth: 1240, margin: '0 auto' }}>
      <MailchimpAccountsActionsTableHeader />

      <Divider />

      <MailchimpAccountsActionsTableFilterBar
        sortOption={sortOption}
        onChangeSortOption={setSortOption}
        itemsPerPage={itemsPerPage}
        onChangeItemsPerPage={setItemsPerPage}
      />

      <MailchimpAccountsActionsTable
        filter={sortOption.labelValue}
        page={Number(page)}
        itemsPerPage={Number(itemsPerPage.labelValue)}
        onSetTotalItems={setTotalItems}
      />

      <If condition={Number(totalItems) > Number(itemsPerPage.labelValue)}>
        <Pagination
          total={totalItems}
          onPageChange={setPage}
          items={itemsPerPage.labelValue}
        />
      </If>
    </FlexContainer>
  );
};
