/* eslint-disable @typescript-eslint/indent */
import React, { FC, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { saveToEditorLocalbase } from 'src/api/local/editor';
import { surveyClient } from '../../../api';
import * as images from '../../../assets/index';
import { dataUrlActions } from '../../../redux/slice/dataUrl';
import { editorActions } from '../../../redux/slice/editor';
import { surveyActions } from '../../../redux/slice/survey';
import { useAppDispatch } from '../../../redux/store';
import { getDataUrlWithoutValue } from '../../../utils/helpers/editor/getDataUrlWithoutValue';
import { showErrorMessage } from '../../../utils/helpers/errorHandler';
import { safeParse } from '../../../utils/helpers/safeParse';
import { AccountStatsSurveyInfo } from '../../../utils/types/Mailchimp';
import { Divider } from '../../common/Divider/Divider';
import { FlexContainer } from '../../common/FlexContainer/FlexContainer';
import { If } from '../../common/If/If';
import { Loader } from '../../common/Loader/Loader';
import { SurveyEnterData } from './SurveyEnterData';
import styles from './index.module.scss';
import { recentColorsActions } from 'src/redux/slice/recentColors';

interface Props {
  item: AccountStatsSurveyInfo;
}

export const SurveyTableItem: FC<Props> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { audienceId = '', accountId = '' } = useParams<Params>();

  const [isLoading, setIsLoading] = useState(false);

  const handleSurveyItemClick = async () => {
    setIsLoading(true);

    try {
      const { data: survey } = await surveyClient.getSurveyWithCanvases(
        item.id,
      );

      const parsedResultsCanvases = safeParse(survey.resultsCanvases);
      const parsedSurveyCanvas = safeParse(survey.surveyCanvas);

      const resultsPromise = new Promise(resolve => {
        saveToEditorLocalbase(
          'resultsCanvases',
          {
            canvases: parsedResultsCanvases,
          },
          () => {
            resolve(true);
          },
        );
      });
      const surveyPromise = new Promise(resolve => {
        saveToEditorLocalbase(
          'surveyCanvas',
          {
            objects: parsedSurveyCanvas,
          },
          () => {
            resolve(true);
          },
        );
      });

      await Promise.all([resultsPromise, surveyPromise]);

      dispatch(
        surveyActions.update({
          ...survey,
          initSurveyObjectsJSON: safeParse(survey.surveyCanvas),
          initResultsObjectsJSON: safeParse(survey.resultsCanvases),
        }),
      );

      const dataUrl = getDataUrlWithoutValue(survey);

      dispatch(
        dataUrlActions.update({
          [survey.saveResultAs]: dataUrl,
        }),
      );

      dispatch(recentColorsActions.clear());

      if (safeParse(survey.resultsCanvases).length > 1) {
        dispatch(
          editorActions.update({
            keepResultsSame: survey.isKeepResultsSame,
            currentCanvasId: 'fallback',
            prevCanvasId: 'fallback',
          }),
        );
      }

      navigate(`/new/survey/${accountId}/${audienceId}/${survey.id}`);
    } catch (error: unknown) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FlexContainer
      className={styles.survey_table_item}
      onClick={handleSurveyItemClick}
    >
      <FlexContainer direction='row' align='center' justify='space-between'>
        <FlexContainer direction='row' align='center' gap={12}>
          <If condition={!isLoading}>
            <img
              src={images.surveyIconDark}
              alt='Survey icon'
              className={styles.survey_table_item__icon}
            />
          </If>

          <If condition={isLoading}>
            <Loader size='xsm' />
          </If>

          <p>{item?.title ?? 'Dynamic Image with Survey'}</p>
        </FlexContainer>

        <FlexContainer
          direction='row'
          align='center'
          className={styles.survey_table_item__total_wrapper}
        >
          <img
            src={images.contactIcon}
            alt='Survey icon'
            className={styles.survey_table_item__icon}
          />

          <p>{item.totalAnswersCount.toLocaleString().replace(/\s+/g, ',')}</p>
        </FlexContainer>
      </FlexContainer>

      <If condition={Object.values(item.answerVariantsWithVotesCount).length}>
        <Divider />
      </If>

      {Object.keys(item.answerVariantsWithVotesCount).map(
        (option, index, arr) => (
          <React.Fragment key={index}>
            <If condition={index === arr.length - 1 && arr.length !== 1}>
              <Divider />
            </If>

            <FlexContainer
              direction='row'
              gap={0}
              align='center'
              justify='space-between'
              extraStyles={extraStyles.wrapper}
            >
              <SurveyEnterData item={item} value={option} />

              <FlexContainer
                direction='row'
                align='center'
                justify='space-between'
                extraStyles={extraStyles.dataWrapper}
              >
                <FlexContainer
                  direction='row'
                  align='center'
                  extraStyles={extraStyles.imageWrapper}
                >
                  <img
                    src={images.contactIcon}
                    alt='Survey icon'
                    className={styles.survey_table_item__icon}
                  />

                  <p>
                    {item.answerVariantsWithVotesCount[option]
                      .toLocaleString()
                      .replace(/\s+/g, ',')}
                  </p>
                </FlexContainer>

                <p>
                  {item.totalAnswersCount === 0
                    ? 0
                    : (
                        (item.answerVariantsWithVotesCount[option] /
                          item.totalAnswersCount) *
                        100
                      ).toFixed(2)}
                  %
                </p>
              </FlexContainer>
            </FlexContainer>
          </React.Fragment>
        ),
      )}
    </FlexContainer>
  );
};

const extraStyles = {
  wrapper: { padding: '0 26px' },
  imageWrapper: { maxWidth: 'fit-content' },
  dataWrapper: { maxWidth: 174 },
};
