import React, { FC, useState } from 'react';
import { FlexContainer, If, Loader } from 'src/components/common';
import { AccountStatsSmartLinkInfo } from 'src/utils/types/Mailchimp';

import { useNavigate } from 'react-router-dom';
import { smartLinkClient } from 'src/api';
import { smartLinkActions } from 'src/redux/slice/smartLink';
import { useAppDispatch } from 'src/redux/store';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';

import { SmartLinkEnterData } from './SmartLinkEnterData';
import styles from './index.module.scss';
import * as images from '../../../assets/index';

type Props = {
  item: AccountStatsSmartLinkInfo;
};

export const SmartLinkTableItem: FC<Props> = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    try {
      setIsLoading(true);

      const {
        data: { accountId, ...smartLink },
      } = await smartLinkClient.getById(item.id);

      dispatch(smartLinkActions.update(smartLink));

      navigate(`/new/data-url/${accountId}/${item.audienceId}`);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FlexContainer
      direction='row'
      onClick={handleClick}
      className={styles.smart_link_table_item}
    >
      <If condition={isLoading}>
        <Loader size='xsm' />
      </If>

      <If condition={!isLoading}>
        <img src={images.addpictonSecondIconDark} alt='Smart Link' />
      </If>

      <FlexContainer
        direction='row'
        align='center'
        justify='space-between'
        className={styles.smart_link_table_item__data}
      >
        <SmartLinkEnterData {...item} />
      </FlexContainer>
    </FlexContainer>
  );
};
