import { useCallback, useEffect, useState } from 'react';
import { getFromEditorLocalbase } from 'src/api/local/editor';
import { deepCompare } from 'src/utils/helpers/deepCompare';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { saveSurveyCanvas } from 'src/utils/helpers/editor/saveSurveyCanvas';
import { saveSurveyResultsCanvases } from 'src/utils/helpers/editor/saveSurveyResultsCanvases';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useIsCahnged = (canvas: CustomCanvasType, isOpen: boolean) => {
  const [hasChanged, setHasChanged] = useState(false);

  const survey = useDistinctSelector('survey');
  const { currentCanvasId, keepResultsSame } = useDistinctSelector('editor');

  const isChanged = useCallback(async () => {
    const innerCanvas = getInnerCanvas(canvas);

    await new Promise(resolve => {
      if (innerCanvas?.canvasType === 'survey') {
        saveSurveyCanvas(canvas, false, () => resolve(true));
      } else {
        saveSurveyResultsCanvases(canvas, currentCanvasId, false, () =>
          resolve(true),
        );
      }
    });

    const surveyCanvasJSON =
      (await getFromEditorLocalbase('surveyCanvas'))?.objects ?? [];
    const resultsJSON =
      (await getFromEditorLocalbase('resultsCanvases'))?.canvases ?? [];

    const sortedResults = [...resultsJSON].sort((item1, item2) =>
      item1.canvasId.localeCompare(item2.canvasId),
    );
    const sortedReduxResults = [...survey.initResultsObjectsJSON].sort(
      (item1, item2) => item1.canvasId.localeCompare(item2.canvasId),
    );

    return (
      !deepCompare(
        surveyCanvasJSON,
        survey.initSurveyObjectsJSON,
        'path',
        'left',
        'clipPath',
      ) ||
      !deepCompare(
        sortedResults,
        sortedReduxResults,
        'path',
        'left',
        'clipPath',
      )
    );
  }, [
    survey.initSurveyObjectsJSON,
    survey.initResultsObjectsJSON,
    currentCanvasId,
    canvas,
    survey.id,
  ]);

  useEffect(() => {
    isChanged().then(setHasChanged);
  }, [isOpen]);

  return hasChanged || survey.isKeepResultsSame !== keepResultsSame;
};
