import React, { useState } from 'react';
import { SmartFormCreationArea } from '../SmartFormCreationArea';
import { SmartFormSubheader } from '../SmartFormSubheader';
import { SmartFormEditorPage } from 'src/utils/types/SmartForm';
import { SmartFormPreview } from '../SmartFormPreview';
import { FlexContainer } from '../common';

export const SmartFormEditor = () => {
  const [pageType, setPageType] = useState<SmartFormEditorPage>('form');

  return (
    <>
      <SmartFormSubheader pageType={pageType} onPageTypeChange={setPageType} />

      <FlexContainer direction='row' gap={60}>
        <SmartFormCreationArea pageType={pageType} />

        <SmartFormPreview pageType={pageType} />
      </FlexContainer>
    </>
  );
};
