import React, { FC } from 'react';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';

import styles from './index.module.scss';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  icon: string;
}

export const CreateActionButton: FC<Props> = ({ title, icon, ...props }) => {
  return (
    <button className={styles.create_action_btn} {...props}>
      <FlexContainer
        className={styles.create_action_btn__top_container}
        justify='center'
        align='center'
      >
        <img
          src={icon}
          alt={`${title} Logo`}
          className={styles.create_action_btn__image}
        />
      </FlexContainer>

      <FlexContainer
        className={styles.create_action_btn__bottom_container}
        justify='center'
        align='center'
      >
        <h4 className={styles.create_action_btn__title}>{title}</h4>
      </FlexContainer>
    </button>
  );
};
