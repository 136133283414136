export const snapToGrid = (
  movingObject: any,
  step: number,
  ...fields: string[]
) => {
  for (const field of fields) {
    const newValue = Math.round(movingObject[field] / step) * step;

    movingObject.set({ [field]: newValue });
  }
};
