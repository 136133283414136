/* eslint-disable @typescript-eslint/indent */
import { CustomCanvasType, CustomImageType } from 'src/utils/types/Editor';
import { getInnerCanvas } from './getInnerCanvas';
import { Id, toast } from 'react-toastify';
import sha256 from 'sha256';
import { imageClient } from 'src/api';

export const onUpdateUploadedImage = (
  activeImage: CustomImageType,
  files: File[],
  canvas: CustomCanvasType,
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void,
) => {
  const innerCanvas = getInnerCanvas(canvas);

  if (activeImage?.type !== 'image') {
    return;
  }

  if (!innerCanvas) {
    return;
  }

  if (files.length < 1) {
    return;
  }

  const reader = new FileReader();

  const name = files[0]?.name;
  const size = files[0]?.size / (1024 * 1024);
  
  if (size > 3) {
    toast.error('The image size should not exceed 3MB');

    return;
  }

  const id = toast.loading('Loading image...');

  reader.onload = async event => {
    const result = event.target?.result as string;

    try {
      const { data: tempImage } = await imageClient.getByHash(sha256(result));

      if (tempImage) {
        updateImage(tempImage);
        updateToast(id, 'Image loaded successfully!', 'success');

        return;
      }

      const { data: imageUrl } = await imageClient.upload({
        image: result,
        fileName: name,
        fileFolder: 'surveys/canvas-images',
      });

      updateImage(imageUrl);
      updateToast(id, 'Image loaded successfully!', 'success');
    } catch {
      updateToast(id, 'Error while loading image!', 'error');
    } finally {
      onGetCanvasObjects();
    }
  };

  function updateToast(
    toastId: Id,
    message: string,
    type: 'success' | 'error',
  ) {
    toast.update(toastId, {
      autoClose: 2000,
      render: message,
      type: type,
      isLoading: false,
    });
  }

  const updateImage = (src: string) => {
    activeImage.setSrc(src, () => {
      activeImage.set({
        fileSize: size,
        fileName: name,
      });

      canvas.renderAll();
    });
  };

  reader.readAsDataURL(files[0]);
};
