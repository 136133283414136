/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';

import { MailchimpAccountsActionsTableItem } from '../MailchimpAccountsActionsTableItem/MailchimpAccountsActionsTableItem';
import { Divider, If } from '../common';

import { AccountStatsInfo } from '../../utils/types/Mailchimp';

type Props = {
  accountStatsInfo: AccountStatsInfo[];
  isLoading: boolean;
};

export const MailchimpAccountActionsList: FC<Props> = ({
  accountStatsInfo,
  isLoading,
}) => (
  <>
    {accountStatsInfo.map((item, index) => (
      <React.Fragment key={index}>
        <If condition={!isLoading && index === 0}>
          <Divider />
        </If>

        <If condition={!isLoading} key={index}>
          <MailchimpAccountsActionsTableItem item={item} />

          <Divider />
        </If>
      </React.Fragment>
    ))}
  </>
);
