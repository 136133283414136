import React, { FC, useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';
import { FilterByEnum } from 'src/utils/types/general/enums';
import { mailchimpActions } from '../../redux/slice/mailchimp';
import { useGetMailchimpAccountsQuery } from '../../redux/slice/mailchimpApi';
import { useAppDispatch } from '../../redux/store';
import { safeStringify } from '../../utils/helpers/safeStringify';
import { AccountStatsInfo } from '../../utils/types/Mailchimp';
import { MailchimpAccountActionsList } from '../MailchimpAccountActionsList';
import { TableLoader } from '../MailchimpAccountsActionsTableItem/Loader';
import { Divider } from '../common/Divider/Divider';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import styles from './MailchimpAccountsActionsTable.module.scss';
import { useAccountStats } from './actions/useAccountStats';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

interface Props {
  filter: FilterByEnum;
  page: number;
  itemsPerPage: number;
  onSetTotalItems: React.Dispatch<React.SetStateAction<string | number>>;
}

export const MailchimpAccountsActionsTable: FC<Props> = ({
  filter,
  page,
  itemsPerPage,
  onSetTotalItems,
}) => {
  const { audienceId = '', accountId = '' } = useParams<Params>();
  const { isLoading } = useAccountStats({
    accountId: accountId || '',
    audienceId,
    filter,
    page,
    items: itemsPerPage,
  });
  const accountStats = useDistinctSelector('accountStats');
  const { data: mailchimpAccounts } = useGetMailchimpAccountsQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    onSetTotalItems((accountStats as any)?.total || 25);
  }, [isLoading]);

  useEffect(() => {
    const audienceList = mailchimpAccounts?.map(acc => ({
      audience: acc.audience,
      audienceId: acc.audienceId,
    }));

    dispatch(
      mailchimpActions.update({
        audiencesList: audienceList,
        currentAudienceId: audienceId,
        currentAudience: audienceList?.find(
          audience => audienceId === audience.audienceId,
        )?.audience,
      }),
    );
  }, [safeStringify(mailchimpAccounts)]);

  return (
    <FlexContainer gap={0} className={styles.mailchimp_accounts_actions_table}>
      <If condition={isLoading}>{generateLoader(itemsPerPage)}</If>

      <If condition={accountStats?.info}>
        <MailchimpAccountActionsList
          accountStatsInfo={accountStats?.info as AccountStatsInfo[]}
          isLoading={isLoading}
        />

        <If condition={accountStats?.info.length}>
          <Divider />
        </If>
      </If>

      <If condition={!accountStats?.info.length}>
        <FlexContainer justify='center' align='center'>
          <p>No items yet!</p>
        </FlexContainer>
      </If>
    </FlexContainer>
  );
};

const generateLoader = (itemsPerPage: number) => {
  return (
    <>
      {new Array(itemsPerPage).fill(0).map((_, index) => (
        // eslint-disable-next-line react/jsx-key
        <TableLoader key={index} />
      ))}
    </>
  );
};
