import { getFromEditorLocalbase } from 'src/api/local/editor';
import {
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from 'src/utils/types/Editor';
import { ClickableArea } from './customClasses/ClickableArea';
import { CustomCanvas } from './customClasses/CustomCanvas';
import { CustomImage } from './customClasses/CustomImage';
import { CustomTextbox } from './customClasses/CustomTextBox';

export const loadSurveyCanvasObjects = async (
  canvas: CustomCanvasType,
  onGetCanvasObjects?: () => void,
) => {
  const data = await getFromEditorLocalbase('surveyCanvas');

  const isCanvasExists = data?.objects?.find(obj => obj.type === 'canvas');

  if (!data?.objects || !isCanvasExists) {
    return;
  }

  canvas.clear();

  await Promise.all(
    data?.objects?.map(async object => {
      let newObject: CustomFabricObject | InnerCanvasType;

      switch (object.type) {
        case 'canvas':
          const customCanvas = new CustomCanvas(object);

          canvas.add(customCanvas);

          newObject = customCanvas;

          break;

        case 'text':
          const text = new CustomTextbox(object.text, object);

          canvas.add(text);

          newObject = text;

          break;

        case 'image':
          const img = new Image();
          const image = new CustomImage(img, object);

          canvas.add(image);

          newObject = image;

          break;

        case 'clickableArea':
        default:
          const clickableArea = new ClickableArea(object);

          canvas.add(clickableArea);

          newObject = clickableArea;

          break;
      }

      await new Promise(resolve => {
        if (newObject && newObject.type === 'image') {
          newObject.setSrc(object.src, () => {
            onGetCanvasObjects?.();

            resolve(true);
          });

          return;
        }

        resolve(true);
      });
    }),
  );
};
