import sha256 from 'sha256';
import { safeParse } from './safeParse';

export const findImageInLSByHash = (imageBase64Code: string): string | null => {
  const images = localStorage.getItem('images');
  const imageHash = sha256(imageBase64Code);

  if (!images) {
    return null;
  }

  const parsedImages = safeParse(images);
  const image = parsedImages.find((img: any) => img.hash === imageHash);

  if (!image) {
    return null;
  }

  return image.url;
};
