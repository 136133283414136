import React, { ChangeEvent, useState } from 'react';
import { MailchimpLogin } from '../../components/MailchimpLogin/MailchimpLogin';

import { MailchimpLoginWithAPIKey } from '../../components/MailchimpLoginWithAPIKey/MailchimpLoginWithAPIKey';
import { FlexContainer } from '../../components/common/FlexContainer/FlexContainer';
import styles from './MailchimpConnectPage.module.scss';
import { MailchimpLoginEnum } from 'src/utils/types/ESP';
import { If, Radio } from 'src/components/common';

export const MailchimpConnectPage = () => {
  const [loginType, setLoginType] = useState<MailchimpLoginEnum>(
    MailchimpLoginEnum.DEFAULT,
  );

  const handleLoginTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: type } = e.target;

    if (!isValidType(type)) {
      return;
    }

    setLoginType(type);
  };

  return (
    <div className={styles.mailchimpConnectPage}>
      <section className={styles.mailchimpConnectPage_leftSection}>
        <h1 className={styles.mailchimpConnectPage_title}>
          Add mailchimp account
        </h1>

        <FlexContainer
          direction='row'
          align='center'
          gap={28}
          className={styles.mailchimpConnectPage__radios_container}
        >
          <Radio
            name='image-fit'
            labelText='Link with Mailchimp Login'
            value={MailchimpLoginEnum.DEFAULT}
            currentValue={loginType}
            onChange={handleLoginTypeChange}
          />

          <Radio
            name='image-fit'
            labelText='Link with Mailchimp API Key'
            value={MailchimpLoginEnum.APIKEY}
            currentValue={loginType}
            onChange={handleLoginTypeChange}
          />
        </FlexContainer>

        <FlexContainer gap={28} extraStyles={{ marginBottom: 40 }}>
          <p className={styles.mailchimpConnectPage_description}>
            First, we need to link your Mailchimp Account so we can write data
            to your Contacts. You can add as many Mailchimp Accounts as you want
            and don’t worry about having multiple Audiences for an account,
            we’ll separate them out when your account is added.
          </p>

          <If condition={loginType === MailchimpLoginEnum.APIKEY}>
            <ol>
              <li>
                <p className={styles.mailchimpConnectPage_description}>
                  In your Mailchimp Account, go to Profiles → Extras → API Keys.
                </p>
              </li>
              <li>
                <p className={styles.mailchimpConnectPage_description}>
                  Click the “Create A Key” button to create an API key.
                </p>
              </li>
              <li>
                <p className={styles.mailchimpConnectPage_description}>
                  Then copy & paste that API key below and press the Link
                  Mailchimp Account button.
                </p>
              </li>
            </ol>
          </If>
        </FlexContainer>

        <If condition={loginType === MailchimpLoginEnum.DEFAULT}>
          <MailchimpLogin />
        </If>

        <If condition={loginType === MailchimpLoginEnum.APIKEY}>
          <MailchimpLoginWithAPIKey />
        </If>
      </section>
    </div>
  );
};

function isValidType(value: string): value is MailchimpLoginEnum {
  return Object.values(MailchimpLoginEnum).includes(
    value as MailchimpLoginEnum,
  );
}
