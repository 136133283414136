import { useEffect, useState } from 'react';
import { handlePropSet } from 'src/utils/helpers/editor/handlePropSet';
import { CustomTextBoxType } from 'src/utils/types/Editor';

export const useObjectText = (activeObject: CustomTextBoxType) => {
  const [text, setText] = useState(activeObject.text || '');
  const [fallback, setFallback] = useState(activeObject.fallback || '');
  const [saveFallback, setSaveFallback] = useState<boolean>(
    activeObject.saveFallback ?? true,
  );

  useEffect(() => {
    activeObject.set({ saveFallback });
  }, [saveFallback]);

  const handleTextChange = (value: string) => {
    handlePropSet(activeObject, value || '', 'text', setText);
  };

  return {
    text,
    setText,
    fallback,
    setFallback,
    saveFallback,
    setSaveFallback,
    handleTextChange,
  };
};
