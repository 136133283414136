import React, { FC } from 'react';
import { useDistinctSelector } from '../../../utils/hooks/useDistinctSelector';
import { EmbeddedCodeModalContent } from '../../EmbeddedCodeModalContent';
import { Modal } from '../../common/Modal/Modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  tempSurveyCode?: string;
  tempSurveyResultsCode?: string;
  tempTitle?: string;
  tempSaveAs?: string;
  surveyId: string | number;
  isReduxPriority?: boolean;
}

export const EmbedSurvey: FC<Props> = ({
  closeModal,
  isOpen,
  tempSurveyCode = '',
  tempSurveyResultsCode = '',
  tempTitle = '',
  tempSaveAs = '',
  surveyId,
  isReduxPriority = true,
}) => {
  const { embededCode, embedSurveyResultsCode, title, saveResultAs, id } =
    useDistinctSelector('survey');

  const surveyCode = isReduxPriority
    ? embededCode || tempSurveyCode
    : tempSurveyCode || embededCode;
  const resultsCode = isReduxPriority
    ? embedSurveyResultsCode || tempSurveyResultsCode
    : tempSurveyResultsCode || embedSurveyResultsCode;
  const validTitle = isReduxPriority ? title || tempTitle : tempTitle || title;
  const validSaveResultAs = isReduxPriority
    ? saveResultAs || tempSaveAs
    : tempSaveAs || saveResultAs;
  const validSurveyId = isReduxPriority ? id || surveyId : surveyId || id;
  const validResultsCode = resultsCode
    .replace(
      /imageContainer/g,
      `imageContainer-${Math.random().toString(36).slice(7)}`,
    )
    .replace(
      /getQueryParam/g,
      `getQueryParam${Math.random().toString(36).slice(2, 15)}`,
    );
  const validSurveyCode = surveyCode.replace(
    /areas/g,
    `areas-${Math.random().toString(36).slice(7)}`,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title='Embed Survey / Survey Result'
    >
      <EmbeddedCodeModalContent
        data={[
          { title: 'Embed Survey', code: validSurveyCode },
          {
            title: 'Embed Survey Results',
            code: validResultsCode,
          },
        ]}
        tempSaveAs={validSaveResultAs}
        tempTitle={validTitle}
        surveyId={validSurveyId}
      />
    </Modal>
  );
};
