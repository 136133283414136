import React, { FC, ReactNode } from 'react';

import styles from './index.module.scss';

type Props = {
  children: ReactNode;
};

export const SmartLinkCreatingBlock: FC<Props> = ({ children }) => (
  <section className={styles.smart_link_creating_block}>{children}</section>
);
