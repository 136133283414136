import { UploadImageRequestBody } from 'src/utils/types/Image';
import { Response } from '../utils/types/general/api';
import { createClient } from './client';

const imagesAxiosAPI = createClient('images');

export const getByHash = async (imageBase64Hash: string): Response<string> => {
  return imagesAxiosAPI.get(`/by-hash/${imageBase64Hash}`);
};

export const upload = async (
  data: UploadImageRequestBody,
): Response<string> => {
  return imagesAxiosAPI.post('/upload', data);
};
