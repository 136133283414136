import React, { FC, useState } from 'react';

import { Modal, FlexContainer, Input, Dropdown, Switch } from '../../common';
import { useCreateSurvey, useIsDisableConfirm } from './actions';

import { constants } from '../../../utils/constants';
import * as images from '../../../assets/index';
import styles from './index.module.scss';
import { DataUrlForm } from 'src/components/DataUrlForm';
import { useSurveyData } from './actions/useSurveyData';
import { ButtonClicksTrackingInfoDataURLType } from 'src/utils/types/CreatedDataURLStats';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export const CreateNewSurveyModal: FC<Props> = ({ isOpen, closeModal }) => {
  const [surveyTitle, setSurveyTitle] = useState('');
  const [collectAdditionalData, setCollectAdditionalData] = useState(false);
  const [currentSaveResultsOption, setCurrentSaveResultsOption] = useState(
    constants.surveySaveResultsOptions[0],
  );
  const {
    groupOrLabel,
    mergeTag,
    mergeTagType,
    handleGroupOrLabelChange,
    handleMergeTagChange,
    setMergeTagType,
  } = useSurveyData();

  const { handleCreate, onErrorChange, isLoading, hasError } = useCreateSurvey({
    surveyTitle,
    collectAdditionalData,
    currentSaveResultsOption,
    groupOrLabel,
    mergeTag,
    mergeTagType: mergeTagType.labelValue,
  });

  const isDisabledConfirm = useIsDisableConfirm({
    currentSaveResultsOption,
    surveyTitle,
    groupOrLabel,
    mergeTag,
    mergeTagType: mergeTagType.labelValue,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title='New Survey'
      type='confirmation'
      confirmationButtonText='Create new survey'
      onConfirm={handleCreate}
      isLoading={isLoading}
      disableConfirm={isDisabledConfirm}
    >
      <FlexContainer className={styles.create_new_survey_modal} gap={15}>
        <Input
          value={surveyTitle}
          onChange={e => {
            onErrorChange(false);
            setSurveyTitle(e.target.value);
          }}
          placeholder='Untitled Survey'
          icon={images.verticalColumnIcon}
          hasError={hasError}
        />

        <Dropdown
          options={constants.surveySaveResultsOptions}
          value={currentSaveResultsOption}
          onChange={setCurrentSaveResultsOption}
          extraStyles={extraStyles.dropdown}
          extraStylesSubContainer={extraStyles.dropdown}
          extraStylesMainContainer={extraStyles.dropdownLabel}
          extraStylesList={extraStyles.dropdownLabel}
          icon={images.mailchimpIcon}
        />

        <DataUrlForm
          groupOrLabel={groupOrLabel}
          mergeTag={mergeTag}
          mergeTagType={mergeTagType}
          onGroupOrLableChange={handleGroupOrLabelChange}
          onMergeTagChange={handleMergeTagChange}
          onMergeTagTypeChange={setMergeTagType}
          type={
            currentSaveResultsOption.labelValue as ButtonClicksTrackingInfoDataURLType
          }
        />

        <Switch
          value={collectAdditionalData}
          onChange={setCollectAdditionalData}
          label='Collect Additional Data (optional)'
          size={9}
          extraLabelStyles={extraStyles.switchLabel}
        />

        <p className={styles.create_new_survey_modal__aditional_data}>
          Collect additional data such as Timezone, Country, State, City,
          Devices and more when your email campaign is open and when a clickable
          area is clicked. This additional data will be added to your Contacts
          in your Mailchimp Account.
        </p>
      </FlexContainer>
    </Modal>
  );
};

const extraStyles = {
  dropdown: { width: '100%' },
  dropdownLabel: { maxWidth: '100%' },
  switchLabel: { fontWeight: 400 },
};
