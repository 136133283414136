import { FabricJSEditor } from 'fabricjs-react';
import { checkClickableAreasForValidity } from 'src/utils/helpers/editor/checkClickableAreasValidity';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { useModal } from 'src/utils/hooks/useModal';
import { useSaveSurvey } from 'src/utils/hooks/useSaveSurvey';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useHandleSave = (editor: FabricJSEditor | undefined) => {
  const survey = useDistinctSelector('survey');
  const { isOpen, openModal, closeModal } = useModal();
  const { saveSurvey, isLoading } = useSaveSurvey(survey);

  const handleSave = async () => {
    const isValid = await checkClickableAreasForValidity(
      editor?.canvas as CustomCanvasType,
    );

    if (!isValid) {
      return false;
    }

    return saveSurvey(editor?.canvas as CustomCanvasType, {
      onCloseModal: closeModal,
    });
  };

  return {
    isLoading,
    handleSave,
    isOpen,
    openModal,
    closeModal,
  };
};
