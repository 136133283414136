/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';
import { onUpdateUploadedImage } from '../../utils/helpers/editor/updateUploadedImage';
import { Divider } from '../common/Divider/Divider';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { TextButton } from '../common/TextButton/TextButton';
import styles from './index.module.scss';
import { CustomImageType } from 'src/utils/types/Editor';

interface Props {
  activeObject: CustomImageType;
  value: { file: string; fileName: string; fileSize: number };
  imageFit: string;
  canvas: any;
  onImageChange: React.Dispatch<
    React.SetStateAction<{
      file: string;
      fileName: string;
      fileSize: number;
    }>
  >;
  onImageFitChange: (imageFit: 'cover' | 'contain') => void;
  onImageDelete: () => void;
  onGetCanvasObjects: () => void;
}

export const SurveysEditorUploadedImageContainer: FC<Props> = ({
  value,
  activeObject,
  canvas,
  onImageDelete,
  onGetCanvasObjects,
}) => {
  // const handleSelectDataUrlType = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   onImageFitChange(event.target.value as 'cover' | 'contain');
  // };

  const replaceImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files as unknown as File[];

    onUpdateUploadedImage(activeObject, files, canvas, onGetCanvasObjects);
  };

  return (
    <FlexContainer>
      <FlexContainer
        direction='row'
        align='center'
        className={styles.drag_and_drop}
      >
        <img
          src={value.file}
          alt={value.fileName}
          className={styles.drag_and_drop__uploaded_file}
        />

        <FlexContainer gap={4}>
          <p className={styles.drag_and_drop__uploaded_file__title}>
            {value.fileName}
          </p>

          <p className={styles.drag_and_drop__uploaded_file__title}>
            {value.fileSize.toFixed(3)} mb
          </p>

          <FlexContainer direction='row' align='center'>
            <label>
              <TextButton text='Replace' onCustomClick={() => {}} />

              <input
                type='file'
                accept='image/*'
                style={extraStyles.input}
                onChange={replaceImage}
              />
            </label>

            <Divider type='vertical' extraStyles={extraStyles.divider} />

            <TextButton text='Remove' onCustomClick={() => onImageDelete()} />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      {/* <FlexContainer direction='row' align='center' justify='space-between'>
        <Radio
          name='image-fit'
          labelText='Cover Image'
          value='cover'
          currentValue={imageFit}
          onChange={handleSelectDataUrlType}
        />

        <Radio
          name='image-fit'
          labelText='Contain Image'
          value='contain'
          currentValue={imageFit}
          onChange={handleSelectDataUrlType}
        />
      </FlexContainer> */}
    </FlexContainer>
  );
};

const extraStyles = {
  input: { display: 'none' },
  divider: { height: 16 },
};
