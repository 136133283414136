import { useEffect } from 'react';

export const useBeforeUnloadPageEffect = () => {
  useEffect(() => {
    const beforeUnloadHandler = event => {
      event.preventDefault();
      event.returnValue = '';

      return 'Are you sure you want to leave? Your changes may not be saved.';
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, []);
};
