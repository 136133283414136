import React, { FC } from 'react';
import { toast } from 'react-toastify';
import * as images from '../../../assets/index';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import styles from './DragAndDropZone.module.scss';

interface Props {
  onValueChange: React.Dispatch<React.SetStateAction<any>>;
}

export const DragAndDropZone: FC<Props> = ({ onValueChange }) => {
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const validateImage = (file: File): string | null => {
    if (file.size > 10 * 1024 * 1024) {
      return 'Image size must be less than 10MB.';
    }

    if (!file.type.includes('image')) {
      return 'Only image files are allowed.';
    }

    return null;
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const files = e.dataTransfer.files;

    if (files.length !== 1) {
      toast.error('Please upload exactly one image.');

      return;
    }

    const file = files[0];
    const validationError = validateImage(file);

    if (validationError) {
      toast.error(validationError);

      return;
    }

    const reader = new FileReader();

    reader.onload = event => {
      const imageDataUrl = event.target?.result as string;

      onValueChange({
        file: imageDataUrl,
        fileName: file.name,
        fileSize: file.size / 1048576,
      });
      toast.error(null);
    };

    reader.readAsDataURL(file);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const fileInput = e.target;

    const files = fileInput.files;

    if (files && files.length === 1) {
      const file = files[0];

      const validationError = validateImage(file);

      if (validationError) {
        toast.error(validationError);

        return;
      }

      const reader = new FileReader();

      reader.onload = event => {
        const imageDataUrl = event.target?.result as string;

        onValueChange({
          file: imageDataUrl,
          fileName: file.name,
          fileSize: file.size / 1048576,
        });
      };

      reader.readAsDataURL(file);
    } else {
      toast.error('Please upload exactly one image.');
    }
  };

  return (
    <label className={styles.drag_and_drop__wrapper}>
      <FlexContainer
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className={styles.drag_and_drop}
        align='center'
        justify='center'
        gap={0}
      >
        <FlexContainer align='center' justify='center'>
          <img
            src={images.addImageIcon}
            alt='Add image icon'
            className={styles.drag_and_drop__upload_icon}
          />
          <input
            type='file'
            accept='image/*'
            onChange={handleFileInput}
            id='fileInput'
            className={styles.drag_and_drop__input}
          />

          <p className={styles.drag_and_drop__title}>
            Drag & Drop or click to upload the Image
          </p>
        </FlexContainer>
      </FlexContainer>
    </label>
  );
};
