import React, { FC, useEffect, useState } from 'react';
import {
  getObjectWidthAndHeight,
  useGeneralObjectProperties,
} from 'src/utils/hooks/useGeneralObjectProperties';
import { constants } from '../../utils/constants';
import { getInnerCanvas } from '../../utils/helpers/editor/getInnerCanvas';
import { handlePropSet } from '../../utils/helpers/editor/handlePropSet';
import {
  CustomCanvasType,
  SurveyResultType,
  SurveyResultVariant,
} from '../../utils/types/Editor';
import { DropdownOption } from '../../utils/types/general/types';
import { SurveysEditorSizePropertiesBlock } from '../EditorSizePropertiesBlock';
import { SurveyResultOptions } from '../SurveyResultOptions';
import { Divider } from '../common/Divider/Divider';
import { Dropdown } from '../common/Dropdown/Dropdown';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import { Input } from '../common/Input/Input';
import styles from './index.module.scss';

interface Props {
  activeObject: SurveyResultType;
  canvas: CustomCanvasType;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
}

export const EditorSurveyResultSidebar: FC<Props> = ({
  canvas,
  activeObject,
  onGetCanvasObjects,
}) => {
  const canvasRect = getInnerCanvas(canvas);
  const [currentSurveyResultType, setCurrentSurveyResultType] =
    useState<DropdownOption>(
      constants.surveyResultTypeOptions.find(
        option => option.labelValue === activeObject.variant,
      ) || constants.surveyResultTypeOptions[0],
    );

  const {
    x,
    y,
    width,
    height,
    angle,
    setWidth,
    setHeight,
    setAngle,
    handleCoordsSet,
  } = useGeneralObjectProperties(activeObject, canvas);
  const [radius, setRadius] = useState(activeObject?.borderRadius.toFixed(0));
  const [isPieResult, setIsPieResult] = useState(
    activeObject?.variant.includes('pie'),
  );
  const [pieSize, setPieSize] = useState(activeObject?.pieSize.toFixed(0));
  const [holeSize, setHoleSize] = useState(activeObject?.holeRadius.toFixed(0));

  useEffect(() => {
    canvas.requestRenderAll();
  }, [x, y, width, height, angle]);

  useEffect(() => {
    setIsPieResult(
      (currentSurveyResultType.labelValue as string).includes('pie'),
    );

    if (activeObject.variant === currentSurveyResultType.labelValue) {
      return;
    }

    if (
      (currentSurveyResultType.labelValue as string).includes('column') &&
      activeObject.sizeX > activeObject.sizeY
    ) {
      activeObject.set({
        sizeX: activeObject.sizeY,
        sizeY: activeObject.sizeX,
      });
    }

    if (
      (currentSurveyResultType.labelValue as string).includes('bar') &&
      activeObject.sizeY > activeObject.sizeX
    ) {
      activeObject.set({
        sizeX: activeObject.sizeY,
        sizeY: activeObject.sizeX,
      });
    }

    activeObject?.changeVariant(
      currentSurveyResultType.labelValue as SurveyResultVariant,
    );

    const { objWidth, objHeight } = getObjectWidthAndHeight(activeObject);

    setWidth(objWidth.toFixed(0));
    setHeight(objHeight.toFixed(0));

    onGetCanvasObjects();
    canvas.requestRenderAll();
  }, [currentSurveyResultType.labelValue]);

  useEffect(() => {
    setCurrentSurveyResultType(
      constants.surveyResultTypeOptions.find(
        option => option.labelValue === activeObject.variant,
      ) || constants.surveyResultTypeOptions[0],
    );
    setPieSize(activeObject.pieSize.toFixed(0) || '');
    setHoleSize(activeObject.holeRadius.toFixed(0) || '');

    setIsPieResult(activeObject?.variant.includes('pie'));
  }, [activeObject.objectId, canvasRect]);

  useEffect(() => {
    const halfWidth = Number(width) / 2;
    const halfHeight = Number(height) / 2;
    const numRadius = Number(radius);

    switch (activeObject.variant) {
      case 'bar:stacked':
      case 'bar:single':
        if (halfHeight < numRadius) {
          activeObject.set({
            borderRadius: halfHeight,
          });

          setRadius(String(halfHeight));
        }

        break;

      case 'column:stacked':
      case 'column:single':
        if (halfWidth < numRadius) {
          activeObject.set({
            borderRadius: halfWidth,
          });

          setRadius(String(halfWidth));
        }

        break;
    }

    canvas.requestRenderAll();
  }, [width, height]);

  const handleSurveyResultSizeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    switch (field) {
      case 'height':
        handlePropSet(activeObject, event, 'sizeY', setHeight);

        break;

      case 'width':
        handlePropSet(activeObject, event, 'sizeX', setWidth);

        break;

      case 'holeRadius':
        handlePropSet(activeObject, event, 'holeRadius', setHoleSize);

        break;

      case 'pieSize':
        handlePropSet(activeObject, event, 'pieSize', setPieSize);

        break;
    }

    activeObject.rerenderGroup();
  };

  return (
    <FlexContainer className={styles.editor_survey_result_sidebar}>
      <FlexContainer>
        <SurveysEditorSizePropertiesBlock
          activeObject={activeObject}
          x={x}
          y={y}
          width={width}
          height={height}
          angle={angle}
          setWidth={setWidth}
          setHeight={setHeight}
          setAngle={setAngle}
          handleCoordsSet={handleCoordsSet}
          handleImageSizeChange={handleSurveyResultSizeChange}
          radius={radius}
          setRadius={setRadius}
          withBottomRow={!isPieResult}
        />

        <If condition={isPieResult}>
          <FlexContainer direction='row' align='center'>
            <Input
              rightIcon='size'
              value={pieSize}
              onChange={e => handleSurveyResultSizeChange(e, 'pieSize')}
              variant='editor-sidebar-input'
              extraStyles={extraStyles.input}
            />

            <Input
              rightIcon='hole'
              value={holeSize}
              onChange={e => handleSurveyResultSizeChange(e, 'holeRadius')}
              variant='editor-sidebar-input'
            />
          </FlexContainer>
        </If>
      </FlexContainer>

      <Divider extraStyles={extraStyles.divider} />

      <Dropdown
        onChange={setCurrentSurveyResultType}
        options={constants.surveyResultTypeOptions}
        value={currentSurveyResultType}
        extraStyles={extraStyles.dropdown}
      />

      <Divider extraStyles={extraStyles.divider} />

      <p className={styles.editor_survey_result_sidebar__title}>
        Survey Options
      </p>

      <Divider />

      <SurveyResultOptions activeObject={activeObject} canvas={canvas} />
    </FlexContainer>
  );
};

const extraStyles = {
  divider: { margin: '10px 0' },
  dropdown: { height: 30 },
  input: { minWidth: 150 },
};
