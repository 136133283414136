import React, { FC, useState } from 'react';
import { DropdownOption } from 'src/utils/types/general/types';
import * as images from '../../assets/index';
import { EditorEmdebCode } from '../EditorEmdebCode';
import { If, Input } from '../common';
import { useWixCode } from './actions/useWixCode';
import { useContainerId } from './actions/useContainerId';
import { useGoDaddyCode } from './actions/useGoDaddy';

type Props = {
  title: string;
  code: string;
  surveyId: string | number;
};

enum CodeVariants {
  PLAIN = 'plain',
  WIX = 'wix',
  GODADDY = 'godaddy',
}

const codeVariants: DropdownOption<CodeVariants>[] = [
  { labelValue: CodeVariants.PLAIN, label: 'Plain', icon: images.codeIcon },
  { labelValue: CodeVariants.WIX, label: 'Wix', icon: images.wixLogo },
  {
    labelValue: CodeVariants.GODADDY,
    label: 'GoDaddy',
    icon: images.goDaddyLogo,
  },
];

export const EditorSurveyResultsEmbedCode: FC<Props> = ({
  title,
  code,
  surveyId,
}) => {
  const [currentCodeVariant, setCurrentCodeVariant] = useState(codeVariants[0]);
  const uniqueContainerId = useContainerId();
  const wixCode = useWixCode(surveyId, uniqueContainerId);
  const goDaddyCode = useGoDaddyCode(surveyId);

  return (
    <>
      <If condition={currentCodeVariant.labelValue === CodeVariants.PLAIN}>
        <EditorEmdebCode
          title={title}
          code={code}
          dropdownList={codeVariants}
          current={currentCodeVariant}
          setCurrent={setCurrentCodeVariant}
        />
      </If>

      <If condition={currentCodeVariant.labelValue === CodeVariants.WIX}>
        <EditorEmdebCode
          title={title}
          code={wixCode}
          dropdownList={codeVariants}
          current={currentCodeVariant}
          setCurrent={setCurrentCodeVariant}
        />

        <Input
          value={uniqueContainerId}
          onChange={() => {}}
          label='Unique container ID'
          variant='editor-sidebar-input'
        />
      </If>

      <If condition={currentCodeVariant.labelValue === CodeVariants.GODADDY}>
        <EditorEmdebCode
          title={title}
          code={goDaddyCode}
          dropdownList={codeVariants}
          current={currentCodeVariant}
          setCurrent={setCurrentCodeVariant}
        />
      </If>
    </>
  );
};
