import React from 'react';
import { MailchimpAccountDetails } from '../../components/MailchimpAccountDetails/MailchimpAccountDetails';
import styles from './MailchimpAccountDetailsPage.module.scss';

export const MailchimpAccountDetailsPage = () => {
  return (
    <section className={styles.mailchimp_account_details_page}>
      <h2 className={styles.mailchimp_account_details_page__title}>
        Mailchimp account
      </h2>

      <h3 className={styles.mailchimp_account_details_page__description}>
        Unlinking this Mailchimp Account will make all existing Data Links
        associated with this Mailchimp Account will not collect data and write
        to this Mailchimp Account. The links will still take contacts to the
        destination URL.
      </h3>

      <MailchimpAccountDetails />
    </section>
  );
};
