import { ChangeEvent, useEffect, useState } from 'react';
import { AccountStatsSurveyInfo } from '../types/Mailchimp';
import { Survey } from '../types/Survey';

const regex = /^[a-zA-Z0-9]+$/;

export const useSaveResultAsData = (
  survey: AccountStatsSurveyInfo | Survey,
) => {
  const saveAsProperty = 'saveAs' in survey ? 'saveAs' : 'saveResultAs';
  const groupOrLabelProperty = 'groupOrLabel' in survey ? 'groupOrLabel' : '';
  const mergeTagProperty = 'mergeTag' in survey ? 'mergeTag' : '';

  const [groupOrLabel, setGroupOrLabel] = useState(
    survey[saveAsProperty] !== 'tag' ? survey[groupOrLabelProperty] : '',
  );
  const [mergeTag, setMergeTag] = useState(
    survey[saveAsProperty] === 'mergeTag' ? survey[mergeTagProperty] : '',
  );

  useEffect(() => {
    setGroupOrLabel(
      survey[saveAsProperty] !== 'tag' ? survey[groupOrLabelProperty] : '',
    );

    setMergeTag(
      survey[saveAsProperty] === 'mergeTag' ? survey[mergeTagProperty] : '',
    );
  }, [survey.id, survey[saveAsProperty]]);

  const handleGroupInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setGroupOrLabel(value);
  };

  const handleMergeTagInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value && !regex.test(value)) {
      return;
    }

    setMergeTag(value);
  };

  return {
    groupOrLabel,
    mergeTag,
    handleGroupInput,
    handleMergeTagInput,
  };
};
