export const deleteEmptyImages = (canvas: any) => {
  canvas.discardActiveObject();
  
  const objects = canvas.getObjects();

  for (const object of objects) {
    if (object.type !== 'image') {
      continue;
    }

    if (!object.getSrc()) {
      canvas.remove(object);
    }
  }
};
