import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearEditorLocalbase } from 'src/api/local/editor';
import { recentColorsActions } from 'src/redux/slice/recentColors';
import { useAppDispatch } from 'src/redux/store';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { saveSurveyCanvas } from 'src/utils/helpers/editor/saveSurveyCanvas';
import { saveSurveyResultsCanvases } from 'src/utils/helpers/editor/saveSurveyResultsCanvases';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useGoBack = (
  canvas: CustomCanvasType,
  openSaveModal: () => void,
  isChanged: () => Promise<boolean>,
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentCanvasId } = useDistinctSelector('editor');

  const handleGoBack = useCallback(() => {
    clearEditorLocalbase();
    dispatch(recentColorsActions.clear());
    window.localStorage.removeItem('recentColors');
    navigate(-1);
  }, [window.location.pathname]);

  const handleGoBackButtonPress = () => {
    const innerCanvas = getInnerCanvas(canvas);

    if (!innerCanvas) {
      handleGoBack();

      return;
    }

    if (innerCanvas.canvasType === 'results') {
      saveSurveyResultsCanvases(canvas, currentCanvasId, false, () => {
        isChanged().then(hasChanged => {
          if (hasChanged) {
            openSaveModal();

            return;
          }

          handleGoBack();
        });
      });
    } else {
      saveSurveyCanvas(canvas, false);

      isChanged().then(hasChanged => {
        if (hasChanged) {
          openSaveModal();

          return;
        }

        handleGoBack();
      });
    }
  };

  return {
    handleGoBack,
    handleGoBackButtonPress,
  };
};
