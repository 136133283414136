import { clearEditorLocalbase } from 'src/api/local/editor';
import { surveyActions } from 'src/redux/slice/survey';
import { useAppDispatch } from 'src/redux/store';
import { checkClickableAreasForValidity } from 'src/utils/helpers/editor/checkClickableAreasValidity';
import { CustomCanvasType } from 'src/utils/types/Editor';

type Props = {
  canvas: CustomCanvasType;
  saveSurvey: (canvas: CustomCanvasType) => Promise<boolean>;
  closeSaveModal: () => void;
  handleGoBack: () => void;
};

export const useHandleSave = ({
  canvas,
  saveSurvey,
  closeSaveModal,
  handleGoBack,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleSave = async () => {
    const isValid = await checkClickableAreasForValidity(canvas);

    if (!isValid) {
      return;
    }

    const isSaved = await saveSurvey(canvas);

    if (!isSaved) {
      return;
    }

    closeSaveModal();

    if (window.location.href.includes('/new/survey')) {
      clearEditorLocalbase();
    }

    dispatch(surveyActions.update({}));
    handleGoBack();
  };

  return handleSave;
};
