import { ClickableAreaType } from 'src/utils/types/Editor';
import { getSurveyCanvas } from './getSurveyCanvas';
import { getSurveyResultsCanvases } from './getSurveyResultsCanvases';

export const getValidResultsCanvases = async () => {
  const surveyResultsCanvases = await getSurveyResultsCanvases();
  const surveyCanvas = await getSurveyCanvas();

  const filteredClickableAreas = surveyCanvas.objects.filter(
    obj => obj?.type === 'clickableArea',
  ) as ClickableAreaType[];

  const clickableAreasIds = filteredClickableAreas.map(area => area.objectId);
  const filteredSurveyResultsCanvases = surveyResultsCanvases.filter(
    canv =>
      clickableAreasIds.includes(canv.canvasId) || canv.canvasId === 'fallback',
  );

  const validResultsCanvases = filteredSurveyResultsCanvases.map(canv => {
    const newObjects = canv.objects.map(obj => {
      if (obj?.type === 'surveyResult') {
        const nonSurveyResultClickableAreas = filteredClickableAreas
          .filter(
            area =>
              !obj?.clickableAreas?.some(
                area2 => area2.objectId === area.objectId,
              ),
          )
          .map(area => {
            area.fill = getRandomRgbaColor();
            
            area.defaultColor =
              area?.defaultColor ?? changeRgbaColorOpacity(area.fill, 0.2);

            return area;
          });

        const existingClickableAreas = filteredClickableAreas
          .map(area => {
            const foundArea = obj?.clickableAreas?.find(
              tempArea => tempArea?.objectId === area?.objectId,
            );

            if (!foundArea) {
              return null;
            }

            return {
              ...area,
              fill: foundArea.fill,
              defaultColor: foundArea.defaultColor,
              order: foundArea.order,
            };
          })
          .filter(Boolean);

        return {
          ...obj,
          clickableAreas: [
            ...nonSurveyResultClickableAreas,
            ...existingClickableAreas,
          ],
        };
      }

      return obj;
    });

    return {
      ...canv,
      objects: newObjects,
    };
  });

  const nonResultsClickableAreasIds = clickableAreasIds.filter(
    id =>
      !validResultsCanvases.some(
        canv => canv.canvasId === id && canv.canvasId !== 'fallback',
      ),
  );

  nonResultsClickableAreasIds.forEach(id => {
    validResultsCanvases.push({ canvasId: id, objects: [] });
  });

  return validResultsCanvases;
};

function getRandomRgbaColor() {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);
  const alpha = Math.random().toFixed(2); // Opacity ranges from 0 to 1

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function changeRgbaColorOpacity(rgbaColor, newOpacity) {
  const rgbaValues = rgbaColor.match(/\d+\.?\d*/g);

  if (!rgbaValues || rgbaValues.length < 4) {
    throw new Error('Invalid RGBA color');
  }

  rgbaValues[3] = newOpacity.toFixed(2);

  return `rgba(${rgbaValues.join(', ')})`;
}
