import classnames from 'classnames';
import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as images from '../../../assets/index';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import { PaginationButton } from './PaginationButton';
import styles from './index.module.scss';
import { Input } from '../Input/Input';

interface Props {
  total: number | string;
  onPageChange: (page: string) => void;
  items?: string | number;
  page?: number | string;
}

export const Pagination: FC<Props> = ({ items, page, total, onPageChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const itemsPerPage = Number(items) || Number(searchParams.get('items')) || 25;
  const currentPage = Number(page) || Number(searchParams.get('page')) || 1;

  const [inputValue, setInputValue] = useState(currentPage.toString());
  const [tempInputValue, setTempInputValue] = useState(currentPage.toString());

  const totalPages = Math.ceil(Number(total) / itemsPerPage);
  const amountOfVisiblePages = 5;

  const buttons: Array<number | string> = (() => {
    const temp: Array<number | string> = [];

    if (totalPages <= amountOfVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        temp.push(i);
      }
    } else {
      if (currentPage < 4) {
        for (let i = 1; i <= 4; i++) {
          temp.push(i);
        }

        temp.push('...');
        temp.push(totalPages);
      }

      if (currentPage >= 4 && currentPage <= totalPages - 3) {
        temp.push(1);
        temp.push('...');

        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          temp.push(i);
        }

        temp.push('...');
        temp.push(totalPages);
      }

      if (currentPage > totalPages - 3) {
        temp.push(1);
        temp.push('...');

        for (let i = totalPages - 3; i <= totalPages; i++) {
          temp.push(i);
        }
      }
    }

    return temp;
  })();

  const handleChange = (tempPage: string | number) => {
    if (tempPage === '...') {
      return;
    }

    searchParams.set('page', tempPage.toString());
    setSearchParams(searchParams, { replace: true });
    onPageChange(tempPage.toString());
    setTempInputValue(tempPage.toString());
    setInputValue(tempPage.toString());
    window.scrollTo(0, 0);
  };

  const handleInputValue = (value: string) => {
    const numValue = Number(value);

    if (!value.trim() || isNaN(numValue) || numValue < 1) {
      setTempInputValue(currentPage.toString());
      setInputValue(value);

      return;
    }

    if (numValue > totalPages) {
      setTempInputValue(currentPage.toString());
      setInputValue(value);

      return;
    }

    setTempInputValue(numValue.toString());
    setInputValue(value);
  };

  const handlePrevArrowClick = () => {
    if (currentPage !== 1) {
      handleChange(currentPage - 1);
    }
  };

  const handleNextArrowClick = () => {
    if (currentPage !== totalPages) {
      handleChange(currentPage + 1);
    }
  };

  return (
    <FlexContainer
      direction='row'
      align='center'
      justify='center'
      gap={55}
      className={styles.pagination}
    >
      <FlexContainer
        direction='row'
        align='center'
        justify='center'
        gap={10}
        className={styles.pagination__buttons_section}
      >
        <img
          src={images.nextArrowIcon}
          alt='Prev'
          className={classnames(
            styles.pagination__arrow,
            styles.pagination__arrow_prev,
          )}
          onClick={handlePrevArrowClick}
        />

        <FlexContainer
          direction='row'
          align='center'
          justify='center'
          gap={10}
          className={styles.pagination__buttons_container}
        >
          {buttons.map((el, index) => (
            <PaginationButton
              key={index}
              page={el}
              currentPage={currentPage}
              onChange={handleChange}
            />
          ))}
        </FlexContainer>

        <img
          src={images.nextArrowIcon}
          alt='Next'
          className={classnames(
            styles.pagination__arrow,
            styles.pagination__arrow_next,
          )}
          onClick={handleNextArrowClick}
        />
      </FlexContainer>

      <FlexContainer
        direction='row'
        align='center'
        extraStyles={extraStyles.inputContainer}
        className={styles.pagination__input_section}
      >
        <Input
          value={inputValue}
          onChange={e => handleInputValue(e.target.value)}
          onBlur={() => handleChange(tempInputValue)}
          extraStyles={extraStyles.input}
        />

        <p className={styles.pagination__number_of_total}>
          of {totalPages} pages
        </p>
      </FlexContainer>
    </FlexContainer>
  );
};

const extraStyles = {
  inputContainer: {
    maxWidth: 'fit-content',
  },
  input: { borderRadius: 6, width: 120 },
};
