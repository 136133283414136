import { CustomCanvasType } from 'src/utils/types/Editor';
import { getInnerCanvas } from './getInnerCanvas';

export const centerCanvasView = (
  canvas: CustomCanvasType,
  zoom: number = 1,
  resetZoom: boolean = false,
) => {
  if (!canvas) {
    return;
  }

  const zoomLevel = !resetZoom ? zoom : canvas.getZoom();

  const innerCanvas = getInnerCanvas(canvas);

  if (!innerCanvas) {
    return;
  }

  const centerX = innerCanvas.left + innerCanvas.width / 2;
  const centerY = innerCanvas.top + innerCanvas.height / 2;

  const canvasCenterX = (canvas.width ?? 1) / 2;
  const canvasCenterY = (canvas.height ?? 1) / 2;
  const newViewportCenterX = centerX * zoomLevel;
  const newViewportCenterY = centerY * zoomLevel;

  const deltaX = canvasCenterX - newViewportCenterX;
  const deltaY = canvasCenterY - newViewportCenterY;

  canvas.setViewportTransform([zoomLevel, 0, 0, zoomLevel, deltaX, deltaY]);
  canvas.renderAll();
};
