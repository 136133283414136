import React, { FC } from 'react';
import styles from './Radio.module.scss';

interface Props {
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  labelText?: string;
  currentValue?: string;
  dataCy?: string;
}

export const Radio: FC<Props> = ({
  value,
  onChange,
  name,
  labelText,
  currentValue,
  dataCy,
}) => {
  return (
    <label className={styles.radio}>
      <input
        type='radio'
        className={styles.radio__input}
        name={name}
        checked={value === (currentValue || '')}
        value={value}
        onChange={onChange}
        data-cy={dataCy}
      />

      <p className={styles.radio__label}>{labelText}</p>
    </label>
  );
};
