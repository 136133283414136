import React from 'react';
import { FlexContainer } from 'src/components/common';

import styles from './index.module.scss';

export const NotFoundPage = () => {
  return (
    <FlexContainer
      justify='center'
      align='center'
      className={styles.not_found_page}
    >
      <h1 className={styles.not_found_page__title}>
        Oops, seems like this page do not exist. [404 Error]
      </h1>
    </FlexContainer>
  );
};
