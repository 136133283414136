import classnames from 'classnames';
import { fabric } from 'fabric';
import { FabricJSCanvas } from 'fabricjs-react';
import React, { FC, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import sha256 from 'sha256';
import { imageClient } from 'src/api';
import { saveToEditorLocalbase } from 'src/api/local/editor';
import { editorActions } from 'src/redux/slice/editor';
import { useAppDispatch } from 'src/redux/store';
import { addImageToLs } from 'src/utils/helpers/addImageToLS';
import { convertToObjects } from 'src/utils/helpers/editor/convertToObjects';
import { CustomCanvas } from 'src/utils/helpers/editor/customClasses/CustomCanvas';
import { findImageInLSByHash } from 'src/utils/helpers/findImageInLSByHash';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomImage } from '../../utils/helpers/editor/customClasses/CustomImage';
import { getInnerCanvas } from '../../utils/helpers/editor/getInnerCanvas';
import { CanvasProps, CustomCanvasType } from '../../utils/types/Editor';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './index.module.scss';

interface Props {
  canvasProps: CanvasProps;
  canvas: CustomCanvasType;
  onReady: (canvas: fabric.Canvas) => void;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
}

export const SurveysEditor: FC<Props> = ({
  onReady,
  canvas,
  onGetCanvasObjects,
  canvasProps,
}) => {
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const { isLoadingObjects, currentCanvasType } = useDistinctSelector('editor');

  const [searchParams, setSearchParams] = useSearchParams();
  const isNewSurvey = searchParams.get('new') === 'true';

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!canvas || isLoadingObjects || !isNewSurvey) {
      return;
    }

    const objects = canvas.getObjects();

    if (objects?.length && objects.some(object => object.type === 'canvas')) {
      return;
    }

    const customCanvas = new CustomCanvas({
      width: canvasProps.width,
      height: canvasProps.height,
      fill: canvasProps.backgroundColor,
      canvasType: 'survey',
    });

    canvas.add(customCanvas);
    canvas.requestRenderAll();

    saveToEditorLocalbase(
      'surveyCanvas',
      {
        objects: convertToObjects(canvas),
      },
      () => {
        searchParams.delete('new');
        setSearchParams(searchParams, { replace: true });
      },
    );
  }, [canvas, canvasProps, isLoadingObjects]);

  useEffect(() => {
    if (!canvasRef || !canvas) {
      return;
    }

    canvasRef.current?.addEventListener('dragover', event => {
      event.preventDefault();
    });

    const handleDrop = e => {
      e.preventDefault();

      dispatch(editorActions.update({ isLoadingImage: true }));

      const { files } = e.dataTransfer;
      const reader = new FileReader();

      const name = files[0]?.name;
      const size = files[0]?.size / (1024 * 1024);

      if (size > 3) {
        toast.error('The image size should not exceed 3MB');
        dispatch(editorActions.update({ isLoadingImage: false }));

        return;
      }

      const id = toast.loading('Loading image...');

      reader.onload = async event => {
        const result = event.target?.result as string;
        const image = findImageInLSByHash(result);

        if (image) {
          await addImageToCanvas(image);
          updateToast(id, 'Image loaded successfully!', 'success');
          dispatch(editorActions.update({ isLoadingImage: false }));

          return;
        }

        try {
          const { data: tempImage } = await imageClient.getByHash(
            sha256(result),
          );

          if (tempImage) {
            await addImageToCanvas(tempImage);
            addImageToLs(result, tempImage);
            updateToast(id, 'Image loaded successfully!', 'success');

            return;
          }

          const { data: imageUrl } = await imageClient.upload({
            image: result,
            fileName: name,
            fileFolder: 'surveys/canvas-images',
          });

          await addImageToCanvas(imageUrl);
          addImageToLs(result, imageUrl);
          updateToast(id, 'Image loaded successfully!', 'success');
        } catch {
          updateToast(id, 'Error while loading image!', 'error');
        } finally {
          onGetCanvasObjects();
          dispatch(editorActions.update({ isLoadingImage: false }));
          e.target.value = '';
        }
      };

      function loadImage(src: string) {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();

          img.src = src;
          img.onload = () => resolve(img);
          img.onerror = reject;
        });
      }

      async function addImageToCanvas(src: string) {
        const img = await loadImage(src);
        const canvasRect = getInnerCanvas(canvas);

        const image = new CustomImage(img, {
          fileName: name,
          fileSize: size,
          left: 40 + canvasRect.left,
          top: 40 + canvasRect.top,
        });

        canvas.add(image);
        canvas.setActiveObject(image);
        canvas.renderAll();
      }

      function updateToast(
        toastId: Id,
        message: string,
        type: 'success' | 'error',
      ) {
        toast.update(toastId, {
          autoClose: 2000,
          render: message,
          type: type,
          isLoading: false,
        });
      }

      reader.readAsDataURL(files[0]);
    };

    canvasRef.current?.addEventListener('drop', handleDrop);

    return () => {
      canvasRef.current?.removeEventListener('drop', handleDrop);
    };
  }, [canvasRef, canvas]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    canvas.setWidth(canvasRef.current?.offsetWidth || 1);
    canvas.setHeight(canvasRef.current?.offsetHeight || 1);
  }, [canvasRef.current?.offsetWidth, canvasRef.current?.offsetHeight, canvas]);

  return (
    <FlexContainer
      justify='center'
      align='center'
      className={classnames(styles.editor, {
        [styles.editor__results]: currentCanvasType === 'results',
      })}
    >
      <FlexContainer justify='center' align='center'>
        <div className={styles.editor__canvas_wrapper} id='canvas-wrapper'>
          <div
            className={styles.editor__canvas_container}
            id='canvas-container'
            ref={canvasRef}
          >
            <FabricJSCanvas
              className={styles.editor__canvas}
              onReady={onReady}
            />
          </div>
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};
