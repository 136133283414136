import React, { FC, JSX, useEffect, useMemo, useState } from 'react';

import { Header } from '../Header/Header';
import { HeaderLoader } from './HeaderLoader';

import { If } from '../common/If/If';

import { userActions } from '../../redux/slice/user';
import { useAppDispatch } from '../../redux/store';

import classnames from 'classnames';
import { userClient } from '../../api';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { Footer } from '../Footer/Footer';
import { useLastAppUsed } from './actions/useLastAppUsed';
import styles from './BaseLayout.module.scss';

interface Props {
  children: JSX.Element;
  withReturnBackButton?: boolean;
  withoutFooter?: boolean;
  padding?: number;
}

export const BaseLayout: FC<Props> = ({
  children,
  withReturnBackButton,
  withoutFooter,
  padding,
}) => {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const user = useDistinctSelector('user');
  const dispatch = useAppDispatch();

  useLastAppUsed();

  useEffect(() => {
    const getUser = async () => {
      if (user?.email) {
        setIsLoading(false);

        return;
      }

      try {
        setIsLoading(true);

        const { data } = await userClient.getUser();

        setHasError(false);
        dispatch(userActions.create(data));
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);

  const handleCloseNavPopup = () => {
    setIsOpenNav(false);
  };

  const mainStyles = useMemo(
    () => ({ padding: padding ?? undefined }),
    [padding],
  );

  return (
    <>
      <If condition={isLoading}>
        <HeaderLoader />
      </If>

      <If condition={!isLoading}>
        <Header
          withReturnBackButton={withReturnBackButton || false}
          isOpenNav={isOpenNav}
          handleCloseNavPopup={handleCloseNavPopup}
          onOpenNav={setIsOpenNav}
          hasError={hasError}
        />
      </If>

      <main
        onClick={handleCloseNavPopup}
        className={classnames(styles.main, {
          [styles.main__without_footer]: withoutFooter,
        })}
        style={mainStyles}
      >
        {children}
      </main>

      <If condition={!withoutFooter}>
        <Footer handleCloseNavPopup={handleCloseNavPopup} />
      </If>
    </>
  );
};
