import React from 'react';
import ContentLoader from 'react-content-loader';

export const MailchimpAccountsTableLoader = () => {
  return (
    <ContentLoader
      height='30vh'
      style={{ maxWidth: 1200, width: '100%' }}
      backgroundColor='#eaeced'
      foregroundColor='#ffffff'
    >
      <rect x='2vw' y='0' rx='3' ry='6' width='27vw' height='25' />
      <rect x='32vw' y='0' rx='3' ry='6' width='17vw' height='25' />
      <rect x='51vw' y='0' rx='3' ry='6' width='17vw' height='25' />
      <rect x='70vw' y='0' rx='3' ry='6' width='17vw' height='25' />

      <circle cx='4vw' cy='8.75vh' r='15' />
      <rect x='7vw' y='50' rx='3' ry='6' width='16vw' height='25' />
      <circle cx='33vw' cy='8.75vh' r='15' />
      <rect x='36vw' y='50' rx='3' ry='6' width='8vw' height='25' />
      <circle cx='52vw' cy='8.75vh' r='15' />
      <rect x='55vw' y='50' rx='3' ry='6' width='8vw' height='25' />
      <circle cx='71vw' cy='8.75vh' r='15' />
      <rect x='74vw' y='50' rx='3' ry='6' width='8vw' height='25' />

      <circle cx='4vw' cy='16vh' r='15' />
      <rect x='7vw' y='100' rx='3' ry='6' width='16vw' height='25' />
      <circle cx='33vw' cy='16vh' r='15' />
      <rect x='36vw' y='100' rx='3' ry='6' width='8vw' height='25' />
      <circle cx='52vw' cy='16vh' r='15' />
      <rect x='55vw' y='100' rx='3' ry='6' width='8vw' height='25' />
      <circle cx='71vw' cy='16vh' r='15' />
      <rect x='74vw' y='100' rx='3' ry='6' width='8vw' height='25' />

      <circle cx='4vw' cy='23vh' r='15' />
      <rect x='7vw' y='150' rx='3' ry='6' width='16vw' height='25' />
      <circle cx='33vw' cy='23vh' r='15' />
      <rect x='36vw' y='150' rx='3' ry='6' width='8vw' height='25' />
      <circle cx='52vw' cy='23vh' r='15' />
      <rect x='55vw' y='150' rx='3' ry='6' width='8vw' height='25' />
      <circle cx='71vw' cy='23vh' r='15' />
      <rect x='74vw' y='150' rx='3' ry='6' width='8vw' height='25' />
    </ContentLoader>
  );
};
