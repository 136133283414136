import { useEffect, useState } from 'react';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';

export const useCanvasObjects = (canvas: CustomCanvasType) => {
  const [canvasObjects, setCanvasObjects] = useState<CustomFabricObject[]>([]);
  const { currentCanvasId } = useDistinctSelector('editor');

  useEffect(() => {
    if (!canvas) {
      return;
    }

    setCanvasObjects(
      canvas
        .getObjects()
        .filter(obj => obj.type !== 'canvas') as CustomFabricObject[],
    );
  }, [canvas, currentCanvasId]);

  return canvasObjects;
};
