import { User } from '../utils/types/User';
import { Response } from '../utils/types/general/api';
import { createClient } from './client';

const userAxiosAPI = createClient('user');

export const getUser = async (): Response<Readonly<User>> => {
  return userAxiosAPI.get('/me');
};

export const saveUserData = async (): Response<void> => {
  return userAxiosAPI.post('/admin/save-data');
};

export const saveLastAppUsed = async (): Response<void> => {
  return userAxiosAPI.post('/admin/save-last-app-use');
};

export const deleteAccount = async (): Response<void> => {
  return userAxiosAPI.post('/delete');
};

export const signout = async (): Response<void> => {
  return userAxiosAPI.get('/signout');
};
