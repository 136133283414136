/* eslint-disable @typescript-eslint/indent */
import React, { ChangeEvent, FC } from 'react';
import { CustomFabricObject } from '../../utils/types/Editor';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { CoordsAndSizeBlock } from './CoordsAndSizeBlock';
import { ProporionalResizeButton } from './ProporionalResizeButton';
import { RadiusAndAngleBlock } from './RadiusAndAngleBlock';
import { If } from '../common';

type Props = {
  activeObject: CustomFabricObject;
  x: string;
  y: string;
  width: string;
  height: string;
  angle: string;
  radius?: string;
  withBottomRow?: boolean;
  withLock?: boolean;
  setWidth: React.Dispatch<React.SetStateAction<string>>;
  setHeight: React.Dispatch<React.SetStateAction<string>>;
  setAngle: React.Dispatch<React.SetStateAction<string>>;
  handleImageSizeChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => void;
  setRadius?: React.Dispatch<React.SetStateAction<string>>;
  handleCoordsSet?: (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
  ) => void;
};

export const SurveysEditorSizePropertiesBlock: FC<Props> = ({
  activeObject,
  x,
  y,
  width,
  height,
  angle,
  radius,
  withLock,
  setWidth,
  setHeight,
  setAngle,
  handleImageSizeChange,
  setRadius,
  handleCoordsSet,
  withBottomRow = true,
}) => {
  const isProportionalResizable =
    activeObject.type === 'image' || activeObject.type === 'clickableArea';

  return (
    <FlexContainer direction='row' gap={withLock ? 4 : 10}>
      <CoordsAndSizeBlock
        activeObject={activeObject}
        x={x}
        y={y}
        width={width}
        height={height}
        withBottomRow={withBottomRow}
        setWidth={setWidth}
        setHeight={setHeight}
        handleCoordsSet={handleCoordsSet}
        handleImageSizeChange={handleImageSizeChange}
      />

      <If condition={isProportionalResizable}>
        <ProporionalResizeButton activeObject={activeObject} />
      </If>

      <RadiusAndAngleBlock
        activeObject={activeObject}
        angle={angle}
        radius={radius}
        setAngle={setAngle}
        setRadius={setRadius}
      />
    </FlexContainer>
  );
};
