import { CustomCanvasType, CustomImageType } from 'src/utils/types/Editor';

export const applyImageFit = (
  activeObject: CustomImageType,
  canvas: CustomCanvasType,
  imageFit: 'cover' | 'contain',
) => {
  switch (imageFit) {
    case 'contain':
      activeObject.set({
        disableCrop: true,
      });

      break;

    case 'cover':
      activeObject.set({
        disableCrop: false,
      });
  }

  canvas.renderAll();

  return {
    x: activeObject.left.toFixed(0),
    y: activeObject.top.toFixed(0),
    width: activeObject.getScaledWidth().toFixed(0),
    height: activeObject.getScaledHeight().toFixed(0),
  };
};
