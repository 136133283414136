import React from 'react';

import { Button, Divider, FlexContainer } from '../common';

import { toast } from 'react-toastify';
import { getSmartFormCode } from 'src/redux/slice/smartForm';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import * as images from '../../assets/index';
import { ReturnBackButton } from '../common/ReturnBackButton';
import styles from './index.module.scss';

export const SmartFormHeader = () => {
  const smartFormData = useDistinctSelector('smartForm');

  const handleCopySmartCode = () => {
    const { code, script } = getSmartFormCode(smartFormData);

    try {
      navigator.clipboard.writeText(`${code}\n<script>${script}</script>`);

      toast.success('Smart Code Form copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy Smart Code Form');
    }
  };

  return (
    <header className={styles.form_header}>
      <FlexContainer direction='row' align='center' gap={20}>
        <ReturnBackButton />

        <Divider type='vertical' extraStyles={extraStyles.divider} />

        <img
          src={images.smartFormColoredIcon}
          alt='Survey Page Logotype'
          className={styles.form_header__logo}
        />

        <h3 className={styles.form_header__title}>Smart Form</h3>
      </FlexContainer>

      <FlexContainer
        direction='row'
        align='center'
        gap={20}
        extraStyles={extraStyles.fitContentWrapper}
      >
        <Button
          onClick={handleCopySmartCode}
          type='outlined-blue'
          text='Copy Smart Code Form'
          className={styles.form_header__copy_button}
          extraTextStyles={extraStyles.buttonText}
          disable={!smartFormData.tag}
        />
      </FlexContainer>
    </header>
  );
};

const extraStyles = {
  divider: { height: 20, margin: '0 10px' },
  fitContentWrapper: { maxWidth: 'max-content' },
  buttonText: { fontSize: 14 },
};
