import { toast } from 'react-toastify';

export const safeStringify = <T>(obj: T) => {
  try {
    return JSON.stringify(obj);
  } catch {
    window.console.log('🚀 stringify error', obj);

    toast.error('Unexpected error occured!');

    return '';
  }
};
