export const getSuccesSupportSendMessage = (selectedMessageType: string) => {
  switch (selectedMessageType) {
    case 'question':
      return 'Thanks for the question! We’ll get back to you ASAP!';

    case 'suggestion':
      return 'Great suggestion! We’ll let our product team know.';

    case 'bug':
      return 'Doh! A bug! We hate those, thanks for letting us know!';

    case 'other':
    default:
      return 'Hi right back at ya!';
  }
};
