import React, { FC } from 'react';
import { FlexContainer, If, Input } from 'src/components/common';
import { handlePropSet } from 'src/utils/helpers/editor/handlePropSet';
import { CustomFabricObject } from 'src/utils/types/Editor';
import { ExtraStyles } from 'src/utils/types/general/types';
import { useRadiusChanges } from './actions/useRadiusChanges';

type Props = {
  activeObject: CustomFabricObject;
  angle: string;
  radius?: string;
  setAngle: React.Dispatch<React.SetStateAction<string>>;
  setRadius?: React.Dispatch<React.SetStateAction<string>>;
};

export const RadiusAndAngleBlock: FC<Props> = ({
  activeObject,
  angle,
  radius,
  setAngle,
  setRadius,
}) => {
  const { handleRadiusChange, handleRadiusInputBlur } = useRadiusChanges(
    activeObject,
    setRadius,
  );

  const isProportionalResizable =
    activeObject.type === 'image' ||
    activeObject.type === 'clickableArea' ||
    (activeObject.type === 'surveyResult' &&
      !activeObject.variant.includes('pie'));

  return (
    <FlexContainer align='center'>
      <Input
        value={angle}
        labelExtraStyles={extraStyles.label}
        onChange={event =>
          handlePropSet(activeObject, event, 'angle', setAngle)
        }
        variant='editor-sidebar-input'
        rightIcon='°'
      />

      <If condition={radius !== undefined && isProportionalResizable}>
        <Input
          value={radius}
          onChange={handleRadiusChange}
          variant='editor-sidebar-input'
          rightIcon='radius'
          onBlur={handleRadiusInputBlur}
        />
      </If>
    </FlexContainer>
  );
};

const extraStyles: ExtraStyles = {
  input: { width: 70 },
  label: { fontSize: 12 },
  wrapper: { maxWidth: 'max-content', flexDirection: 'row' },
};
