import { useEffect } from 'react';
import { centerCanvasView } from 'src/utils/helpers/editor/centerCanvasView';
import { wait } from 'src/utils/helpers/wait';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useCenterCanvas = (canvas: CustomCanvasType) => {
  useEffect(() => {
    (async () => {
      await wait(0);
      centerCanvasView(canvas);
    })();
  }, [canvas]);
};
