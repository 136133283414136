import { CancelToken } from 'axios';
import {
  AutocompleteListRoute,
  AutocompleteLists,
  MailchimpAudience,
} from '../utils/types/Mailchimp';
import { Response } from '../utils/types/general/api';
import { createClient } from './client';

const mailchimpAxiosAPI = createClient('mailchimp');

export const getAudience = async (
  audienceId: string,
): Response<MailchimpAudience> => {
  return mailchimpAxiosAPI.get(`/audience/${audienceId}`);
};

export const checkConnection = async (apiKey: string): Response<void> => {
  return mailchimpAxiosAPI.post('/auth/check-connection', {
    apiKey,
  });
};

export const getAutocompleteList = async <T extends AutocompleteListRoute>(
  route: T,
  cancelToken: CancelToken,
): Response<AutocompleteLists[T]> => {
  return mailchimpAxiosAPI.get(`/${route}/list`, { cancelToken });
};
