import { ChangeEvent, useEffect, useState } from 'react';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { ColorItem } from '..';
import { rgbaToHexAndOpacity } from '../../ColorPicker/ColorPicker';

type Colors = {
  hex: string;
  opacity: number;
};

type ColorsById = Record<string, Colors>;

export const useTempValues = (colors: ColorItem[]) => {
  const [tempValues, setTempValues] = useState<ColorsById>(
    getTempValues(colors),
  );

  useEffect(() => {
    setTempValues(getTempValues(colors));
  }, [safeStringify(colors)]);

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const { value } = e.target;
    const type = e.target.getAttribute('data-input-type');
    const foundItem = colors.find(color => color.id === id);

    if (!foundItem) {
      return;
    }

    if (type === 'hex') {
      if (value.startsWith('#')) {
        if (value.length > 7) {
          return;
        }
      } else {
        if (value.length > 6) {
          return;
        }
      }

      if (!/^#?[A-Fa-f0-9]*$/.test(value)) {
        return;
      }

      setTempValues(prev => {
        const item = prev[id];

        item.hex = value;

        return { ...prev, [id]: item };
      });
    } else if (type === 'opacity') {
      const numValue = Number(value);

      if (numValue < 0 || numValue > 100) {
        return;
      }

      if (value.length && isNaN(numValue)) {
        return;
      }

      setTempValues(prev => {
        const item = prev[id];

        item.opacity = numValue;

        return { ...prev, [id]: item };
      });
    }
  };

  return { tempValues, handleValueChange, setTempValues };
};

const getTempValues = (colors: ColorItem[]): ColorsById =>
  colors.reduce((acc, color) => {
    const { hex, opacity } = rgbaToHexAndOpacity(color.color);

    acc[color.id] = {
      hex,
      opacity: Math.round(opacity * 100),
    };

    return acc;
  }, {});
