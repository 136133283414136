import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { safeParse } from 'src/utils/helpers/safeParse';

const initialState: string[] =
  safeParse(window.localStorage.getItem('recentColors') || '') || [];

export const recentColorSlice = createSlice({
  name: 'recentColors',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<string | string[]>) => {
      const rgba = action.payload;

      if (Array.isArray(rgba)) {
        return rgba;
      }

      if (rgba.startsWith('#')) {
        return state;
      }

      const filteredRecentColors = state.filter(temp => rgba !== temp);
      let newRecentColors: string[] = [];

      if (filteredRecentColors.length > 9) {
        newRecentColors = [rgba, ...filteredRecentColors.slice(0, -1)];
      } else {
        newRecentColors = [rgba, ...filteredRecentColors];
      }

      const uniqueRecentColors = Array.from(new Set(newRecentColors));

      return uniqueRecentColors;
    },
    clear: () => [],
  },
});

export default recentColorSlice.reducer;
export const recentColorsActions = recentColorSlice.actions;
