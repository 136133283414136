import React, { FC } from 'react';
import BaseTable, { AutoResizer } from 'react-base-table';
import 'react-base-table/styles.css';
import './Table.scss';
import { Loader } from '../Loader/Loader';
import { FlexContainer } from '../FlexContainer/FlexContainer';

interface Props {
  identifier: string;
  data: any[];
  columns: any[];
  isLoading?: boolean;
  rowHeight?: number;
}

export const Table: FC<Props> = ({
  identifier,
  data,
  columns,
  isLoading = false,
  rowHeight = 50,
}) => {
  const renderOverlay = isLoading ? <LoadingOverlay /> : null;
  const renderEmptyBody =
    !data.length && !isLoading ? <EmptyTableBody /> : null;

  return (
    <AutoResizer>
      {({ width, height }) => (
        <BaseTable
          key={identifier}
          data={!isLoading ? data : undefined}
          columns={columns}
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={rowHeight}
          overlayRenderer={renderOverlay}
          emptyRenderer={renderEmptyBody}
        />
      )}
    </AutoResizer>
  );
};

const LoadingOverlay = () => {
  return (
    <FlexContainer
      justify='center'
      align='center'
      extraStyles={extraStyles.loadingOverlayContainer}
    >
      <Loader size='md' extraStyles={extraStyles.loadingOverlayLoader} />
    </FlexContainer>
  );
};

const EmptyTableBody = () => (
  <FlexContainer
    justify='center'
    align='center'
    extraStyles={extraStyles.emptyTableLoader}
  >
    <h3>No items yet!</h3>
  </FlexContainer>
);

const extraStyles = {
  emptyTableLoader: {
    height: '100%',
  },
  loadingOverlayContainer: {
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  loadingOverlayLoader: { marginTop: 50 },
};
