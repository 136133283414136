/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { EditorResultsSectionItem } from '../EditorResultsSectionItem';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import styles from './index.module.scss';
import {
  ClickableAreaType,
  CustomCanvasType,
  InnerCanvasType,
} from 'src/utils/types/Editor';
import { getFromEditorLocalbase } from 'src/api/local/editor';

interface Props {
  canvas: CustomCanvasType;
  innerCanvas: InnerCanvasType;
}

export const EditorResultsSection: FC<Props> = ({ innerCanvas }) => {
  const [editorResultClickableZones, setEditorResultClickableZones] = useState<
    (
      | ClickableAreaType
      | {
          type: string;
        }
    )[]
  >([]);
  const editorState = useDistinctSelector('editor');

  useEffect(() => {
    if (editorState.showPreview) {
      return;
    }

    getFromEditorLocalbase('surveyCanvas').then(data => {
      const surveyCanvasObjects = data?.objects;

      if (!surveyCanvasObjects || innerCanvas?.canvasType === 'survey') {
        return;
      }

      const filteredObjects: ClickableAreaType[] = surveyCanvasObjects
        .filter(obj => obj?.type === 'clickableArea')
        .sort((a, b) => b?.order - a?.order);

      const objectsToAdd = editorState.keepResultsSame
        ? [{ type: 'fallback' }]
        : [{ type: 'fallback' }, ...filteredObjects];

      setEditorResultClickableZones(objectsToAdd);
    });
  }, [innerCanvas?.canvasType, editorState?.keepResultsSame]);

  return (
    <If condition={innerCanvas?.canvasType === 'results'}>
      <FlexContainer
        className={styles.editor_results_section}
        gap={20}
        id='editor-left-sidebar'
      >
        {editorResultClickableZones.map(clickableZone => (
          <EditorResultsSectionItem
            clickableZone={clickableZone}
            key={(clickableZone as ClickableAreaType)?.objectId}
          />
        ))}
      </FlexContainer>
    </If>
  );
};
