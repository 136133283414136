import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mailchimpClient } from '../../api';
import { Button } from '../common/Button/Button';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { Input } from '../common/Input/Input';

export const MailchimpLoginWithAPIKey = () => {
  const [apiKey, setApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLinkMailchimpAccount = useCallback(async () => {
    if (!apiKey) {
      toast.error('API key cannot be empty!');

      return;
    }

    if (apiKey.length <= 20 || apiKey.length >= 60) {
      toast.error('API key is incorrect!');

      return;
    }

    try {
      setIsLoading(true);

      const { status } = await mailchimpClient.checkConnection(apiKey);
      const [, prefix] = apiKey.split('-');

      if (status === 204) {
        navigate(
          `/login/mailchimp/loading?accessToken=${apiKey}&prefix=${prefix}&status=success`,
        );
      }
    } catch {
      toast.error(
        'Error occured! Double-check the API key, if error persists, please, contact our support team.',
        { autoClose: 10000 },
      );
    } finally {
      setIsLoading(false);
    }
  }, [apiKey]);

  return (
    <FlexContainer>
      <Input
        type='password'
        value={apiKey}
        onChange={event => setApiKey(event.target.value)}
        placeholder='Enter your API key'
        extraStyles={{ marginBottom: 20 }}
        dataCy='login-with-apikey-input'
      />

      <Button
        onClick={handleLinkMailchimpAccount}
        bgColor='#45869F'
        color='#fff'
        type='fill'
        text='Link Mailchimp with API key'
        extraStyles={{ minWidth: 300 }}
        isLoading={isLoading}
        dataCy='login-with-apikey-btn'
      />
    </FlexContainer>
  );
};
