import { AccountStatsInfo } from './Mailchimp';

export type ESPMailchimpPrefixType = `us${string}`;

export type UserESPLink = {
  id: number;
  userId: number;
  accessToken: string;
  espName: string;
  espAccountId: string;
  prefix: string;
  createdAt: string;
  updatedAt: string;
};

export type ESPLinkCreationProps = {
  espAccessToken: string;
  prefix: ESPMailchimpPrefixType;
  espName: 'Mailchimp';
};

export enum MailchimpLoginEnum {
  DEFAULT = 'default',
  APIKEY = 'apikey',
}

export type AccountStatsSearchParams = {
  accountId: string;
  audienceId: string;
  items?: string;
  filter?: string;
  page?: string;
};

export type AccountStats = {
  info: AccountStatsInfo[];
  total: number;
};
