import React, { ChangeEvent, useEffect, useState } from 'react';
import { Divider, FlexContainer, Input, Textarea } from '../common';
import { SmartFormPropertiesSection } from '../SmartFormPropertiesSection';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { SmartFormSuccessMessageTemplate } from '../SmartFormSuccessMessageTemplate';
import { useAppDispatch } from 'src/redux/store';
import { smartFormActions } from 'src/redux/slice/smartForm';

export const SmartFormSuccessMessagesProperties = () => {
  const {
    successTitleColor,
    successMessageColor,
    successTitle,
    successMessage,
  } = useDistinctSelector('smartForm');

  const dispatch = useAppDispatch();

  const [tempSuccessTitleColor, setTempSuccessTitleColor] =
    useState(successTitleColor);
  const [tempSuccessMessageColor, setTempSuccessMessageColor] =
    useState(successMessageColor);

  useEffect(() => {
    dispatch(
      smartFormActions.update({
        successTitleColor: tempSuccessTitleColor,
        successMessageColor: tempSuccessMessageColor,
      }),
    );
  }, [tempSuccessTitleColor, tempSuccessMessageColor]);

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value, name } = e.target;

    dispatch(smartFormActions.update({ [name]: value }));
  };

  return (
    <FlexContainer gap={30}>
      <SmartFormPropertiesSection title='Success Title'>
        <SmartFormSuccessMessageTemplate
          color={tempSuccessTitleColor}
          onColorChange={setTempSuccessTitleColor}
        >
          <Input
            value={successTitle}
            onChange={handleInput}
            name='successTitle'
          />
        </SmartFormSuccessMessageTemplate>
      </SmartFormPropertiesSection>

      <Divider />

      <SmartFormPropertiesSection title='Success Message'>
        <SmartFormSuccessMessageTemplate
          color={tempSuccessMessageColor}
          onColorChange={setTempSuccessMessageColor}
        >
          <Textarea
            value={successMessage}
            onChange={handleInput}
            name='successMessage'
          />
        </SmartFormSuccessMessageTemplate>
      </SmartFormPropertiesSection>
    </FlexContainer>
  );
};
