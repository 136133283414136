import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from '../../utils/types/User';

const initialState: User = {
  id: 1,
  email: '',
  verifiedEmail: false,
  firstName: '',
  lastName: '',
  publicProfileURL: '',
  currentJobTitle: '',
  currentCompany: '',
  industry: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    create: (_user, action: PayloadAction<User>) => action.payload,
  },
});

export default userSlice.reducer;
export const userActions = userSlice.actions;
