import React, { FC } from 'react';
import { Divider, FlexContainer } from '../common';

import { CustomCanvasType } from 'src/utils/types/Editor';
import { PreviewMergeTagsList } from '../PreviewMergeTagsList';
import styles from './index.module.scss';

type Props = {
  canvas: CustomCanvasType;
};

export const EditorPreviewRightSidebar: FC<Props> = ({ canvas }) => {
  return (
    <FlexContainer className={styles.preview_right_sidebar} gap={16}>
      <h4 className={styles.preview_right_sidebar__title}>Survey Preview</h4>

      <Divider />

      <PreviewMergeTagsList canvas={canvas} />
    </FlexContainer>
  );
};
