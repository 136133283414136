import React, { FC } from 'react';
import { SmartFormMailchimpData } from '../SmartFormMailchimpData';
import { Divider, FlexContainer, If } from '../common';

import styles from './index.module.scss';
import { SmartFormButtonProperties } from '../SmartFormButtonProperties';
import { SmartFormEditorPage } from 'src/utils/types/SmartForm';
import { SmartFormSuccessMessagesProperties } from '../SmartFormSuccessMessagesProperties';

type Props = {
  pageType: SmartFormEditorPage;
};

export const SmartFormCreationArea: FC<Props> = ({ pageType }) => {
  return (
    <FlexContainer
      className={styles.smart_form_creation_area__container}
      gap={20}
    >
      <SmartFormMailchimpData />

      <Divider />

      <If condition={pageType === 'form'}>
        <SmartFormButtonProperties />
      </If>

      <If condition={pageType === 'success-message'}>
        <SmartFormSuccessMessagesProperties />
      </If>
    </FlexContainer>
  );
};
