import React from 'react';
import ContentLoader from 'react-content-loader';
import { FlexContainer } from '../../common/FlexContainer/FlexContainer';

export const TableLoader = props => (
  <FlexContainer
    extraStyles={extraStyles.loader}
    justify='center'
    align='center'
  >
    <ContentLoader
      speed={3}
      width={1100}
      viewBox='0 0 1200 20'
      backgroundColor='rgb(245, 245, 245)'
      foregroundColor='rgb(230, 230, 230)'
      {...props}
    >
      <rect x='0' y='2' rx='6' ry='6' width='196' height='18' />
      <rect x='204' y='2' rx='6' ry='6' width='196' height='18' />
      <rect x='409' y='2' rx='6' ry='6' width='586' height='18' />
      <rect x='1036' y='2' rx='6' ry='6' width='39' height='18' />
      <rect x='1096' y='2' rx='6' ry='6' width='39' height='18' />
      <rect x='1156' y='2' rx='6' ry='6' width='39' height='18' />
    </ContentLoader>
  </FlexContainer>
);

const extraStyles = {
  loader: { height: 30, maxWidth: 1040, margin: '0 auto' },
};
