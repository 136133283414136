import React, { CSSProperties, ChangeEvent, useEffect, useState } from 'react';
import { SmartFormPropertiesSection } from '../SmartFormPropertiesSection';
import { ColorPicker, Input } from '../common';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { smartFormActions } from 'src/redux/slice/smartForm';
import { useAppDispatch } from 'src/redux/store';

export const SmartFormButtonProperties = () => {
  const {
    buttonText,
    buttonActiveColor,
    buttonDefaultColor,
    buttonHoverColor,
    textColor,
  } = useDistinctSelector('smartForm');

  const dispatch = useAppDispatch();

  const [tempButtonDefaultColor, setTempButtonDefaultColor] =
    useState(buttonDefaultColor);
  const [tempButtonHoverColor, setTempButtonHoverColor] =
    useState(buttonHoverColor);
  const [tempButtonActiveColor, setTempButtonActiveColor] =
    useState(buttonActiveColor);
  const [tempTextColor, setTempTextColor] = useState(textColor);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    dispatch(smartFormActions.update({ buttonText: value }));
  };

  useEffect(() => {
    dispatch(
      smartFormActions.update({
        buttonDefaultColor: tempButtonDefaultColor,
        buttonHoverColor: tempButtonHoverColor,
        buttonActiveColor: tempButtonActiveColor,
        textColor: tempTextColor,
      }),
    );
  }, [
    tempButtonDefaultColor,
    tempButtonHoverColor,
    tempButtonActiveColor,
    tempTextColor,
  ]);

  return (
    <SmartFormPropertiesSection title='Button'>
      <Input
        value={buttonText}
        onChange={handleInput}
        variant='editor-sidebar-input'
        extraStyles={extraStyles.input}
      />

      <ColorPicker
        color={tempTextColor}
        onColorChange={setTempTextColor}
        extraStyles={extraStyles.colorPicker}
        label='Text Color'
      />

      <ColorPicker
        color={tempButtonDefaultColor}
        onColorChange={setTempButtonDefaultColor}
        extraStyles={extraStyles.colorPicker}
        label='Button Default'
      />

      <ColorPicker
        color={tempButtonHoverColor}
        onColorChange={setTempButtonHoverColor}
        extraStyles={extraStyles.colorPicker}
        label='Button Hover'
      />

      <ColorPicker
        color={tempButtonActiveColor}
        onColorChange={setTempButtonActiveColor}
        extraStyles={extraStyles.colorPicker}
        label='Button Active'
      />
    </SmartFormPropertiesSection>
  );
};

const extraStyles: Record<string, CSSProperties> = {
  colorPicker: {
    top: -340,
  },
  input: {
    maxWidth: 270,
  },
};
