import { getFromEditorLocalbase } from 'src/api/local/editor';
import { SurveyCanvas } from '../../types/Survey';

export const getSurveyCanvas = async (): Promise<SurveyCanvas> => {
  const data = await getFromEditorLocalbase('surveyCanvas');

  if (!data?.objects?.length) {
    return { objects: [] };
  }

  return data;
};
