import React, { Dispatch, FC, SetStateAction } from 'react';
import { FlexContainer, If } from 'src/components/common';
import {
  CanvasType,
  CustomCanvasType,
  CustomFabricObject,
} from 'src/utils/types/Editor';
import { useAddObject } from '../actions/useAddObject';
import * as images from '../../../assets/index';
import styles from './index.module.scss';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

type Props = {
  canvas: CustomCanvasType;
  type: CanvasType;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
  onSetActiveObject: Dispatch<SetStateAction<CustomFabricObject | null>>;
  onSetActiveObjects: Dispatch<SetStateAction<CustomFabricObject[]>>;
};

export const AddObjectsBlock: FC<Props> = ({
  canvas,
  type,
  onGetCanvasObjects,
  onSetActiveObject,
  onSetActiveObjects,
}) => {
  const { showPreview } = useDistinctSelector('editor');
  const { addText, addImage, addClickableArea, addSurveyResult } = useAddObject(
    canvas,
    onGetCanvasObjects,
    onSetActiveObjects,
    onSetActiveObject,
  );

  return (
    <FlexContainer direction='row' align='center' gap={20}>
      <p className={styles.surveys_editor_header__add_text}>Add</p>

      <button
        onClick={addText}
        className={styles.surveys_editor_header__button}
        tabIndex={1}
      >
        <img
          src={images.addTextIcon}
          className={styles.surveys_editor_header__button_icon}
          alt='Add Text'
        />
      </button>

      <label
        className={styles.surveys_editor_header__add_image_label}
        tabIndex={2}
      >
        <input
          type='file'
          accept='image/png, image/jpeg, image/jpg'
          className={styles.surveys_editor_header__add_image_input}
          onClick={e => {
            if (showPreview) {
              e.preventDefault();
            }
          }}
          onChange={addImage}
        />

        <img
          src={images.addImageIcon}
          className={styles.surveys_editor_header__button_icon}
          alt='Add Image'
        />
      </label>

      <If condition={type === 'survey'}>
        <button
          onClick={addClickableArea}
          className={styles.surveys_editor_header__button}
          tabIndex={3}
        >
          <img
            src={images.addClickableAreaIcon}
            className={styles.surveys_editor_header__button_icon}
            alt='Add Clickable Area'
          />
        </button>
      </If>

      <If condition={type === 'results'}>
        <button
          onClick={addSurveyResult}
          className={styles.surveys_editor_header__button}
        >
          <img
            src={images.addStatsIcon}
            className={styles.surveys_editor_header__button_icon}
            alt='Add Survey Result'
          />
        </button>
      </If>
    </FlexContainer>
  );
};
