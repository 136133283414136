import React, { FC, useEffect } from 'react';
import { CustomCanvasType } from 'src/utils/types/Editor';

import classnames from 'classnames';
import { toast } from 'react-toastify';
import { editorActions } from 'src/redux/slice/editor';
import { useAppDispatch } from 'src/redux/store';
import { loadSurveyCanvasObjects } from 'src/utils/helpers/editor/loadSurveyCanvasObjects';
import { loadSurveyResultsCanvasObjects } from 'src/utils/helpers/editor/loadSurveyResultsCanvasObjects';
import { saveSurveyCanvas } from 'src/utils/helpers/editor/saveSurveyCanvas';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import * as images from '../../../assets/index';
import styles from './index.module.scss';
import { saveSurveyResultsCanvases } from 'src/utils/helpers/editor/saveSurveyResultsCanvases';

type Props = {
  canvas: CustomCanvasType;
};

export const PreviewButton: FC<Props> = ({ canvas }) => {
  const {
    showPreview,
    currentCanvasId,
    isLoadingObjects,
    isLoadingImage,
    currentCanvasType: type,
  } = useDistinctSelector('editor');
  const dispatch = useAppDispatch();

  const handleSurveyPreview = async (newShowPreview: boolean) => {
    if (newShowPreview) {
      try {
        await new Promise(resolve =>
          saveSurveyCanvas(canvas, false, () => resolve(true)),
        );

        canvas.getObjects().forEach(object => {
          if (object.type === 'clickableArea') {
            canvas.remove(object);
          } else {
            (object as any).set('selectable', false);
            (object as any).set('evented', false);
          }
        });

        canvas.renderAll();
      } catch (error) {
        toast.error('Unknown error occured');
      }
    } else {
      await loadSurveyCanvasObjects(canvas);
    }
  };

  const handleResultsPreview = async (newShowPreview: boolean) => {
    if (newShowPreview) {
      try {
        await new Promise(resolve =>
          saveSurveyResultsCanvases(canvas, currentCanvasId, true, () =>
            resolve(true),
          ),
        );
      } catch (error) {
        toast.error('Unknown error occured');
      }
    } else {
      await loadSurveyResultsCanvasObjects(canvas, currentCanvasId);

      dispatch(editorActions.update({ prevCanvasId: currentCanvasId }));
    }
  };

  const handlePreviewClick = async () => {
    const newShowPreview = !showPreview;

    if (type === 'survey') {
      await handleSurveyPreview(newShowPreview);
    } else {
      await handleResultsPreview(newShowPreview);
    }

    dispatch(editorActions.update({ showPreview: !showPreview }));
  };

  useEffect(() => {
    if (!canvas || type === 'survey') {
      return;
    }

    (async () => {
      if (!showPreview || isLoadingImage || isLoadingObjects) {
        return;
      }

      dispatch(editorActions.update({ isLoadingObjects: true }));

      await loadSurveyResultsCanvasObjects(canvas, currentCanvasId);

      canvas.getObjects().forEach(object => {
        (object as any).set('selectable', false);
        (object as any).set('evented', false);

        if (object.type === 'surveyResult') {
          (object as any).generateChartWithData(
            object.clickableAreas.map(area => ({
              count: 0,
              vote: area.value,
            })),
          );
        }
      });

      canvas.renderAll();

      dispatch(editorActions.update({ isLoadingObjects: false }));
    })();
  }, [currentCanvasId, showPreview, type, canvas]);

  return (
    <button
      className={classnames(styles.preview_button, {
        [styles.preview_button__active]: showPreview,
      })}
      onClick={handlePreviewClick}
    >
      <img
        src={showPreview ? images.previewDarkIcon : images.previewIcon}
        alt='Preview'
        className={styles.preview_button__icon}
      />

      <p className={styles.preview_button__title}>Preview</p>
    </button>
  );
};
