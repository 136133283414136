import classnames from 'classnames';
import React, { FC } from 'react';
import { FlexContainer, If, Loader } from 'src/components/common';
import { CanvasType } from 'src/utils/types/Editor';
import styles from './index.module.scss';

type Props = {
  type: CanvasType;
  isLoadingSurveyCanvas: boolean;
  isLoadingSurveyResultsCanvas: boolean;
  handleTypeChangeToSurvey: () => Promise<void>;
  handleTypeChangeToResults: () => Promise<void>;
};

export const CanvasTypeSwitcher: FC<Props> = ({
  type,
  isLoadingSurveyCanvas,
  isLoadingSurveyResultsCanvas,
  handleTypeChangeToSurvey,
  handleTypeChangeToResults,
}) => {
  return (
    <FlexContainer
      direction='row'
      align='center'
      gap={0}
      id='top-right-actions'
    >
      <button
        className={classnames(
          styles.right_side_button,
          styles.right_side_button__left,
          {
            [styles.right_side_button__selected]:
              type === 'survey' && !isLoadingSurveyCanvas,
          },
        )}
        onClick={handleTypeChangeToSurvey}
      >
        <If condition={isLoadingSurveyCanvas}>
          <Loader size='xsm' />
        </If>

        <If condition={!isLoadingSurveyCanvas}>
          <p>Survey</p>
        </If>
      </button>

      <button
        className={classnames(
          styles.right_side_button,
          styles.right_side_button__right,
          {
            [styles.right_side_button__selected]:
              type === 'results' && !isLoadingSurveyResultsCanvas,
          },
        )}
        onClick={handleTypeChangeToResults}
      >
        <If condition={isLoadingSurveyResultsCanvas}>
          <Loader size='xsm' />
        </If>

        <If condition={!isLoadingSurveyResultsCanvas}>
          <p>Results</p>
        </If>
      </button>
    </FlexContainer>
  );
};
