/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';
import {
  AccountActionTypes,
  AccountStatsInfo,
  AccountStatsSmartLinkInfo,
  AccountStatsSurveyInfo,
} from 'src/utils/types/Mailchimp';
import { MailchimpAccountActionButtons } from '../MailchimpAccountActionButtons';
import { MailchimpAccountActionsTableItemDate } from '../MailchimpAccountActionsTableItemDate';
import { If } from '../common';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './MailchimpAccountsActionsTableItem.module.scss';
import { SmartLinkTableItem } from './SmartLinkTableItem';
import { SurveyTableItem } from './SurveyTableItem';

interface Props {
  item: AccountStatsInfo;
}

export const MailchimpAccountsActionsTableItem: FC<Props> = ({ item }) => {
  return (
    <FlexContainer
      direction='row'
      align='center'
      gap={20}
      className={styles.account_stat_item}
    >
      <MailchimpAccountActionsTableItemDate date={item.updatedAt} />

      <If condition={item.type === AccountActionTypes.SURVEY}>
        <SurveyTableItem item={item as AccountStatsSurveyInfo} />
      </If>

      <If condition={item.type === AccountActionTypes.SMARTLINK}>
        <SmartLinkTableItem item={item as AccountStatsSmartLinkInfo} />
      </If>

      <MailchimpAccountActionButtons item={item} />
    </FlexContainer>
  );
};
