import { fabric } from 'fabric';
import { CustomTextBoxType } from '../../../types/Editor';

export const CustomTextbox = fabric.util.createClass(fabric.Textbox, {
  ...fabric.Textbox,
  initialize: function (text, options: Partial<CustomTextBoxType> = {}) {
    this.callSuper('initialize', text, {
      objectCaching: false,
      ...options,
    });

    this.setControlsVisibility({
      mt: false,
      mb: false,
    });

    this.objectId = options?.objectId ?? crypto.randomUUID();
    this.type = options.type ?? 'text';
    this.fontFamily = options.fontFamily ?? 'Arial';
    this.fontSize = options.fontSize ?? 14;
    this.lineHeight = options.lineHeight ?? 1.5;
    this.fill = options.fill ?? '#000';
    this.width = options.width ?? 300;
    this.cursorColor = options.cursorColor ?? '#000';
    this.lockUniScaling = options.lockUniScaling ?? true;
    this.strokeWidth = options.strokeWidth ?? 0;
    this.strokeUniform = options.strokeUniform ?? true;
    this.isLocked = options.isLocked ?? false;
    this.fallback = options.fallback ?? '';
    this.saveFallback = options.saveFallback ?? true;
    this.objectCaching = options.objectCaching ?? false;
    this.applyObjectLock(options?.isLocked);
    this.applySnapToGrid();
  },

  applySnapToGrid() {
    this.on('resizing', e => {
      const { target, corner } = e.transform;
      const { x, y } = e.pointer;
      const { isShiftPressed = false } = e.e;

      let desiredWidth = target.getSnappedToGrid(x - target.left, 10) || 1;

      let newX = target.getSnappedToGrid(x, 10);
      let newY = target.getSnappedToGrid(y, 10);

      const destY = target.top + target.getScaledHeight();
      let height = target.getSnappedToGrid(Math.abs(destY - newY)) || 1;

      const destX = target.left + target.getScaledWidth();
      let width = target.getSnappedToGrid(Math.abs(destX - newX)) || 1;

      if (isShiftPressed) {
        desiredWidth = target.getSnappedToGrid(x - target.left, 1) || 1;

        newX = target.getSnappedToGrid(x, 1) || 1;
        newY = target.getSnappedToGrid(y, 1) || 1;

        height = target.getSnappedToGrid(Math.abs(destY - newY), 1) || 1;
        width = target.getSnappedToGrid(Math.abs(destX - newX), 1) || 1;
      }

      if (isNaN(height) || isNaN(destY) || isNaN(width) || isNaN(destX)) {
        return;
      }

      switch (corner) {
        case 'ml': {
          if (width < 10) {
            target.set({
              width: desiredWidth || 1,
              scaleX: 1,
            });

            break;
          }

          target.set({
            left: newX,
            width,
            scaleX: 1,
          });

          break;
        }

        case 'mr': {
          if (desiredWidth < 10) {
            target.set({
              left: newX,
              width,
              scaleX: 1,
            });

            break;
          }

          target.set({
            width: desiredWidth || 1,
            scaleX: 1,
          });

          break;
        }
      }

      target.canvas.renderAll();
    });

    this.on('scaling', e => {
      const { target, corner } = e.transform;
      const { x, y } = e.pointer;
      const { isShiftPressed = false } = e.e;

      let desiredWidth = target.getSnappedToGrid(x - target.left, 10) || 1;

      let newX = target.getSnappedToGrid(x, 10);
      let newY = target.getSnappedToGrid(y, 10);

      const destY = target.top + target.getScaledHeight();
      let height = target.getSnappedToGrid(Math.abs(destY - newY)) || 1;

      const destX = target.left + target.getScaledWidth();
      let width = target.getSnappedToGrid(Math.abs(destX - newX)) || 1;

      if (isShiftPressed) {
        desiredWidth = target.getSnappedToGrid(x - target.left, 1) || 1;

        newX = target.getSnappedToGrid(x, 1) || 1;
        newY = target.getSnappedToGrid(y, 1) || 1;

        height = target.getSnappedToGrid(Math.abs(destY - newY), 1) || 1;
        width = target.getSnappedToGrid(Math.abs(destX - newX), 1) || 1;
      }

      if (isNaN(width) || isNaN(destX)) {
        return;
      }

      const scalingXFactor = Math.max(desiredWidth, width) / target.width;

      const newFontSize = target.fontSize * scalingXFactor;

      switch (corner) {
        case 'br': {
          target.set({
            fontSize: newFontSize,
            width: desiredWidth,
            scaleX: 1,
            scaleY: 1,
          });

          if (desiredWidth < 10) {
            target.set({
              fontSize: newFontSize,
              width: 1,
            });
          }

          break;
        }

        case 'tr': {
          if (height < 10) {
            target.set({
              fontSize: newFontSize,
              width: desiredWidth || 1,
              scaleX: 1,
              scaleY: 1,
            });

            break;
          }

          target.set({
            fontSize: newFontSize,
            width: desiredWidth || 1,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }

        case 'tl': {
          target.set({
            fontSize: newFontSize,
            width,
            left: newX,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }

        case 'bl': {
          target.set({
            fontSize: newFontSize,
            width,
            left: newX,
            scaleX: 1,
            scaleY: 1,
          });

          break;
        }
      }

      target.canvas.renderAll();
    });
  },

  applyObjectLock(isLocked?: boolean) {
    this.isLocked = isLocked !== undefined ? isLocked : this.isLocked ?? false;

    if (this.isLocked) {
      this.lockMovementX = true;
      this.lockMovementY = true;
      this.hasControls = false;
      this.hasBorders = false;
      this.evented = false;
      this.selectable = false;
    } else {
      this.lockMovementX = false;
      this.lockMovementY = false;
      this.hasControls = true;
      this.hasBorders = true;
      this.evented = true;
      this.selectable = true;
    }
  },

  getSnappedToGrid(value: number, step: number = 10) {
    return Math.round(value / step) * step;
  },
}) as new (
  text: string,
  options?: Partial<CustomTextBoxType>,
) => CustomTextBoxType;
