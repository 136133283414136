import React, { FC } from 'react';
import { Dropdown, FlexContainer, Modal } from 'src/components/common';
import { useMailchimpAccountsDropdown } from 'src/utils/hooks/useMailchimpAccountDropdown';
import { AccountStatsSmartLinkInfo } from 'src/utils/types/Mailchimp';
import { useDuplicateSmartLink } from './actions/useDuplicateSmartLink';
import { ExtraStyles } from 'src/utils/types/general/types';

type Props = {
  item: AccountStatsSmartLinkInfo;
  isOpen: boolean;
  onCloseModal: () => void;
};

export const DuplicateSmartLink: FC<Props> = ({
  item,
  isOpen,
  onCloseModal,
}) => {
  const { isLoading, duplicateSmartLink } = useDuplicateSmartLink();
  const { accounts, currentAudience, currentAccount, setCurrentAudience } =
    useMailchimpAccountsDropdown();

  return (
    <Modal
      type='confirmation'
      onConfirm={() => duplicateSmartLink(item, currentAccount)}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onCloseModal}
      confirmationButtonText='Duplicate Smart Link'
      title='Duplicate Smart Link'
    >
      <FlexContainer style={extraStyles.wrapper}>
        <Dropdown
          options={accounts}
          value={currentAudience}
          onChange={setCurrentAudience}
          extraStyles={extraStyles.dropdown}
          extraStylesSubContainer={extraStyles.dropdown}
          extraStylesMainContainer={extraStyles.dropdownLabel}
          extraStylesList={extraStyles.dropdownLabel}
          icon={currentAudience?.icon || ''}
        />

        <p style={extraStyles.info}>
          Duplicating will create an exact copy of the selected Smart Link,
          including its destination URL, rules, and analytics settings. The new
          Smart Link will be independent and can be edited or deleted without
          affecting the original one.
        </p>

        <p style={extraStyles.info}>NOTE:</p>

        <p style={extraStyles.info}>
          Traffic and analytics data will not be copied to the new link,
          allowing you to track its performance separately from the original.
        </p>
      </FlexContainer>
    </Modal>
  );
};

const extraStyles: ExtraStyles = {
  dropdown: { width: '100%', height: 38 },
  dropdownLabel: { maxWidth: '100%' },
  switchLabel: { fontWeight: 400 },
  info: { fontSize: 12, lineHeight: '20px' },
  wrapper: { marginBottom: 20 },
};
