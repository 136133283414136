import { getFromEditorLocalbase } from 'src/api/local/editor';
import {
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from 'src/utils/types/Editor';
import { centerCanvasView } from './centerCanvasView';
import { CustomCanvas } from './customClasses/CustomCanvas';
import { CustomImage } from './customClasses/CustomImage';
import { CustomTextbox } from './customClasses/CustomTextBox';
import { SurveyResult } from './customClasses/SurveyResult';

export const loadSurveyResultsCanvasObjects = async (
  canvas: CustomCanvasType,
  canvasId: string,
  onGetCanvasObjects?: () => void,
) => {
  const resultCanvas = await getFromEditorLocalbase('resultsCanvases');

  const newCanvas = resultCanvas?.canvases?.find(
    canv => canv?.canvasId === canvasId,
  );

  if (!newCanvas || !newCanvas?.objects?.length) {
    const customCanvas = new CustomCanvas({
      width: 600,
      height: 400,
      fill: 'rgba(255, 255, 255, 1)',
      canvasType: 'results',
    });

    canvas.add(customCanvas);
    centerCanvasView(canvas, canvas.getZoom());
    canvas.renderAll();

    return;
  }

  canvas.clear();

  await Promise.all(
    newCanvas.objects.map(async object => {
      let newObject: CustomFabricObject | InnerCanvasType;

      switch (object.type) {
        case 'canvas':
          const customCanvas = new CustomCanvas(object);

          canvas.add(customCanvas);

          newObject = customCanvas;

          break;

        case 'text':
          const text = new CustomTextbox(object.text, object);

          canvas.add(text);

          newObject = text;

          break;

        case 'image':
          const img = new Image();

          const image = new CustomImage(img, object);

          canvas.add(image);

          onGetCanvasObjects?.();

          newObject = image;

          break;

        case 'surveyResult':
        default:
          const group = new SurveyResult(canvas, [], object.clickableAreas, {
            ...object,
          });

          group.replaceObjectsInGroup(object.clickableAreas);

          canvas.add(group);

          group.rerenderGroup();

          group.set({
            left: object?.left ?? 40,
            top: object?.top ?? 40,
          });

          newObject = group;

          break;
      }

      await new Promise(resolve => {
        if (newObject && newObject.type === 'image') {
          newObject.setSrc(object.src, () => {
            onGetCanvasObjects?.();

            resolve(true);
          });

          return;
        }

        resolve(true);
      });
    }),
  );

  canvas.renderAll();
};
