import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SmartLink } from 'src/utils/types/SmartLink';

const initialState: SmartLink = {
  id: 0,
  userESPLinkId: 0,
  audienceId: '',
  destinationUrl: '',
  value: '',
  updatedAt: '',
  saveResultAs: 'mergeTag',
  groupOrLabel: '',
  mergeTag: '',
  isDeleted: false,
};

const smartLinkSlice = createSlice({
  name: 'smartLink',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<SmartLink>>) => {
      const validPayload = {
        ...action.payload,
        saveResultAs: action.payload.saveResultAs || 'mergeTag',
      };

      return {
        ...state,
        ...validPayload,
      } as any;
    },
    clear: () => initialState,
  },
});

export default smartLinkSlice.reducer;
export const smartLinkActions = smartLinkSlice.actions;
