import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EditorState } from '../../utils/types/general/types';

const initialState: EditorState = {
  keepResultsSame: true,
  showPreview: false,
  isLoadingImage: false,
  isLoadingObjects: false,
  currentCanvasId: 'fallback',
  prevCanvasId: 'fallback',
  currentCanvasType: 'survey',
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    update: (
      state: EditorState,
      action: PayloadAction<Partial<EditorState>>,
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export default editorSlice.reducer;
export const editorActions = editorSlice.actions;
