import React, { FC } from 'react';
import { Group, MergeTag, Tag } from 'src/utils/types/Survey';
import styles from './index.module.scss';

type Props = {
  value: string;
} & (MergeTag | Group | Tag);

const arrow = (
  <span style={{ whiteSpace: 'nowrap' }}>
    &nbsp;&nbsp;&nbsp;→&nbsp;&nbsp;&nbsp;
  </span>
);

export const SmartLinkEnterData: FC<Props> = ({
  value,
  ...saveResultAsData
}) => {
  switch (saveResultAsData.saveResultAs) {
    case 'mergeTag': {
      const highlightedMergeTag = (
        <span style={{ whiteSpace: 'nowrap' }}>
          {highlightData(`*|${saveResultAsData.mergeTag}|*`, 'Merge Tag: ')}
        </span>
      );
      const highlightedValue = highlightData(value, 'Value: ');

      return (
        <p className={styles.smart_link}>
          {highlightedMergeTag} {arrow} {highlightedValue}
        </p>
      );
    }

    case 'group':
      const correctGroup = decodeURIComponent(saveResultAsData.groupOrLabel);
      const correctSubgroup = decodeURIComponent(value);

      const highlightedGroup = highlightData(correctGroup, 'Group: ');

      return (
        <p className={styles.smart_link}>
          Groups {arrow} {highlightedGroup} {arrow}{' '}
          {highlightData(correctSubgroup, 'Add: ')}
        </p>
      );

    case 'tag':
      const correctTag = decodeURIComponent(value);
      const highlightedValue = highlightData(correctTag, 'Add: ');

      return (
        <p className={styles.smart_link}>
          Tags {arrow} {highlightedValue}
        </p>
      );
  }
};

const highlightData = (data: string, title: string) => {
  return (
    <span
      style={{
        whiteSpace: 'nowrap',
      }}
    >
      {title}
      <span style={{ color: '#437E05' }}>{data}</span>
    </span>
  );
};
