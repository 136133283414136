import React, { FC, useEffect, useState } from 'react';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { If } from '../common/If/If';
import styles from './PageBodyHeader.module.scss';

interface Props {
  page: 'user' | 'support';
}

export const PageBodyHeader: FC<Props> = ({ page }) => {
  const user = useDistinctSelector('user');
  const [greeting, setGretting] = useState('');

  useEffect(() => {
    const currentHour = new Date().getHours();
    let greet = '';

    switch (true) {
      case currentHour >= 0 && currentHour < 6:
        greet = 'Good night, ';

        break;

      case currentHour >= 6 && currentHour < 12:
        greet = 'Good morning, ';

        break;

      case currentHour >= 12 && currentHour < 18:
        greet = 'Good afternoon, ';

        break;

      case currentHour >= 18 && currentHour <= 23:
        greet = 'Good evening, ';

        break;

      default:
        greet = 'Hello, ';
    }

    setGretting(greet + user.firstName);
  }, []);

  return (
    <>
      <img
        className={styles.support_page_header__avatar}
        src={user.publicProfileURL}
        alt='User avatar from Linkedin'
      />

      <h1 className={styles.support_page_header__greeting}>{greeting}</h1>

      <h3 className={styles.support_page_header__questions}>
        <If condition={page === 'support'}>
          Have questions? Have suggestions? How can we help?
        </If>

        <If condition={page === 'user'}>
          Your profile is based on your LinkedIn Sign-In
        </If>
      </h3>
    </>
  );
};
