import { fabric } from 'fabric';
import { useEffect, useState } from 'react';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useCanvasScroll = (canvas: CustomCanvasType) => {
  const [canvasEl, setCanvasEl] = useState(
    document.querySelector('.canvas-container') as HTMLElement,
  );

  useEffect(() => {
    if (!canvasEl) {
      setCanvasEl(document.querySelector('.canvas-container') as HTMLElement);
    }
  }, [canvas]);

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleScroll = (
      e: WheelEvent & { wheelDeltaX: number; wheelDeltaY: number },
    ) => {
      if (e.ctrlKey) {
        return;
      }

      const rightSidebar = document.querySelector(
        '#editor-right-sidebar',
      ) as HTMLElement;
      const leftSidebar = document.querySelector(
        '#editor-left-sidebar',
      ) as HTMLElement;
      const modal = document.querySelector('#modal');

      if (
        !isMouseOverElement(e, canvasEl) ||
        (rightSidebar && isMouseOverElement(e, rightSidebar)) ||
        (leftSidebar && isMouseOverElement(e, leftSidebar)) ||
        modal
      ) {
        return;
      }

      const maxScrollX = Math.max(Math.abs(e.deltaX), Math.abs(e.wheelDeltaX));
      const maxScrollY = Math.max(Math.abs(e.deltaY), Math.abs(e.wheelDeltaY));

      const userPlatform = navigator.platform.toLowerCase();
      const isAppleOS =
        userPlatform.includes('mac') ||
        userPlatform.includes('iphone') ||
        userPlatform.includes('ipad');

      const maxScrollAmount =
        Math.max(maxScrollX, maxScrollY) * (isAppleOS ? 0.35 : 0.6);

      if (maxScrollX > maxScrollY || e.shiftKey) {
        const isNegativeValue = e.shiftKey ? e.wheelDeltaY > 0 : e.deltaX > 0;
        const scrollAmount = isNegativeValue
          ? -maxScrollAmount
          : maxScrollAmount;

        canvas.relativePan(new fabric.Point(scrollAmount, 0));
      } else {
        const scrollAmount = e.deltaY > 0 ? -maxScrollAmount : maxScrollAmount;

        canvas.relativePan(new fabric.Point(0, scrollAmount));
      }

      canvas.requestRenderAll();
    };

    document.addEventListener('wheel', handleScroll as any);

    return () => {
      document.removeEventListener('wheel', handleScroll as any);
    };
  }, [canvas]);
};

const isMouseOverElement = (e: MouseEvent, element: HTMLElement) => {
  const rect = element?.getBoundingClientRect?.();
  const mouseX = e.clientX;
  const mouseY = e.clientY;

  return (
    mouseX >= rect.left &&
    mouseX <= rect.right &&
    mouseY >= rect.top &&
    mouseY <= rect.bottom
  );
};
