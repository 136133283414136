/* eslint-disable @typescript-eslint/indent */
import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import * as images from '../../assets/index';
import { ColorPicker } from '../common/ColorPicker/ColorPicker';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import styles from './index.module.scss';
import { ClickableAreaType, SurveyResultType } from '../../utils/types/Editor';
import { If } from '../common';
import { MultipleColorPicker } from '../common/MultipleColorPicker';

interface Props {
  option: ClickableAreaType;
  activeObject: SurveyResultType;
  onChnageSurveyResultOptions: React.Dispatch<
    React.SetStateAction<ClickableAreaType[]>
  >;
}

export const SurveyResultOption: FC<Props> = ({
  option,
  activeObject,
  onChnageSurveyResultOptions,
}) => {
  const [color, setColor] = useState(option?.fill as string);
  const [defaultColor, setDefaultColor] = useState(option?.defaultColor);

  useEffect(() => {
    if (color === option.fill && defaultColor === option.defaultColor) {
      return;
    }

    activeObject.replaceColorInChartPart(color, defaultColor, option.objectId);

    onChnageSurveyResultOptions(
      prev =>
        prev.map(resultOption =>
          resultOption.objectId === option.objectId
            ? { ...resultOption, fill: color, defaultColor }
            : resultOption,
        ) as ClickableAreaType[],
    );
  }, [color, defaultColor]);

  return (
    <FlexContainer
      direction='row'
      align='center'
      justify='space-between'
      className={classnames(styles.survey_result_option, 'draggable-item')}
    >
      <FlexContainer
        direction='row'
        align='center'
        className={styles.survey_result_option__left_side}
      >
        <If condition={activeObject.variant.includes('stacked')}>
          <ColorPicker color={color} onColorChange={setColor} />
        </If>

        <If condition={activeObject.variant.includes('single')}>
          <MultipleColorPicker
            colors={[
              {
                color: color,
                id: 'Fill',
                changeHandler: setColor,
              },
              {
                color: defaultColor,
                id: 'Default',
                changeHandler: setDefaultColor,
              },
            ]}
          />
        </If>

        <p className={styles.survey_result_option__title}>{option.value}</p>
      </FlexContainer>

      <img
        src={images.moveArrowsIcon}
        alt='Drag & Drop handler'
        className={classnames(
          'column-drag-handle',
          styles.survey_result_option__drag_handler,
        )}
        onMouseDown={e => e.preventDefault()}
      />
    </FlexContainer>
  );
};
