import React, { FC } from 'react';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import styles from './Textarea.module.scss';
import classnames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLTextAreaElement> {
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  cols?: number;
  placeholder?: string;
  name?: string;
}

export const Textarea: FC<Props> = ({
  value,
  onChange,
  rows = 6,
  cols = 30,
  placeholder,
  disabled = false,
  name = '',
  ...props
}) => {
  return (
    <FlexContainer className={styles.textarea}>
      <textarea
        value={value}
        onChange={onChange}
        cols={cols}
        rows={rows}
        className={classnames(styles.textarea__input, {
          [styles.textarea__input_disabled]: disabled,
        })}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        {...props}
      ></textarea>
    </FlexContainer>
  );
};
