import React, { FC } from 'react';
import * as images from '../../assets/index';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { useSaveSurvey } from '../../utils/hooks/useSaveSurvey';
import { CustomCanvasType } from '../../utils/types/Editor';
import { SaveSurvey } from '../SurveyDialogs';
import { UpdateSurvey } from '../SurveyDialogs/UpdateSurvey';
import { Button } from '../common/Button/Button';
import { Divider } from '../common/Divider/Divider';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { ReturnBackButton } from '../common/ReturnBackButton';
import { useBeforeUnloadPageEffect } from './actions/useBeforeUnloadPageEffect';
import { useGoBack } from './actions/useGoBack';
import { useHandleSave } from './actions/useHandleSave';
import { useIsCahnged } from './actions/useIsChanged';
import { useRenamedModals } from './actions/useRenamedModals';

import styles from './index.module.scss';

interface Props {
  canvas: CustomCanvasType;
  openSaveSurveyModal: () => void;
  onEmbedCodeModalOpen: (isChanged: boolean) => void;
}

export const SurveysPageHeader: FC<Props> = ({
  canvas,
  openSaveSurveyModal,
  onEmbedCodeModalOpen,
}) => {
  const survey = useDistinctSelector('survey');

  const { saveSurvey, isLoading } = useSaveSurvey(survey);
  const {
    isOpenSaveModal,
    openSaveModal,
    closeSaveModal,
    isOpenUpdateModal,
    openUpdateModal,
    closeUpdateModal,
  } = useRenamedModals();

  useBeforeUnloadPageEffect();

  const isChanged = useIsCahnged(canvas);
  const { handleGoBack, handleGoBackButtonPress } = useGoBack(
    canvas,
    openSaveModal,
    isChanged,
  );
  const handleSave = useHandleSave({
    canvas,
    saveSurvey,
    closeSaveModal,
    handleGoBack,
  });

  const handleClose = () => {
    // return to editor if there are error in clickable areas

    // const isValid = checkClickableAreasForValidity(canvas, false);

    // if (!isValid) {
    //   closeSaveModal();

    //   return;
    // }

    handleGoBack();
  };

  return (
    <>
      <header className={styles.survey_header}>
        <FlexContainer direction='row' align='center' gap={20}>
          <ReturnBackButton customReturnCallback={handleGoBackButtonPress} />

          <Divider type='vertical' extraStyles={extraStyles.divider} />

          <img
            src={images.surveyLogo}
            alt='Survey Page Logotype'
            className={styles.survey_header__logo}
          />

          <h3 className={styles.survey_header__title}>{survey.title}</h3>
        </FlexContainer>

        <FlexContainer
          direction='row'
          align='center'
          gap={20}
          extraStyles={extraStyles.fitContentWrapper}
        >
          <FlexContainer
            direction='row'
            gap={10}
            align='center'
            extraStyles={extraStyles.fitContentWrapper}
          >
            <Button
              text='Save'
              onClick={openSaveSurveyModal}
              type='outlined-blue'
              variant='small'
              extraStyles={extraStyles.button}
            />

            <Button
              text='Embed'
              onClick={() =>
                isChanged().then(hasChanged => onEmbedCodeModalOpen(hasChanged))
              }
              type='outlined-blue'
              variant='small'
              extraStyles={extraStyles.button}
            />
          </FlexContainer>

          <img
            src={images.settingsIcon}
            alt='Settings'
            onClick={() => {
              openUpdateModal();
            }}
            className={styles.survey_header__settings}
          />
        </FlexContainer>
      </header>

      <UpdateSurvey
        onCloseModal={closeUpdateModal}
        isOpen={isOpenUpdateModal}
        canvas={canvas}
      />

      <SaveSurvey
        onClose={handleClose}
        isOpen={isOpenSaveModal}
        isLoading={isLoading}
        saveSurvey={handleSave}
      />
    </>
  );
};

const extraStyles = {
  button: { height: 30, minWidth: 112 },
  divider: { height: 20, margin: '0 5px' },
  fitContentWrapper: { maxWidth: 'max-content' },
};
