import { CustomCanvasType, CustomTextBoxType } from 'src/utils/types/Editor';
import { MergeTagListItem } from './useMergeTags';

export const updateTextToMergeTags = (
  canvas: CustomCanvasType,
  mergeTagsList: MergeTagListItem[],
) => {
  canvas
    .getObjects()
    .filter(obj => obj.type === 'text')
    .forEach(obj => {
      const foundObject = obj as CustomTextBoxType;
      const objectMergeTags = mergeTagsList.filter(item =>
        Boolean(item.originalLinks[foundObject.objectId]),
      );

      if (!objectMergeTags.length) {
        return;
      }

      let text = objectMergeTags[0].originalLinks[foundObject.objectId];

      for (const mergeTag of objectMergeTags) {
        if (!mergeTag.value) {
          if (foundObject.saveFallback) {
            foundObject.set({ text: foundObject.fallback });
          } else {
            foundObject.set({ text });
          }

          canvas.renderAll();

          return;
        }

        const mergeTagPattern = new RegExp(
          `\\*\\|${mergeTag.mergeTag}\\|\\*`,
          'g',
        );

        text = text.replace(mergeTagPattern, mergeTag.value);
      }

      foundObject.set({ text });
      canvas.renderAll();
    });
};
