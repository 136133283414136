import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { Divider, FlexContainer } from '../common';

import classnames from 'classnames';
import styles from './index.module.scss';
import { SmartFormEditorPage } from 'src/utils/types/SmartForm';

type Props = {
  pageType: SmartFormEditorPage;
  onPageTypeChange: Dispatch<SetStateAction<SmartFormEditorPage>>;
};

export const SmartFormSubheader: FC<Props> = ({
  pageType,
  onPageTypeChange,
}) => {
  return (
    <>
      <FlexContainer
        direction='row'
        justify='space-between'
        align='center'
        className={styles.smart_form__subheader}
      >
        <h1 className={styles.smart_form__title}>Create Smart Form</h1>

        <FlexContainer
          direction='row'
          align='center'
          gap={0}
          id='top-right-actions'
          className={styles.smart_form__right_side_buttons}
        >
          <button
            className={classnames(
              styles.smart_form__right_side_button,
              styles.smart_form__right_side_button__left,
              {
                [styles.smart_form__right_side_button__selected]:
                  pageType === 'form',
              },
            )}
            onClick={() => onPageTypeChange('form')}
          >
            <p>Smart Form</p>
          </button>

          <button
            className={classnames(
              styles.smart_form__right_side_button,
              styles.smart_form__right_side_button__right,
              {
                [styles.smart_form__right_side_button__selected]:
                  pageType === 'success-message',
              },
            )}
            onClick={() => onPageTypeChange('success-message')}
          >
            <p>Success</p>
          </button>
        </FlexContainer>
      </FlexContainer>

      <Divider extraStyles={extraStyles.divider} />
    </>
  );
};

const extraStyles: Record<string, CSSProperties> = {
  divider: {
    margin: '30px 0',
  },
};
