import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SmartFormPropertiesSection } from '../SmartFormPropertiesSection';
import { Dropdown, Input, Switch } from '../common';

import { useGetMailchimpAccountsQuery } from 'src/redux/slice/mailchimpApi';
import { smartFormActions } from 'src/redux/slice/smartForm';
import { useAppDispatch } from 'src/redux/store';

import { findDropdownOption } from 'src/utils/helpers/findDropdownOptions';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { DropdownOption } from 'src/utils/types/general/types';

import * as images from 'src/assets/index';

type StringDropdownOption = DropdownOption<string>;

export const SmartFormMailchimpData = () => {
  const { audienceId, tag, collectAdditionalData } =
    useDistinctSelector('smartForm');

  const [tempCollectAdditionalData, setTempCollectAdditionalData] = useState(
    collectAdditionalData,
  );

  const { data: accounts } = useGetMailchimpAccountsQuery();

  const [options, setOptions] = useState<StringDropdownOption[]>([]);
  const [currentAudience, setCurrentAudience] = useState<StringDropdownOption>(
    options[0] ?? { label: 'Loading...', labelValue: '' },
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts?.length) {
      setOptions(
        accounts.map(account => ({
          label: account?.accountName,
          labelValue: account?.audienceId,
        })),
      );
    }
  }, [accounts?.length]);

  useEffect(() => {
    if (!options.length) {
      setCurrentAudience({ label: 'Loading...', labelValue: '' });

      return;
    }

    setCurrentAudience(findDropdownOption(options, audienceId, options[0]));
  }, [options?.length]);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(smartFormActions.update({ tag: value }));
  };

  useEffect(() => {
    const account = accounts?.find(
      temp => temp.audienceId === currentAudience.labelValue,
    );

    if (!account) {
      return;
    }

    dispatch(
      smartFormActions.update({
        collectAdditionalData: tempCollectAdditionalData,
        accountId: account?.accountId || '',
        audienceId: currentAudience.labelValue,
      }),
    );

    navigate(`/new/form/${account?.accountId}/${account?.audienceId}`, {
      replace: true,
    });
  }, [tempCollectAdditionalData, currentAudience.labelValue]);

  return (
    <SmartFormPropertiesSection title='Mailchimp'>
      <Dropdown
        value={currentAudience}
        options={options}
        onChange={setCurrentAudience}
        icon={images.mailchimpIcon}
        extraStyles={extraStyles.dropdown}
        extraStylesList={extraStyles.dropdown}
      />

      <Input
        extraStyles={extraStyles.input}
        icon={images.mailchimpIcon}
        value={tag}
        onChange={handleInput}
        name='tag'
        placeholder='Beta User'
      />

      <Switch
        value={tempCollectAdditionalData}
        onChange={setTempCollectAdditionalData}
        label='Collect additional data'
        size={10}
      />
    </SmartFormPropertiesSection>
  );
};

const extraStyles = {
  input: { maxWidth: 270, minWidth: 270, minHeight: 40 },
  dropdown: {
    maxWidth: 270,
    minWidth: 270,
  },
  label: { fontSize: 12 },
};
