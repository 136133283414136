export enum ModalActionEnum {
  NONE = 'none',
  CREATE = 'create',
  UPDATE = 'update',
  EMBED = 'embed',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
}

export enum FilterByEnum {
  ALL = 'all',
  SURVEY = 'survey',
  SMARTLINK = 'dataUrl',
}
