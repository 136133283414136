/* eslint-disable @typescript-eslint/indent */
import { getFromEditorLocalbase } from 'src/api/local/editor';

export const getSurveyResultsCanvases = async () => {
  const data = await getFromEditorLocalbase('resultsCanvases');

  if (!data?.canvases?.length) {
    return [
      {
        canvasId: 'fallback',
        objects: [],
      },
    ];
  }

  return data?.canvases ?? [];
};
