import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { espClient, userClient } from '../../api';
import { Loader } from '../../components/common/Loader/Loader';
import { useGetMailchimpAccountsQuery } from '../../redux/slice/mailchimpApi';
import { userActions } from '../../redux/slice/user';
import { useAppDispatch } from '../../redux/store';
import { ESPLinkCreationProps } from '../../utils/types/ESP';

export const MailchimpConnectingLoader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const { refetch } = useGetMailchimpAccountsQuery();
  const [, setAccountId] = useLocalStorage('accountId');

  useEffect(() => {
    const saveConnection = async () => {
      const accessToken = queryParams.get('accessToken') || '';
      const prefix = queryParams.get('prefix') || '';

      const data = {
        espAccessToken: accessToken,
        prefix,
        espName: 'Mailchimp',
      };

      try {
        if (!accessToken || !prefix) {
          toast.error('Some error occured');
          navigate('/accounts');

          return;
        }

        const { data: mcAccountId } = await espClient.create(
          data as ESPLinkCreationProps,
        );
        const { data: user } = await userClient.getUser();

        dispatch(userActions.create(user));

        const { data: mailchimpAccounts } = await refetch();

        if (!mailchimpAccounts) {
          return;
        }

        if (mailchimpAccounts.length === 1) {
          setAccountId(mcAccountId);

          navigate(
            `/actions/${mcAccountId}/${mailchimpAccounts[0].audienceId}?items=25&filter=all&page=1`,
          );

          return;
        }

        navigate('/accounts');
      } catch (error: unknown) {
        window.console.log(
          '🚀 ~ file: MailchimpConnectingLoader.tsx:56 ~ saveConnection ~ error:',
          error,
        );
        navigate('/login/mailchimp?status=error');
      }
    };

    saveConnection();
  }, []);

  return <Loader fullScreen />;
};
