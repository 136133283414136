import { useEffect } from 'react';
import { surveyClient } from 'src/api';
import { getSurveyCanvas } from 'src/utils/helpers/editor/getSurveyCanvas';
import { getSurveyResultsCanvases } from 'src/utils/helpers/editor/getSurveyResultsCanvases';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useBackgroundSave = (canvas: CustomCanvasType) => {
  const { id } = useDistinctSelector('survey');
  const { currentCanvasId } = useDistinctSelector('editor');

  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleBackgroundSave = async () => {
      if (!id) {
        return;
      }

      const resultCanvasesObjects = await getSurveyResultsCanvases();
      const surveyCanvasObjects = await getSurveyCanvas();

      try {
        await surveyClient.updateCanvases(id, {
          resultCanvasesObjects: safeStringify(resultCanvasesObjects),
          surveyCanvasObjects: safeStringify(surveyCanvasObjects),
        });
      } catch {}
    };

    const interval = setInterval(handleBackgroundSave, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [canvas, id, currentCanvasId]);
};
