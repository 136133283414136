import classnames from 'classnames';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import * as images from '../../../assets/index';
import { mailchimpActions } from '../../../redux/slice/mailchimp';
import { useAppDispatch } from '../../../redux/store';
import { useDistinctSelector } from '../../../utils/hooks/useDistinctSelector';
import { If } from '../../common/If/If';
import { Loader } from '../../common/Loader/Loader';
import styles from './TableItem.module.scss';

interface Props {
  text: string | React.ReactNode;
  leftIcon: string;
  accountId: string;
  audienceId: string;
  withRightIcon?: boolean;
  iconWithoutBorder?: boolean;
  isStats?: boolean;
}

export const TableItem: FC<Props> = ({
  text,
  leftIcon: profileUrl,
  accountId,
  audienceId,
  withRightIcon,
  iconWithoutBorder,
  isStats,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { audiencesList } = useDistinctSelector('mailchimp');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [, setAccountId] = useLocalStorage('accountId');

  const handleTableItemClick = async () => {
    if (isStats) {
      return;
    }

    try {
      setIsLoading(true);
      setAccountId(accountId);

      const audience = audiencesList.find(aud => aud.audienceId === audienceId);

      dispatch(
        mailchimpActions.update({
          currentAudienceId: audienceId,
          currentAudience: audience?.audience,
        }),
      );

      navigate(
        `/actions/${accountId}/${audienceId}?items=25&filter=all&page=1`,
      );
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      onClick={handleTableItemClick}
      className={classnames(styles.mailchimp_accounts_table_item, {
        [styles.mailchimp_accounts_table_item__without_hover]: isStats,
      })}
      data-cy='mailchimp-account-item'
    >
      <div className={styles.mailchimp_accounts_table_item__left_side}>
        <If condition={isLoading}>
          <Loader size='sm' extraStyles={extraStyles.loader} />
        </If>

        <If condition={!isLoading}>
          <img
            className={classnames(styles.mailchimp_accounts_table_item__logo, {
              [styles.mailchimp_accounts_table_item__logo__small]:
                !withRightIcon,
              [styles.mailchimp_accounts_table_item__logo__without_border]:
                iconWithoutBorder,
            })}
            src={profileUrl || images.mailchimpIcon}
          />
        </If>

        <p
          className={styles.mailchimp_accounts_table_item__account_name}
          style={{ fontWeight: !withRightIcon ? 400 : undefined }}
          data-cy='data-url-stat'
        >
          {text}
        </p>

        <If condition={hasError}>
          <p style={extraStyles.error} data-cy='mc-accounts-error'>
            Error occured!
          </p>
        </If>
      </div>

      <If condition={withRightIcon}>
        <img
          className={styles.mailchimp_accounts_table_item__edit_button}
          src={images.editPencilIcon}
          alt='Edit button'
          data-cy='edit-button'
          onClick={e => {
            if (isStats) return;

            e.stopPropagation();
            navigate(`/accounts/${accountId}`);
          }}
        />
      </If>
    </div>
  );
};

const extraStyles = {
  loader: { width: 28, height: 28, marginRight: 2 },
  error: { color: 'red' },
};
