import React, { Dispatch, FC, SetStateAction } from 'react';
import { ExtraStyles, SaveAs } from 'src/utils/types/general/types';
import { DataUrlForm } from '../DataUrlForm';
import { DataUrlRadioGroup } from '../DataUrlRadioGroup/DataUrlRadioGroup';
import { SmartLinkCreatingBlock } from '../SmartLinkCreatingBlock';
import { StepWithDescription } from '../StepWithDescription/StepWithDescription';
import { FlexContainer, Input } from '../common';
import * as images from '../../assets/index';

const inputValuePlaceholder = {
  mergeTag: 'Enter value',
  group: 'Horror',
  tag: 'Test',
};

const inputValueLabel = {
  mergeTag: 'Tag Value',
  group: 'Group Name',
  tag: 'Tag',
};

type Props = {
  groupOrLabel: string;
  mergeTag: string;
  data: { value: string; destinationUrl: string };
  saveAs: SaveAs;
  handleMergeTagChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleGroupOrLabelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSaveAs: Dispatch<SetStateAction<'mergeTag' | 'group' | 'tag'>>;
};

export const SmartLinkMailchimpData: FC<Props> = ({
  groupOrLabel,
  mergeTag,
  data,
  saveAs,
  handleMergeTagChange,
  handleGroupOrLabelChange,
  handleDataChange,
  setSaveAs,
}) => {
  return (
    <SmartLinkCreatingBlock>
      <StepWithDescription title='Step 1.'>
        What data do you want to add to Contacts?
      </StepWithDescription>

      <FlexContainer gap={20}>
        <DataUrlRadioGroup
          selectedDataUrlType={saveAs}
          onSelectDataUrl={setSaveAs}
        />

        <FlexContainer>
          <DataUrlForm
            type={saveAs}
            groupOrLabel={groupOrLabel}
            mergeTag={mergeTag}
            onGroupOrLableChange={handleGroupOrLabelChange}
            onMergeTagChange={handleMergeTagChange}
          />

          <Input
            value={data.value}
            onChange={handleDataChange}
            name='value'
            placeholder={inputValuePlaceholder[saveAs]}
            label={inputValueLabel[saveAs]}
            icon={images.mailchimpIcon}
            labelExtraStyles={extraStyles.label}
            extraStyles={extraStyles.input}
          />
        </FlexContainer>
      </FlexContainer>
    </SmartLinkCreatingBlock>
  );
};

const extraStyles: ExtraStyles = {
  input: {
    width: '220px',
  },
  label: {
    fontSize: '14px',
  },
};
