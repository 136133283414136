import { useEffect, useState } from 'react';
import { espClient } from 'src/api';
import { accountStatsActions } from 'src/redux/slice/accountStats';
import { useAppDispatch } from 'src/redux/store';
import { AccountStats } from 'src/utils/types/ESP';
import { FilterByEnum } from 'src/utils/types/general/enums';

type Props = {
  accountId: string;
  audienceId: string;
  filter: FilterByEnum;
  page: number;
  items: number;
};

let cachedAccountStats: Record<string, AccountStats> = {};

export const useAccountStats = ({
  accountId,
  audienceId,
  filter = FilterByEnum.ALL,
  page = 1,
  items = 25,
}: Props) => {
  const [accountStats, setAccountStats] = useState<AccountStats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchAccountStats = async () => {
      setIsLoading(true);

      try {
        const url = `${accountId}/${audienceId}?filter=${filter}&page=${page}&items=${items}`;

        if (cachedAccountStats[url]) {
          const data = cachedAccountStats[url];

          setAccountStats(data);
          dispatch(accountStatsActions.update(data));
          setHasError(false);
          setIsLoading(false);

          return;
        }

        const { data } = await espClient.getAccountActions(url);

        dispatch(accountStatsActions.update(data));

        cachedAccountStats[url] = data;

        setAccountStats(data);
        setHasError(false);
      } catch {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountStats();
  }, [accountId, audienceId, filter, page, items]);

  useEffect(() => {
    return () => {
      cachedAccountStats = {};
    };
  }, []);

  return { accountStats, isLoading, hasError };
};
