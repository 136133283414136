import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { applyImageBorberRadius } from 'src/utils/helpers/editor/applyImageBorberRadius';
import { CustomFabricObject } from 'src/utils/types/Editor';

export const useRadiusChanges = (
  activeObject: CustomFabricObject,
  setRadius: Dispatch<SetStateAction<string>> | undefined,
) => {
  const handleRadiusChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value && setRadius) {
      setRadius('');
      applyRadius(0);

      return;
    }

    const value = Number(event.target.value);

    if (setRadius) {
      setRadius(value.toFixed(0));
    }

    applyRadius(value);
  };

  const applyRadius = (value: number) => {
    let maxRadius = activeObject?.height / 2;

    if (activeObject?.type === 'surveyResult') {
      maxRadius = activeObject?.variant.includes('bar')
        ? activeObject?.sizeY / 2
        : activeObject?.sizeX / 2;

      activeObject.set({
        borderRadius: maxRadius < value ? maxRadius : value,
      });
      activeObject.rerenderGroup();

      return;
    }

    if (activeObject.type === 'image') {
      if (!value) {
        activeObject.set({
          clipPath: undefined,
          borderRadius: 0,
        });

        return;
      }

      activeObject.set({
        clipPath: applyImageBorberRadius(
          activeObject,
          maxRadius < value ? maxRadius : value,
        ),
        borderRadius: maxRadius < value ? maxRadius : value,
      });
    }
  };

  const handleRadiusInputBlur = () => {
    if (setRadius && activeObject.type === 'surveyResult') {
      setRadius(String(activeObject.borderRadius));
    }
  };

  return {
    handleRadiusChange,
    handleRadiusInputBlur,
  };
};
