import React from 'react';
import { useFabricJSEditor } from 'fabricjs-react';

import { SurveysEditorContainer } from '../../components/EditorContainer';
import { SurveysPageHeader } from '../../components/EditorPageHeader';
import { EmbedSurvey, SaveSurvey } from '../../components/SurveyDialogs';

import { Loader } from '../../components/common/Loader/Loader';
import { If } from '../../components/common/If/If';

import { ExtraStyles } from '../../utils/types/general/types';
import { CustomCanvasType } from '../../utils/types/Editor';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { useModal } from 'src/utils/hooks/useModal';

import {
  useGetSurveyDataFromServer,
  useHandleDuplicatedSurvey,
  useHandleEmbed,
  useHandleSave,
  useHandleSaveBeforeEmebd,
  useIsCahnged,
} from './actions';

import styles from './SurveyEditorPage.module.scss';

export const SurveysEditorPage: React.FC = () => {
  const {
    isOpen: isOpenSaveBeforeEmbed,
    openModal: openSaveBeforeEmbed,
    closeModal: closeSaveBeforeEmbed,
  } = useModal();

  const { editor, onReady } = useFabricJSEditor();

  const { isLoadingSurvey } = useGetSurveyDataFromServer(editor);
  const { isLoading: isLoadDuplicate } = useHandleDuplicatedSurvey(
    editor?.canvas as CustomCanvasType,
  );
  const survey = useDistinctSelector('survey');

  const { handleSave, isLoading, isOpen, closeModal, openModal } =
    useHandleSave(editor);
  const {
    handleEmbed,
    isOpenEmbedCode,
    openEmbedCodeModal,
    closeEmbedCodeModal,
  } = useHandleEmbed(openSaveBeforeEmbed);
  const { handleSaveBeforeEmbed } = useHandleSaveBeforeEmebd(
    handleSave,
    openEmbedCodeModal,
    closeSaveBeforeEmbed,
  );

  const hasChanged = useIsCahnged(editor?.canvas as CustomCanvasType, isOpen);

  return (
    <div className={styles.page_container}>
      <If condition={isLoading}>
        <div style={extraStyles.saveBackdrop} />
      </If>

      <If condition={isLoadingSurvey || isLoadDuplicate}>
        <Loader fullScreen backdropStyles={extraStyles.editorLoaderBackdrop} />
      </If>

      <SurveysPageHeader
        canvas={editor?.canvas as CustomCanvasType}
        openSaveSurveyModal={openModal}
        onEmbedCodeModalOpen={handleEmbed}
      />

      <SurveysEditorContainer editor={editor} onReady={onReady} />

      <SaveSurvey
        isLoading={isLoading}
        isOpen={isOpen}
        hasChanged={hasChanged}
        onClose={closeModal}
        saveSurvey={handleSave}
      />

      <SaveSurvey
        isLoading={isLoading}
        isOpen={isOpenSaveBeforeEmbed}
        onClose={closeSaveBeforeEmbed}
        saveSurvey={handleSaveBeforeEmbed}
      />

      <EmbedSurvey
        isOpen={isOpenEmbedCode}
        closeModal={closeEmbedCodeModal}
        surveyId={survey.id}
      />
    </div>
  );
};

const extraStyles: ExtraStyles = {
  editorLoaderBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: 10000,
  },
  saveBackdrop: {
    zIndex: 10000,
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    backgroundColor: 'transparent',
  },
};
