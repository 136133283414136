import { useEffect } from 'react';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType, VoteType } from 'src/utils/types/Editor';

export const useChartUpdate = (canvas: CustomCanvasType, votes: VoteType[]) => {
  const { currentCanvasId, isLoadingObjects } = useDistinctSelector('editor');

  useEffect(() => {
    if (!canvas || isLoadingObjects) {
      return;
    }

    canvas.getObjects().forEach(object => {
      if (object.type !== 'surveyResult') {
        return;
      }

      object.generateChartWithData(
        votes.map(vote => ({
          count: Number(vote.count),
          vote: vote.vote,
        })),
      );
    });
  }, [canvas, safeStringify(votes), currentCanvasId, isLoadingObjects]);
};
