import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Id, toast } from 'react-toastify';
import sha256 from 'sha256';
import { imageClient } from 'src/api';
import { editorActions } from 'src/redux/slice/editor';
import { useAppDispatch } from 'src/redux/store';
import { addImageToLs } from 'src/utils/helpers/addImageToLS';
import { ClickableArea } from 'src/utils/helpers/editor/customClasses/ClickableArea';
import { CustomImage } from 'src/utils/helpers/editor/customClasses/CustomImage';
import { CustomTextbox } from 'src/utils/helpers/editor/customClasses/CustomTextBox';
import { SurveyResult } from 'src/utils/helpers/editor/customClasses/SurveyResult';
import { deleteEmptyImages } from 'src/utils/helpers/editor/deleteEmptyImages';
import { getInnerCanvas } from 'src/utils/helpers/editor/getInnerCanvas';
import { getSurveyCanvas } from 'src/utils/helpers/editor/getSurveyCanvas';
import { findImageInLSByHash } from 'src/utils/helpers/findImageInLSByHash';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';

export const useAddObject = (
  canvas: CustomCanvasType,
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void,
  onSetActiveObjects: Dispatch<SetStateAction<CustomFabricObject[]>>,
  onSetActiveObject: Dispatch<SetStateAction<CustomFabricObject | null>>,
) => {
  const survey = useDistinctSelector('survey');
  const { showPreview } = useDistinctSelector('editor');
  const dispatch = useAppDispatch();

  const addText = () => {
    if (showPreview) {
      return;
    }

    deleteEmptyImages(canvas);

    const innerCanvas = getInnerCanvas(canvas);

    if (!innerCanvas) {
      return;
    }

    const text = new CustomTextbox('Default text', {
      top: 40 + innerCanvas.top,
      left: 40 + innerCanvas.left,
    });

    canvas.add(text);
    canvas.setActiveObject(text);
    canvas.requestRenderAll();

    onGetCanvasObjects();
    onSetActiveObjects([]);
    onSetActiveObject(text);
  };

  const addImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (showPreview) {
      return;
    }

    e.preventDefault();

    const files = e.target.files as unknown as File[];

    if (files.length < 1) {
      return;
    }

    dispatch(editorActions.update({ isLoadingImage: true }));

    const name = files[0]?.name;
    const size = files[0]?.size / (1024 * 1024);

    if (size > 3) {
      toast.error('The image size should not exceed 3MB');
      dispatch(editorActions.update({ isLoadingImage: false }));

      return;
    }

    const reader = new FileReader();

    const id = toast.loading('Loading image...');

    reader.onload = async event => {
      const result = event.target?.result as string;
      const image = findImageInLSByHash(result);

      if (image) {
        await addImageToCanvas(image);
        updateToast(id, 'Image loaded successfully!', 'success');
        dispatch(editorActions.update({ isLoadingImage: false }));

        e.target.value = '';

        return;
      }

      try {
        const { data: tempImage } = await imageClient.getByHash(sha256(result));

        if (tempImage) {
          await addImageToCanvas(tempImage);
          addImageToLs(result, tempImage);
          updateToast(id, 'Image loaded successfully!', 'success');

          return;
        }

        const { data: imageUrl } = await imageClient.upload({
          image: result,
          fileName: name,
          fileFolder: 'surveys/canvas-images',
        });

        await addImageToCanvas(imageUrl);
        addImageToLs(result, imageUrl);
        updateToast(id, 'Image loaded successfully!', 'success');
      } catch {
        updateToast(id, 'Error while loading image!', 'error');
      } finally {
        dispatch(editorActions.update({ isLoadingImage: false }));
        e.target.value = '';
      }
    };

    function loadImage(src: string) {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    }

    async function addImageToCanvas(src: string) {
      const img = await loadImage(src);

      const canvasRect = getInnerCanvas(canvas);

      const image = new CustomImage(img, {
        fileName: name,
        fileSize: size,
        left: 40 + canvasRect.left,
        top: 40 + canvasRect.top,
      });

      canvas.add(image);
      canvas.setActiveObject(image);
      canvas.renderAll();
      onSetActiveObject(image);
    }

    function updateToast(
      toastId: Id,
      message: string,
      type: 'success' | 'error',
    ) {
      toast.update(toastId, {
        autoClose: 2000,
        render: message,
        type: type,
        isLoading: false,
      });
    }

    reader.readAsDataURL(files[0]);
  };

  const addClickableArea = () => {
    if (showPreview) {
      return;
    }

    canvas.discardActiveObject();

    const innerCanvas = getInnerCanvas(canvas);

    if (!innerCanvas) {
      return;
    }

    const clickableArea = new ClickableArea({
      top: innerCanvas.top + 40,
      left: innerCanvas.left + 40,
    });

    clickableArea.set({
      value: '',
      saveResultAs: survey.saveResultAs,
    });

    canvas.add(clickableArea);
    canvas.setActiveObject(clickableArea);
    canvas.renderAll();

    onGetCanvasObjects();
    onSetActiveObjects([]);
    onSetActiveObject(clickableArea);
  };

  const addSurveyResult = async () => {
    if (showPreview) {
      return;
    }

    const surveyResult = new SurveyResult(
      canvas,
      [],
      (await getSurveyCanvas()).objects.filter(
        el => el.type === 'clickableArea',
      ),
    );

    canvas.add(surveyResult);
    canvas.setActiveObject(surveyResult);
    canvas.renderAll();

    onGetCanvasObjects();
    onSetActiveObjects([]);
    onSetActiveObject(surveyResult);
  };

  return {
    addText,
    addImage,
    addClickableArea,
    addSurveyResult,
  };
};
