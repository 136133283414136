import { toast } from 'react-toastify';

export const isValidTitle = (value: string) => {
  const trimmedValue = value.trim();

  if (!trimmedValue) {
    toast.error('Survey title cannot be empty!');

    return false;
  }

  if (trimmedValue.length < 4) {
    toast.error('Survey title too short!');

    return false;
  }

  if (trimmedValue.length > 100) {
    toast.error('Survey title too long!');

    return false;
  }

  return true;
};
