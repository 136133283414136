import { useState, useEffect } from 'react';
import { applyFontStyles } from 'src/utils/helpers/editor/applyFontStyles';
import { getFontStyles } from 'src/utils/helpers/editor/getFontStyles';
import { CustomTextBoxType } from 'src/utils/types/Editor';

export const useObjectFontStyles = (activeObject: CustomTextBoxType) => {
  const [fontStyles, setFontStyles] = useState<string[]>(
    getFontStyles(activeObject),
  );

  useEffect(() => {
    applyFontStyles(fontStyles, activeObject);
  }, [fontStyles.length]);

  const handleFontStyleClick = (value: string) => {
    if (fontStyles.includes(value)) {
      const filteredFontStyles = fontStyles.filter(
        fontStyle => fontStyle !== value,
      );

      setFontStyles(filteredFontStyles);

      return;
    }

    setFontStyles(prev => [...prev, value]);
  };

  return {
    fontStyles,
    setFontStyles,
    handleFontStyleClick,
  };
};
