/* eslint-disable @typescript-eslint/indent */
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { SurveyDialogsTopInfo } from 'src/components/SurveyDialogsTopInfo';
import { Modal } from 'src/components/common/Modal/Modal';
import { surveyClient } from '../../../api';
import { showErrorMessage } from '../../../utils/helpers/errorHandler';
import { accountStatsActions } from 'src/redux/slice/accountStats';
import { AccountActionTypes } from 'src/utils/types/Mailchimp';
import { useAppDispatch } from 'src/redux/store';

interface Props {
  surveyId: number;
  title: string;
  saveResultAs: string;
  isOpen: boolean;
  onCloseModal: () => void;
}

export const DeleteSurvey: FC<Props> = ({
  surveyId,
  title,
  saveResultAs,
  isOpen,
  onCloseModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await surveyClient.remove(surveyId);

      dispatch(
        accountStatsActions.removeItem({
          type: AccountActionTypes.SURVEY,
          id: surveyId,
        }),
      );

      toast.success(`Survey "${title}" successfully deleted!`);
      onCloseModal();
    } catch (error: unknown) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      type='confirmation'
      onConfirm={handleDelete}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onCloseModal}
      confirmationButtonText='Delete Survey'
      title='Delete Survey'
    >
      <SurveyDialogsTopInfo
        surveyTitle={title}
        surveySaveResultAs={saveResultAs}
      />

      <p style={{ fontSize: 12, lineHeight: '20px', margin: '20px 0' }}>
        NOTE: <br />
        If you have already embedded the Survey in your email campaign, the
        Survey will be replaced with a 1 x 1 pixel transparent image. If you
        have already embedded the Survey Results into your email campaign or
        webpage, the Survey results will be replaced with a 1 x 1 pixel
        transparent image.
      </p>
    </Modal>
  );
};
