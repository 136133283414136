import { useEffect, useState } from 'react';
import { getSurveyCanvas } from 'src/utils/helpers/editor/getSurveyCanvas';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import {
  ClickableAreaType,
  CustomFabricObject,
  VoteType,
} from 'src/utils/types/Editor';

export const useVotes = () => {
  const [votes, setVotes] = useState<VoteType[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { objects }: { objects: CustomFabricObject[] } =
          await getSurveyCanvas();

        const clickableAreas = objects.filter(
          obj => obj.type === 'clickableArea',
        ) as ClickableAreaType[];

        setVotes(
          clickableAreas.map(area => ({
            objectId: area.objectId,
            vote: area.value,
            count: '',
          })),
        );
      } catch (error) {
        showErrorMessage(error);
      }
    })();
  }, []);

  return {
    votes,
    setVotes,
  };
};
