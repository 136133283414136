import React, { CSSProperties, FC } from 'react';
import styles from './TextButton.module.scss';
import { useNavigate } from 'react-router-dom';

interface Props {
  text: string;
  url?: string;
  onCustomClick?: () => void;
  extraStyles?: CSSProperties;
}

export const TextButton: FC<Props> = ({
  text,
  url,
  onCustomClick,
  extraStyles,
}) => {
  const navigate = useNavigate();

  return (
    <span
      style={extraStyles}
      className={styles.text_link}
      onClick={url ? () => navigate(url) : onCustomClick}
    >
      {text}
    </span>
  );
};
