/* eslint-disable @typescript-eslint/indent */
import {
  DuplicatedSurveyReturnType,
  SaveSurveyProps,
  SaveSurveyReturnType,
  Survey,
  SurveyCanavsesCreationProps,
  SurveyCanvases,
  SurveyCreationProps,
  SurveyDuplicateProps,
  SurveyDynamicObjectCreationProps,
  SurveyOption,
  SurveyOptionCreationProps,
  SurveyResultCreationProps,
  SurveyWithCanvases,
} from '../utils/types/Survey';
import { Response } from '../utils/types/general/api';
import { createClient } from './client';

type RequestSurveyId = number | string;

const surveyAxiosAPI = createClient('survey');

export const getById = async (surveyId: RequestSurveyId): Response<Survey> => {
  return surveyAxiosAPI.get(`/${surveyId}`);
};

export const getSurveyOptions = async (
  surveyId: RequestSurveyId,
): Response<SurveyOption[]> => {
  return surveyAxiosAPI.get(`/options/${surveyId}`);
};

export const getSurveyCanvases = async (
  surveyId: RequestSurveyId,
): Response<SurveyCanvases> => {
  return surveyAxiosAPI.get(`/canvases/${surveyId}`);
};

export const getSurveyWithCanvases = async (
  surveyId: RequestSurveyId,
): Response<SurveyWithCanvases> => {
  return surveyAxiosAPI.get(`/canvases-and-surveyinfo/${surveyId}`);
};

export const create = async (
  accountId: string,
  body: SurveyCreationProps,
): Response<Survey> => {
  return surveyAxiosAPI.post(`/create/${accountId}`, body);
};

export const createCanvases = async (
  surveyId: RequestSurveyId,
  body?: SurveyCanavsesCreationProps,
): Response<SurveyCanvases> => {
  return surveyAxiosAPI.post(`/create/canvases/${surveyId}`, body);
};

export const update = async (
  surveyId: RequestSurveyId,
  audienceId: string,
  body: Partial<Survey>,
): Response<Survey> => {
  return surveyAxiosAPI.patch(`/update/${audienceId}/${surveyId}`, body);
};

export const updateCanvases = async (
  surveyId: RequestSurveyId,
  body?: SurveyCanavsesCreationProps,
): Response<SurveyCanvases> => {
  return surveyAxiosAPI.patch(`/update/canvases/${surveyId}`, body);
};

export const duplicate = async (
  body: SurveyDuplicateProps,
): DuplicatedSurveyReturnType => {
  return surveyAxiosAPI.post(`/duplicate`, body);
};

export const deleteSurveyOptions = async (
  surveyId: RequestSurveyId,
  optionClickableAreasIds: string[],
): Response<void> => {
  return surveyAxiosAPI.delete(`/survey-option/${surveyId}`, {
    data: { optionClickableAreasIds },
  });
};

export const remove = async (surveyId: RequestSurveyId): Response<void> => {
  return surveyAxiosAPI.delete(`/${surveyId}`);
};

export const removeCharts = async (
  surveyId: RequestSurveyId,
): Response<void> => {
  return surveyAxiosAPI.delete(`/charts/${surveyId}`);
};

export const removeDynamicTexts = async (
  surveyId: RequestSurveyId,
): Response<void> => {
  return surveyAxiosAPI.delete(`/dynamic-texts/${surveyId}`);
};

export const removeDynamicObjects = async (
  surveyId: RequestSurveyId,
): Response<void> => {
  return surveyAxiosAPI.delete(`/dynamic-objects/${surveyId}`);
};

export const reset = async (
  surveyId: RequestSurveyId,
  audienceId: string,
  accountId: string,
): Response<void> => {
  return surveyAxiosAPI.post(
    `/reset/${surveyId}/${audienceId}?account_id=${accountId}`,
  );
};

export const saveSurveyOption = async (
  body: SurveyOptionCreationProps,
): Response<SurveyOption> => {
  return surveyAxiosAPI.post('/save/survey-option', body);
};

export const saveSurvey = async (
  body: SaveSurveyProps,
): Response<SaveSurveyReturnType> => {
  return surveyAxiosAPI.post('/save', body);
};

export const saveSurveyResult = async (
  body: SurveyResultCreationProps,
): Response<void> => {
  return surveyAxiosAPI.post('/save/survey-results', body);
};

export const saveDynamicObjects = async (
  body: SurveyDynamicObjectCreationProps[],
): Response<void> => {
  return surveyAxiosAPI.post('/save/dynamic-objects', body);
};
