import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { supportClient } from '../../api';
import { getSuccesSupportSendMessage } from '../../utils/helpers/getSuccesSupportSendMessage';
import { isValidMessage } from '../../utils/helpers/isValidString';
import { SupportMessageType } from '../../utils/types/User';
import { Button } from '../common/Button/Button';
import { If } from '../common/If/If';
import { Radio } from '../common/Radio/Radio';
import styles from './SupportPageForm.module.scss';

export const SupportPageForm = () => {
  const [selectedMessageType, setSelectedMessageType] =
    useState<SupportMessageType>('question');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSelectFormInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedMessageType(event.target.value as SupportMessageType);
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setMessage(event.target.value);
  };

  const handleMessageSend = async () => {
    if (!isValidMessage(selectedMessageType)) {
      toast.error('Please, select a message type.');

      return;
    }

    if (!isValidMessage(message, { min: 4 })) {
      toast.error('Minimum message length must be at least 4 characters!');

      return;
    }

    try {
      setIsLoading(true);

      await supportClient.support(message, selectedMessageType);

      toast.success(getSuccesSupportSendMessage(selectedMessageType), {
        autoClose: 5000,
      });

      setHasError(false);
      setSelectedMessageType('question');
      setMessage('');
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const placeholder = useMemo(() => {
    switch (selectedMessageType) {
      case 'question':
        return 'Ask your question here';
      case 'bug':
        return 'Describe your bug here with steps to reproduce the bug';
      case 'suggestion':
        return 'Write your suggestion here';
      case 'other':
        return 'Hi!';
    }
  }, [selectedMessageType]);

  return (
    <div className={styles.support_page_form__container}>
      <div className={styles.support_page_form__radios_wrapper}>
        <Radio
          labelText='I have a question!'
          value='question'
          currentValue={selectedMessageType}
          onChange={handleSelectFormInput}
          name='support-form'
        />

        <Radio
          labelText='I have a suggestion!'
          value='suggestion'
          currentValue={selectedMessageType}
          onChange={handleSelectFormInput}
          name='support-form'
        />

        <Radio
          labelText='I found a bug!'
          value='bug'
          currentValue={selectedMessageType}
          onChange={handleSelectFormInput}
          name='support-form'
        />

        <Radio
          labelText='Just saying hi!'
          value='other'
          currentValue={selectedMessageType}
          onChange={handleSelectFormInput}
          name='support-form'
        />
      </div>

      <textarea
        value={message}
        onChange={handleMessageChange}
        placeholder={placeholder}
        className={styles.support_page_form__textarea}
        cols={30}
        rows={10}
      />

      <Button
        text='Send'
        onClick={() => handleMessageSend()}
        type='outlined-blue'
        extraStyles={{ minWidth: '100%' }}
        isLoading={isLoading}
      />

      <If condition={hasError}>
        <p style={{ color: 'red', fontSize: 12 }}>Error occured!</p>
      </If>
    </div>
  );
};
