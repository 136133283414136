/* eslint-disable @typescript-eslint/indent */
import React, { FC, useState } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { saveToEditorLocalbase } from 'src/api/local/editor';
import { DataUrlForm } from 'src/components/DataUrlForm';
import { getValidSaveAsData } from 'src/utils/helpers/editor/getValidSaveAsData';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { useMailchimpAccountsDropdown } from 'src/utils/hooks/useMailchimpAccountDropdown';
import { useSaveResultAsData } from 'src/utils/hooks/useSaveResultAsData';
import { ButtonClicksTrackingInfoDataURLType } from 'src/utils/types/CreatedDataURLStats';
import {
  AccountStatsSurveyInfo,
  MergeFieldsType,
} from 'src/utils/types/Mailchimp';
import { surveyClient } from '../../../api';
import * as images from '../../../assets/index';
import { editorActions } from '../../../redux/slice/editor';
import { useGetMailchimpAccountsQuery } from '../../../redux/slice/mailchimpApi';
import { surveyActions } from '../../../redux/slice/survey';
import { useAppDispatch } from '../../../redux/store';
import { constants } from '../../../utils/constants';
import { showErrorMessage } from '../../../utils/helpers/errorHandler';
import { isValidTitle } from '../../../utils/helpers/isValidTitle';
import { safeParse } from '../../../utils/helpers/safeParse';
import { useDistinctSelector } from '../../../utils/hooks/useDistinctSelector';
import { SurveyDuplicateProps } from '../../../utils/types/Survey';
import { Dropdown } from '../../common/Dropdown/Dropdown';
import { FlexContainer } from '../../common/FlexContainer/FlexContainer';
import { Input } from '../../common/Input/Input';
import { Modal } from '../../common/Modal/Modal';
import { Switch } from '../../common/Switch/Switch';
import { useIsDisableConfirm } from './actions/useIsDisableConfirm';
import { useSurveyData } from './actions/useSurveyData';

interface Props {
  item: AccountStatsSurveyInfo;
  isOpen: boolean;
  onCloseModal: () => void;
}

export const DuplicateSurvey: FC<Props> = ({ isOpen, item, onCloseModal }) => {
  const [, setAccountId] = useLocalStorage('accountId');

  const navigate = useNavigate();
  const { accountId = '' } = useParams<Params>();

  const { currentAudience: audienceName } = useDistinctSelector('mailchimp');

  const { title } = item;
  const [isLoading, setIsLoading] = useState(false);
  const { data: mailchimpAccounts } = useGetMailchimpAccountsQuery();

  const dispatch = useAppDispatch();

  const {
    tempTitle,
    tempCollectAdditionalData,
    currentSaveResultsOption,
    currentMergeTagType,
    setTempTitle,
    setTempCollectAdditionalData,
    setCurrentSaveResultsOption,
    setCurrentMergeTagType,
  } = useSurveyData(item);

  const { accounts, currentAudience, setCurrentAudience } =
    useMailchimpAccountsDropdown();

  const { groupOrLabel, mergeTag, handleGroupInput, handleMergeTagInput } =
    useSaveResultAsData(item);

  const handleDuplicateSurvey = async () => {
    if (!isValidTitle(tempTitle)) {
      return;
    }

    setIsLoading(true);

    const saveResultAsData = getValidSaveAsData(
      currentSaveResultsOption.labelValue as ButtonClicksTrackingInfoDataURLType,
      groupOrLabel,
      mergeTag,
    );

    try {
      const cuurentAccount = mailchimpAccounts?.find(
        acc => acc.audienceId === (currentAudience.labelValue as string),
      );
      const tempAccountId = cuurentAccount?.accountId || accountId;

      const body: SurveyDuplicateProps = {
        title: tempTitle.trim(),
        audienceName,
        audienceId: currentAudience.labelValue as string,
        embededCode: item.embededCode,
        imageUrl: item.imageUrl,
        width: item.width,
        height: item.height,
        collectAdditionalData: tempCollectAdditionalData,
        surveyId: item?.id,
        espAccountId: tempAccountId,
        mergeTagType: currentMergeTagType.labelValue as MergeFieldsType,
        isKeepResultsSame: item.isKeepResultsSame,
        ...saveResultAsData,
      };

      const { data: duplicatedSurvey } = await surveyClient.duplicate(body);

      await surveyClient.createCanvases(duplicatedSurvey.id, {
        surveyCanvasObjects: duplicatedSurvey.surveyCanvas,
        resultCanvasesObjects: duplicatedSurvey.resultsCanvases,
      });

      dispatch(
        surveyActions.update({
          ...duplicatedSurvey,
          ...saveResultAsData,
          initSurveyObjectsJSON: [],
          initResultsObjectsJSON: [],
        }),
      );

      saveToEditorLocalbase('resultsCanvases', {
        canvases: safeParse(duplicatedSurvey.resultsCanvases),
      });

      saveToEditorLocalbase('surveyCanvas', {
        objects: safeParse(duplicatedSurvey.surveyCanvas),
      });

      if (safeParse(duplicatedSurvey.resultsCanvases).length > 1) {
        dispatch(
          editorActions.update({
            keepResultsSame: duplicatedSurvey.isKeepResultsSame,
            currentCanvasId: 'fallback',
            prevCanvasId: 'fallback',
          }),
        );
      }

      setAccountId(tempAccountId);

      navigate(
        `/new/survey/${tempAccountId}/${currentAudience.labelValue}/${duplicatedSurvey.id}?duplicated=true`,
      );
    } catch (error: unknown) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const isDisabledConfirm = useIsDisableConfirm({
    tempTitle,
    title,
    currentSaveResultsOption,
    mergeTag,
    groupOrLabel,
  });

  return (
    <Modal
      type='confirmation'
      onConfirm={handleDuplicateSurvey}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onCloseModal}
      confirmationButtonText='Duplicate Survey'
      title='Duplicate Survey'
      disableConfirm={
        isDisabledConfirm && currentAudience.labelValue === item.audienceId
      }
    >
      <FlexContainer style={extraStyles.wrapper}>
        <Input
          value={tempTitle}
          onChange={e => setTempTitle(e.target.value)}
          icon={images.surveyIconDark}
        />

        <Dropdown
          options={accounts}
          value={currentAudience}
          onChange={setCurrentAudience}
          extraStyles={extraStyles.dropdown}
          extraStylesSubContainer={extraStyles.dropdown}
          extraStylesMainContainer={extraStyles.dropdownLabel}
          extraStylesList={extraStyles.dropdownLabel}
          icon={currentAudience?.icon || ''}
        />

        <Dropdown
          options={constants.surveySaveResultsOptions}
          value={currentSaveResultsOption}
          onChange={setCurrentSaveResultsOption}
          extraStyles={extraStyles.dropdown}
          extraStylesSubContainer={extraStyles.dropdown}
          extraStylesMainContainer={extraStyles.dropdownLabel}
          extraStylesList={extraStyles.dropdownLabel}
          icon={images.mailchimpIcon}
        />

        <DataUrlForm
          type={
            currentSaveResultsOption.labelValue as ButtonClicksTrackingInfoDataURLType
          }
          groupOrLabel={groupOrLabel}
          mergeTag={mergeTag}
          mergeTagType={currentMergeTagType}
          onGroupOrLableChange={handleGroupInput}
          onMergeTagChange={handleMergeTagInput}
          onMergeTagTypeChange={setCurrentMergeTagType}
        />

        <Switch
          value={tempCollectAdditionalData}
          onChange={setTempCollectAdditionalData}
          label='Collect Additional Data (optional)'
          size={9}
          extraLabelStyles={extraStyles.switchLabel}
        />

        <p style={extraStyles.info}>
          Collect additional data such as Timezone, Country, State, City,
          Devices and more when your email campaign is open and when a clickable
          area is clicked. This additional data will be added to your Contacts
          in your Mailchimp Account.
        </p>
      </FlexContainer>
    </Modal>
  );
};

const extraStyles = {
  dropdown: { width: '100%' },
  dropdownLabel: { maxWidth: '100%' },
  switchLabel: { fontWeight: 400 },
  info: { fontSize: 12, marginLeft: 40, lineHeight: '20px' },
  wrapper: { marginBottom: 20 },
};
