import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { useSaveSurvey } from 'src/utils/hooks/useSaveSurvey';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useHandleDuplicatedSurvey = (canvas: CustomCanvasType) => {
  const { isLoadingObjects } = useDistinctSelector('editor');
  const survey = useDistinctSelector('survey');

  const { saveSurvey, isLoading } = useSaveSurvey(survey);

  const [serachParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!canvas || isLoadingObjects || !canvas.getObjects().length) {
      return;
    }

    (async () => {
      const isDuplicated = serachParams.get('duplicated');

      if (isDuplicated) {
        serachParams.delete('duplicated');

        setSearchParams(serachParams, { replace: true });

        await saveSurvey(canvas, { disableSuccessMessage: true });
      }
    })();
  }, [serachParams.size, canvas, isLoadingObjects]);

  return { isLoading };
};
