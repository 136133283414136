import { useEffect } from 'react';
import { userClient } from 'src/api';

export const useLastAppUsed = () => {
  useEffect(() => {
    (async () => {
      try {
        const lastSaved = localStorage.getItem('lastAppUse');

        if (!lastSaved) {
          await userClient.saveLastAppUsed();
          localStorage.setItem('lastAppUse', new Date().toISOString());

          return;
        }

        const todaysDate = new Date();
        const lastAppUseDate = new Date(lastSaved);

        if (
          lastAppUseDate.getDate() !== todaysDate.getDate() ||
          lastAppUseDate.getMonth() !== todaysDate.getMonth() ||
          lastAppUseDate.getFullYear() !== todaysDate.getFullYear()
        ) {
          await userClient.saveLastAppUsed();
          localStorage.setItem('lastAppUse', new Date().toISOString());
        }
      } catch {}
    })();
  }, []);
};
