import { constants } from 'src/utils/constants';

export const generateSurveyResultsCode = (
  surveyId: string | number,
  withParentQuery: boolean = false,
) => {
  const getQueryParamCode = withParentQuery
    ? `const getQueryParam = (e) => {
    let t = new URLSearchParams(window.location.search);
    let k = new URLSearchParams(window.parent.location.search);
    return t.get(e) || k.get(e);
  }`
    : `const getQueryParam = (e) => {
    let t = new URLSearchParams(window.location.search);
    return t.get(e);
  }`;

  return `<div id="imageContainer" style="display: flex; margin: 0 auto; width: 100%; height: 100%; justify-content: center;"></div>

  <script>
  (function() {
    ${getQueryParamCode},
      optionId = getQueryParam("optionId");
      email = getQueryParam("email");
    fetch(\`${constants.apiHost}/survey/result/${surveyId}?optionId=\${optionId || null}\\&email=\${email || null}\`)
      .then((e) => {
        if (200 !== e?.status) throw Error();
        return e.json();
      })
      .then((e) => {
        let t = e,
        r = document.createElement("img");
        r.src = t;
        r.style.maxWidth = "100%";
        r.style.maxHeight = "100%";
        let n = document.getElementById("imageContainer");
        n.appendChild(r);
      })
      .catch((e) => {
        console.error("Unexpected error occured!");
      });
  })();
  </script>`;
};
