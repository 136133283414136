import React, { FC } from 'react';
import { Modal } from '../../common/Modal/Modal';
import { FlexContainer } from '../../common/FlexContainer/FlexContainer';
import { SurveyDialogsTopInfo } from '../../SurveyDialogsTopInfo';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  hasChanged?: boolean;
  onClose: () => void;
  isLoading: boolean;
  saveSurvey: () => void;
  onConfirm?: () => void;
}

export const SaveSurvey: FC<Props> = ({
  isOpen,
  hasChanged = true,
  onClose,
  isLoading,
  saveSurvey,
  onConfirm,
}) => {
  const handleConfirm = () => {
    if (!hasChanged) {
      toast.success('Changes saved!');
      onClose();

      return;
    }

    if (onConfirm) {
      onConfirm();

      return;
    }

    saveSurvey();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      confirmationButtonText='Save Survey'
      isLoading={isLoading}
      onConfirm={handleConfirm}
      type='confirmation'
      title='Save Survey'
    >
      <FlexContainer>
        <SurveyDialogsTopInfo />

        <p style={{ fontSize: 12, lineHeight: '20px', marginBottom: 20 }}>
          NOTE: <br />
          If you have already embedded the Survey into your email campaign, any
          changes made to this file may break the functionality of the embedded
          Survey. If you’ve updated this file, please paste the updated embed
          code into your email campaign.
        </p>
      </FlexContainer>
    </Modal>
  );
};
