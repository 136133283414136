export const splitAndUppercase = (value: string) => {
  let result = '';
  const splittedValue = value.split('');

  for (const letter of splittedValue) {
    if (/[A-Z]/.test(letter)) {
      result += ` ${letter}`;

      continue;
    }

    result += letter;
  }

  return result.charAt(0).toUpperCase() + result.slice(1);
};
