import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { splitAndUppercase } from '../../utils/helpers/splitAndUppercase';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { useObjectWithOmittedFields } from '../../utils/hooks/useObjectWithOmittedFields';
import { Divider } from '../common/Divider/Divider';
import styles from './UserAccountDetails.module.scss';

export const UserAccountDetails = () => {
  const user = useDistinctSelector('user');
  const profileFileds = useObjectWithOmittedFields(
    user,
    'id',
    'verifiedEmail',
    'publicProfileURL',
    'createdAt',
  );

  return (
    <section className={styles.user_account_details}>
      <Divider />

      {Object.entries(profileFileds).map(profileField => (
        <React.Fragment key={profileField[0]}>
          <div className={styles.user_account_details__data_container}>
            <div className={styles.user_account_details__data_wrapper}>
              <p
                className={classnames(
                  styles.user_account_details__data,
                  styles.user_account_details__left_data,
                )}
              >
                {splitAndUppercase(profileField[0])}
              </p>
            </div>

            <div className={styles.user_account_details__data_wrapper}>
              <p
                className={classnames(
                  styles.user_account_details__data,
                  styles.user_account_details__right_data,
                )}
              >
                {profileField[1] as ReactNode}
              </p>
            </div>
          </div>

          <Divider />
        </React.Fragment>
      ))}
    </section>
  );
};
