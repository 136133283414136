import { Dispatch, SetStateAction, useEffect } from 'react';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { CustomCanvasType, CustomFabricObject } from 'src/utils/types/Editor';

export const useKeyDownListener = (
  canvas: CustomCanvasType,
  activeObjects: CustomFabricObject[],
  activeObject: CustomFabricObject | null,
  onGetCanvasObjects: (tempCanvas?: any) => void,
  onSetActiveObject: Dispatch<SetStateAction<CustomFabricObject | null>>,
) => {
  useEffect(() => {
    if (!canvas) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;
      const isInputField =
        activeElement?.tagName === 'INPUT' ||
        activeElement?.tagName === 'TEXTAREA';

      if ((!activeObject && !activeObjects?.length) || isInputField) {
        return;
      }

      switch (event.key.toLowerCase()) {
        case 'arrowleft':
          if (activeObject) {
            (activeObject as any).set({
              left: event.shiftKey
                ? (activeObject.left || 0) - 1
                : (activeObject.left || 0) - 10,
            });
          }
          break;

        case 'arrowup':
          if (activeObject) {
            (activeObject as any).set({
              top: event.shiftKey
                ? (activeObject.top || 0) - 1
                : (activeObject.top || 0) - 10,
            });
          }
          break;

        case 'arrowright':
          if (activeObject) {
            (activeObject as any).set({
              left: event.shiftKey
                ? (activeObject.left || 0) + 1
                : (activeObject.left || 0) + 10,
            });
          }
          break;

        case 'arrowdown':
          if (activeObject) {
            (activeObject as any).set({
              top: event.shiftKey
                ? (activeObject.top || 0) + 1
                : (activeObject.top || 0) + 10,
            });
          }
          break;

        case 'escape':
          canvas?.discardActiveObject();
          onSetActiveObject(null);
          break;

        case 'backspace':
        case 'delete':
          if (isInputField) {
            return;
          }

          if (activeObjects?.length) {
            const currentActiveObjects = canvas.getActiveObjects();

            canvas.discardActiveObject();

            currentActiveObjects?.forEach((object: any) => {
              canvas.remove(object);
            });

            canvas.requestRenderAll();
            onGetCanvasObjects();
          }

          if (activeObject) {
            canvas.discardActiveObject();
            canvas.remove(activeObject);

            canvas.requestRenderAll();
            onGetCanvasObjects();
          }

          break;
        default:
      }

      canvas.requestRenderAll();
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    activeObject?.objectId,
    safeStringify(activeObjects),
    onGetCanvasObjects,
  ]);
};
