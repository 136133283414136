import { fabric } from 'fabric';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const convertToObjects = (
  canvas: CustomCanvasType,
  objects?: any[],
): Record<string, any>[] => {
  if (objects?.length) {
    const copy = objects.map(obj => convertToObject(obj));

    return copy;
  }

  const canvasObjects = canvas?.getObjects?.() || [];
  const copy = canvasObjects.map(obj => convertToObject(obj));

  return copy;
};

export const convertToObject = (obj: any) => {
  const copy = fabric.util.object.clone(obj);

  if (copy.clipPath) {
    delete copy.clipPath;
  }

  return copy.toObject([
    'CLIP_POSITIONS',
    'angle',
    'borderRadius',
    'canvasId',
    'canvasType',
    'clickableAreas',
    'clipPosition',
    'cropHeight',
    'cropWidth',
    'cropX',
    'cropY',
    'destinationUrl',
    'defaultColor',
    'disableCrop',
    'enablePrefilledFields',
    'fallback',
    'fileSize',
    'fileName',
    'fill',
    'holeRadius',
    'imageFit',
    'initialHeight',
    'initialPatternScaleX',
    'initialPatternScaleY',
    'initialScaleX',
    'initialScaleY',
    'initialWidth',
    'isLocked',
    'isValidDestinationUrl',
    'isValidUrlData',
    'keepResultsSame',
    'objectId',
    'originX',
    'originY',
    'order',
    'ownMatrix',
    'patternOffsetX',
    'patternOffsetY',
    'pieSize',
    'prefilledFields',
    'proportionalResize',
    'radius',
    'saveFallback',
    'saveResultAs',
    'scaleX',
    'scaleY',
    'sizeX',
    'sizeY',
    'src',
    'type',
    'urlData',
    'variant',
    'value',
  ]);
};
