import React, { ChangeEvent, FC } from 'react';
import { FlexContainer, If, Input } from 'src/components/common';

import { handlePropSet } from 'src/utils/helpers/editor/handlePropSet';
import { CustomFabricObject } from 'src/utils/types/Editor';

import styles from './index.module.scss';

type Props = {
  activeObject: CustomFabricObject;
  x: string;
  y: string;
  width: string;
  height: string;
  setWidth: React.Dispatch<React.SetStateAction<string>>;
  setHeight: React.Dispatch<React.SetStateAction<string>>;
  handleCoordsSet?: (
    event: ChangeEvent<HTMLInputElement>,
    field: string,
  ) => void;
  handleImageSizeChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => void;
  withBottomRow?: boolean;
};

export const CoordsAndSizeBlock: FC<Props> = ({
  activeObject,
  x,
  y,
  width,
  height,
  withBottomRow = true,
  setWidth,
  setHeight,
  handleCoordsSet,
  handleImageSizeChange,
}) => {
  return (
    <FlexContainer>
      <FlexContainer direction='row' align='center'>
        <Input
          value={x}
          onChange={event =>
            handleCoordsSet ? handleCoordsSet(event, 'left') : () => {}
          }
          rightIcon='X'
          variant='editor-sidebar-input'
          className={styles.input}
        />

        <Input
          value={y}
          onChange={event =>
            handleCoordsSet ? handleCoordsSet(event, 'top') : () => {}
          }
          rightIcon='Y'
          variant='editor-sidebar-input'
          className={styles.input}
        />
      </FlexContainer>

      <If condition={withBottomRow}>
        <FlexContainer direction='row' align='center'>
          <Input
            value={width}
            onChange={event =>
              handleImageSizeChange
                ? handleImageSizeChange(event, 'width')
                : handlePropSet(activeObject, event, 'width', setWidth)
            }
            variant='editor-sidebar-input'
            rightIcon='W'
            className={styles.input}
          />

          <Input
            value={height}
            onChange={event =>
              handleImageSizeChange
                ? handleImageSizeChange(event, 'height')
                : handlePropSet(activeObject, event, 'height', setHeight)
            }
            variant='editor-sidebar-input'
            rightIcon='H'
            className={styles.input}
            disabled={activeObject.type === 'text'}
          />
        </FlexContainer>
      </If>
    </FlexContainer>
  );
};
