/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { smartLinkClient } from 'src/api';
import { MailchimpAccountInfo } from 'src/redux/slice/mailchimpApi';
import { smartLinkActions } from 'src/redux/slice/smartLink';
import { useAppDispatch } from 'src/redux/store';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';
import { AccountStatsSmartLinkInfo } from 'src/utils/types/Mailchimp';
import { SmartLinkCreationType } from 'src/utils/types/SmartLink';

export const useDuplicateSmartLink = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const duplicateSmartLink = async (
    item: AccountStatsSmartLinkInfo,
    account: MailchimpAccountInfo | null,
  ) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      if (!account) {
        throw new Error('Account ID missing!');
      }

      const body = getSmartLinkCreationProps(item, account);
      const { data: smartLink } = await smartLinkClient.create(body);

      dispatch(smartLinkActions.update(smartLink));

      navigate(`/new/data-url/${body.accountId}/${body.audienceId}`);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, duplicateSmartLink };
};

const getSmartLinkCreationProps = (
  item: AccountStatsSmartLinkInfo,
  account: MailchimpAccountInfo,
): SmartLinkCreationType => {
  const { id, count, updatedAt, tracks, type, userESPLinkId, ...rest } = item;

  return {
    ...rest,
    accountId: account.accountId,
    audienceId: account.audienceId,
  };
};
