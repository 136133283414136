import React from 'react';
import styles from './SupportPage.module.scss';
import { Divider } from '../../components/common/Divider/Divider';
import { PageBodyHeader } from '../../components/PageBodyHeader/PageBodyHeader';
import { SupportPageForm } from '../../components/SupportPageForm/SupportPageForm';

export const SupportPage = () => {
  return (
    <div className={styles.support_page}>
      <div className={styles.support_page__wrapper}>
        <section className={styles.support_page__header}>
          <PageBodyHeader page='support' />
        </section>

        <Divider />

        <section className={styles.support_page__form}>
          <SupportPageForm />
        </section>
      </div>
    </div>
  );
};
