import {
  ButtonClicksTrackingInfoCreationProps,
  ButtonClicksTrackingInfoType,
} from '../utils/types/CreatedDataURLStats';
import { Response } from '../utils/types/general/api';
import { createClient } from './client';

const trackingAxiosAPI = createClient('button');

export const saveButtonClick = async (
  body: ButtonClicksTrackingInfoCreationProps,
): Response<ButtonClicksTrackingInfoType> => {
  return trackingAxiosAPI.post('/save-click', body);
};
