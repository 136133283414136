/* eslint-disable @typescript-eslint/indent */
import classnames from 'classnames';
import React, {
  CSSProperties,
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { constants } from '../../../utils/constants';
import { useUpdateEffect } from '../../../utils/hooks/useUpdateEffect';
import { CanvasProps } from '../../../utils/types/Editor';
import { Divider } from '../Divider/Divider';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import { If } from '../If/If';
import styles from './ColorPicker.module.scss';
import { useExpandedHex } from './actions/useExpandedHex';
import { useTempValues } from './actions/useTempValues';
import { useAppDispatch } from 'src/redux/store';
import { recentColorsActions } from 'src/redux/slice/recentColors';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { useRecentColors } from './actions/useRecentColors';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color: string;
  onColorChange:
    | React.Dispatch<React.SetStateAction<CanvasProps>>
    | React.Dispatch<React.SetStateAction<string>>;
  label?: string;
  extraStyles?: CSSProperties;
  extraStylesInput?: CSSProperties;
}

export const ColorPicker: FC<Props> = ({
  color,
  onColorChange,
  extraStyles,
  extraStylesInput,
  label,
  ...props
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const { tempColor, tempTransparency, setTempColor, setTempTransparency } =
    useTempValues(color);
  const recentColors = useDistinctSelector('recentColors');

  const { expandHexColor } = useExpandedHex();
  const dispatch = useAppDispatch();

  useRecentColors(showPicker);

  // const surveyId = useParams().surveyId || '';

  // const { data: colors, isLoading } = useGetRecentColorsQuery(Number(surveyId));
  // const [uploadRecentColors] = useUploadRecentColorsMutation();

  // useEffect(() => {
  //   setRecentColors(colors || []);
  // }, [isLoading]);

  // useUpdateEffect(() => {
  //   if (!recentColors.length) {
  //     return;
  //   }

  //   const interval = setInterval(() => {
  //     const isChanged =
  //       safeStringify(
  //         colors?.sort((color1, color2) => color1.localeCompare(color2)),
  //       ) !==
  //       safeStringify(
  //         recentColors?.sort((color1, color2) => color1.localeCompare(color2)),
  //       );

  //     if (isChanged)
  //       uploadRecentColors({
  //         surveyId: Number(surveyId),
  //         colors: recentColors,
  //       });
  //   }, 60000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [safeStringify(recentColors)]);

  useUpdateEffect(() => {
    const { hex: tempHex, opacity: tempOpacity } = rgbaToHexAndOpacity(color);

    dispatch(recentColorsActions.update(color));
    setTempColor(tempHex);
    setTempTransparency((tempOpacity * 100).toFixed(0));
    setShowPicker(false);
  }, [color]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node) &&
        !(event.target as Element).className.includes(
          'ColorPicker_color_picker',
        )
      ) {
        handleInputBlur();
        setShowPicker(false);
      }
    };

    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker, tempColor, tempTransparency]);

  const handleColorChange = (selectedColor: string) => {
    onColorChange((prev: any) => {
      if (typeof prev === 'string') {
        return selectedColor;
      }

      return { ...prev, backgroundColor: selectedColor };
    });
  };

  const toggleColorPicker = () => {
    setShowPicker(prev => !prev);
  };

  const handleColorInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setTempColor(rgbaToHexAndOpacity(value).hex);
  };

  const handleTransparencyInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!value) {
      setTempTransparency('');

      return;
    }

    const numValue = Number(value);

    if (isNaN(numValue) || numValue < 0 || numValue > 100) {
      return;
    }

    setTempTransparency(value);
  };

  const handleInputBlur = () => {
    if (!isValidHex(tempColor)) {
      toast.error('Incorrect hex value!');

      return;
    }

    const hexWithoutHash = tempColor.replace('#', '');
    const sixDecimalsHex =
      hexWithoutHash.length === 3
        ? expandHexColor(hexWithoutHash)
        : hexWithoutHash;

    const numValue = Number(tempTransparency);

    if (isNaN(numValue) || numValue < 0 || numValue > 100) {
      toast.error('Incorrect opacity value!');

      return;
    }

    const colorRGBA = hexToRGBA(sixDecimalsHex, numValue / 100);

    handleColorChange(colorRGBA);
    dispatch(recentColorsActions.update(color));
  };

  return (
    <FlexContainer direction='row' align='center' className={styles.wrapper}>
      <FlexContainer className={styles.color_picker} {...props}>
        <FlexContainer
          className={styles.color_picker__container}
          onClick={toggleColorPicker}
          direction='row'
          align='center'
          gap={8}
          containerRef={inputRef}
          extraStyles={extraStylesInput}
        >
          <div
            className={styles.color_picker__color}
            style={{
              backgroundColor: color,
            }}
          />

          <p className={styles.color_picker__color_title}>
            {rgbaToHexAndOpacity(color).hex}
          </p>
        </FlexContainer>

        <If condition={showPicker}>
          <FlexContainer
            className={styles.color_picker__picker}
            containerRef={colorPickerRef}
            extraStyles={extraStyles}
            gap={0}
          >
            <FlexContainer
              className={styles.color_picker__block_wrapper}
              direction='row'
              align='center'
            >
              <div
                className={classnames(
                  styles.color_picker__item,
                  styles.color_picker__item__big,
                )}
                style={{
                  backgroundColor: isValidHex(tempColor)
                    ? hexToRGBA(tempColor, Number(tempTransparency) / 100)
                    : color,
                }}
              />

              <FlexContainer
                direction='row'
                justify='center'
                align='center'
                gap={0}
                className={styles.color_picker__input_container}
              >
                <input
                  type='text'
                  value={tempColor}
                  onChange={handleColorInput}
                  onBlur={handleInputBlur}
                  className={classnames(
                    styles.color_picker__input,
                    styles.color_picker__input__left,
                  )}
                />

                <input
                  type='text'
                  value={tempTransparency}
                  onChange={handleTransparencyInput}
                  onBlur={handleInputBlur}
                  className={classnames(
                    styles.color_picker__input,
                    styles.color_picker__input__right,
                  )}
                />
              </FlexContainer>
            </FlexContainer>

            <Divider />

            <FlexContainer className={styles.color_picker__block_wrapper}>
              <p className={styles.color_picker__text}>Recent:</p>

              <FlexContainer direction='row' align='center' gap={5}>
                {recentColors.map(colorValue => (
                  <div
                    className={styles.color_picker__item}
                    style={{ backgroundColor: colorValue }}
                    key={colorValue}
                    onClick={() => handleColorChange(colorValue)}
                  />
                ))}
              </FlexContainer>
            </FlexContainer>

            <Divider />

            <FlexContainer className={styles.color_picker__block_wrapper}>
              <FlexContainer gap={5}>
                {constants.defaultColorPickerColors.map(row => (
                  <FlexContainer
                    key={row[0]}
                    direction='row'
                    align='center'
                    gap={5}
                  >
                    {row.map(colorValue => (
                      <div
                        key={colorValue}
                        className={classnames(styles.color_picker__item, {
                          [styles.color_picker__item__selected]:
                            colorValue === color,
                        })}
                        style={{ backgroundColor: colorValue }}
                        onClick={() => {
                          handleColorChange(colorValue);
                        }}
                      />
                    ))}
                  </FlexContainer>
                ))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </If>
      </FlexContainer>

      <If condition={label}>
        <p className={styles.color_picker__label}>{label}</p>
      </If>
    </FlexContainer>
  );
};

export function hexToRGBA(hex: string, opacity: number) {
  let validHex = hex.replace(/[#]/, '');

  if (validHex.length < 6) {
    validHex = validHex
      .split('')
      .map(char => char + char)
      .join('');
  }

  const bigint = parseInt(validHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function rgbaToHexAndOpacity(rgbaColor: string) {
  if (rgbaColor.startsWith('#')) {
    return { hex: rgbaColor, opacity: 1 };
  }

  const rgbaValues = rgbaColor.match(
    /rgba\((\d+),\s*(\d+),\s*(\d+),\s*([.\d]+)\)/,
  );

  if (!rgbaValues || rgbaValues.length !== 5) {
    return { hex: rgbaColor, opacity: 1 };
  }

  const r = parseInt(rgbaValues[1]);
  const g = parseInt(rgbaValues[2]);
  const b = parseInt(rgbaValues[3]);
  const opacity = parseFloat(rgbaValues[4]);

  const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)}`;

  return { hex, opacity };
}

export function isValidHex(hex: string): boolean {
  if (
    /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(
      hex.startsWith('#') ? hex : `#${hex}`,
    )
  ) {
    return true;
  }

  return false;
}
