/* eslint-disable @typescript-eslint/indent */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Input } from '../common';

import { ButtonClicksTrackingInfoDataURLType } from 'src/utils/types/CreatedDataURLStats';
import * as images from '../../assets/index';
import { DropdownOption } from 'src/utils/types/general/types';
import { MergeFieldsType } from 'src/utils/types/Mailchimp';

type InputChangeType = (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  type: ButtonClicksTrackingInfoDataURLType;
  groupOrLabel: string;
  mergeTag: string;
  mergeTagType?: DropdownOption<MergeFieldsType>;
  onGroupOrLableChange: InputChangeType;
  onMergeTagChange: InputChangeType;
  onMergeTagTypeChange?: Dispatch<
    SetStateAction<DropdownOption<MergeFieldsType>>
  >;
  hasGroupOrLabelError?: boolean;
  hasMergeTagError?: boolean;
};

export const DataUrlForm: FC<Props> = ({
  type,
  groupOrLabel,
  mergeTag,
  onGroupOrLableChange,
  onMergeTagChange,
  hasGroupOrLabelError = false,
  hasMergeTagError = false,
}) => {
  switch (type) {
    case 'mergeTag':
      return (
        <>
          <Input
            value={groupOrLabel}
            onChange={onGroupOrLableChange}
            placeholder={'Untitled Merge Tag'}
            icon={images.mailchimpIcon}
            dataCy='merge-label-in'
            label='Merge Tag Label'
            extraStyles={extraStyles.input}
            labelExtraStyles={extraStyles.label}
            hasError={hasGroupOrLabelError}
          />

          <Input
            value={mergeTag}
            onChange={onMergeTagChange}
            placeholder={'MERGE10'}
            icon={images.mailchimpIcon}
            dataCy='merge-tag-in'
            label='Merge Tag'
            extraStyles={extraStyles.input}
            labelExtraStyles={extraStyles.label}
            hasError={hasMergeTagError}
          />
        </>
      );
    case 'group':
      return (
        <Input
          value={groupOrLabel}
          onChange={onGroupOrLableChange}
          placeholder={'Genre'}
          icon={images.mailchimpIcon}
          labelExtraStyles={extraStyles.label}
          dataCy='merge-tag-in'
          extraStyles={extraStyles.input}
          label='Group'
          hasError={hasGroupOrLabelError}
        />
      );
    case 'tag':
      return <></>;
  }
};

const extraStyles = {
  input: {
    width: '220px',
  },
  label: {
    fontSize: '14px',
  },
};
