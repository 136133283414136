import React, { FC, JSX, ReactNode } from 'react';

interface Props {
  condition: any;
  children: ReactNode | JSX.Element | JSX.Element[];
}

export const If: FC<Props> = ({ condition, children }) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};
