import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userClient } from 'src/api';
import { showErrorMessage } from 'src/utils/helpers/errorHandler';

export const useSignout = (onClosePopup: () => void) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      setIsLoading(true);

      await userClient.signout();

      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('accountId');

      onClosePopup();

      navigate('/signin?status=signedout');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { handleSignOut, isLoading };
};
