/* eslint-disable @typescript-eslint/indent */
import { ChangeEvent, useState } from 'react';
import { constants } from 'src/utils/constants';
import { MergeFieldsType } from 'src/utils/types/Mailchimp';
import { DropdownOption } from 'src/utils/types/general/types';

const regex = /^[a-zA-Z0-9]+$/;

export const useSurveyData = () => {
  const [groupOrLabel, setGroupOrLabel] = useState('');
  const [mergeTag, setMergeTag] = useState('');
  const [mergeTagType, setMergeTagType] = useState<
    DropdownOption<MergeFieldsType>
  >(constants.mergeFieldTypes[0] as DropdownOption<MergeFieldsType>);

  const handleMergeTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value && !regex.test(value)) {
      return;
    }

    setMergeTag(value);
  };

  const handleGroupOrLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setGroupOrLabel(value);
  };

  return {
    groupOrLabel,
    mergeTag,
    mergeTagType,
    handleMergeTagChange,
    handleGroupOrLabelChange,
    setMergeTagType,
  };
};
