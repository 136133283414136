import React, { FC } from 'react';
import { toast } from 'react-toastify';
import {
  DeleteSmartLink,
  DuplicateSmartLink,
} from 'src/components/SmartLinkDialogs';
import { DeleteSurvey, EmbedSurvey } from 'src/components/SurveyDialogs';
import { DuplicateSurvey } from 'src/components/SurveyDialogs/DuplicateSurvey';
import { constants } from 'src/utils/constants';
import { generateSurveyResultsCode } from 'src/utils/helpers/editor/generateSurveyResultsCode';
import {
  AccountActionTypes,
  AccountStatsInfo,
} from 'src/utils/types/Mailchimp';
import { ModalActionEnum } from 'src/utils/types/general/enums';

type Props = {
  modalType: ModalActionEnum;
  item: AccountStatsInfo;
  handleModalClose: () => void;
};

export const AccountActionModal: FC<Props> = ({
  modalType,
  item,
  handleModalClose,
}) => {
  if (item.type === AccountActionTypes.SURVEY) {
    switch (modalType) {
      case ModalActionEnum.DELETE:
        return (
          <DeleteSurvey
            surveyId={item?.id}
            title={item?.title}
            saveResultAs={item?.saveResultAs}
            isOpen={true}
            onCloseModal={handleModalClose}
          />
        );

      case ModalActionEnum.DUPLICATE:
        return (
          <DuplicateSurvey
            item={item}
            isOpen={true}
            onCloseModal={handleModalClose}
          />
        );

      case ModalActionEnum.EMBED:
        return (
          <EmbedSurvey
            isOpen={true}
            closeModal={handleModalClose}
            tempSurveyCode={item?.embededCode}
            tempSurveyResultsCode={generateSurveyResultsCode(item?.id)}
            tempTitle={item.title}
            tempSaveAs={item.saveResultAs}
            isReduxPriority={false}
            surveyId={item?.id}
          />
        );

      default:
        return null;
    }
  } else {
    switch (modalType) {
      case ModalActionEnum.DELETE:
        return (
          <DeleteSmartLink
            isOpen={true}
            onCloseModal={handleModalClose}
            smartLinkId={item.id}
          />
        );

      case ModalActionEnum.DUPLICATE:
        return (
          <DuplicateSmartLink
            item={item}
            isOpen
            onCloseModal={handleModalClose}
          />
        );

      case ModalActionEnum.EMBED:
        (async () => {
          try {
            await navigator.clipboard.writeText(
              `${constants.apiHost}/smart-link/collect-click/*|EMAIL|*/${item.id}`,
            );
            toast.success('Smart link copied to clipboard');
          } catch {}
        })();

        return null;

      default:
        return null;
    }
  }
};
