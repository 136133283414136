type Options = {
  min?: number;
  max?: number;
};

export const isValidString = (
  value: string,
  regex: RegExp = /[a-zA-Z0-9 ]/g,
) => {
  const decodedValue = decodeURIComponent(value).trim();

  return decodedValue.replace(regex, '').length === 0;
};

export const isValidMessage = (value: string, options?: Options) => {
  const trimmedValue = value.trim();

  if (options?.max && trimmedValue.length > options.max) {
    return false;
  }

  if (options?.min && trimmedValue.length < options.min) {
    return false;
  }

  return trimmedValue.length !== 0;
};
