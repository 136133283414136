import classnames from 'classnames';
import React, { FC } from 'react';
import { editorActions } from '../../redux/slice/editor';
import { useAppDispatch } from '../../redux/store';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { If } from '../common/If/If';
import styles from './index.module.scss';

interface Props {
  clickableZone: any;
}

export const EditorResultsSectionItem: FC<Props> = ({ clickableZone }) => {
  const editorState = useDistinctSelector('editor');
  const dispatch = useAppDispatch();

  const handleCanvasClick = (canvasId: string) => {
    if (editorState.isLoadingObjects) {
      return;
    }
    
    dispatch(
      editorActions.update({
        prevCanvasId: editorState.currentCanvasId,
        currentCanvasId: canvasId,
      }),
    );
  };

  return (
    <FlexContainer
      className={classnames(styles.editor_results_section_item, {
        [styles.editor_results_section_item__selected]:
          clickableZone?.type === editorState.currentCanvasId ||
          clickableZone?.objectId === editorState.currentCanvasId,
      })}
      justify='center'
      align='center'
      onClick={() => handleCanvasClick(clickableZone?.objectId || 'fallback')}
      id='editor-right-sidebar-item'
    >
      <p className={styles.editor_results_section_item__title}>
        <If condition={clickableZone?.type !== 'fallback'}>
          {clickableZone?.value}
        </If>

        <If condition={clickableZone?.type === 'fallback'}>Fallback</If>
      </p>
    </FlexContainer>
  );
};
