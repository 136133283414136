import { useEffect, useState } from 'react';
import { CustomCanvasType } from 'src/utils/types/Editor';

export const useActiveObjects = (canvas: CustomCanvasType) => {
  const [activeObject, setActiveObject] = useState(canvas?.getActiveObject());
  const [activeObjects, setActiveObjects] = useState(
    canvas ? canvas.getActiveObjects() : [],
  );

  useEffect(() => {
    if (activeObjects.length === 1) {
      setActiveObjects([]);
      setActiveObject(activeObjects[0]);
    }
  }, [activeObjects]);

  return {
    activeObject,
    activeObjects,
    setActiveObject,
    setActiveObjects,
  };
};
