import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountStats } from 'src/utils/types/ESP';
import { AccountActionTypes } from 'src/utils/types/Mailchimp';

const initialState: AccountStats = {
  info: [],
  total: 0,
};

const accountStatsSlice = createSlice({
  name: 'accountStats',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<AccountStats>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    removeItem: (
      state,
      action: PayloadAction<{ type: AccountActionTypes; id: number }>,
    ) => {
      return {
        ...state,
        info: state.info.filter(item => {
          if (item.type !== action.payload.type) {
            return true;
          }

          return item.id !== action.payload.id;
        }),
      };
    },
  },
});

export default accountStatsSlice.reducer;
export const accountStatsActions = accountStatsSlice.actions;
