/* eslint-disable @typescript-eslint/indent */
import React, { FC } from 'react';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';
import { constants } from '../../utils/constants';
import { DropdownOption } from '../../utils/types/general/types';
import { Dropdown } from '../common/Dropdown/Dropdown';
import styles from './index.module.scss';
import { FilterByEnum } from 'src/utils/types/general/enums';

interface Props {
  sortOption: DropdownOption;
  onChangeSortOption: React.Dispatch<
    React.SetStateAction<DropdownOption<FilterByEnum>>
  >;
  itemsPerPage: DropdownOption;
  onChangeItemsPerPage: React.Dispatch<React.SetStateAction<DropdownOption>>;
}

export const MailchimpAccountsActionsTableFilterBar: FC<Props> = ({
  sortOption,
  onChangeSortOption,
  itemsPerPage,
  onChangeItemsPerPage,
}) => {
  return (
    <FlexContainer
      direction='row'
      justify='space-between'
      align='center'
      className={styles.mailchimp_accounts_actions_table_filter_bar}
    >
      <Dropdown
        options={constants.filterOptions}
        value={sortOption}
        onChange={onChangeSortOption}
        extraStyles={extraStyles.sortDropdown}
      />

      <Dropdown
        options={constants.itemsPerPageOptions}
        value={itemsPerPage}
        onChange={onChangeItemsPerPage}
        extraStyles={extraStyles.itemsPerPageDropdown}
      />
    </FlexContainer>
  );
};

const extraStyles = {
  sortDropdown: { width: 200 },
  itemsPerPageDropdown: { width: 120 },
};
