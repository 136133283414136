import { Survey } from '../../types/Survey';

export const getDataUrlWithoutValue = (survey: Survey) => {
  const saveAs = survey.saveResultAs;

  switch (saveAs) {
    case 'tag':
      return {
        action: 'add',
      };

    case 'group':
      return {
        action: 'add',
        group: survey.groupOrLabel,
      };

    case 'mergeTag':
      return {
        fieldType: 'text',
        fieldLabel: survey.groupOrLabel,
        mergeTag: survey.mergeTag,
      };
  }
};
