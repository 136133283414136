import React, { FC } from 'react';
import styles from './StepWithDescription.module.scss';

interface Props {
  title: string;
  children: any;
}

export const StepWithDescription: FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.step_with_description}>
      <h2 className={styles.step_with_description__title}>{title}</h2>

      <p className={styles.step_with_description__description}>{children}</p>
    </div>
  );
};
