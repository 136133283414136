/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { applyDrag } from '../../utils/helpers/applyDrag';
import { useDistinctSelector } from '../../utils/hooks/useDistinctSelector';
import {
  CustomCanvasType,
  CustomFabricObject,
  InnerCanvasType,
} from '../../utils/types/Editor';
import { SurveysEditorSidebarLayer } from '../EditorSidebarLayer';

interface Props {
  activeObject: CustomFabricObject | null;
  activeObjects: CustomFabricObject[];
  canvasObjects: (CustomFabricObject | InnerCanvasType)[];
  canvas: CustomCanvasType;
  onGetCanvasObjects: (canvas?: CustomCanvasType) => void;
  onSetActiveObject: React.Dispatch<
    React.SetStateAction<CustomFabricObject | null>
  >;
}

export const SurveysEditorLayers: FC<Props> = ({
  activeObject,
  activeObjects,
  canvas,
  canvasObjects,
  onGetCanvasObjects,
  onSetActiveObject,
}) => {
  const [reorderedObjects, setReorderedObjects] = useState(
    getOrderedObjects(canvasObjects),
  );
  const { currentCanvasId } = useDistinctSelector('editor');

  useEffect(() => {
    setReorderedObjects(getOrderedObjects(canvasObjects));
  }, [canvasObjects, currentCanvasId]);

  const renderReorderedObjects = (objects: CustomFabricObject[]) => {
    objects.forEach(object => {
      canvas.remove(object);
    });

    objects.forEach(object => {
      canvas.add(object);
    });

    if (activeObject) {
      canvas.setActiveObject(activeObject);
    }

    canvas.requestRenderAll();

    setReorderedObjects(objects);
    onGetCanvasObjects();
  };

  const handleDrag = (e: DropResult) => {
    const newRorderedObjects = applyDrag(reorderedObjects, e);
    const changedObjects = newRorderedObjects.map((obj, index) => {
      obj.order = index;

      return obj;
    });
    const sortedObjects = getOrderedObjects(changedObjects);

    return sortedObjects;
  };

  return (
    <Container
      dragHandleSelector='.column-drag-handle'
      animationDuration={500}
      onDrop={e => renderReorderedObjects(handleDrag(e))}
      render={ref => (
        <div ref={ref}>
          {reorderedObjects.map(object => (
            <Draggable
              key={object.objectId}
              render={() => (
                <div className='draggable-item'>
                  <SurveysEditorSidebarLayer
                    canvasObject={object as CustomFabricObject}
                    activeObject={activeObject}
                    activeObjects={activeObjects}
                    canvas={canvas}
                    onGetCanvasObjects={onGetCanvasObjects}
                    onSetActiveObject={onSetActiveObject}
                  />
                </div>
              )}
            />
          ))}
        </div>
      )}
    />
  );
};

const getOrderedObjects = (
  canvasObjects: (CustomFabricObject | InnerCanvasType)[],
) => {
  const filteredObjects = canvasObjects.filter(
    obj => obj.type !== 'canvas',
  ) as CustomFabricObject[];
  const orderedObjects = filteredObjects.sort(
    (obj1, obj2) => obj2.order - obj1.order,
  );

  return orderedObjects;
};
