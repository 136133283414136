import React, { CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userClient } from '../../api';
import * as images from '../../assets/index';
import { PageBodyHeader } from '../../components/PageBodyHeader/PageBodyHeader';
import { UserAccountDetails } from '../../components/UserAccountDetails/UserAccountDetails';
import { Button } from '../../components/common/Button/Button';
import { If } from '../../components/common/If/If';
import { Modal } from '../../components/common/Modal/Modal';
import { useModal } from '../../utils/hooks/useModal';
import styles from './UserAccountPage.module.scss';

const closeAccountButtonExtraStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 4,
  paddingTop: 8,
};

export const UserAccountPage = () => {
  const { isOpen, openModal, closeModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const handleCloseAccount = async () => {
    setIsLoading(true);

    try {
      await userClient.deleteAccount();

      window.localStorage.clear();

      navigate('/signin');
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <section className={styles.user_account_page__header}>
        <PageBodyHeader page='user' />
      </section>

      <section className={styles.user_account_page__body}>
        <UserAccountDetails />

        <If condition={hasError}>
          <p style={{ color: 'red' }}>
            Error occured when trying to delete the account!
          </p>
        </If>

        <Button
          icon={images.deleteIcon}
          text='Close Account'
          extraStyles={{
            ...closeAccountButtonExtraStyle,
          }}
          onClick={openModal}
          type='fill'
          bgColor='#F9FBFD'
        />
      </section>

      <Modal
        type='confirmation'
        isOpen={isOpen}
        onConfirm={handleCloseAccount}
        onClose={closeModal}
        isLoading={isLoading}
        title='Confirm delete account'
        confirmationButtonText='Yes, close account'
      >
        <div className={styles.user_account_page__modal}>
          <h4>
            All your Mailchimp Accounts will be unlinked. All your Data URLs
            will no longer work.
          </h4>

          <p>Are you sure you want to close this account?</p>
        </div>
      </Modal>
    </div>
  );
};
