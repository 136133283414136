import React, { FC } from 'react';
import styles from './Footer.module.scss';
import { FooterInfo } from '../FooterInfo/FooterInfo';
import { Link } from 'react-router-dom';
import * as images from '../../assets/index';

interface Props {
  handleCloseNavPopup: () => void;
}

export const Footer: FC<Props> = ({ handleCloseNavPopup }) => {
  return (
    <footer onClick={handleCloseNavPopup} className={styles.footer}>
      <FooterInfo type='dark' />

      <Link className={styles.footer_link} to='/support'>
        <div className={styles.footer_helpTextContainer}>
          <img
            src={images.questionmarkIcon}
            alt='Questionmark Icon'
            className={styles.footer_questionmark}
          />

          <p className={styles.footer_helpText}>Help</p>
        </div>
      </Link>
    </footer>
  );
};
