import React, { FC, useEffect, useState } from 'react';
import * as images from '../../../assets';
import { EditorDataURLFormProps } from '../../../utils/types/DataURL';
import { Input } from '../../common/Input/Input';

export const EditorTagForm: FC<EditorDataURLFormProps> = ({
  activeObject,
  focusedInput,
  onFocusedInput,
  onHandleInput,
  onInputBlur,
}) => {
  const [tag, setTag] = useState(activeObject.value);

  useEffect(() => {
    setTag(activeObject.value);
  }, [activeObject.objectId]);

  return (
    <Input
      value={tag}
      onChange={event => onHandleInput(event.target.value, setTag)}
      hasError={!activeObject.isValidUrlData}
      placeholder={'Enter tag'}
      icon={images.mailchimpIcon}
      extraStyles={{ maxWidth: 260 }}
      onBlur={() => onInputBlur(tag)}
      required
      label='Tag'
      onFocus={() => onFocusedInput('Tag')}
      focusedInput={focusedInput || ''}
      dataCy='tag-in'
      variant='editor-sidebar-input'
    />
  );
};
