export const useObjectWithOmittedFields = <T extends Record<string, any>>(
  obj: T,
  ...keys: (keyof T)[]
): Partial<T> => {
  const copy = { ...obj };

  for (const key of keys) {
    delete copy[key];
  }

  return copy;
};
