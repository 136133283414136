import React, { FC, useEffect, useState } from 'react';
import { getSmartFormCode } from 'src/redux/slice/smartForm';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { SmartFormEditorPage } from 'src/utils/types/SmartForm';
import { FlexContainer, If } from '../common';

import styles from './index.module.scss';

type Props = {
  pageType: SmartFormEditorPage;
};

export const SmartFormPreview: FC<Props> = ({ pageType }) => {
  const smartFormData = useDistinctSelector('smartForm');
  const [code, setCode] = useState('');

  useEffect(() => {
    const { code: generatedCode, script } = getSmartFormCode(smartFormData);

    setCode(generatedCode);

    let newScript = document.getElementById('myScript');

    if (!newScript) {
      newScript = document.createElement('script');
      newScript.id = 'myScript';
      document.body.appendChild(newScript);
    } else {
      newScript.remove();
      newScript = document.createElement('script');
      newScript.id = 'myScript';
      document.body.appendChild(newScript);
    }

    newScript.textContent = script;
  }, [safeStringify(smartFormData)]);

  return (
    <FlexContainer className={styles.smart_form_preview}>
      <If condition={pageType === 'form'}>
        <div dangerouslySetInnerHTML={{ __html: code }} />
      </If>

      <If condition={pageType === 'success-message'}>
        <FlexContainer>
          <p
            style={{
              color: smartFormData.successTitleColor,
            }}
          >
            {smartFormData.successTitle}
          </p>

          <p
            style={{
              color: smartFormData.successMessageColor,
            }}
          >
            {smartFormData.successMessage}
          </p>
        </FlexContainer>
      </If>
    </FlexContainer>
  );
};
