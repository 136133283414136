/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import * as images from '../../../assets';
import { EditorDataURLFormProps } from '../../../utils/types/DataURL';
import { DropdownOption } from '../../../utils/types/general/types';
import { Dropdown } from '../../common/Dropdown/Dropdown';
import { Input } from '../../common/Input/Input';
import { findDropdownOption } from 'src/utils/helpers/findDropdownOptions';
import { constants } from 'src/utils/constants';

const dum = { label: 'Text', labelValue: 'text' };

export const EditorMergeTagForm: FC<EditorDataURLFormProps> = ({
  activeObject,
  focusedInput,
  onHandleInput,
  onInputBlur,
  onFocusedInput,
}) => {
  const survey = useDistinctSelector('survey');

  const fieldLabel =
    survey.saveResultAs === 'mergeTag' ? survey.groupOrLabel || '' : '';
  const mergeTag =
    survey.saveResultAs === 'mergeTag' ? survey.mergeTag || '' : '';

  const [value, setValue] = useState(activeObject.value);
  const [mergeFieldType, setMergeFieldType] = useState<DropdownOption>(
    findDropdownOption(constants.mergeFieldTypes, survey.mergeTagType, dum),
  );

  useEffect(() => {
    setValue(activeObject.value);
  }, [activeObject.objectId]);

  return (
    <>
      <Input
        value={fieldLabel}
        onChange={() => {}}
        placeholder={'Untitled Merge Tag'}
        icon={images.mailchimpIcon}
        extraStyles={{ maxWidth: 190 }}
        required
        label='Field Label'
        onBlur={() => onInputBlur(fieldLabel)}
        onFocus={() => onFocusedInput('Field Label')}
        dataCy='merge-label-in'
        variant='editor-sidebar-input'
        disabled
      />

      <Dropdown
        value={mergeFieldType}
        onChange={setMergeFieldType}
        options={constants.mergeFieldTypes}
        icon={images.mailchimpIcon}
        label='Field Type'
        extraStyles={{ height: 30 }}
        extraStylesLabel={{ fontSize: 12 }}
        width={240}
        dataCy='merge-type'
        disabled
      />

      <Input
        value={mergeTag}
        onChange={() => {}}
        placeholder={'MERGE10'}
        icon={images.mailchimpIcon}
        extraStyles={{ maxWidth: 190 }}
        required
        label='Merge Tag'
        onBlur={() => onInputBlur(mergeTag, 'merge-tag')}
        onFocus={() => onFocusedInput('Merge Tag')}
        focusedInput={focusedInput || ''}
        dataCy='merge-tag-in'
        variant='editor-sidebar-input'
        disabled
      />

      <Input
        value={value}
        onChange={event => onHandleInput(event.target.value, setValue)}
        placeholder={'Enter value'}
        icon={images.mailchimpIcon}
        extraStyles={{ maxWidth: 190 }}
        required
        label='Tag Value'
        onBlur={() => onInputBlur(value)}
        onFocus={() => onFocusedInput('Tag Value')}
        hasError={!activeObject.isValidUrlData}
        dataCy='merge-value-in'
        variant='editor-sidebar-input'
      />
    </>
  );
};
