import { CustomCanvasType, InnerCanvasType } from '../../types/Editor';
import { CustomCanvas } from './customClasses/CustomCanvas';

export const getInnerCanvas = (canvas: CustomCanvasType): InnerCanvasType => {
  if (!canvas) {
    return new CustomCanvas();
  }

  const canvasRect = canvas
    ?.getObjects()
    .find(object => object.type === 'canvas') as InnerCanvasType;

  return canvasRect;
};
