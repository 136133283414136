import React from 'react';

import * as images from '../../assets';
import { constants } from '../../utils/constants';

import { FlexContainer } from '../common';
import styles from './index.module.scss';

export const LinkedinLoginButton = () => {
  return (
    <a href={`${constants.apiHost}/linkedin/oauth`}>
      <FlexContainer
        className={styles.button}
        direction='row'
        align='center'
        gap={40}
      >
        <img
          src={images.linkedinLogo}
          alt='Linkedin Logo'
          className={styles.button__logo}
        />

        <p className={styles.button__text}>Sign In with LinkedIn</p>
      </FlexContainer>
    </a>
  );
};
