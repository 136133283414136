/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from 'react';
import { scraperClient } from 'src/api';
import { safeStringify } from 'src/utils/helpers/safeStringify';
import { ClickableAreaType } from 'src/utils/types/Editor';
import { GoogleFormEntry } from 'src/utils/types/Scraper';
import { GooglePrefilledFormField } from 'src/utils/types/Survey';

export const usePrefilledFields = (
  formUrl: string,
  activeObject: ClickableAreaType,
) => {
  const [prefilledFields, setPrefieldFields] = useState<
    GooglePrefilledFormField[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const scrapTheForm = async () => {
    try {
      const { data } = await scraperClient.getGoogleFormEntrys(formUrl);
      const tempPrefilledFields = getPrefilledFieldsWithValue(
        data,
        activeObject.prefilledFields,
      );

      activeObject.set({
        prefilledFields: tempPrefilledFields,
      });

      setPrefieldFields(tempPrefilledFields);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!activeObject.prefilledFields.length) {
      scrapTheForm();
    } else {
      setPrefieldFields(activeObject.prefilledFields);
    }
  }, [formUrl, activeObject.objectId]);

  useEffect(() => {
    activeObject.set({
      prefilledFields,
    });
  }, [safeStringify(prefilledFields)]);

  return { isLoading, prefilledFields, setPrefieldFields, scrapTheForm };
};

const getPrefilledFieldsWithValue = (
  data: GoogleFormEntry[],
  oldPrefilledFields: GooglePrefilledFormField[],
): GooglePrefilledFormField[] => {
  return data.map(item => {
    const foundItem = oldPrefilledFields.find(
      field => field.entryId === item.entryId,
    );

    if (foundItem) {
      return { ...foundItem, ...item };
    }

    return { ...item, value: '' };
  });
};
