import { useModal } from 'src/utils/hooks/useModal';

export const useRenamedModals = () => {
  const {
    closeModal: closeUpdateModal,
    openModal: openUpdateModal,
    isOpen: isOpenUpdateModal,
  } = useModal();

  const {
    closeModal: closeSaveModal,
    openModal: openSaveModal,
    isOpen: isOpenSaveModal,
  } = useModal();

  return {
    isOpenSaveModal,
    isOpenUpdateModal,
    closeSaveModal,
    closeUpdateModal,
    openSaveModal,
    openUpdateModal,
  };
};
