import React from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { useModal } from '../../utils/hooks/useModal';
import { FlexContainer } from '../common/FlexContainer/FlexContainer';

import { constants } from '../../utils/constants';
import { CreateActionButton } from '../CreateActionButton';
import { CreateNewSurveyModal } from '../SurveyDialogs/CreateNewSurveyModal';

export const MailchimpAccountsActionsTableHeader = () => {
  const navigate = useNavigate();
  const { audienceId = '', accountId = '' } = useParams<Params>();
  const { isOpen, openModal, closeModal } = useModal();

  const handleClick = (type: string) => {
    switch (type) {
      case 'survey':
        openModal();

        return;

      case 'link':
        navigate(`/new/data-url/${accountId}/${audienceId}`);

        return;

      case 'form':
        navigate(`/new/form/${accountId}/${audienceId}`);
    }
  };

  return (
    <FlexContainer>
      <FlexContainer direction='row' justify='center' align='center' gap={20}>
        <FlexContainer
          direction='row'
          justify='center'
          extraStyles={extraStyles.wrapper}
          gap={20}
        >
          {constants.createActionButtons.map(button => (
            <CreateActionButton
              {...button}
              key={button.title}
              onClick={() => handleClick(button.type)}
            />
          ))}
        </FlexContainer>
      </FlexContainer>

      <CreateNewSurveyModal closeModal={closeModal} isOpen={isOpen} />
    </FlexContainer>
  );
};

const extraStyles = {
  wrapper: { padding: 20 },
};
