import React, { FC } from 'react';
import * as images from '../../../assets/index';
import { constants } from '../../../utils/constants';
import { ButtonClicksTrackingInfoDataURLType } from '../../../utils/types/CreatedDataURLStats';
import { CustomCanvasType } from '../../../utils/types/Editor';
import { useUpdateSurvey } from './actions/useUpdateSurvey';
import { useUpdatedValues } from './actions/useUpdatedValues';
import { DataUrlForm } from 'src/components/DataUrlForm';
import { useSaveResultAsData } from 'src/utils/hooks/useSaveResultAsData';
import {
  Modal,
  FlexContainer,
  Input,
  Dropdown,
  Switch,
} from 'src/components/common';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

interface Props {
  onCloseModal: () => void;
  isOpen: boolean;
  canvas: CustomCanvasType;
}

export const UpdateSurvey: FC<Props> = ({ onCloseModal, isOpen, canvas }) => {
  const survey = useDistinctSelector('survey');
  const { groupOrLabel, mergeTag, handleGroupInput, handleMergeTagInput } =
    useSaveResultAsData(survey);

  const {
    currentSaveResultsOption,
    currentTitle,
    currentCollectAdditionalData,
    currentMergeTagType,
    setCurrentSaveResultsOption,
    setCurrentTitle,
    setCurrentCollectAdditionalData,
    setCurrentMergeTagType,
    isDisabledConfirm,
  } = useUpdatedValues({
    newGroupOrLabel: groupOrLabel,
    newMergeTag: mergeTag,
    isOpen,
  });

  const { isLoading, updateSurvey } = useUpdateSurvey(
    onCloseModal,
    groupOrLabel,
    mergeTag,
  );

  const handleUpdateSurvey = async () => {
    const currentSaveAs =
      currentSaveResultsOption.labelValue as ButtonClicksTrackingInfoDataURLType;

    await updateSurvey(
      canvas,
      currentSaveAs,
      currentCollectAdditionalData,
      currentTitle,
      currentMergeTagType.labelValue,
    );
  };

  return (
    <Modal
      type='confirmation'
      onConfirm={handleUpdateSurvey}
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onCloseModal}
      confirmationButtonText='Save Changes'
      title='Survey Settings'
      disableConfirm={isDisabledConfirm}
    >
      <FlexContainer style={extraStyles.wrapper}>
        <Input
          value={currentTitle}
          onChange={e => setCurrentTitle(e.target.value)}
          icon={images.surveyIconDark}
        />

        <Dropdown
          options={constants.surveySaveResultsOptions}
          value={currentSaveResultsOption}
          onChange={setCurrentSaveResultsOption}
          extraStyles={extraStyles.fullWidth}
          extraStylesSubContainer={extraStyles.fullWidth}
          extraStylesMainContainer={extraStyles.maxFullWidth}
          extraStylesList={extraStyles.maxFullWidth}
          icon={images.mailchimpIcon}
        />

        <DataUrlForm
          type={
            currentSaveResultsOption.labelValue as ButtonClicksTrackingInfoDataURLType
          }
          mergeTagType={currentMergeTagType}
          groupOrLabel={groupOrLabel}
          mergeTag={mergeTag}
          onGroupOrLableChange={handleGroupInput}
          onMergeTagChange={handleMergeTagInput}
          onMergeTagTypeChange={setCurrentMergeTagType}
        />

        <Switch
          value={currentCollectAdditionalData}
          onChange={setCurrentCollectAdditionalData}
          label='Collect Additional Data (optional)'
          size={9}
          extraLabelStyles={extraStyles.switchLable}
        />

        <p style={extraStyles.note}>
          Collect additional data such as Timezone, Country, State, City,
          Devices and more when your email campaign is open and when a clickable
          area is clicked. This additional data will be added to your Contacts
          in your Mailchimp Account.
        </p>
      </FlexContainer>
    </Modal>
  );
};

const extraStyles = {
  switchLable: { fontWeight: 400 },
  note: { fontSize: 12, marginLeft: 40, lineHeight: '20px' },
  wrapper: { marginBottom: 20 },
  fullWidth: { width: '100%' },
  maxFullWidth: { maxWidth: '100%' },
};
