import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { CustomTextBoxType } from 'src/utils/types/Editor';

export const useObjectTextAlign = (
  activeObject: CustomTextBoxType,
): [string, Dispatch<SetStateAction<string>>] => {
  const [textAlign, setTextAlign] = useState(activeObject.textAlign || 'left');

  useEffect(() => {
    activeObject.set({ textAlign });
  }, [textAlign]);

  return [textAlign, setTextAlign];
};
