import React, { FC, useEffect, useState } from 'react';
import { CustomFabricObject } from 'src/utils/types/Editor';

import styles from './index.module.scss';
import * as images from '../../../assets/index';

type Props = {
  activeObject: CustomFabricObject;
};

export const ProporionalResizeButton: FC<Props> = ({ activeObject }) => {
  const [isProportionalResize, setIsProportionalResize] = useState(
    activeObject.proportionalResize,
  );

  useEffect(() => {
    setIsProportionalResize(activeObject.proportionalResize);
  }, [activeObject.objectId]);

  const handleProportionalResizeClick = () => {
    (activeObject as any).set(
      'proportionalResize',
      !activeObject.proportionalResize,
    );

    setIsProportionalResize(!isProportionalResize);
  };

  return (
    <button
      className={styles.proportional_resize_button}
      onClick={handleProportionalResizeClick}
    >
      <img
        src={isProportionalResize ? images.lockIcon : images.unlockIcon}
        className={styles.proportional_resize_button__icon}
        alt='Proportional Resize Button'
      />
    </button>
  );
};
