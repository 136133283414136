/* eslint-disable @typescript-eslint/indent */
import { useEffect, useMemo, useState } from 'react';
import { constants } from 'src/utils/constants';
import { findDropdownOption } from 'src/utils/helpers/findDropdownOptions';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';
import { ButtonClicksTrackingInfoDataURLType } from 'src/utils/types/CreatedDataURLStats';
import { MergeFieldsType } from 'src/utils/types/Mailchimp';
import { DropdownOption } from 'src/utils/types/general/types';

type Props = {
  newGroupOrLabel: string;
  newMergeTag: string;
  isOpen: boolean;
};

export const useUpdatedValues = ({
  newGroupOrLabel,
  newMergeTag,
  isOpen,
}: Props) => {
  const { title, collectAdditionalData, id, mergeTagType, ...restSurvey } =
    useDistinctSelector('survey');
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentCollectAdditionalData, setCurrentCollectAdditionalData] =
    useState(collectAdditionalData);
  const [currentSaveResultsOption, setCurrentSaveResultsOption] = useState(
    constants.surveySaveResultsOptions.find(
      option => option.labelValue === restSurvey.saveResultAs,
    ) || constants.surveySaveResultsOptions[0],
  );
  const [currentMergeTagType, setCurrentMergeTagType] = useState<
    DropdownOption<MergeFieldsType>
  >({
    labelValue: mergeTagType,
    label: findDropdownOption(
      constants.mergeFieldTypes,
      mergeTagType,
      constants.mergeFieldTypes[0],
    ).label,
  });

  useEffect(() => {
    setCurrentSaveResultsOption(
      constants.surveySaveResultsOptions.find(
        option => option.labelValue === restSurvey.saveResultAs,
      ) || constants.surveySaveResultsOptions[0],
    );
    setCurrentTitle(title);
    setCurrentCollectAdditionalData(collectAdditionalData);

    setCurrentMergeTagType(
      findDropdownOption<MergeFieldsType>(
        constants.mergeFieldTypes,
        mergeTagType,
        constants.mergeFieldTypes[0],
      ),
    );

    if (isOpen === false) {
      setCurrentSaveResultsOption(
        constants.surveySaveResultsOptions.find(
          option => option.labelValue === restSurvey.saveResultAs,
        ) || constants.surveySaveResultsOptions[0],
      );
      setCurrentTitle(title);
      setCurrentCollectAdditionalData(collectAdditionalData);

      setCurrentMergeTagType(
        findDropdownOption<MergeFieldsType>(
          constants.mergeFieldTypes,
          mergeTagType,
          constants.mergeFieldTypes[0],
        ),
      );
    }
  }, [id, isOpen]);

  const isDisabledConfirm = useMemo(() => {
    const saveAs =
      currentSaveResultsOption.labelValue as ButtonClicksTrackingInfoDataURLType;

    const isInitialSame =
      currentTitle === title &&
      currentCollectAdditionalData === collectAdditionalData &&
      saveAs === restSurvey.saveResultAs;
    const isMergeTagInvalid =
      saveAs === 'mergeTag' &&
      (!newMergeTag || newMergeTag.length <= 3 || newMergeTag.length > 10);
    const isGroupOrLabelInvalid = !newGroupOrLabel;

    if (collectAdditionalData !== currentCollectAdditionalData) {
      return false;
    }

    if (currentMergeTagType.labelValue !== mergeTagType) {
      return false;
    }

    if (isMergeTagInvalid || isGroupOrLabelInvalid) {
      return true;
    }

    if (saveAs !== restSurvey.saveResultAs) {
      return isGroupOrLabelInvalid;
    }

    if (isInitialSame) {
      if (restSurvey.saveResultAs === 'mergeTag') {
        return !(
          restSurvey.mergeTag !== newMergeTag ||
          restSurvey.groupOrLabel !== newGroupOrLabel
        );
      }

      if (restSurvey.saveResultAs === 'group') {
        return restSurvey.groupOrLabel === newGroupOrLabel;
      }
    }

    return false;
  }, [
    newGroupOrLabel,
    newMergeTag,
    currentSaveResultsOption.labelValue,
    currentTitle,
    currentCollectAdditionalData,
    currentMergeTagType.labelValue,
  ]);

  return {
    currentSaveResultsOption,
    currentTitle,
    currentCollectAdditionalData,
    currentMergeTagType,
    setCurrentSaveResultsOption,
    setCurrentTitle,
    setCurrentCollectAdditionalData,
    setCurrentMergeTagType,
    isDisabledConfirm,
  };
};
