import React, { CSSProperties, Dispatch, FC, SetStateAction } from 'react';
import { ColorPicker, FlexContainer } from '../common';

type Props = {
  color: string;
  onColorChange: Dispatch<SetStateAction<string>>;
  children: React.ReactNode;
};

export const SmartFormSuccessMessageTemplate: FC<Props> = ({
  color,
  onColorChange,
  children,
}) => {
  return (
    <FlexContainer>
      {children}

      <ColorPicker
        color={color}
        onColorChange={onColorChange}
        extraStyles={extraStyles.colorPicker}
        label='Text Color'
      />
    </FlexContainer>
  );
};

const extraStyles: Record<string, CSSProperties> = {
  colorPicker: {
    top: -340,
  },
  input: {
    maxWidth: 270,
  },
};
