import { ChangeEvent, useEffect, useState } from 'react';
import { smartLinkActions } from 'src/redux/slice/smartLink';
import { useAppDispatch } from 'src/redux/store';
import { getValidSaveAsData } from '../helpers/editor/getValidSaveAsData';
import { safeStringify } from '../helpers/safeStringify';
import { useDistinctSelector } from './useDistinctSelector';

const regex = /^[a-zA-Z0-9]+$/;

export const useSmartLinkData = () => {
  const initValues = useDistinctSelector('smartLink');

  const [groupOrLabel, setGroupOrLabel] = useState(
    initValues.groupOrLabel ?? '',
  );
  const [mergeTag, setMergeTag] = useState(initValues.mergeTag ?? '');
  const [saveAs, setSaveAs] = useState(initValues.saveResultAs);
  const [data, setData] = useState({
    value: initValues.value ?? '',
    destinationUrl: initValues.destinationUrl ?? '',
  });

  const handleMergeTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value && !regex.test(value)) {
      return;
    }

    setMergeTag(value);
  };

  const handleGroupOrLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setGroupOrLabel(value);
  };

  const handleDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setData(prev => ({ ...prev, [name]: value }));
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    const saveAsData = getValidSaveAsData(saveAs, groupOrLabel, mergeTag);

    const updatedData = {
      ...data,
      ...saveAsData,
    };

    if (safeStringify(updatedData) === safeStringify(initValues)) {
      return;
    }

    dispatch(smartLinkActions.update(updatedData));
  }, [data, groupOrLabel, mergeTag, saveAs]);

  return {
    groupOrLabel,
    mergeTag,
    data,
    saveAs,
    handleMergeTagChange,
    handleGroupOrLabelChange,
    handleDataChange,
    setSaveAs,
  };
};
