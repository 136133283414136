export const getUniqueObjects = <T>(array: T[], field: keyof T): T[] => {
  const uniqueObjectMap = {};

  const uniqueObjects = array.filter(obj => {
    if (!uniqueObjectMap[obj[field] as any]) {
      uniqueObjectMap[obj[field] as any] = true;

      return true;
    }

    return false;
  });

  return uniqueObjects;
};
