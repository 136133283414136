import React, { FC, useMemo } from 'react';
import { SmartLinkCreatingBlock } from '../SmartLinkCreatingBlock';
import { StepWithDescription } from '../StepWithDescription/StepWithDescription';
import { FlexContainer, Input, Button, Loader } from '../common';
import styles from './index.module.scss';
import * as images from '../../assets/index';
import { ExtraStyles } from 'src/utils/types/general/types';
import { useDistinctSelector } from 'src/utils/hooks/useDistinctSelector';

type Props = {
  data: { value: string; destinationUrl: string };
  isLoading: boolean;
  handleDataChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateSmartLink: () => void;
  handleUpdateSmartLink: () => void;
};

export const SmartLinkDestionationUrlBlock: FC<Props> = ({
  data,
  isLoading,
  handleCreateSmartLink,
  handleUpdateSmartLink,
  handleDataChange,
}) => {
  const { id, destinationUrl, groupOrLabel, mergeTag, value, saveResultAs } =
    useDistinctSelector('smartLink');

  const isCreatedSmartLink = Boolean(id);
  const buttonText = isCreatedSmartLink
    ? 'Update Smart Link'
    : 'Create Smart Link';

  const isDisabledButton = useMemo(() => {
    if (!destinationUrl) {
      return true;
    }

    switch (saveResultAs) {
      case 'mergeTag':
        return !groupOrLabel || !mergeTag || !value;
      case 'group':
        return !groupOrLabel || !value;
      case 'tag':
        return !value;
    }
  }, [destinationUrl, groupOrLabel, mergeTag, value, saveResultAs]);

  return (
    <SmartLinkCreatingBlock>
      <StepWithDescription title='Step 2.'>
        Enter Destination URL (must include {`"https://"`})
      </StepWithDescription>

      <FlexContainer>
        <Input
          value={data.destinationUrl}
          onChange={handleDataChange}
          placeholder='Enter web address'
          name='destinationUrl'
          icon={images.webIcon}
          dataCy='web-adress-in'
        />

        <Button
          type='outlined'
          text={isLoading ? <Loader size='sm' /> : buttonText}
          onClick={
            isCreatedSmartLink ? handleUpdateSmartLink : handleCreateSmartLink
          }
          className={styles.create_data_url_button}
          extraStyles={extraStyles.button}
          dataCy='create-dcu-btn'
          disable={isDisabledButton}
        />
      </FlexContainer>
    </SmartLinkCreatingBlock>
  );
};

const extraStyles: ExtraStyles = {
  button: {
    minWidth: 172,
  },
};
