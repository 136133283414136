/* eslint-disable @typescript-eslint/indent */
import { fabric } from 'fabric';
import { useState } from 'react';
import { Params, useParams } from 'react-router-dom';
import { surveyClient } from 'src/api';
import {
  getFromEditorLocalbase,
  saveToEditorLocalbase,
} from 'src/api/local/editor';
import { editorActions } from 'src/redux/slice/editor';
import { surveyActions } from 'src/redux/slice/survey';
import { useAppDispatch } from 'src/redux/store';
import { applyImageBorberRadius } from '../helpers/editor/applyImageBorberRadius';
import { centerCanvasView } from '../helpers/editor/centerCanvasView';
import { convertToObject } from '../helpers/editor/convertToObjects';
import { CustomCanvas } from '../helpers/editor/customClasses/CustomCanvas';
import { generateSurveyResultsCode } from '../helpers/editor/generateSurveyResultsCode';
import { getInnerCanvas } from '../helpers/editor/getInnerCanvas';
import { getSurveyResultsCanvases } from '../helpers/editor/getSurveyResultsCanvases';
import { getValidResultsCanvases } from '../helpers/editor/getValidResultsCanvases';
import { loadSurveyCanvasObjects } from '../helpers/editor/loadSurveyCanvasObjects';
import { loadSurveyResultsCanvasObjects } from '../helpers/editor/loadSurveyResultsCanvasObjects';
import { saveSurveyCanvas } from '../helpers/editor/saveSurveyCanvas';
import { saveSurveyResultsCanvases } from '../helpers/editor/saveSurveyResultsCanvases';
import { showErrorMessage } from '../helpers/errorHandler';
import { getUniqueObjects } from '../helpers/getUniqueObjects';
import { safeStringify } from '../helpers/safeStringify';
import {
  checkForMergeTags,
  checkForResultMergeTags,
  getDynamicTextOptions,
} from '../helpers/saveDynamicTexts';
import {
  ClickableAreaType,
  CustomCanvasType,
  CustomFabricObject,
  CustomImageType,
  CustomTextBoxType,
  InnerCanvasType,
  SurveyResultType,
} from '../types/Editor';
import {
  Chart,
  ChartColor,
  ChartType,
  ExtendedSurveyDynamicObjectCreationProps,
  SurveyCanvas,
  SurveyDynamicObjectCreationProps,
  SurveyDynamicObjectTypeEnum,
  SurveyDynamicObjectVariantEnum,
  SurveyOption,
  ReduxSurvey,
  SaveSurveyOptions,
} from '../types/Survey';
import { useDistinctSelector } from './useDistinctSelector';
import { toast } from 'react-toastify';
import { generateNewDestionationUrl } from '../helpers/editor/generateNewDestionationUrl';

type CanvasObject = CustomFabricObject | InnerCanvasType;

export const useSaveSurvey = (survey: ReduxSurvey) => {
  const [isLoading, setIsLoading] = useState(false);

  const { audienceId = '', accountId = '' } = useParams<Params>();

  const { currentCanvasId, keepResultsSame } = useDistinctSelector('editor');
  const dispatch = useAppDispatch();

  const saveCurrentCanvasToLS = async (
    canvas: CustomCanvasType,
    callback: () => void,
  ) => {
    const innerCanvas = getInnerCanvas(canvas);

    if (innerCanvas.canvasType === 'survey') {
      await new Promise(resolve => {
        saveSurveyCanvas(canvas, false, () => resolve(true));
      });

      const resultsCanvases = await getSurveyResultsCanvases();
      const fallbackCanvas = resultsCanvases.find(
        canv => canv.canvasId === 'fallback',
      );

      if (!fallbackCanvas?.objects?.length) {
        const customCanvas = new CustomCanvas({
          width: 600,
          height: 400,
          fill: 'rgba(255, 255, 255, 1)',
          canvasType: 'results',
        });
        const canvasObject = convertToObject(customCanvas);

        await new Promise(resolve => {
          saveToEditorLocalbase(
            'resultsCanvases',
            {
              canvases: [{ canvasId: 'fallback', objects: [canvasObject] }],
            },
            () => {
              resolve(true);
            },
          );
        });
      }

      callback?.();
    } else {
      saveSurveyResultsCanvases(canvas, currentCanvasId, false, () => {
        callback?.();
      });
    }
  };

  const removeDynamicObjects = (
    canvas: CustomCanvasType,
    innerCanvasType: 'survey' | 'results',
  ) => {
    const canvasObjects = canvas.getObjects();

    switch (innerCanvasType) {
      case 'survey':
        canvasObjects.forEach(obj => {
          if (obj.type === 'clickableArea') {
            canvas.remove(obj);
          }
        });

        break;

      case 'results':
        canvasObjects.forEach(obj => {
          if (obj.type === 'surveyResult') {
            canvas.remove(obj);
          }
        });

        break;
    }

    canvasObjects.forEach(obj => {
      if (obj.type !== 'text') {
        return;
      }

      const isContainDynamicText =
        innerCanvasType === 'survey'
          ? checkForMergeTags(obj.text)
          : checkForResultMergeTags(obj.text);

      if (isContainDynamicText) {
        canvas.remove(obj);
      }
    });
  };

  const prepareCanvasToSave = (
    canvasCopy: CustomCanvasType,
    innerCanvas: InnerCanvasType,
  ) => {
    removeDynamicObjects(canvasCopy, innerCanvas.canvasType);

    const imageCanvas = new fabric.Canvas(null, {
      width: innerCanvas.width,
      height: innerCanvas.height,
    });

    centerCanvasView(canvasCopy);

    canvasCopy.getObjects().forEach(object => {
      if (object.type !== 'canvas') {
        (object as any).set({
          left: (object.left || 1) - (innerCanvas.left || 1),
          top: (object.top || 1) - (innerCanvas.top || 1),
        });

        imageCanvas.add(object);
      }
    });

    imageCanvas.renderAll();

    return imageCanvas;
  };

  const checkIfChangedClickableAreas = (
    currentClickableAreas: ClickableAreaType[],
    savedClickableAreas: ClickableAreaType[],
  ) => {
    const updatedClickableAreas: ClickableAreaType[] = [];
    const deletedClickableAreas: string[] = [];

    currentClickableAreas.forEach(currentArea => {
      const savedArea = savedClickableAreas.find(
        area => area.objectId === currentArea.objectId,
      );

      if (!savedArea) {
        updatedClickableAreas.push(currentArea);
      } else {
        const hasChanged =
          currentArea.width !== savedArea.width ||
          currentArea.height !== savedArea.height ||
          currentArea.left !== savedArea.left ||
          currentArea.top !== savedArea.top ||
          currentArea.angle !== savedArea.angle ||
          currentArea.destinationUrl !== savedArea.destinationUrl ||
          currentArea.value !== savedArea.value ||
          currentArea.enablePrefilledFields !==
            savedArea.enablePrefilledFields ||
          safeStringify(currentArea.prefilledFields) !==
            safeStringify(savedArea.prefilledFields);

        if (hasChanged) {
          updatedClickableAreas.push(currentArea);
        }
      }
    });

    savedClickableAreas.forEach(savedArea => {
      const currentArea = currentClickableAreas.find(
        area => area.objectId === savedArea.objectId,
      );

      if (!currentArea) {
        deletedClickableAreas.push(savedArea.objectId);
      }
    });

    return { updatedClickableAreas, deletedClickableAreas };
  };

  const saveSurveyOptions = async (
    canvas: CustomCanvasType,
    innerCanvas: InnerCanvasType,
  ): Promise<SurveyOption[]> => {
    const objects: CanvasObject[] = survey.initSurveyObjectsJSON;

    const currentClickableAreas = canvas.getObjectsByType('clickableArea');
    const savedClickableAreas = objects.filter(
      obj => obj.type === 'clickableArea',
    ) as ClickableAreaType[];

    const { updatedClickableAreas, deletedClickableAreas } =
      checkIfChangedClickableAreas(currentClickableAreas, savedClickableAreas);

    await Promise.all([
      ...updatedClickableAreas.map(async clickableArea => {
        const destinationUrl = clickableArea.enablePrefilledFields
          ? generateNewDestionationUrl(
              clickableArea.destinationUrl,
              clickableArea.prefilledFields,
            )
          : clickableArea.destinationUrl;

        const { data: surveyOption } = await surveyClient.saveSurveyOption({
          surveyId: survey.id,
          clickableAreaId: clickableArea.objectId,
          destinationUrl,
          value: clickableArea.value,
          width: clickableArea.width * clickableArea.scaleX,
          height: clickableArea.height * clickableArea.scaleY,
          radius: 0,
          angle: clickableArea.angle,
          coords: {
            x: clickableArea.left - innerCanvas.left,
            y: clickableArea.top - innerCanvas.top,
          },
        });

        dispatch(surveyActions.updateSurveyOptions(surveyOption));
      }),
      surveyClient.deleteSurveyOptions(survey.id, deletedClickableAreas),
    ]);

    return surveyClient.getSurveyOptions(survey.id).then(res => res.data);
  };

  const getSurveyCanvasCopy = async (canvas: CustomCanvasType) => {
    const canvasCopy = new fabric.Canvas(null, {
      width: canvas.width,
      height: canvas.height,
    }) as CustomCanvasType;

    canvasCopy.getObjectsByType = canvas.getObjectsByType;

    await loadSurveyCanvasObjects(canvasCopy);

    return { canvasCopy, innerCanvas: getInnerCanvas(canvasCopy) };
  };

  const getSurveyAnswers = (surveyCanvasObjects: CustomFabricObject[]) => {
    const clickableAreas = surveyCanvasObjects.filter(
      obj => obj.type === 'clickableArea',
    ) as ClickableAreaType[];
    const answerVariants = clickableAreas.map(area => area.value);

    return answerVariants;
  };

  const applyBorderRadiusForImage = (surveyCanvas: SurveyCanvas) => {
    const { objects } = surveyCanvas;

    return objects.map(temp =>
      temp?.type === 'image'
        ? {
            ...temp,
            clipPath: applyImageBorberRadius(temp, temp?.radius),
          }
        : temp,
    );
  };

  const getOverlappingObjects = (canvas: CustomCanvasType) => {
    const surveyResultsObjects = canvas.getObjectsByType('surveyResult');
    const nonSurveyResultsObjects = canvas
      .getObjects()
      .filter(obj => obj.type === 'image' || obj.type === 'text') as (
      | CustomTextBoxType
      | CustomImageType
    )[];

    const intersectedObjects: ExtendedSurveyDynamicObjectCreationProps[] = [];

    surveyResultsObjects.forEach(surveyResult => {
      nonSurveyResultsObjects.forEach(obj => {
        if (
          surveyResult.intersectsWithObject(obj) &&
          surveyResult.order < obj.order
        ) {
          if (obj.type === 'image') {
            intersectedObjects.push({
              variant: SurveyDynamicObjectVariantEnum.IMAGE,
              width: obj.width * obj.scaleX * 3,
              height: obj.height * obj.scaleY * 3,
              cropX: obj.cropX * obj.scaleX,
              cropY: obj.cropY * obj.scaleY,
              cropWidth: obj.cropWidth * obj.scaleX,
              cropHeight: obj.cropHeight * obj.scaleY,
              angle: obj.angle,
              borderRadius: obj.borderRadius * 3,
              coords: {
                x: obj.left * 3,
                y: obj.top * 3,
              },
              imageUrl: obj.src,
              objectId: obj.objectId,
              order: obj.order,
            });
          } else {
            intersectedObjects.push({
              ...getDynamicTextOptions(obj, getInnerCanvas(canvas)),
              objectId: obj.objectId,
              order: obj.order,
            });
          }

          canvas.remove(obj);
        }
      });
    });

    return intersectedObjects;
  };

  const getDynamicTexts = (canvas: CustomCanvasType) => {
    const innerCanvas = getInnerCanvas(canvas);
    const canvasObjects = canvas.getObjects();

    const isSurveyCanvas = innerCanvas.canvasType === 'survey';
    const dynamicTexts: ExtendedSurveyDynamicObjectCreationProps[] = [];

    for (const object of canvasObjects) {
      if (object.type === 'text') {
        const isIncludingMergeTags = checkForMergeTags(object.text);
        const isIncludeDynamicText = isSurveyCanvas
          ? isIncludingMergeTags
          : checkForResultMergeTags(object.text) || isIncludingMergeTags;

        if (isIncludeDynamicText) {
          dynamicTexts.push({
            ...getDynamicTextOptions(object, innerCanvas),
            objectId: object.objectId,
            order: object.order,
          });

          canvas.remove(object);
        }
      }
    }

    return dynamicTexts;
  };

  const saveSurveyCanvasOnServer = async (canvas: CustomCanvasType) => {
    const { canvasCopy, innerCanvas } = await getSurveyCanvasCopy(canvas);
    const surveyCanvas = await getFromEditorLocalbase('surveyCanvas');
    const options = await saveSurveyOptions(canvasCopy, innerCanvas);

    await saveSurveyDynamicObjects(
      canvasCopy,
      null,
      SurveyDynamicObjectTypeEnum.POLL,
    );

    const imageCanvas = prepareCanvasToSave(canvasCopy, innerCanvas);

    await new Promise((resolve, reject) => {
      imageCanvas.setBackgroundColor('rgba(0, 0, 0, 0)', async () => {
        const canvasImageURL = imageCanvas.toDataURL({
          format: 'png',
          quality: 1,
          multiplier: 3,
        });

        try {
          const resultsCanvases = await getSurveyResultsCanvases();
          const answerVariants = getSurveyAnswers(surveyCanvas.objects);

          const { data: updatedSurvey } = await surveyClient.saveSurvey({
            image: canvasImageURL,
            width: imageCanvas.width ?? 600,
            height: imageCanvas.height ?? 400,
            fileName: survey.title,
            surveyId: survey.id,
            audienceId,
            accountId,
            resultCanvasesObjects: safeStringify(resultsCanvases),
            surveyCanvasObjects: safeStringify(surveyCanvas.objects),
            answerVariants,
            isKeepResultsSame: keepResultsSame,
            surveyBackgroundColor:
              (innerCanvas.fill as string) || 'rgba(255, 255, 255, 1)',
          });

          const surveyCanvasObjects = applyBorderRadiusForImage(surveyCanvas);

          dispatch(
            surveyActions.update({
              ...survey,
              ...updatedSurvey,
              initSurveyObjectsJSON: surveyCanvasObjects,
              initResultsObjectsJSON: resultsCanvases,
            }),
          );
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    });

    return options;
  };

  const generateSurveyResulChartsColors = (
    obj: SurveyResultType,
    options: SurveyOption[],
  ): ChartColor[] => {
    return obj.clickableAreas.map((area, index) => {
      const optionId = options?.find(option => option.value === area.value)?.id;

      if (!optionId) {
        throw new Error('SurveyOption id was not found!');
      }

      return {
        color: area.fill as string,
        defaultColor: area.defaultColor,
        order: index,
        optionId,
      };
    });
  };

  const generateSurveyResultCharts = (
    canvas: CustomCanvasType,
    canvasRect: InnerCanvasType,
    options: SurveyOption[],
  ): Chart[] => {
    const surveyResults = canvas.getObjectsByType('surveyResult');

    return surveyResults.map((obj): Chart => {
      const [type, variant] = obj.variant.split(':');
      const { width, height } = obj.getChartSize();
      const left = obj.left - canvasRect.left;
      const top = obj.top - canvasRect.top;
      const gap = variant === 'single' ? 20 : 0;

      return {
        type: type as ChartType,
        coords: { x: left * 3, y: top * 3 },
        gap: gap * 3,
        pieDiameter: obj.pieSize * 3,
        holeDiameter: obj.holeRadius * 3,
        width: width * 3,
        height: height * 3,
        borderRadius: obj?.borderRadius * 3,
        order: obj.order,
        angle: Number(obj.angle),
        colors: generateSurveyResulChartsColors(obj, options),
      };
    });
  };

  const saveSurveyDynamicObjects = async (
    canvas: CustomCanvasType,
    optionId: number | null = null,
    type: SurveyDynamicObjectTypeEnum,
  ) => {
    const dynamicTexts = getDynamicTexts(canvas);
    const overlapingObjects = getOverlappingObjects(canvas);

    const uniqueObjects = getUniqueObjects(
      [...overlapingObjects, ...dynamicTexts],
      'objectId',
    );
    const validObjects = uniqueObjects.map(obj => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { objectId, order, ...rest } = obj;

      return {
        order: order,
        optionId,
        surveyId: survey.id,
        type,
        variant: rest.variant,
        object: rest,
      } as SurveyDynamicObjectCreationProps;
    });

    await surveyClient.saveDynamicObjects(validObjects);
  };

  const saveSurveyResultsCanvasesOnServer = async (
    canvas: CustomCanvasType,
    SurveyOptions: SurveyOption[],
  ) => {
    let validResultsCanvases = await getValidResultsCanvases();

    await new Promise(resolve => {
      saveToEditorLocalbase(
        'resultsCanvases',
        { canvases: validResultsCanvases },
        () => resolve(true),
      );
    });

    if (keepResultsSame) {
      validResultsCanvases = validResultsCanvases.filter(
        temp => temp.canvasId === 'fallback',
      );
    }

    await Promise.all(
      validResultsCanvases.map(async resultCanvas => {
        const canvasCopy = new fabric.Canvas(null, {
          width: canvas.width,
          height: canvas.height,
        }) as CustomCanvasType;

        canvasCopy.getObjectsByType = canvas.getObjectsByType;

        const surveyOption = SurveyOptions?.find(
          option => option.clickableAreaId === resultCanvas.canvasId,
        );

        await loadSurveyResultsCanvasObjects(canvasCopy, resultCanvas.canvasId);

        await saveSurveyDynamicObjects(
          canvasCopy,
          surveyOption?.id,
          SurveyDynamicObjectTypeEnum.RESULT,
        );

        const innerCanvas = getInnerCanvas(canvasCopy);
        const charts = generateSurveyResultCharts(
          canvasCopy,
          innerCanvas,
          SurveyOptions,
        );
        const imageCanvas = prepareCanvasToSave(canvasCopy, innerCanvas);

        await new Promise((resolve, reject) => {
          imageCanvas.setBackgroundColor(
            innerCanvas.fill as string,
            async () => {
              const canvasImageURL = imageCanvas.toDataURL({
                format: 'png',
                quality: 1,
                multiplier: 3,
              });

              try {
                await surveyClient.saveSurveyResult({
                  imageUrl: canvasImageURL,
                  width: imageCanvas.width ?? 600,
                  height: imageCanvas.height ?? 400,
                  fileName: `${survey.id}-${resultCanvas.canvasId}`,
                  surveyId: survey.id,
                  optionId: surveyOption?.id ?? null,
                  isFallback: resultCanvas.canvasId === 'fallback',
                  charts,
                });

                resolve(true);
              } catch (error) {
                reject(error);
              }
            },
          );
        });
      }),
    );
  };

  const saveSurvey = async (
    canvas: CustomCanvasType,
    options?: Partial<SaveSurveyOptions>,
  ) => {
    dispatch(editorActions.update({ isLoadingObjects: true }));
    setIsLoading(true);

    try {
      canvas.discardActiveObject();

      await new Promise(async resolve => {
        await saveCurrentCanvasToLS(canvas, async () => {
          resolve(true);
        });
      });

      await surveyClient.removeDynamicObjects(survey.id);

      const SurveyOptions = await saveSurveyCanvasOnServer(canvas);

      await saveSurveyResultsCanvasesOnServer(canvas, SurveyOptions);

      if (!options?.disableSuccessMessage) {
        toast.success('Changes saved!');
      }

      options?.onCloseModal?.();

      return true;
    } catch (error) {
      showErrorMessage(error);

      return false;
    } finally {
      dispatch(editorActions.update({ isLoadingObjects: false }));
      setIsLoading(false);
    }
  };

  const generatedSurveyResultsCode = generateSurveyResultsCode(survey.id);

  if (generatedSurveyResultsCode !== survey.embedSurveyResultsCode) {
    dispatch(
      surveyActions.update({
        ...survey,
        embedSurveyResultsCode: generatedSurveyResultsCode,
      }),
    );
  }

  return {
    saveSurvey,
    isLoading,
  };
};
