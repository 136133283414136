export const useExpandedHex = () => {
  const expandHexColor = (hex: string) =>
    hex
      .split('')
      .map(char => char + char)
      .join('');

  const convertToExapndedHexColors = (hexs: string[]) => {
    return hexs.map(hex => (hex.length > 3 ? hex : expandHexColor(hex)));
  };

  return { expandHexColor, convertToExapndedHexColors };
};
