import React from 'react';
import ContentLoader from 'react-content-loader';

export const HeaderLoader = () => (
  <ContentLoader
    speed={1.5}
    width='100%'
    height={70}
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    style={extraStyles.loader}
  >
    <rect x='80' y='26' rx='3' ry='3' width='300' height='16' />
    <circle cx='40' cy='35' r='22' />
    <circle cx='1480' cy='35' r='22' />
  </ContentLoader>
);

const extraStyles = {
  loader: { display: 'flex', justifyContent: 'space-between' },
};
